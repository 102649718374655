import React, { Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks'
import * as Price from '@matchstik/utils/.dist/price';
import { Colors } from '../styles/Colors';
import Loader, { LoaderSizes } from '../elements/Loader';
import ScrollTable, {
  ScrollTableBody,
  ScrollTableBodyCell,
  ScrollTableBodyRow,
  ScrollTableHeader,
  ScrollTableHeaderCell,
  ScrollTableNoContent,
  ScrollTableSpace,
  ScrollTableRow
} from './ScrollTable';
import IMembership from "@matchstik/models/.dist/interfaces/IMembership";
import { ModalTypes } from './modal/Modal';
import { useDispatch } from "react-redux";
import * as AppActions from "../redux/actions/app.actions";
import Icon, { Icons } from '../elements/Icon'
import LIST_MEMBERSHIPS from '../graphql/queries/listMemberships.query';
import ReactTooltip from "react-tooltip";

const Container = styled.div`
  height: fit-content;
  max-height: 100%;
  max-width: 1400px;
  padding: 0 0px 30px;
`;

const RefundText = styled.div`
  color: ${Colors.Red};
  font-size: 1.4rem;
  font-weight: 700;
`;

const NoContentHead = styled.span`
  font-weight: 600;
  font-size: 1.8rem;
  color: ${Colors.Grey1};
  padding: 10px 0px;
  display:block;
  text-align: center;
`;

const NoContentBody = styled.td`
  font-weight: 500;
  font-size: 1.8rem;
  color: ${Colors.Grey3};
  padding: 10px 25px;
  line-height: 1.42857143;
  vertical-align: top;
  display: table-cell;
  text-align: center;
  border-top: 2px solid #F2F2F2;
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.White};
  border-radius: 0px 0px 10px 10px;
  padding: 10px 0px;
`;

// const TooltipStyle = styled.span ({
//   width:"10px"
// })

type TableHeaderProps = {
  memberships: IMembership[];
};


const TableHeader: React.FC<TableHeaderProps> = ({ memberships }) => (
  <ScrollTableHeader>
     <ScrollTableRow>
        <ScrollTableHeaderCell >Membership ID</ScrollTableHeaderCell>
        <ScrollTableHeaderCell >Monthly Donation</ScrollTableHeaderCell>
        <ScrollTableHeaderCell >Total Donation Value</ScrollTableHeaderCell>
        <ScrollTableHeaderCell >Contributor</ScrollTableHeaderCell>
        <ScrollTableHeaderCell >Campaign</ScrollTableHeaderCell>
        <ScrollTableHeaderCell >Next Donation At</ScrollTableHeaderCell>
        <ScrollTableHeaderCell >Actions</ScrollTableHeaderCell>
    </ScrollTableRow>
  </ScrollTableHeader>
);

type TableRowsProps = {
  memberships: IMembership[];
  searchQuery?: string;
};

const TableRows: React.FC<TableRowsProps> = ({ memberships, searchQuery }) => {
  /* Actions */
  const dispatch = useDispatch();

  const viewMembershipDetails = ({membershipId,stripeCustomerId,paymentStatus}:{membershipId:string,stripeCustomerId:string,paymentStatus:string}) => {
    dispatch(AppActions.setSelectedMembership({membershipId,stripeCustomerId,paymentStatus}));
    dispatch(AppActions.pushModal(ModalTypes.MembershipDetails));
  };

  const viewUpdatePaymentModal = ({membershipId,stripeCustomerId,paymentStatus}:{membershipId:string,stripeCustomerId:string,paymentStatus:string}) => {
      dispatch(AppActions.setSelectedMembership({membershipId,stripeCustomerId,paymentStatus}));
      dispatch(AppActions.pushModal(ModalTypes.UpdatePayment))
  }

  // Filter membership by searchQuery
  // This is not optimal but a quick solution while there is no pagination.
  let filteredMemberships = [...memberships];
  if (searchQuery) {
    filteredMemberships = filteredMemberships.filter((membership) => {
      return Boolean(JSON.stringify(membership).toLowerCase().includes(searchQuery.toLowerCase()))
    });
  }

  return (
    <Fragment>
      {filteredMemberships.map((membership: IMembership, index: number) => {
        const {
          _id,
          amount,
          transactionDetails,
          nextDonationAt,
          anonymous,
          name,
          totalDonationValue,
          active,
          status,
          campaignName,
          invalidPaymentMethod

        } = membership;

        const dateAndTime = moment(parseInt(nextDonationAt as any)).format(
          "MMM DD, YYYY h:mma"
        );

        const onClick = () => {
          viewMembershipDetails({membershipId:membership._id as any,stripeCustomerId:membership?.stripeCustomerId,paymentStatus:membership?.status as string});
        };

        const onUpdatePayment = () => {
          viewUpdatePaymentModal({membershipId:membership._id as any,stripeCustomerId:membership?.stripeCustomerId, paymentStatus:membership?.status as string})
        }

        return (
          <ScrollTableBodyRow
            key={index}
            // height="40px"
          // onClick={(e) => onClick()}
          >
            <ScrollTableBodyCell data-label="Membership ID" onClick={(e) => onClick()} >{_id}</ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Monthly Donation" onClick={(e) => onClick()} >
              ${Price.output(transactionDetails?.clientAmount || amount, true)}
            </ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Total Donation Value" onClick={(e) => onClick()} >
              ${Price.output(totalDonationValue, true)}
            </ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Contributor" onClick={(e) => onClick()}>{anonymous ? 'Anonymous Donor' : name}</ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Campaign" onClick={(e) => onClick()}> {campaignName }</ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Next Donation At" onClick={(e) => onClick()}>
              {active ? dateAndTime : <RefundText>Cancelled</RefundText>}
            </ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Actions">
            {active && <p style={{ width: 'min-content', margin: '0', display: 'inline-block' }} data-tip='Update Payment'>
                <Icon icon={Icons.Edit} color={Colors.Pink} size={20} onClick={onUpdatePayment}/>
              </p>}
              {status === "Failed" && <p style={{ width: 'min-content', margin: '0' , display: 'inline-block' }} 
              data-tip={!invalidPaymentMethod ? 'Your last donation payment was unsuccessful, please process your payment' : 'The last processing of membership encountered a problem. Update the payment method to get back on track.'}>
                <Icon icon={Icons.Warning} color={Colors.Yellow} size={20} />
              </p>}
            </ScrollTableBodyCell>
          </ScrollTableBodyRow>
        );
      })}
        <ReactTooltip
          place="top"
          type="dark"
          effect="solid"
          clickable={false}
        />
    </Fragment>
  );
};

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  box-shadow: 0px 4px 16px rgba(17, 0, 40, 0.05);
  border-radius: 10px;
  border: 1px solid ${Colors.Grey6};
  padding: 0 25px;
  margin-bottom: 30px;
  justify-content: space-between;
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SearchInput = styled.input`
  border: 0px;
  outline: 0px;
  font-size: 1.4rem;
  color: ${Colors.Grey2};
  width: 100%;

  ::placeholder {
    color: ${Colors.Grey3};
  }
`;


type MembershipsTableProps = {};

const MembershipsTable: React.FC<MembershipsTableProps> = () => {
  /** State **/
  const scrollContainer = React.useRef<any>(null);
  const [searchQuery, setSearchQuery] = React.useState('');

  /** GraphQL **/
  const { data, } = useQuery(LIST_MEMBERSHIPS);

  const memberships = data ? data.memberships : [];

  /** Render **/
  if (memberships?.length <= 0) {
    return (
      <Container>
        <ScrollTable>
          <TableHeader memberships={memberships} />
          <ScrollTableBody>
            <ScrollTableNoContent>
              <NoContentBody colSpan={6}>
                <NoContentHead>No memberships yet</NoContentHead>
                Memberships will show up here when they are created
              </NoContentBody>
            </ScrollTableNoContent>
          </ScrollTableBody>
        </ScrollTable>
      </Container>
    );
  }

  return (
    <Fragment>
      {memberships?.length > 0 ? (
        <Container>
          <SearchContainer>
            <Search>
              <Icon
                icon={Icons.SearchRegular}
                color={Colors.Grey3}
                size={14}
                margin="0px 7px 0px 0"
              />
              <SearchInput
                placeholder="Search Memberships..."
                value={searchQuery}
                type="text"
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  setSearchQuery(e.currentTarget.value);
                }}
              />
            </Search>
          </SearchContainer>
          <ScrollTable>
            {(loading: boolean) => {
              return (
                <Fragment>
                  <TableHeader memberships={memberships} />
                  <ScrollTableBody ref={scrollContainer}>
                    <TableRows memberships={memberships} searchQuery={searchQuery} />
                    {loading && (
                      <LoaderContainer>
                        <Loader size={LoaderSizes.Large} color={Colors.Pink} />
                      </LoaderContainer>
                    )}
                  </ScrollTableBody>
                </Fragment>
              );
            }}
          </ScrollTable>
        </Container>
      ) : (
        <div />
      )}
    </Fragment>
  );
}

export default MembershipsTable;