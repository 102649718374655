import React, { useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../styles/Colors";
import { Title } from "../components/DashboardLayout";
import frameSrc from "./../assets/images/frameimage.jpg";
import { useHistory } from "react-router-dom";
import CampaignOverview from "../components/CampaignOverview";
import CampaignMatchesList from "../components/CampaignMatchesList";

export const Layout = styled.div`
   display:flex;
   width: 100%;
   margin: 0 0 30px 0;
   padding-bottom: 25px;
   border-bottom: 1px solid ${Colors.Grey5};
   @media (max-width:640px){
    display:block;
   }
    `;
export const LayoutImage = styled.div<any>`
  width:150px;
  margin-right:40px;
  height: 117px;
  background-image:url(${props => props.posterUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius:10px;
  @media (max-width:640px){
    margin:0 0 20px 0;
   }
   @media (max-width:479px){
    width:100%;
    margin-right:0px;
    height:175px;
   }
    `;
export const LayoutDesc = styled.div`
   
    `;
export const ListView = styled.div`
display:flex;
@media (max-width:640px){
    display:block; 
}
    `;
export const ListItem = styled.div<{ active: boolean }>`
font-weight: ${[(props) => (props.active ? "bold" : "normal")]};
font-size: 18px;
line-height: 22px;
margin:0 25px 0 0;
color: ${[(props) => (props.active ? Colors.Pink : Colors.Grey4)]};;
transition:0.5s;
cursor:pointer;
&:hover{
    color: ${Colors.Pink};
}
@media (max-width:1199px){
    font-size: 14px;
    line-height: 20px;
    margin: 0 20px 0 0;
}
@media (max-width:640px){
    display: inline-block;
    width: 50%;
    margin: 0;
    padding: 7px 0;
}
    `;
export const CampaignsLayout = styled.div`
    display:flex;
    justify-content:space-between;
    @media (max-width:767px){
    display:block;
    }
    `;
export const LeftLayout = styled.div`
    width:65%;
    @media (max-width:1199px){
        width:60%;   
    }
    @media (max-width:767px){
        width:100%;
        margin:0 0 30px 0;
    }
    `;
export const CardLayout = styled.div`
    background: ${Colors.White};
    border: 1px solid ${Colors.Grey6};
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin: 0 0 50px 0;
    &:last-child{
        margin:0; 
    }
`;
export const CardHeader = styled.div`
font-size: 14px;
line-height: 21px;
padding: 14px 165px 14px 15px;
color: ${Colors.Grey1};
border-bottom: 1px solid ${Colors.Grey6};
display:flex;
font-weight:400;
position:relative;
&.bold{
    font-weight:500;
}
`;
export const CardTabs = styled.div`
    position: absolute;
    right: 15px;
    display: flex;
`;
export const TabItem = styled.div`
  padding:5px 10px;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  &.active{
    background: rgba(255, 20, 99, 0.05);
    border-radius: 10px;
    color:${Colors.Pink};
  }
`;
export const CardBody = styled.div`
`;
export const MiddleSection = styled.div`
    width:100%;
    display:flex;
    justify-content:space-between;
    margin: 25px 50px;
    width: calc(100% - 100px);
    align-items: center;
    @media (max-width:1199px){
        margin: 25px 15px;
        width: calc(100% - 30px);   
    }
    `;
export const Transactions = styled.div`
    width:30%;
    border-right: 1px solid ${Colors.Grey5}; 
    `;
export const Donors = styled.div`
    width:30%;
    border-right: 1px solid ${Colors.Grey5}; 
    `;
export const AverageDonation = styled.div`
    width:30%;
    `;
export const CardTitle = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${Colors.Black};
    text-transform:uppercase;
    &.Grey3{
        color: ${Colors.Grey3};    
    }
    @media (max-width:1199px)
    {
        font-size:11px;
        line-height:18px;  
    }
    @media (max-width:576px)
    {
        font-size:9px;
        line-height: normal;    
    }
    `;
export const Amount = styled.div`
        font-weight: 600;
        font-size:24px;
        line-height:36px;
        color: ${Colors.Pink};
        &.blackColor{
            color: ${Colors.Black};   
        }
        @media (max-width:1199px)
        {
            font-size:14px;
            line-height:22px;  
        }
        @media (max-width:576px)
        {
            font-size:14px;
            line-height:normal;  
        }
    `;
export const RightLayout = styled.div`
    width:30%;
    @media (max-width:1199px){
        width:35%;   
    }
    @media (max-width:767px){
        width:100%;
    }
    `;

export const ButtonsLayout = styled.div`
     margin:0 0 40px 0;
     `;
export const ButtonsInner = styled.div`
     margin: 0 0 20px 0;
     background: rgba(242,242,242,0.8);
     border-radius: 10px;
     font-weight: 500;
     font-size: 16px;
     line-height: 24px;
     padding: 17px 35px;
     display: flex;
     color: ${Colors.Grey2};
     cursor:pointer;
     @media (max-width:1199px){
         padding: 13px 15px;
         margin: 0 0 15px 0;
         font-size: 14px;
     }
     `;

export const MatchLayout = styled.div`
     background:${Colors.White};;
     border: 1px solid ${Colors.Pink};;
     box-sizing: border-box;
     border-radius: 10px;
     padding:23px;
     margin: 0 0 30px 0;
     `;
export const MatchHeader = styled.div`
  padding: 0px 20px 18px 20px;
  font-size: 14px;
  line-height: 21px;
  color: ${Colors.Grey1};
  display: flex;
  align-items: center;
  `;
export const NavIcon = styled.div`
margin:0 20px 0 0;
`;
export const Logo = styled.img`
position: relative;
width:22px;
`;
export const MatchListing = styled.div`

     `;
export const MatchListItem = styled.div`
border-top: 1px solid ${Colors.Grey5};;
padding: 20px 20px 14px 20px;
`;

enum CampaignStateEnum {
    Overview = "Overview",
    Matches = "Matches",
}



interface CampaignsPageProps { }

const CampaignsPage: React.FC<CampaignsPageProps> = () => {

    /* State */
    const [pageState, setPageState] = React.useState(CampaignStateEnum.Overview);


    /* Action */
    const onCampaignOverview = (() => {
        setPageState(CampaignStateEnum.Overview);
        history.push({
            pathname: 'overview',
            state: { campaignId: campaignId,posterUrl: posterUrl, campaignName: campaignName, editCampaign:true }
        });
    });

    const onCampaignMatches = (() => {
        setPageState(CampaignStateEnum.Matches)
        history.push({
            pathname: 'matches',
            state: { campaignId: campaignId, posterUrl: posterUrl, campaignName: campaignName, editCampaign:true }
        });
    });

    
    const history = useHistory();
    const historyState: any = history.location.state
    const campaignId = historyState?.campaignId
    const campaignName = historyState?.campaignName
    const posterUrl = historyState?.posterUrl

    useEffect(() => {
        const pathName = history.location.pathname
        if (!campaignId && (pathName === "/dashboard/campaigns/overview" || pathName === "/dashboard/campaigns/matches")) {
            history.push({ pathname: `/dashboard/campaigns` });
        }
        else if (campaignId && pathName === "/dashboard/campaigns/matches") {
            setPageState(CampaignStateEnum.Matches);
        }
    }, []);


    return (
        <>
            <Layout>
                <LayoutImage posterUrl={posterUrl ? posterUrl : frameSrc}>
                </LayoutImage>
                <LayoutDesc>
                    <Title>{campaignName}</Title>
                    <ListView>
                        <ListItem
                            onClick={() => onCampaignOverview()}
                            active={pageState === CampaignStateEnum.Overview}
                        >
                            {CampaignStateEnum.Overview}
                        </ListItem>
                        <ListItem
                            onClick={() => onCampaignMatches()}
                            active={pageState === CampaignStateEnum.Matches}
                        >
                            {CampaignStateEnum.Matches}
                        </ListItem>
                        {/* <ListItem className="active">Overview</ListItem>
                        <ListItem >Matches</ListItem> */}
                        {/* <ListItem>Transactions</ListItem>
                        <ListItem>Donors</ListItem>
                        <ListItem>Updates</ListItem>
                        <ListItem>Analytics</ListItem> */}
                    </ListView>
                </LayoutDesc>
            </Layout>
            {(() => {
        // if (loading)
        //   return <LoaderDonationPage> <Loader size={LoaderSizes.Small} />
        //   </LoaderDonationPage>
        switch (pageState) {
          case CampaignStateEnum.Overview:
            return <CampaignOverview  />;
          case CampaignStateEnum.Matches:
            return <CampaignMatchesList/>;
          default:
            return <CampaignOverview  />;
        }
      })()}

        </>
    );
};

export default CampaignsPage;

