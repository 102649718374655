import gql from 'graphql-tag';

const query = gql`
query matches($matchId: String!){
    matches(matchId: $matchId){ 
          _id
          name
          matchAmount
          timeFrame
          startDate
          endDate
          description
          posterUrl
          meta {
              createdAt
          },
          active
          isComplete 
          matchedAmount
          
    },
  }`;
  
export default query;