import React from "react";
import styled from "styled-components";
import { useQuery, useMutation } from '@apollo/react-hooks';
import {  useSelector, useDispatch } from "react-redux";
import * as AppActions from "../../redux/actions/app.actions";
import Loader, { LoaderSizes } from "../../elements/Loader";
import { Colors } from "../../styles/Colors";
import { MatchstikState } from "../../redux/store";
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from "./Modal";
import GET_MEMBERSHIP from '../../graphql/queries/membership.query';
import CANCEL_MEMBERSHIP from '../../graphql/mutations/cancelMembership.mutation';
import BackButton from '../../elements/BackButton';
import Button, { ButtonTypes } from '../../elements/Button';
import toast, { Toaster } from 'react-hot-toast';
import { successMessages } from '../../utils/MessageUtil';

const Container = styled.div`
  width: 350px;
  @media (max-width:640px){
    width: 100%; 
  }
`;

const LoaderContainer = styled.div`
  width: 350px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfirmText = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Grey1};
  padding: 30px 0px 10px;
`;

const Spacer = styled.div`
  height: 20px;
`;

const SubText = styled.div`
  font-size: 1.2rem;
  color: ${Colors.Grey2};
  padding-bottom: 20px;
`;

type CancelMembershipModal = {};

const CancelMembershipModal: React.FC<CancelMembershipModal> = () => {
  /* State */
  const appState = useSelector((state: MatchstikState) => state.app);
  const { membershipId } = appState;

  /* Actions */
  const dispatch = useDispatch();
  const popModal = () => dispatch(AppActions.popModal());

  /* GraphQL */
  const { data, loading } = useQuery(GET_MEMBERSHIP, {
    variables: {
      membershipId,
    },
  });

  const [cancelMembership, { loading: loadingRefund }] = useMutation(CANCEL_MEMBERSHIP, {
    refetchQueries: [{ query: GET_MEMBERSHIP, variables: { membershipId }}],
    awaitRefetchQueries: true,
    onCompleted:(data)=> {
      
      toast.success(successMessages.CANCEL_MEMBERSHIP_SUCCESS);
      setTimeout(() => {
        popModal();
      }, 2000);
    },
    onError(error) {
      // TODO: handle Error
      console.log('Error', error); 
    }
  })

  /** Render */
  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    <ModalContainer width="410px">
      <ModalHeader title={`Membership ${membershipId}`} close={popModal} />
      <ModalContent>
        {loading ? (
          <ModalContent>
            <LoaderContainer>
              <Loader size={LoaderSizes.Large}/>
            </LoaderContainer>
          </ModalContent>
        ) : (
          <Container>
            <BackButton onClick={popModal} />
            <ConfirmText>
              Are you sure you want to cancel this membership?
            </ConfirmText>
            <SubText>
              This member will no longer be a recurring donor.
            </SubText>
            <Button
              type={ButtonTypes.Submit}
              text={'Confirm Cancellation'}
              onClick={() => cancelMembership({
                variables: {
                  membershipId,
                },
              })}
              loading={loadingRefund}
            />
          </Container>
        )}
      </ModalContent>
    </ModalContainer>
    </>
  );
};

export default CancelMembershipModal;
