import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { ModalContainer, ModalContent, ModalHeader } from "./Modal";
import * as AppActions from "../../redux/actions/app.actions";
import DateTimePicker from 'react-datetime-picker';
import Button from "../../elements/Button";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { useMutation, useQuery } from '@apollo/react-hooks';
import { MatchstikState } from "../../redux/store";
import LIST_MEMBERSHIPS from '../../graphql/queries/listMemberships.query';
import CHANGE_DONATION_FAILED_TASK_METHOD from '../../graphql/mutations/createDonationFailedTask.mutation';
import { SuccessText } from "../AuthLayout";
import Icon, { Icons } from "../../elements/Icon";
import { Colors } from "../../styles/Colors";

const Form = styled.form`
    .Element {
      margin: 0;
      max-width: 100%;
      flex: 1 0 auto;
      outline: 0;
      text-align: left;
      line-height: 1.21428571em;
      background: #fff;
      border-radius: 10px;
      transition: box-shadow 0.1s ease, border-color 0.1s ease;
      box-shadow: none;
      font-size: 14px;
      margin-top: 5px;
      padding: 1.4rem;
      padding-left: 3.7rem;
      height: 17px;
    }
  `;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: left;
    margin-bottom: 10px;
    flex: 1;
    justify-content: left;
    margin: 15px 0;
    position: relative;
    @media (max-width:576px){
      flex-direction: column;
      row-gap: 10px;
      margin: 15px 0 25px;
    }
    .showDateTime{
       .react-datetime-picker__wrapper{
        padding:6px 12px;
        border:1px solid #f2f2f2;
        border-radius:5px;
        font-size: 15px;
        height: 50px;
       }
    };
    .showTime{
      .rc-time-picker-input{
        padding:6px 12px;
        border:1px solid #f2f2f2;
        border-radius:5px;
        font-size: 15px;
        height: 50px;
        width: 150px;
        @media (max-width:576px){
          width: 100%;
        }
      }
    }
  `;

const Spacer = styled.div`
  flex: 0.075;
`;
const ErrorText = styled.span`
  color: ${Colors.Red};
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 15px;
  position: absolute;
  left: 172px;
  bottom: -34px;
  @media (max-width:576px){
    left: 0;
  }
`;

type SuccessModalProps = {
};

const SuccessModal: React.FC<SuccessModalProps> = () => {
  /*State */
  const [selectDate, setSelectDate] = useState(new Date());
  const [selectTime, setSelectTime] = useState(moment())
  const [loading, setLoading] = useState(false);
  const [isSucessfullyUpdated, setIsSucessfullyUpdated] = useState(false)
  const appState = useSelector((state: MatchstikState) => state.app);
  const { membershipId } = appState;

  /** GraphQL **/
  const { data } = useQuery(LIST_MEMBERSHIPS)

  /* Actions */


  const dispatch = useDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  const [onCreateFailedTaskDonationSubmit, { loading: changeFailedTaskDonationLoading }] = useMutation(CHANGE_DONATION_FAILED_TASK_METHOD, {
    refetchQueries: [{ query: LIST_MEMBERSHIPS, variables: { membershipId }, }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setTimeout(() => {
        setIsSucessfullyUpdated(true);
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        popModal();
        popModal();
      }, 3000);
    },
  })

  const newSelectDate = moment(selectDate).format('MM-DD-YYYY');
  const newSelectTime = moment(selectTime).format('h:mm a');
  const dateAndTime = newSelectDate.concat(' ', newSelectTime)
  const showDateAndTime = new Date(dateAndTime).getTime()
  const onCreateFailedTaskDonation = async () => {
    
    setLoading(true);

    onCreateFailedTaskDonationSubmit({
      variables: {
        "executeAt": showDateAndTime.toString(),
        "membershipId": membershipId
      }
    })
  };

  /** Render */
  return (
    <ModalContainer width="550px" >
      <ModalHeader title="" close={popModal} />
      {(() => {
        return (
          <Fragment>
            <ModalContent >
              <Form>
                <h2 style={{ margin: 'auto', padding: '15px 0' }}>Payment method has been updated. When should this membership be processed next?</h2>
                <Row>
                  <DateTimePicker
                    className="showDateTime"
                    value={selectDate}
                    format="MM-dd-y"
                    onChange={(value: React.SetStateAction<Date>) => {
                      setSelectDate(value);
                    }}
                    clearIcon={null}
                    disableClock
                    minDate={new Date()}
                  />
                  <Spacer />
                  <TimePicker
                    placeholder="Time"
                    value={selectTime}
                    onChange={setSelectTime}
                    showSecond={false}
                    allowEmpty={false}
                    use12Hours
                    className="showTime"
                    popupClassName="showTimePopup"
                  />
                {newSelectTime === "Invalid date" && <ErrorText>Time is required</ErrorText>}
                 
                </Row>
                <h3>Your next donation will be processed on {moment(selectDate).format('MM-DD-YYYY')}, {moment(selectTime).format('h:mm a')}</h3>
                <Button
                  state={""}
                  type="button"
                  onClick={onCreateFailedTaskDonation}
                  text="OK"
                  margin="auto"
                  width="120px"
                  loading={loading}
                  style={isSucessfullyUpdated || newSelectTime === "Invalid date" ? { pointerEvents: "none", opacity: "0.4" } : {}}
                />
                <div style={{ textAlign: 'center' }}>
                  {isSucessfullyUpdated &&
                    <SuccessText >
                      Your donation will be processed
                      <Icon
                        icon={Icons.Check}
                        color={Colors.Green}
                        size={15}
                        margin="0 0 0 12px"
                      />
                    </SuccessText>}
                </div>
              </Form>
            </ModalContent>
          </Fragment>
        );
      })()}
    </ModalContainer>
  );
};

export default SuccessModal;
