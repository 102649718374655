import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from "@apollo/client";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Label from '../elements/Label';
import TextArea from "../elements/TextArea";
import Icon, { Icons } from "../elements/Icon";
import { Colors } from "../styles/Colors";
import { ModalTypes } from './modal/Modal';
import ReactTooltip from "react-tooltip";
import Loader, { LoaderSizes } from '../elements/Loader';
import LabeledInput from '../elements/LabeledInput';
import Joi from "@hapi/joi";
import * as Schema from "../utils/Schema";
import * as ErrorUtil from "../utils/ErrorUtil";
import makeEventHandler from "../utils/makeEventHandler";
import ErrorText from "../elements/ErrorText";
import Button, { ButtonTypes } from "../elements/Button";
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import ImageUpload from '../elements/ImageUpload';
import GET_USER from '../graphql/queries/user.query';
import UPDATE_ORGANIZATION from '../graphql/mutations/updateOrganization.mutation';
import { useDispatch } from 'react-redux';
import * as AppActions from "../redux/actions/app.actions";
import toast, { Toaster } from 'react-hot-toast';
import { successMessages } from '../utils/MessageUtil';


const schema = Joi.object({
  ein: Schema.organization.ein().error(([error]) => {
    const message = "Email EIN number is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  headerImageUrl: Schema.organization.headerImageUrl().error(([error]) => {
    const message = "Email header image is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  footerImageUrl: Schema.organization.footerImageUrl().error(([error]) => {
    const message = "Email footer image is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  signatureImageUrl: Schema.organization
    .signatureImageUrl()
    .error(([error]) => {
      const message = "Email signature image is invalid";
      return new Error(
        JSON.stringify({
          field: error.path[0],
          message,
        })
      );
    }),
  signatureText: Schema.organization.signatureText().error(([error]) => {
    const message = "Email signature text is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  signaturePosition: Schema.organization
    .signaturePosition()
    .error(([error]) => {
      const message = "Email signatory position is invalid";
      return new Error(
        JSON.stringify({
          field: error.path[0],
          message,
        })
      );
    }),
  presetDonations: Schema.organization
    .presetDonations()
    .error(([error]) => {
      const message = "Preset donations are invalid";
      return new Error(
        JSON.stringify({
          field: error.path[0],
          message,
        })
      );
    }),
  donationReceiptText: Schema.organization.donationReceiptText().error(([error]) => {
    const message = "Donation receipt text is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
});

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spacer = styled.div`
  height: 30px;
`;

const LabelContainer = styled.div`
display: flex;
`;

enum ErrorKeyEnum {
  Ein = "ein",
  HeaderImageUrl = "headerImageUrl",
  FooterImageUrl = "footerImageUrl",
  SignatureImageUrl = "signatureImageUrl",
  SignatureText = "signatureText",
  SignaturePosition = "signaturePosition",
  PresetDonations = 'presetDonations',
  DonationReceiptText = 'donationReceiptText',
}

type SettingsPageProps = {};

const SettingsPage: React.FC<SettingsPageProps> = ({ }) => {
  // / State /
  const [ein, setEin] = React.useState("");
  const [headerImageUrl, setHeaderImageUrl] = React.useState("");
  const [donationReceiptText, setDonationReceiptText] = React.useState("");
  const [footerImageUrl, setFooterImageUrl] = React.useState("");
  const [signatureImageUrl, setSignatureImageUrl] = React.useState("");
  const [signatureText, setSignatureText] = React.useState("");
  const [signaturePosition, setSignaturePosition] = React.useState("");
  const [presetDonations, setPresetDonations] = React.useState<number[]>([]);
  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [supportingOrg, setSupportingOrg] = React.useState({} as any);
  const [fieldErrors, setFieldErrorsInternal] = React.useState({
    [ErrorKeyEnum.Ein]: null,
    [ErrorKeyEnum.HeaderImageUrl]: null,
    [ErrorKeyEnum.FooterImageUrl]: null,
    [ErrorKeyEnum.SignatureImageUrl]: null,
    [ErrorKeyEnum.SignatureText]: null,
    [ErrorKeyEnum.SignaturePosition]: null,
    [ErrorKeyEnum.PresetDonations]: null,
    [ErrorKeyEnum.DonationReceiptText]: null,
  });


  // / Actions /
  const eventHandler = makeEventHandler(() => setError(""));
  const setFieldErrors = (field: string, message: string | null) => {
    const newFieldErrors: any = {
      [field]: message,
    };
    setFieldErrorsInternal(newFieldErrors);
  };

  const onChangeEin = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.Ein, null);
    setEin(value);
  });
  const onChangeHeaderImageUrl = (value: string) => {
    setFieldErrors(ErrorKeyEnum.HeaderImageUrl, null);
    setHeaderImageUrl(value);
  };
  const onChangeDonationReceiptText = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.DonationReceiptText, null);
    setDonationReceiptText(value);
  });
  const onChangeFooterImageUrl = (value: string) => {
    setFieldErrors(ErrorKeyEnum.FooterImageUrl, null);
    setFooterImageUrl(value);
  };
  const onChangeSignatureImageUrl = (value: string) => {
    setFieldErrors(ErrorKeyEnum.SignatureImageUrl, null);
    setSignatureImageUrl(value);
  };
  const onChangeSignatureText = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.SignatureText, null);
    setSignatureText(value);
  });
  const onChangeSignaturePosition = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.SignaturePosition, null);
    setSignaturePosition(value);
  });
  const setInitialPresetDonations = (donations: number[]) => {
    setFieldErrors(ErrorKeyEnum.PresetDonations, null);
    setPresetDonations(donations);
  };

  const dispatch = useDispatch()
  const onTooltipClick = () => {
    dispatch(AppActions.pushModal(ModalTypes.TextEditor))
  }

  // / GraphQL /
  const { data } = useQuery(GET_USER, {
    onCompleted: (data) => {
      const organization: IOrganization = data?.user?.organization;
      if (organization) {
        setEin(organization.ein || "");
        setHeaderImageUrl(organization.headerImageUrl || "");
        setDonationReceiptText(organization.donationReceiptText || "");
        setFooterImageUrl(organization.footerImageUrl || "");
        setSignatureImageUrl(organization.signatureImageUrl || "");
        setSignatureText(organization.signatureText || "");
        setSignaturePosition(organization.signaturePosition || "");
        setInitialPresetDonations(organization.presetDonations || []);
        setSupportingOrg(organization?.supportingOrg)
        setLoaded(true);
      }
    },
  });

  const [updateOrganizationMutation, { loading: updateLoading }] = useMutation(
    UPDATE_ORGANIZATION,
    {
      variables: {
        organization: {
          ein,
          headerImageUrl,
          donationReceiptText,
          footerImageUrl,
          signatureImageUrl,
          signatureText,
          signaturePosition,
          presetDonations,
        },
      },
      refetchQueries: [{ query: GET_USER }],
      onCompleted: async () => {
        setLoading(false);
        toast.success(successMessages.UPDATE_EMAIL);
        setError('');
      },
      onError: async (error: any) => {
        const errorMsg = ErrorUtil.getErrorMessage(error);
        setError(errorMsg);
      },
    }
  );

  const updateOrganization = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    const params = schema.validate({
      ein,
      headerImageUrl,
      donationReceiptText,
      footerImageUrl,
      signatureImageUrl,
      signatureText,
      signaturePosition,
      presetDonations,
    });
    const { error: schemaError } = params;
    if (schemaError) {
      const { field, message } = JSON.parse(schemaError.message);
      console.log(message);
      setFieldErrors(field, message);
      return;
    }

    setError("");
    toast.remove();
    setLoading(true);
    updateOrganizationMutation();
  };

  // / Render /
  return (
    <LoaderContainer>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      {!loaded && <Loader size={LoaderSizes.Large} />}
      {loaded && (
        <Content>
          <LabeledInput
            label="Tax EIN"
            placeholder="Enter Tax EIN"
            value={ein}
            type="text"
            onChange={onChangeEin}
            error={fieldErrors[ErrorKeyEnum.Ein]}
            width="270px"
            readOnly={supportingOrg?.orgId ? "true" : ""}
          />
          <Spacer />
          <ImageUpload
            imageUrl={headerImageUrl}
            setImageUrl={onChangeHeaderImageUrl}
            label="Email Header Image"
          />
          <Spacer />
          <LabelContainer>
            <Label text="Donation Receipt Text" ></Label>
            <p style={{ width: 'min-content', margin: '0' }} data-tip='Donation Receipt Tags'>
              <Icon margin={"0 6px 8px"} icon={Icons.infoRegular} color={Colors.Grey1} onClick={() => onTooltipClick()} />
            </p>
          </LabelContainer>
          <TextArea
            placeholder="Enter custom text to be included on donation receipt emails"
            value={donationReceiptText}
            onChange={onChangeDonationReceiptText}
            error={fieldErrors[ErrorKeyEnum.DonationReceiptText]}
            width="670px"
            readOnly={supportingOrg?.orgId ? "true" : ""}
          // height="200px"
          />
          <Spacer />
          <ImageUpload
            imageUrl={signatureImageUrl}
            setImageUrl={onChangeSignatureImageUrl}
            label="Email Signature Image"
          />
          <Spacer />
          <LabeledInput
            label="Signatory Name"
            placeholder="Enter Signatory Name"
            value={signatureText}
            type="text"
            onChange={onChangeSignatureText}
            error={fieldErrors[ErrorKeyEnum.SignatureText]}
            width="270px"
            readOnly={supportingOrg?.orgId ? "true" : ""}
          />
          <Spacer />
          <LabeledInput
            label="Signatory Position"
            placeholder="Enter Signatory Position"
            value={signaturePosition}
            type="text"
            onChange={onChangeSignaturePosition}
            error={fieldErrors[ErrorKeyEnum.SignaturePosition]}
            width="270px"
            readOnly={supportingOrg?.orgId ? "true" : ""}
          />
          <Spacer />
          <ImageUpload
            imageUrl={footerImageUrl}
            setImageUrl={onChangeFooterImageUrl}
            label="Email Footer Image"
          />
          <Spacer />
          {error && <ErrorText align="left">{error}</ErrorText>}
          <Button
            type={ButtonTypes.Submit}
            onClick={() => updateOrganization()}
            loading={loading}
            text="Save Settings"
            margin="0"
            width="300px"
            style={supportingOrg?.orgId ? { pointerEvents: "none", opacity: "0.4" } : {}}
          />
          <ReactTooltip
            place="right"
            type="dark"
            effect="solid"
            clickable={false}
          />
        </Content>
      )}
    </LoaderContainer>
  );
};

export default SettingsPage;
