import React from "react";
import styled from "styled-components";
import Icon, { Icons } from '../elements/Icon';
import logoSrc from "./../assets/images/logo-icon.png";
import { useQuery } from "@apollo/client";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Colors } from "../styles/Colors";
import IS_SUPER_USER from "../graphql/queries/isSuperUser.query";
import { useSelector } from "react-redux";
import { MatchstikState } from "../redux/store";


type SideNavigationButtonProps = {
	active: number;
	margin?: string;
};
type SidebarProps = {
	showSidebar?: boolean
};


const AsideContainer = styled.div`
	position:fixed;
	background: rgba(242, 242, 242, 0.6);
	width:220px;
	border-right: 1px solid #BDBDBD;
	height: calc(100% - 70px);
	top:70px;
	transition:0.5s;
	z-index:99;
	overflow: auto;
	@media (max-width:991px) {
	  left:-220px;
	  &.showSidebar{
		 left:0px;
		 background: rgba(242, 242, 242, 1);
	  }
	}
`;
const AsideInner = styled.div`
padding: 38px 25px;
`;
const NavbarNav = styled.div`
display:block;
`;
const NavItem = styled(Link) <SideNavigationButtonProps>`
margin-bottom: 15px;
color: ${(props) => (props.active ? Colors.Pink : Colors.Grey3)};
font-size: 16px;
line-height: 24px;
font-weight: ${(props) => (props.active ? 700 : 400)};
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
padding: 6px 0px;
position: relative;
text-decoration: none;
margin:${(props) => props.margin};
cursor: ${(props) => (props.to ? "pointer" : "inherit")};
&:after{
	content: "";
	width: 4px;
	height: 100%;
	position: absolute;
	right:-25px;
	background:${(props) => (props.active ? Colors.Pink : 'transparent')};
	border-radius: 2px 0px 0px 2px;
}
`;
const NavIcon = styled.div`
margin:0 0 20px 0
`;
const Logo = styled.img`
position: relative;
width:24px;
`;


function useGetQuery() {
	return new URLSearchParams(useLocation().search);
}

const Sidebar: React.FC<SidebarProps> = ({ showSidebar }: SidebarProps) => {
	const user = useSelector((state: MatchstikState) => state.user as any);
	// const { _id } = user.organization;
	const { data } = useQuery(IS_SUPER_USER);
	const { pathname } = useLocation();
	const query = useGetQuery();
	const history = useHistory();

	let buttons: any[] = [
		{
			text: "Dashboard",
			link: "/dashboard/home",
			active: ["/dashboard/home"],
			icon: Icons.HomeLgAlt
		},
		{
			text: "FUNDRAISING",
			active: [""],
			link: "",
			icon: "",
			margin: "25px 0px 20px 0px"
		},
		{
			text: "Donations",
			link: "/dashboard/overview",
			active: ["/dashboard/overview"],
			icon: Icons.HandHoldingUsd
		},
		{
			text: "Campaigns",
			link: "/dashboard/campaigns",
			active: ["/dashboard/campaigns"],
			icon: Icons.HandHoldingHeart
		},
		{
			text: "Your Page",
			link: `/dashboard/your-page`,
			active: [`/dashboard/your-page`],
			icon: Icons.Browser
		},
		{
			text: "Donate Button",
			link: "/dashboard/install-button",
			active: ["/dashboard/install-button"],
			icon: Icons.RectangleWide
		},
		{
			text: "ORGANIZATION",
			link: "",
			active: [""],
			icon: "",
			margin: "25px 0px 20px 0px"
		},
		{
			text: "Settings",
			link: "/dashboard/settings",
			active: ["/dashboard/settings"],
			icon: Icons.Settings
		},

	];

	const superUser = [{
		text: "SUPER ADMIN",
		active: [""],
		link: "",
		icon: "",
		margin: "25px 0px 20px 0px"
	},
	{
		text: "Clients",
		link: "/dashboard/super",
		active: ["/dashboard/super"],
	}
	];

	if (data && data.isSuperUser) {
		buttons.push(...superUser);
	}	

	return (
		<AsideContainer className={showSidebar ? "showSidebar" : ""}>
			<AsideInner>
				<NavIcon><Logo onClick={()=>{
					history.push("/dashboard/home")
				}}  src={logoSrc} /></NavIcon>
				<NavbarNav>
					{buttons.map((button, i) => {
						const active = button.active.includes(pathname);
						/* for handling active case for first time login & move to billing info */

						let activeTab = query.get("activeTab");
						const SettingsActive = activeTab === "banksettings" && button.text === "Settings";
						return (
							<NavItem
								key={i}
								to={button.link}
								active={active || SettingsActive ? 1 : 0}
								margin={button.margin}
							>
								<Icon icon={button.icon} size={16} color={active ? Colors.Pink : Colors.Grey3} margin='0px 13px 0px 0px' />{button.text}
							</NavItem>
						);
					})}
				</NavbarNav>
			</AsideInner>
		</AsideContainer>
	);
};
export default Sidebar;
