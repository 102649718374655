import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import Icon, { Icons } from "../../elements/Icon";
import { Colors } from "../../styles/Colors";
import * as Auth from "../../utils/Auth";
import LogoSrc from "../../assets/images/logo-dark.svg";
import userAvatar from "../../assets/images/logo-icon.png";
import { useDispatch, useSelector } from "react-redux";
import * as UserActions from "../../redux/actions/user.actions";
import { MatchstikState } from "../../redux/store";
import OrganizationTypeEnum from "@matchstik/models/.dist/enums/OrganizationTypeEnum";
import { useQuery } from "@apollo/client";
import IS_SUPER_USER from "../../graphql/queries/isSuperUser.query";
import AuthNavigation from "../AuthNavigation";
// "../../graphql/queries/donorProfile.query";
import GET_DONOR_PROFILE from "../../graphql/queries/donorProfile.query";

export const CircleImage = styled.img<any>`
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
`;

export const UserImage = styled.div`
  width: 50px;
  height: 50px;
  cursor: pointer;
  // min-width: 80px;
  // min-height: 80px;
  // max-width: 80px;
  // max-height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  z-index: 0;
  justify-content: center;
  border: 1px solid #e0e0e0;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  -webkit-user-select: none;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    height: 40px;
    width: 40px;
  }
`;

export const Toggleicon = styled.div`
  align-items: center;
  height: 100%;
  cursor: pointer;
  display: none;
  @media only screen and (max-width: 767px) {
    display: flex;
  }
`;
export const AlignSettingIcon = styled.div`
  align-items: center;
  height: 100%;
  display: flex;
`;
export enum PageStateEnum {
  Donations = "Donations & Matches",
  SupportedCauses = "Supported Causes",
  Following = "Following",
}
export const HeaderContainer = styled.div`
  padding: 0 40px;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 16px rgba(17, 0, 40, 0.05);
  display: flex;
  height: 70px;
  gap: 10px;
  align-items: center;
  position: fixed;
  // width: calc(100% - 80px);
  width: 100%;
  box-sizing: border-box;
  z-index: 99;
  background: #fff;
  background: ${Colors.White};

  @media only screen and (max-width: 767px) {
    padding: 0 20px;
  }
`;
export const UserLogo = styled.div``;
export const Usernavbar = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
export const Navitems = styled.div`
  align-items: center;
  display: flex;
  box-sizing: border-box;
  // @media only screen and (max-width: 767px) {
  //   flex-direction: column;
  //   height: 100vh;
  //   background: white;
  //   position: absolute;
  //   top: 70px;
  //   left: -200px;
  //   align-items: flex-start;
  //   width: 200px;
  // }
`;
export const Navlink = styled.div`
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  line-height: 17px;
  padding: 15px;
  align-items: center;
  font-family: "Nunito Sans", sans-serif;
  background: ${Colors.White};
  display: flex;
  box-sizing: border-box;
  // @media only screen and (max-width: 767px) {
  //   width: 200px;
  //   padding: 15px 25px;
  // }
`;
export const TabsLayout = styled.div`
  width: max-content;
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 0 0 10px #0000001e;
  padding: 10px 10px 10px 20px;
  border-radius: 25px;
`;
export const TabsList = styled.form<{ active?: boolean }>`
  padding: 5px 20px;
  transition: all 0.2s;
  font-weight: 400;
  color: ${[(props) => (props.active ? Colors.Pink : Colors.Black2)]};
  margin-right: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-right: 1px solid #f2f2f2;
  &:last-child {
    border: none;
  }
  font-weight: ${[(props) => (props.active && "700") || "600"]};
  .react-datetime-picker {
    .react-datetime-picker__wrapper {
      padding: 2px 12px;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
    }
  }
`;
export const TabListItem = styled.div<{ active?: boolean }>`
  color: ${[(props) => (props.active ? Colors.Pink : Colors.Black2)]};
  margin: 0;
  &.space {
    margin-right: 15px;
  }
`;
export const Actionstabs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
`;
export const DonationImg = styled.div`
  height: 45px;
  width: 45px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
`;
export const Image = styled.img`
  max-width: 100%;
  width: 100%;
`;
export const Donations = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Donatedlist = styled.div`
  .bold {
    font-weight: 700;
    font-size: 14px;
    font-family: "Nunito Sans", sans-serif;
    line-height: 17px;
  }
`;
export const Donationsdata = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const Donationtab = styled.div`
  margin-bottom: 30px;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-family: "Nunito Sans", sans-serif;
    color: #4f4f4f;
    margin-top: 0;

    &.details {
      margin-left: 80px;
      display: flex;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      font-family: "Nunito Sans", sans-serif;
      color: #4f4f4f;
      gap: 5px;
    }
  }
`;
export const Formitem = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;
export type DropDownProps = {
  display: string;
};
export const DropdownMenu = styled.div<DropDownProps>`
  position: absolute;
  top: 72px;
  right: 22px;
  min-width: 160px;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  display: ${(props) => (props.display === "1" ? "block" : "none")};
`;
export const MenuItem = styled.div`
  display: flex;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  line-height: 17px;
  color: ${Colors.Black2};
  cursor: pointer;
  :hover {
    color: ${Colors.Pink};
    & svg {
      color: ${Colors.Pink}!important;
    }
  }
`;
export const LogoMobile = styled.img`
  position: relative;
  width: auto;
  height: 30px;
  margin-right: 10px;
  top: 0px;
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`;
export const LogoDesktop = styled.img`
  position: relative;
  width: 165px;
  height: auto;
  margin-right: 10px;
  top: 0px;
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
  }
`;
export const Formlink = styled.div``;
export const UserDropdown = styled.div<any>``;

export const OrgLogoContainer = styled.div`
  border: 1px solid ${Colors.Grey5};
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
  position: relative;
`;

type DonorProfileLayoutPops = {};

const DonorProfileLayout: any = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data } = useQuery(IS_SUPER_USER);
  const userState: any = useSelector((state: MatchstikState) => state?.user);
  
  //  const getUser = () => dispatch(UserActions.getUser());
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const donorSlug = localStorage.getItem("donorSlug");
  const UpdateSlug = donorSlug;
  const onOrgClick = () => {
    history.push({
      pathname: "/dashboard/home",
    });
  };

  const {loading, data:donor} = useQuery(GET_DONOR_PROFILE,{variables:{
    slug: UpdateSlug
  }});

  const onDonorClick = () => {
    history.push({
      pathname: "/donor/" + UpdateSlug,
    });
  };
  useEffect(() => {
    // getDonor();
  }, []);

  // useEffect(() => {
  //   const profileType = localStorage.getItem("profileType");
  //   if (profileType !== OrganizationTypeEnum.Individual) {
  //     getUser();
  //   }
  // }, [donor]);

  const homeButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    history.push("/donor/" + UpdateSlug);
  }

  return (
    <>
    {localStorage.length > 0 ? <>
      <HeaderContainer>
        <UserLogo>
          <LogoDesktop
            src={LogoSrc}
            onClick={homeButtonClick}
          />
          <LogoMobile src={userAvatar} />
        </UserLogo>
        <Usernavbar>
          <Navitems>
            <Navlink onClick={homeButtonClick}>
              Home
            </Navlink>
            <Navlink onClick={() => history.push("/non-profits")}>
              Browse Non-Profits
            </Navlink>
          </Navitems>
          <Formitem>
            {/* <Formlink>
              <Icon
                icon={Icons.Bell}
                size={24}
                top="2px"
                color={Colors.Grey4}
              />
            </Formlink> */}
            <UserDropdown onClick={() => setShowDropdown(!showDropdown)}>
              <UserImage>
                <CircleImage src={donor?.getDonorAndUserById?.photoUrl || userAvatar} />
              </UserImage>
              <DropdownMenu display={showDropdown ? "1" : "0"}>
                <>
                  {/* {donor?.users?.organizationType === */}
                  {donor?.getDonorAndUserById?.users?.organizationType === OrganizationTypeEnum.NonProfit ||
                  donor?.getDonorAndUserById?.users?.organizationType === OrganizationTypeEnum.Corporation ||
                  data?.isSuperUser ? (
                    <>
                      <MenuItem onClick={onDonorClick}>
                        <Icon
                          icon={Icons.UserRegular}
                          size={14}
                          color={Colors.Black2}
                          margin="0 10px 0 0"
                        />
                        Donor Profile
                      </MenuItem>
                      <MenuItem onClick={onOrgClick}>
                        <Icon
                          icon={Icons.Dollar}
                          size={14}
                          color={Colors.Black2}
                          margin="0 10px 0 0"
                        />
                        Org Dashboard
                      </MenuItem>
                      <MenuItem onClick={() => Auth.logout()}>
                        <Icon
                          icon={Icons.Logout}
                          size={14}
                          color={Colors.Black2}
                          margin="0 10px 0 0"
                        />
                        Logout
                      </MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem onClick={onDonorClick}>
                        <Icon
                          icon={Icons.UserRegular}
                          size={14}
                          color={Colors.Black2}
                          margin="0 10px 0 0"
                        />
                        Donor Profile
                      </MenuItem>
                      <MenuItem onClick={() => Auth.logout()}>
                        <Icon
                          icon={Icons.Logout}
                          size={14}
                          color={Colors.Black2}
                          margin="0 10px 0 0"
                        />
                        Logout
                      </MenuItem>
                    </>
                  )}
                </>
              </DropdownMenu>
            </UserDropdown>
          </Formitem>
        </Usernavbar>
      </HeaderContainer>
      </> : <AuthNavigation/>}
    </>
  );
};

export default DonorProfileLayout;
