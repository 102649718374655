import React, { useContext, useState } from "react";
import styled from "styled-components";
import Button from "../elements/Button";
import { Colors } from "../styles/Colors";
import moment from "moment";
import { useDispatch } from "react-redux";
import * as ErrorUtil from "../utils/ErrorUtil";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useMutation } from "@apollo/react-hooks";
import BUY_SUBSCRIPTION from "../graphql/mutations/createSubscription.mutation";
import { useHistory } from "react-router";
import {
  SubscriptionPlans,
  SubscriptionAmounts,
  SubscriptionDiscounts,
  SubscriptionFreeTrial,
} from "@matchstik/models/.dist/enums/SubscriptionEnums";
import { PlanTypeEnum } from "./SettingsBilling";
import { GuestSettingsContext } from "../pages/GuestSettings.page";

const ConfirmSubscriptionContainer = styled.div`
  position: relative;
  /* top: 20px;
  right: 25px; */
  background-color: ${Colors.White};
  border-radius: 15px;
  overflow: hidden;
  padding-bottom:0px;
  width: 400px;
  margin-top: 0px;
  @media (max-width:767px){
  width:100%;
  padding-bottom:50px;
  }
  
`;

const Content = styled.div`
  margin: 15px 0 0;
  padding: 0 30px;
  @media (max-width:767px){
   padding:0;
  }
`;

const Heading = styled.h5`
  font-size: 24px;
  font-weight: 700;
  color: ${Colors.Grey2};
  text-align: center;
  margin-top: 0;
`;

const SubHeading = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: ${Colors.Grey2};
  line-height: 25px;
  text-align: center;
`;

const PricingContainer = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: column;
`;

const Amount = styled.div`
  display: flex;
  width: 400px;
  justify-content: space-around;
  @media (max-width:767px){
   width:100%;
  }
`;

type ValueProps = {
  fontWeight?: string;
  color?: string;
};

const Key = styled.div<ValueProps>`
  display: flex;
  width: 200px;
  color: ${(props) => props.color || Colors.Grey3};
  font-size: 12px;
  font-weight: ${(props) => props.fontWeight || "400"};
  line-height: 20.4px;
`;

const Value = styled.div<ValueProps>`
  display: flex;
  width: 100px;
  text-align: end;
  color: ${(props) => props.color || Colors.Grey3};
  font-size: 12px;
  font-weight: ${(props) => props.fontWeight || "400"};
  line-height: 20.4px;
`;

type ErrorTextProps = {
  large?: boolean;
  align?: string;
};

const ErrorText = styled.div<ErrorTextProps>`
  color: ${Colors.Red};
  font-size: ${(props) => (props.large ? "1.6rem" : "1.2rem")};
  font-weight: 600;
  margin: 20px 0;
  text-align: ${(props) => props.align || "center"};
`;

type ConfirmSubscriptionProps = {
  planType: SubscriptionPlans | PlanTypeEnum;
};

const ConfirmSubscription: React.FC<ConfirmSubscriptionProps> = ({
  planType,
}) => {
  return (
    <ConfirmSubscriptionContainer>
      {(() => {
        switch (planType) {
          case SubscriptionPlans.Monthly: {
            return <ConfirmMonthlyPlan />;
          }
          case SubscriptionPlans.Yearly: {
            return <ConfirmYearlyPlan />;
          }
        }
      })()}
    </ConfirmSubscriptionContainer>
  );
};

const useBuySubscription = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const { setHasBillingInfo } = useContext(GuestSettingsContext);
  const [buySubscription, { data }] = useMutation(BUY_SUBSCRIPTION, {
    onCompleted: () => {
      setLoading(false);
      setError("");
      setHasBillingInfo(true);
      // history.push('/guest/settings/?activeTab=banksettings')
    },
    onError: async (error) => {
      const errorMsg = ErrorUtil.getErrorMessage(error);
      setLoading(false);
      setError(
        "Incorrect card details, please try again with correct card details"
      );
    },
  });

  const handleSubmit = async (planType: string) => {
    
    if (!stripe || !elements) return;
    setLoading(true);

    const card = elements.getElement(CardNumberElement);
    if (!card) return;

    const { error, token } = await stripe.createToken(card);

    if (error?.message) {
      setLoading(false);
      setError(
        "Incorrect card details, please try again with correct card details"
      );
      return console.log(error);
    }

    buySubscription({
      variables: {
        subscriptionPlan: planType,
        cardToken: token?.id,
      },
    });
  };

  return { handleSubmit, loading, error };
};

// separating for better readabilty

const ConfirmMonthlyPlan = () => {
  const { handleSubmit, error, loading } = useBuySubscription();

  const dateAfter30Days = moment()
    .add(SubscriptionFreeTrial.FreeTrialDays, "days")
    .format("MMM Do YYYY");
  return (
    <Content>
      <Heading>Confirm subscription & start fundraising!</Heading>
      <SubHeading>
        Your ${SubscriptionAmounts.Monthly} subscription will start billing on{" "}
        {dateAfter30Days}
      </SubHeading>
      <Button
        type="submit"
        onClick={() => handleSubmit(SubscriptionPlans.Monthly)}
        text="Confirm Subscription"
        loading={loading}
      />
      <PricingContainer>
        <Amount>
          <Key>Amount</Key>
          <Value>$0.00</Value>
        </Amount>
        <Amount>
          <Key>Total Monthly: {dateAfter30Days}</Key>
          <Value>${SubscriptionAmounts.Monthly}</Value>
        </Amount>
        <Amount>
          <Key fontWeight="700" color={Colors.Grey1}>
            Due Today
          </Key>
          <Value fontWeight="700" color={Colors.Grey1}>
            $0.00
          </Value>
        </Amount>
        {error && <ErrorText>{error}</ErrorText>}
      </PricingContainer>
    </Content>
  );
};

const ConfirmYearlyPlan = () => {
  const trialEndDate = moment()
    .add(SubscriptionFreeTrial.FreeTrialDays, "days")
    .format("MMM Do YYYY");
  const discountAmount: any = (
    (SubscriptionAmounts.Yearly * SubscriptionDiscounts.Yearly) /
    100
  ).toFixed(2);

  const { handleSubmit, error, loading } = useBuySubscription();

  return (
    <Content>
      <Heading>Confirm subscription & start fundraising!</Heading>
      <SubHeading>
        Your first {SubscriptionFreeTrial.FreeTrialDays} Days are Free! Billing
        will occur Annually after sign up at $
        {SubscriptionAmounts.Yearly - discountAmount}/Year
      </SubHeading>
      <Button
        type="button"
        text="Confirm Subscription"
        onClick={() => handleSubmit(SubscriptionPlans.Yearly)}
        loading={loading}
      />

      <PricingContainer>
        <Amount>
          <Key>Amount</Key>
          <Value>${SubscriptionAmounts.Yearly}</Value>
        </Amount>
        <Amount>
          <Key>{SubscriptionDiscounts.Yearly}% Discount</Key>
          <Value>${discountAmount}</Value>
        </Amount>
        <Amount>
          <Key>Total Annually: {trialEndDate}</Key>
          <Value>${SubscriptionAmounts.Yearly - discountAmount}</Value>
        </Amount>

        <Amount>
          <Key fontWeight="700" color={Colors.Grey1}>
            Due Today
          </Key>
          <Value fontWeight="700" color={Colors.Grey1}>
            {/* ${PlanPriceEnum.Yearly - discountAmount} */}
            $0.00
          </Value>
        </Amount>

        {error && <ErrorText>{error}</ErrorText>}
      </PricingContainer>
    </Content>
  );
};

export default ConfirmSubscription;
