import React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import Loader, { LoaderSizes } from "../elements/Loader";
import ConnectStripe from "../components/ConnectStripe";
import GET_SHORT_USER, { GET_FULL_USER } from "../graphql/queries/user.query";
import LIST_ORGANIZATIONS from "../graphql/queries/getOrgList.query";


const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spacer = styled.div`
  height: 30px;
`;

type selectedStateType = {
  value?: string,
  label?: string
}

type SettingsPageProps = {};

const SettingsPage: React.FC<SettingsPageProps> = ({ }) => {
  /* State */
  const [loaded, setLoaded] = React.useState(false);
  const [orgsArray, setOrgsArray] = React.useState([]);
  const [selectedOrg, setSelectedOrg] = React.useState<selectedStateType | null>(() => {
    return null
  });

  /* GraphQL */
  const { data } = useQuery(GET_FULL_USER, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      setLoaded(true);
    },
  });
  const stripeConnectId = data?.user?.organization?.stripeConnectId
  const supportingOrg = data?.user?.organization?.supportingOrg?.organizationName

  /* LIST_ORGANIZATIONS */
  const { data: orgList } = useQuery(LIST_ORGANIZATIONS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      let organizationsList = data?.getOrgList.map((item: any) => {
        return {
          label: item.name,
          value: item._id,
        }
      });
      setOrgsArray(organizationsList);
    }
  });

  /* Render */
  return (
    <LoaderContainer>
      {!loaded && <Loader size={LoaderSizes.Large} />}
      {loaded && (
        <Content>
          <ConnectStripe
            stripeConnectId={stripeConnectId}
            supportingOrg={supportingOrg}
            selectedOrg={selectedOrg}
            orgsArray={orgsArray}
            setSelectedOrg={setSelectedOrg}
          />
          <Spacer />
        </Content>
      )}
    </LoaderContainer>
  );
};

export default SettingsPage;
