import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Button, { ButtonTypes } from "../../elements/Button";
import Loader, { LoaderSizes } from "../../elements/Loader";
import { Colors } from "../../styles/Colors";
import { ModalContainer, ModalContent, ModalHeader } from "./Modal";
import * as AppActions from "../../redux/actions/app.actions";
import { MatchstikState } from "../../redux/store";

const Container = styled.div`
`;

const LoaderContainer = styled.div`
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfirmText = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Grey1};
  padding: 30px 10px 10px 10px;
  margin: 0 0 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

type DisableOrganizationModalProps = {

};

const ConfirmActionModal: React.FC<DisableOrganizationModalProps> = () => {
  /* State */

  /* Actions */
  const dispatch = useDispatch();
  const modalData = useSelector(
    (state: MatchstikState) => state.app.confirmAction
  );
  const popModal = () => dispatch(AppActions.popModal());

  /* GraphQL */

  /** Render */
  return (
    <ModalContainer width="450px">
      <ModalHeader title={modalData.title} close={popModal} />
      <ModalContent>
        {false ? (
          <ModalContent>
            <LoaderContainer>
              <Loader size={LoaderSizes.Large} />
            </LoaderContainer>
          </ModalContent>
        ) : (
          <Container>
            <ConfirmText>{modalData.message}</ConfirmText>
            <ButtonContainer>
              <Button
                type={ButtonTypes.Submit}
                text="Cancel"
                width={window.innerWidth > 468 ? "150px" : "130px"}
                onClick={popModal}
              />
              <Button
                type={ButtonTypes.Submit}
                text="Confirm"
                width={window.innerWidth > 468 ? "150px" : "130px"}
                onClick={modalData.confirm}
                loading={modalData.loading}
              />
            </ButtonContainer>
          </Container>
        )}
      </ModalContent>
    </ModalContainer>
  );
};

export default ConfirmActionModal;
