import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Colors } from "../../styles/Colors";
import { ModalContainer, ModalContent, ModalHeader } from "./Modal";
import * as AppActions from "../../redux/actions/app.actions";
import GET_LIST_MATCH from "../../graphql/queries/listMatches.query";
import { useQuery } from "@apollo/react-hooks";
import * as Price from '@matchstik/utils/.dist/price';
import moment from "moment";

const ModalBody = styled.div`
  padding: 20px 0;
`;

const LoaderContainer = styled.div`
  width: 350px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfirmText = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Grey1};
  padding: 30px 10px 10px 10px;
  margin: 0 0 30px;
`;

const Spacer = styled.div`
  height: 20px;
`;

const SubText = styled.div`
  font-size: 1.2rem;
  color: ${Colors.Grey2};
  padding-bottom: 20px;
`;

const ButtonContainer = styled.div`
  width: 350px;
  display: flex;
  justify-content: space-around;
`;

const RecentList = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 15px 0;
  box-shadow: 2px 7px 4px #ececec;
  border-radius: 7px;
  `;
const RecentThumb = styled.div`
  margin-right: 15px
  `;
const ThumbProfile = styled.img`
  width: 45px;
  height: 45px;
  background: #eee;
  border-radius: 50%;
  `;
const RecentDesc = styled.div`

  `;
const RecentTitle = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    margin: 0 0 4px 0;
    color:${Colors.Grey1};
  `;
const SubTitle = styled.div`
    font-weight:normal;
    font-size: 14px;
    line-height: 17px;
    color:${Colors.Grey1};
  `;
const Bold = styled.div`
  font-weight:bold;
  display: inline-block;
`;

type ActiveMatchesModalProps = {

};

const ActiveMatchesModal: React.FC<ActiveMatchesModalProps> = (props) => {
  const activeCampaign = sessionStorage.getItem("activeCampaign")
    /* State */

    /* Actions */
    const dispatch = useDispatch();
    const popModal = () => dispatch(AppActions.popModal());

    /* GraphQL */

    const { data } = useQuery(GET_LIST_MATCH, {
        variables: {
            "campaignId": activeCampaign,
            "onlyActive": true,
            "sortByAmount": true
        },
        fetchPolicy: "network-only",

    });

    const listMatches = data?.listMatch;

    /** Render */
    return (
        <ModalContainer width="450px">
            <ModalHeader title={"Active Matches"} close={popModal} />
            <ModalBody>
            <ModalContent>
                    {/* <LoaderContainer>
              <Loader size={LoaderSizes.Large} />
            </LoaderContainer> */}
                    {listMatches?.map((match: any) => {
                        
                            let matchAmount = match.matchAmount === null ? "" : match.matchAmount
                            let endDate = match.endDate && new Date(Number(match.endDate));
                            let newEndDate = moment(endDate).format('MM/YY')
                            return (
                        <RecentList>
                            <RecentThumb>
                                <ThumbProfile src={match.posterUrl} />
                            </RecentThumb>
                            <RecentDesc>
                                <RecentTitle>{match.name} - ${Price.output(matchAmount, true)} {match.endDate && `(ends ${newEndDate})`} </RecentTitle>
                            </RecentDesc>
                        </RecentList>
                            )})}
                </ModalContent>
                </ModalBody>
        </ModalContainer>
    );
};

export default ActiveMatchesModal;
