export const successMessages = {
   LOGIN: "login successfully",
   SEND_RECEIPT_SUCCESS: "Send receipt Success.",
   GENERATE_RECEIPT_SUCCESS: "Generate Receipt Success.",
   REFUND_DONATION_SUCCESS: "Donation refunded successfully.",
   UPDATE_PAYMENT_SUCCESS: "Payment was updated successfully.",
   CANCEL_MEMBERSHIP_SUCCESS: "Membership has been cancelled.",
   CAMPAIGN_SUCCESS: "Campaign created successfully.",
   CAMPAIGN_UPDATE: "Campaign updated successfully.",
   MATCH_SUCCESS: "Match created successfully.",
   MATCH_UPDATE: "Match updated successfully.",
   BUTTON_UPDATE: "Successfully updated.",
   UPDATE_ORGANIZATION: "Organization updated successfully.",
   SET_DEFAULT_CAMPAIGN: "Default campaign saved successfully.",
   UPDATE_EMAIL: "Email setting saved successfully.",
   FORGET_PASSWORD_SUCCESS: "Password is successfully changed.",
   RESEND_CODE_SUCCESS: "Verification code resent successfully.",
   EAMIL_VERIFIED_SUCCESS: "Email verified successfully.",
   EMAIL_RESEND: "Password reset instructions have been sent to your email address.",
   DISABLE_ORG: "Organization disabled successfully",
   ENABLE_ORG: "Organization enabled successfully",
   DELETE_ORG: "Organization deleted successfully",
   DELETE_CAMPAIGN: "Campaign deleted successfully",
   PASSWORD_CHANGED_SUCCESS: "Password has been changed successfully.",
   STRIPE_SUCCESS: "Your stripe subscription created successfully. Please login again!",
   VERIFICATION_CODE_SENT: "Your verification code sent to your registered email.",
   SAVE_NONPROFIT_ORGANIZATION: "Successfully saved",
   DONOR_UPDATE_SUCCESS: "Donor updated successfully",
   STRIPE_CONNECT: "Stripe connected successfully"
};

export const errorMessages = {
   DELETE_ORG: "Please login to a different organization before deleting."

};
