import gql from "graphql-tag";

const query = gql`
  query listOrgsByType($organizationType: String) {
    listOrgsByType(organizationType: $organizationType) {
      _id
      description
      email
      donorFees
      address
      name
      city
      logoUrl
      state
      phoneNumber
      organizationStub
      organizationType
      isFollowing
      meta {
        createdAt
      }
    }
  }
`;

export default query;
