import gql from 'graphql-tag';

const query = gql`
  query memberships {
    memberships {
      _id
      organizationId
      donationId
      stripeCustomerId
      nextDonationAt
      active
      donorType
      coverFees
      email
      name
      phoneNumber
      amount
      anonymous
      totalDonationValue
      campaignId
      campaignName
      numberOfDonations
      cancelledAt
      transactionDetails {
        totalAmount
        stripeFee
        appFee
        clientAmount
      }
      meta {
        createdAt
      }
      status
      failReason
      invalidPaymentMethod
    }
  }
`;

export default query;
