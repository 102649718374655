import gql from "graphql-tag";

const query = gql`
query getFollowerList($donorId: String){
  getFollowerList(donorId: $donorId){
    followerId
    followingId
    since
    slug
    visibility
    imageUrl
    name
    type
  }
}
`;

export default query;