import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../styles/Colors";
import logoSrc from "./../assets/images/logo-dark.svg";
import Icon, { Icons } from "../elements/Icon";
import * as Auth from "../utils/Auth";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as UserActions from "../redux/actions/user.actions";
import { MatchstikState } from "../redux/store";
import * as AppActions from "../redux/actions/app.actions";
import url from "url";
import PageLoader from "./PageLoader";
import {DropdownMenu,MenuItem} from "./DashboardLayout";
const Container = styled.div`
  position: absolute;
  /* height: 100vh; */
  width: 100vw;
`;

const Content = styled.div`
  height: 100%;
  overflow: hidden;
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
  position: relative;
`;

export const Title = styled.div`
  font-size: 26px;
  font-weight: 800;
  color: ${Colors.Grey1};
  margin: 60px 0 30px;
`;

const TopNavigation = styled.form`
  position: relative;
  height: 70px;
  width: calc(100% - 60px);
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.White};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Logo = styled.img`
  position: relative;
  width: 165px;
  height: auto;
  margin-right: 10px;
  top: 0px;
`;

// const ExternalLink = styled.a`
//   font-size: 1.4rem;
//   font-weight: 800;
//   color: ${Colors.Grey1};
//   text-decoration: none;
//   margin-left: 30px;
// `;

const OrgDetails = styled.div`
  display: flex;
  align-items: center;
`;

const OrgLogoContainer = styled.div`
  border: 1px solid ${Colors.Grey5};
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor:pointer;
  position:relative;
`;

// const OrgLogo = styled.img`
//   position: relative;
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
// `;

type DashboardLayoutProps = {
  children: React.ReactNode;
};

const GuestLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  /* GraphQL */

  const dispatch = useDispatch();

  const userState: any = useSelector((state: MatchstikState) => state.user);
  const { userData: user, organization, loading } = userState;
  const parsed = url.parse(window.location.toString(), true);
  // const connectCode = parsed?.query?.code;

  const history = useHistory();
    /* hooks */
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
    
  useEffect(() => {
    dispatch(UserActions.getFullUser());
  }, []);

  useEffect(() => {
    if (!loading) {
      if (
        organization.needSubscription &&
        organization.hasActiveSubscription &&
        organization.stripeConnectId
      ) {
        history.push("/dashboard/overview");
        dispatch(AppActions.popModal());
      }
    }
  }, [loading]);

  if (!Auth.getToken()) {
    return <Redirect to="/login" />;
  }

  if (!loading && !user?.emailVerifiedAt) {
    return <Redirect to="/verify-email" />;
  }

  /* Render */
  return (
    <Container>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <TopNavigation>
            <Row>
              <Logo src={logoSrc} />
              {/* <ExternalLink href="/what">How It Works</ExternalLink>
          <ExternalLink href="/what">Find Fundraisers</ExternalLink>
          <ExternalLink href="/what">Browse Non-Profits</ExternalLink> */}
              <OrgDetails>
              <Icon
                  icon={Icons.Bell}
                  size={24}
                  top="2px"
                  color={Colors.Grey4}
                />
                   <OrgLogoContainer >
                   <Icon
                    icon={Icons.UserSolid}
                    size={30}
                    onClick={() => setShowDropdown(!showDropdown)}
                  />
                     <DropdownMenu display={showDropdown ? '1' : '0'} onClick={() => Auth.logout()}>
                     <MenuItem>
                      <Icon
                        icon={Icons.Logout}
                        size={15}
                        top="2px"
                        margin='0 10px 0 0'
                      />
                      Logout</MenuItem>
                       
                       </DropdownMenu>
                       </OrgLogoContainer>
                  
               
              </OrgDetails>
            </Row>
          </TopNavigation>
          <Content>{children}</Content>
        </>
      )}
    </Container>
  );
};

export default GuestLayout;
