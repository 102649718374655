import gql from 'graphql-tag';

const mutation = gql`
mutation createCampaign($params: createCampaignParams!){
    createCampaign(params:$params){
      _id
    }
  }
`;

export default mutation;
