import React from "react";
import styled from "styled-components";
import { Colors } from "../styles/Colors";
import Input, { InputProps } from './Input';
import Label from './Label';

const Container = styled.div<ContainerStyledProps>`
  position: relative;
  width:${(props) => props.width};
  @media (max-width:767px){
    margin:${(props) => props.width === "auto"?"0 0 30px 0":"auto"};
  }
`;

const ErrorText = styled.div`
  color: ${Colors.Red};
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 5px;
  position: absolute;
`;

type ContainerStyledProps = {
  width?: string;
};

export type LabeledInputProps = InputProps & { label?: string, labelInputwidth?: string }

export default function LabeledInput({
  label,
  labelInputwidth,
  ...inputProps
}: LabeledInputProps) {
  return (
    <Container width={labelInputwidth ?? "100%"}  >
      {label && <Label text={label} />}
      <Input {...inputProps} />
      {inputProps.error && <ErrorText>{inputProps.error}</ErrorText>}
    </Container>
  );
}
