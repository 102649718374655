import gql from 'graphql-tag';

const query = gql`
  query getOrgList {
    getOrgList {
      _id
      name
    }
  }
`;

export default query;
