import gql from 'graphql-tag';

const query = gql`
query listMatch($campaignId:String, $onlyActive:Boolean, $sortByAmount:Boolean){
  listMatch(campaignId:$campaignId , onlyActive: $onlyActive, sortByAmount:$sortByAmount) {
    _id
    name
    organizationId
    startDate,
    endDate,
    matchAmount,
    description,  
    posterUrl
    active
    isComplete
    matchedAmount,
    completed,
    donors
    }
  }`;
  
export default query;