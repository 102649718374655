import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import DateTimePicker from 'react-datetime-picker';
import styled from 'styled-components';
import { Title } from '../components/DashboardLayout';
import Button, { ButtonTypes } from '../elements/Button';
import { useHistory } from "react-router-dom";
import Joi from "@hapi/joi";
import * as Schema from "../utils/Schema";
import Dropdown from "../elements/DropDown";
import Label from '../elements/Label';
import LabeledInput from "../elements/LabeledInput";
import TextArea from '../elements/TextArea';
import makeEventHandler from '../utils/makeEventHandler';
import CREATE_MATCH from '../graphql/mutations/createMatch.mutation';
import UPDATE_MATCH from '../graphql/mutations/updateMatch.mutation';
import ErrorText from '../elements/ErrorText';
import * as ErrorUtil from "../utils/ErrorUtil";
import defaultImg from '../assets/images/defaultCampaignImg.jpg';
import UPLOAD_FILES from '../graphql/mutations/uploadFiles.mutation';
import { useDropzone } from "react-dropzone";
import Loader, { LoaderSizes } from '../elements/Loader';
import { Colors } from '../styles/Colors';
import moment from 'moment';
import Icon, { Icons } from '../elements/Icon';
import { DashboardLayout, LeftLayout, Content, MediaDetails, StyleCampaignDetails, ImagePreview, ThumbNail, DefaultImage, PosterImage, Spacer, CampaignMediaContent, ThumbHeading, DateError, ContentInner, DateContainer, CancelIcon, DatePlaceHolder, ClearDate } from "./NewCampaigns.page";
import { useSelector } from 'react-redux';
import { MatchstikState } from '../redux/store';
import GET_LIST_MATCH from "../graphql/queries/listMatches.query";
import GET_MATCH from "../graphql/queries/getMatch.query";
import * as Price from '@matchstik/utils/.dist/price';
import toast, { Toaster } from 'react-hot-toast';
import { successMessages } from '../utils/MessageUtil';

const schema = Joi.object({
  name: Schema.organization.name().error(([error]) => {
    const message = "Match name is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  timeFrame: Schema.organization.timeFrame().error(([error]) => {
    const message = "Match Time Frame is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  matchAmount: Schema.organization.matchAmount().error(([error]) => {
    const message = "Match amount is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  startDate: Schema.organization.startDate().error(([error]) => {
    const message = "Match start date is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  endDate: Schema.organization.endDate().error(([error]) => {
    const message = "Match endDate is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  matchPosterUrl: Schema.organization.matchPosterUrl().error(([error]) => {
    const message = "Match Image is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
});

const MatchInput = styled.div`
display: flex;
`;

const InputSpacer = styled.div`
margin: 10px;
`;

enum ErrorKeyEnum {
  Name = "name",
  MatchAmount = "matchAmount",
  TimeFrame = "timeFrame",
  StartDate = "startDate",
  EndDate = "endDate",
  MatchPosterUrl = "matchPosterUrl",
}

export enum DropDownOptions {
  WithOutDate = "Match will be active until match amount is reached",
  WithDate = "Match will end on a specific date"
}

type NewMatchesPageProps = {};

const NewMatchesPage: React.FC<NewMatchesPageProps> = ({ }) => {

  /*State */
  const appState: any = useSelector((state: MatchstikState) => state.user);
  const organization = appState?.organization;
  const organizationId = organization?._id;
  const [name, setName] = useState("");
  const [matchAmount, setMatchAmount] = useState("");
  const [matchedAmount, setMatchedAmount] = useState("");
  const [timeFrame, setTimeFrame] = useState(DropDownOptions.WithOutDate);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<any>(() => {
    if (timeFrame) {
      return {
        label: timeFrame,
        value: timeFrame
      }
    }
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [description, setDescription] = useState("");
  const [matchPosterUrl, setPosterUrl] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrorsInternal] = useState({
    [ErrorKeyEnum.Name]: null,
    [ErrorKeyEnum.TimeFrame]: null,
    [ErrorKeyEnum.MatchAmount]: null,
    [ErrorKeyEnum.StartDate]: null,
    [ErrorKeyEnum.EndDate]: null,
    [ErrorKeyEnum.MatchPosterUrl]: null,
  });
  const currentDate = moment(new Date()).format('MM/DD/YYYY');

  //enum to array conversion
  const timeFrameArray = [];
  for (const [propertyKey, propertyValue] of Object.entries(DropDownOptions)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    timeFrameArray.push({ label: propertyValue, value: propertyValue });
  }

  /* Actions */
  const eventHandler = makeEventHandler(() => setError(""));
  const setFieldErrors = (field: string, message: string | null) => {
    const newFieldErrors: any = {
      [field]: message,
    };
    setFieldErrorsInternal(newFieldErrors);
  };
  const onChangeName = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.Name, null);
    setName(value);
  });
  const onChangeMatchAmount = eventHandler((value: string) => {
    const goalValue = /^[0-9\b]+$/;
    if (value.match(goalValue) || value === "") {
      setMatchAmount(value);
    }
  });

  const onChangeStartDate = ((value: any) => {
    const selectedStartDate = moment(value).format('MM/DD/YYYY');
    let selectedEndDate = moment(endDate).format('MM/DD/YYYY');
    if (selectedStartDate >= currentDate) {
      setStartDate(value);
      if (selectedStartDate > selectedEndDate) {
        setEndDate(undefined);
      }
    }
  });

  const onChangeEndDate = ((value: any) => {
    const selectedStartDate = moment(startDate).format('MM/DD/YYYY');
    let selectedEndDate = moment(value).format('MM/DD/YYYY');
    if (selectedEndDate >= currentDate && selectedEndDate >= selectedStartDate) {
      setEndDate(value);
    }
  });

  const onChangeDescription = eventHandler((value: string) => {
    setDescription(value);
  });

  const onClearEndDate = (() => {
    setEndDate(undefined);
  });

  const onCancel = (() => {
    setPosterUrl("");
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files: File[]) => {
      setLoaded(true);
      uploadFiles({
        variables: {
          files: [files[0]],
        },
      });
    },
  });


  const history = useHistory();
  const historyState: any = history.location.state
  const campaignId = historyState?.campaignId
  const matchId = historyState?.matchId
  const campaignName = historyState?.campaignName
  const posterUrl = historyState?.posterUrl;


  /** date format */
  const newSelectStartDate = startDate && moment(startDate).format('MM/DD/YYYY');
  const selectStartDate = newSelectStartDate && new Date(newSelectStartDate).getTime();
  const showStartDate = selectStartDate ? selectStartDate : "";
  const newSelectEndDate = endDate && moment(endDate).format('MM-DD-YYYY');
  const selectEndDate = newSelectEndDate && new Date(newSelectEndDate).getTime();
  const showEndDate = selectEndDate ? selectEndDate : "";

  /** GraphQL */

  const { data } = useQuery(GET_MATCH, {
    fetchPolicy: "network-only",
    variables: {
      matchId,
    },
    onCompleted: (data: any) => {
      const matchDetail = data?.matches
      if (matchDetail) {
        setName(matchDetail.name || "");
        setMatchAmount(matchDetail.matchAmount && (Price.output(matchDetail.matchAmount)).toString());
        setMatchedAmount(matchDetail.matchedAmount && (Price.output(matchDetail.matchedAmount)).toString());
        setSelectedTimeFrame({ label: matchDetail.timeFrame, value: matchDetail.timeFrame })
        setTimeFrame(matchDetail.timeFrame);
        setStartDate(
          matchDetail.startDate &&
          new Date(Number(matchDetail.startDate)) || "");
        setEndDate(
          matchDetail.endDate &&
          new Date(Number(matchDetail.endDate)) || "");
        setDescription(matchDetail.description || "");
        setPosterUrl(matchDetail.posterUrl || "");
      }
    }
  });

  /** Create Match */
  const [onCreateMatchSubmit] = useMutation(CREATE_MATCH, {
    refetchQueries: [{
      query: GET_LIST_MATCH, variables: {
        campaignId
      },
    }],
    awaitRefetchQueries: true,
    onCompleted: async () => {
      toast.success(successMessages.MATCH_SUCCESS);
      setTimeout(() => {
        setLoading(false);
        history.push(`/dashboard/campaigns/matches`, { campaignId: campaignId, campaignName: campaignName, posterUrl:posterUrl, editCampaign: true });
      }, 2000);
    },
    onError: async (error: any) => {
      const errorMsg = ErrorUtil.getErrorMessage(error);
      setError(errorMsg);
    },
  })

  const onCreateMatch = async () => {
    const params = schema.validate({
      name,
      timeFrame,
      matchAmount,
      startDate,
      endDate,
      matchPosterUrl,
    });
    const { error: schemaError } = params;
    if (schemaError) {
      const { field, message } = JSON.parse(schemaError.message);
      setFieldErrors(field, message);
      return;
    }

    setError("");
    setLoading(true);
    onCreateMatchSubmit({
      variables: {
        params: {
          "name": name,
          "matchAmount": matchAmount ? (Number(matchAmount) * 100).toString() : "",
          "timeFrame": selectedTimeFrame.value,
          "startDate": showStartDate.toString(),
          "endDate": showEndDate.toString(),
          "description": description,
          "posterUrl": matchPosterUrl,
          "campaignId": campaignId,
          "organizationId": organizationId,
        }
      }
    })

  };


  /** Update Match */
  const [updateMatchMutation] = useMutation(
    UPDATE_MATCH,
    {
      refetchQueries: [{
        query: GET_LIST_MATCH, variables: {
          campaignId
        },
      }],
      awaitRefetchQueries: true,
      onCompleted: async () => {
        toast.success(successMessages.MATCH_UPDATE);
        setTimeout(() => {
          setLoading(false);
          history.push(`/dashboard/campaigns/matches`, { campaignId: campaignId, campaignName: campaignName, posterUrl:posterUrl, editCampaign: true });
        }, 1000);
      },
      onError: async (error: any) => {
        const errorMsg = ErrorUtil.getErrorMessage(error);
        setError(errorMsg);
      },
    }
  );

  const onUpdateMatch = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    ;
    const params = schema.validate({
      name,
      matchAmount,
      timeFrame,
      startDate,
      endDate,
      matchPosterUrl,
    });
    const { error: schemaError } = params;
    if (schemaError) {
      const { field, message } = JSON.parse(schemaError.message);
      setFieldErrors(field, message);
      return;
    }

    setError("");
    if (Number(matchAmount) < Number(matchedAmount)) {
      setError("Match amount can't be less then donation amount");
    }
    else {
      setLoading(true);
      updateMatchMutation({
        variables: {
          params: {
            "_id": matchId,
            "name": name,
            "matchAmount": matchAmount ? (Number(matchAmount) * 100).toString() : "",
            "timeFrame": selectedTimeFrame.value,
            "startDate": showStartDate.toString(),
            "endDate": showEndDate.toString(),
            "description": description,
            "posterUrl": matchPosterUrl,
          }
        }
      });
    }
  };

  /** Upload Image */
  const [uploadFiles] = useMutation(UPLOAD_FILES, {
    onCompleted: async (data) => {
      const imageUrl = data?.uploadFiles?.[0]?.url;
      setPosterUrl(imageUrl);
      setLoaded(false);
    },
  });

  const matchesData = data?.matches
  const getStartDate = matchesData?.startDate && new Date(Number(matchesData?.startDate)) || "";

  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <Title>{matchId ? "Edit Match" : "New Match"} </Title>
      <DashboardLayout>
        <LeftLayout>
          <Content>
            <MatchInput>
              <LabeledInput
                label="MATCH NAME"
                placeholder="Match Name"
                value={name}
                type="text"
                width="260px"
                onChange={onChangeName}
                error={fieldErrors[ErrorKeyEnum.Name]}
                labelInputwidth="auto"
              />
              <InputSpacer />
              <DateError>
                <LabeledInput
                  label="MATCH AMOUNT"
                  placeholder="$0.00"
                  value={matchAmount}
                  type="text"
                  onChange={onChangeMatchAmount}
                  width="355px"
                  labelInputwidth="auto"
                  error={fieldErrors[ErrorKeyEnum.MatchAmount]}
                />
              </DateError>
            </MatchInput>
            <Spacer />
            <Label text="MATCH TIMING"></Label>
            <StyleCampaignDetails>
              <Dropdown
                placeholder="Specify Match Time Frame"
                value={selectedTimeFrame}
                onChange={({ value }: any) => {
                  setSelectedTimeFrame({ label: value, value })
                  setTimeFrame(value);
                  setEndDate(undefined);
                }}
                width="290px"
                options={timeFrameArray}
                error={fieldErrors[ErrorKeyEnum.TimeFrame]}
              />
              <InputSpacer />
              <DateContainer>
                <DateError >
                  <DateTimePicker
                    className="showDate"
                    value={startDate}
                    format="MM-dd-y"
                    onChange={onChangeStartDate}
                    clearIcon={null}
                    disableClock
                    minDate={matchId && getStartDate <= new Date() ? getStartDate : new Date()}
                  />
                  {!startDate ? <ErrorText margin="5px 0 0 0 " align='left'>{fieldErrors[ErrorKeyEnum.StartDate]}</ErrorText> : ""}
                </DateError>
                <DatePlaceHolder date={startDate}>Match Start Date </DatePlaceHolder>
              </DateContainer>
              {selectedTimeFrame.value === DropDownOptions.WithDate &&
                <DateContainer>
                  <DateError >
                    <DateTimePicker
                      className="showDate"
                      value={endDate}
                      format="MM-dd-y"
                      onChange={onChangeEndDate}
                      clearIcon={null}
                      disableClock
                      minDate={startDate ? startDate : new Date()}
                    />
                    <ErrorText margin="5px 0 0 0 " align='left'>{fieldErrors[ErrorKeyEnum.EndDate]}</ErrorText>
                  </DateError>
                  <DatePlaceHolder date={endDate} >Match End Date </DatePlaceHolder>
                  <ClearDate endDate={endDate}><Icon icon={Icons.Cancel} size={14} color={Colors.Grey2} hoverColor={Colors.Pink} onClick={onClearEndDate} /></ClearDate>
                </DateContainer>}
            </StyleCampaignDetails>
            <Spacer />
            <TextArea
              placeholder="Match Description"
              value={description}
              width="670px"
              onChange={onChangeDescription}
            />
            <Spacer />
            <MediaDetails>
              <ImagePreview>
                <ThumbNail>
                  {loaded && <Loader size={LoaderSizes.Small} color={Colors.Pink} />}
                  {matchPosterUrl && !loaded && <PosterImage src={matchPosterUrl} />}
                  {!matchPosterUrl && !loaded && <DefaultImage src={defaultImg} />}
                </ThumbNail>
                {matchPosterUrl && !loaded &&
                  <CancelIcon>
                    <Icon icon={Icons.Cancel} size={14} color={Colors.Grey2} hoverColor={Colors.Pink} onClick={onCancel} />
                  </CancelIcon>}
              </ImagePreview>
              <CampaignMediaContent>
                <ContentInner>
                  <Label text={'MATCH AVATAR'}></Label>
                  <ThumbHeading>Choose an image for your new match.</ThumbHeading>
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()}/>
                    <Button
                      type={ButtonTypes.Button}
                      text={"Add Media"}
                      border="10px"
                      width='126px'
                    />
                  </div>
                  <ErrorText margin='0px'>{fieldErrors[ErrorKeyEnum.MatchPosterUrl]}</ErrorText>
                </ContentInner>
              </CampaignMediaContent>
            </MediaDetails>

            <Spacer />
            {error && <ErrorText margin='-20px 0px' align="left">{error}</ErrorText>}
            <Button
              type={ButtonTypes.Button}
              text={matchId ? "Save Update" : "Confirm New Match"}
              border="10px"
              width='316px'
              height='57px'
              onClick={matchId ? onUpdateMatch : onCreateMatch}
              icon={Icons.RightChevron}
              loading={loading}
            />
          </Content>
        </LeftLayout>
      </DashboardLayout>
    </>
  );
};
export default NewMatchesPage;
