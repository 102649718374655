import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../styles/Colors";
import Icon, { Icons } from "../elements/Icon";
import * as Auth from "../utils/Auth";
import url from "url";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as UserActions from "../redux/actions/user.actions";
import { MatchstikState } from "../redux/store";
import { useHistory } from "react-router-dom";
import IS_SUPER_USER from "../graphql/queries/isSuperUser.query";
import { useLazyQuery, useQuery } from "@apollo/client";
import PageLoader from "./PageLoader";
import Sidebar from "./Sidebar";

const Container = styled.div`
  // position: absolute;
  // width: 100vw;
`;
export const Heading = styled.div`
  font-weight: 300;
  font-size: 28px;
  line-height: 42px;
  color: ${Colors.Grey1};
  margin: 0px 0 30px 0;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: normal;
    margin: 0px 0 18px 0;
    &:after {
      bottom: -14px;
    }
  }
  @media (max-width: 479px) {
    font-size: 16px;
  }
`;

const BodyContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: fill-available;
  padding: 30px;
  min-height: calc(100% - 60px);
  @media (max-width: 767px) {
    padding: 30px 15px;
  }
`;
const BreadCrumb = styled.div`
  margin: 0 0 23px 0;
  display: flex;
`;
const BreadCrumbItem = styled.div`
font-weight: normal;
font-size: 12px;
line-height: 14px;
color:${Colors.Grey2};
display:flex;
  &.active{
    &:before{
      display: inline-block;
      padding-right: 10px;
      padding-left: 10px;
      color: #6c757d;
      content: ">";
      font-size: 20px;
      position: relative;
      top: 1px;
    }
  }
  a{
    color:${Colors.Pink};
    text-decoration: underline;
  }
}
`;

const Content = styled.div`
  height: calc(100vh - 70px);
  width: calc(100% - 220px);
  padding-left: 220px;
  padding-top:70px;
  @media (max-width:991px){
    width: calc(100% - 0px);
    padding-left:0px;
  }
}
`;

export const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  color: ${Colors.Grey1};
  margin: 0px 0 25px 0;
  padding: 12px 0 0 0;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 36px;
    height: 4px;
    background: ${Colors.Pink};
    left: 0;
    top: 0px;
  }
  @media (max-width: 767px) {
    font-size: 26px;
  }
`;

const TopNavigation = styled.form`
  position: fixed;
  height: 50px;
  width: calc(100% - 50px);
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.White};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  z-index: 99;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const LeftSection = styled.div`
  display: flex;
  height: 100%;
`;

const Humburger = styled.div`
  padding-right: 25px;
  border-right: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 25px;
  cursor: pointer;
`;
const Search = styled.div`
  background: #f9f9f9;
  border-radius: 10px;
  height: 100%;
  width: 375px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    position: absolute;
    top: 70px;
    width: 100%;
    left: -900px;
    transition: 0.5s;
    width: calc(100% - 40px);
    &.show {
      left: 0px;
    }
  }
`;
const SearchMobile = styled.div`
  display: none;
  height: 100%;
  align-items: center;
  cursor: pointer;
  @media (max-width: 767px) {
    display: flex;
  }
`;
const StyleInput = styled.input`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 14px 12px;
  border: 0;
  background: transparent;
  height: 34px;
  font-size: 14px;
  line-height: 17px;
  color: ${Colors.Grey4};
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 14px;
    line-height: 17px;
    color: ${Colors.Grey4};
  }
`;
const OrgDetails = styled.div`
  display: flex;
  align-items: center;
`;

export const OrgLogoContainer = styled.div`
  border: 1px solid ${Colors.Grey5};
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
  position: relative;
`;

export type DropDownProps = {
  display: string;
};

export const DropdownMenu = styled.div<DropDownProps>`
  position: absolute;
  top: 60px;
  left: -102px;
  min-width: 160px;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  display: ${(props) => (props.display === "1" ? "block" : "none")};
`;

export const MenuItem = styled.div`
  display: flex;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  line-height: 17px;
  color: ${Colors.Black2};
  :hover {
    color: ${Colors.Pink};
    & svg {
      color: ${Colors.Pink}!important;
    }
  }
`;

type DashboardLayoutProps = {
  children: React.ReactNode;
};

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  /* GraphQL */
  const { data } = useQuery(IS_SUPER_USER);
  const dispatch = useDispatch();
  const history = useHistory();
  const historyState: any = history.location.state;
  const campaignId = historyState?.campaignId;
  const editCampaign = historyState?.editCampaign;
  const campaignName = historyState?.campaignName;
  const matchName = historyState?.matchName;
  const posterUrl = historyState?.posterUrl;
  const organizationType = historyState?.orgType;
  const getUser = () => dispatch(UserActions.getUser());
  const getDonor = () => dispatch(UserActions.getDonor());
  const userState: any = useSelector((state: MatchstikState) => state.user);
  const { userData: user, organization, loading, donor } = userState;
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [placeholderData, setPlaceholderData] = useState("");
  const [organizationName, setOrganizationName] = useState("")
  const [organizations, setOrganizations] = useState<any>([])
  const [items, setItems] = useState([]);
  const donorSlug = localStorage.getItem('donorData')
  useEffect(() => {
    getUser()
    history.listen((location: any, action: any) => {
      setShowSidebar(false);
    });
    if (items) {
      setItems(items);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(user)?.length > 0) {
      getDonor()
    }
  }, [user])

  const handleDonorClick = () => {
    history.push('/donor/' + donor?.slug);
  }

  useEffect(() => {
    if (organizationType === 'Non-Profit') {
      setPlaceholderData("Search for non-profits, fundraisers, etc.")
    } else {
      setPlaceholderData("Search for corporation, fundraisers, etc.")
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      if (!data?.isSuperUser && organization?.needSubscription) {
        if (!organization?.hasActiveSubscription || !organization?.stripeConnectId) {
          history.push(`/guest/settings`);
        }
      }
    }
  }, [loading]);

  const parsed = url.parse(window.location.toString(), true);
  const stripeConnectCode = parsed?.query?.code;
  useEffect(() => {
    if (!userState?.organization) {
      history.push('/donor/' + localStorage.getItem('donorSlug'));
    } else if(!stripeConnectCode){
      history.push('/dashboard/home');
    }
  }, [user]);

  if (!Auth.getToken()) {
    return <Redirect to="/login" />;
  }

  if (!loading && !user?.emailVerifiedAt) {
    return <Redirect to="/verify-email" />;
  }

  /* Render */
  // {user.organizationType === 'Non-Profit' ? " non-profits, fundraisers, etc." :
  //                  {"Search for non-profits, fundraisers, etc."}}

  return (
    <Container>
      {loading ? (
        <>
          <PageLoader />
        </>
      ) : (
        <>
          <TopNavigation>
            <Row>
              <LeftSection>
                <Humburger onClick={() => setShowSidebar(!showSidebar)}>
                  <Icon icon={Icons.Bars} color={Colors.Grey4} size={18} />
                </Humburger>
                <SearchMobile onClick={() => setShowSearchbar(!showSearchbar)}>
                  <Icon icon={Icons.SearchRegular} color={Colors.Grey4} size={18} margin='0px 16px 0px 0px' />
                </SearchMobile>
                <Search className={showSearchbar ? "show" : ""}>
                  <Icon icon={Icons.SearchRegular} color={Colors.Grey4} size={18} margin='0px 16px 0px 0px' />
                  <StyleInput placeholder={placeholderData} />
                </Search>
              </LeftSection>
              <OrgDetails>
                <Icon
                  icon={Icons.Bell}
                  size={24}
                  top="2px"
                  color={Colors.Grey4}
                />
                <OrgLogoContainer
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <Icon icon={Icons.UserSolid} size={24} />
                  <DropdownMenu
                    display={showDropdown ? "1" : "0"}
                  // onClick={()=>Auth.logout()}
                  >
                    <MenuItem onClick={handleDonorClick} >
                      <Icon
                        icon={Icons.UserRegular}
                        size={14}
                        color={Colors.Black2}
                        margin="0 10px 0 0"
                      />
                      Donor Profile
                    </MenuItem>
                    <MenuItem>
                      <Icon
                        icon={Icons.DashboardSolid}
                        size={14}
                        color={Colors.Black2}
                        margin="0 10px 0 0"
                      />
                      Org Dashboard
                    </MenuItem>

                    <MenuItem onClick={() => Auth.logout()}>
                      <Icon
                        icon={Icons.Logout}
                        size={14}
                        color={Colors.Black2}
                        margin="0 10px 0 0"
                      />
                      Logout
                    </MenuItem>
                  </DropdownMenu>
                </OrgLogoContainer>
              </OrgDetails>
            </Row>
          </TopNavigation>
          <Sidebar showSidebar={showSidebar} />
          <Content
            onClick={() => {
              setShowDropdown(false);
              setShowSidebar(false);
            }}
          >
            <BodyContainer>
              {campaignId && editCampaign && (
                <BreadCrumb>
                  <BreadCrumbItem>
                    <Link
                      to="/dashboard/campaigns"
                      style={{ cursor: "pointer" }}
                    >
                      Campaigns
                    </Link>
                  </BreadCrumbItem>
                  <BreadCrumbItem className="active">
                    <Link
                      to={{
                        pathname: "/dashboard/campaigns/overview",
                        state: {
                          campaignId: campaignId,
                          campaignName: campaignName,
                          posterUrl: posterUrl,
                          editCampaign: true,
                        },
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {campaignName}
                    </Link>
                  </BreadCrumbItem>
                </BreadCrumb>
              )}
              {matchName && (
                <BreadCrumb>
                  <BreadCrumbItem>
                    <Link
                      to={{
                        pathname: "/dashboard/campaigns/matches",
                        state: {
                          campaignId: campaignId,
                          campaignName: campaignName,
                          posterUrl: posterUrl,
                          editCampaign: true,
                        },
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Matches
                    </Link>
                  </BreadCrumbItem>
                  <BreadCrumbItem className="active">
                    {matchName}
                  </BreadCrumbItem>
                </BreadCrumb>
              )}
              <Heading>Welcome, {organization?.name}</Heading>
              {children}
            </BodyContainer>
          </Content>
        </>
      )}
    </Container>
  );
};

export default DashboardLayout;
