import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import img from "../assets/images/logo-icon.png";
import FOLLOWING from "../graphql/queries/following.query";
import FOLLOWER_LIST from "../graphql/queries/followersList.query";
import * as UserActions from "../redux/actions/user.actions";
import { MatchstikState } from "../redux/store";
import NoData from "../components/NoData";
import * as Time from "@matchstik/utils/.dist/time";

const FollowersText = styled.div`
  font-weight: Bold;
  font-size: large;
  margin-top: 30px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  @media screen and (max-width: 767px) {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const NameDetails = styled.div``;

const FollowingDetails = styled.div`
  margin-top: 20px;
  display: flex;
  column-gap: 10px;
`;
const Name = styled.div`
  color: #333333;
  font-weight: 700;
  cursor:pointer;
 

`;

const FollowingDate = styled.div`
  font-weight: 400;
  color: #333333;
`;

const ImageData = styled.div`
width: 50px;
height: 50px;
// min-width: 50px;
// min-height: 50px;
// max-width: 50px;
// max-height: 50px;
border-radius: 50%;
display: flex;
align-items: center;
z-index: 0;
justify-content: center;
border: 1px solid #e0e0e0;
position: relative;
box-sizing: border-box;
flex-shrink: 0;
-webkit-user-select: none;
overflow: hidden;
@media screen and (max-width: 767px) {
  width: 45px;
  height: 45px;
}

`;

const ProfileImage = styled.img<any>`
position: absolute;
min-height: 100%;
min-width: 100%;
height: auto;
width: auto;
 
`;

const Tabcontainer = styled.div`
  margin:30px 0;
  padding-bottom: 30px;
`;


type FollowingProps = {
  donorId: String;
  donor: any;
};
const TIME_FORMAT = "MMM DD, YYYY";

const Following: React.FC<FollowingProps> = ({ donorId, donor }) => {
  const [followingList, setFollowingList] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const history = useHistory();

  const [getFollowingList] = useLazyQuery(FOLLOWING, {
    variables: {
      donorId: donorId,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setFollowingList(data?.getFollowingList);
    },
  });

  const [getAllFollowers] = useLazyQuery(FOLLOWER_LIST, {
    variables: {
      donorId: donorId,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setFollowersList(data?.getFollowerList);
    },
  });
  useEffect(() => {
    getAllFollowers();
    getFollowingList();
  }, []);

  const goToDonorProfile = (item: any) => {
    console.log("first", item)
    if (item?.type === "Organization") {
      if (item?.slug)
        history.push("/org/" + item?.slug);
      else
        history.push("/org/" + item?._id);
    } else {
      history.push("/donor/" + item?.slug);
    }
  };

  return (
    <>
      <Tabcontainer>
        <FollowersText>
          {donor?.users?.firstName + "'s "}
          Following
        </FollowersText>
        {followingList.length > 0 ? (
          <Container>
            {followingList.map((item: any, index) => {
              return (
                <FollowingDetails key={index}>
                  {item?.following?.slug}
                  <ImageData>
                    <ProfileImage src={item?.imageUrl || img} />
                  </ImageData>
                  <NameDetails >
                    <Name onClick={() =>
                      item?.type === "Organization" ? goToDonorProfile(item) :
                        item?.visibility?.toLowerCase() === "public"
                          ? goToDonorProfile(item)
                          : null
                    } >{item?.name}</Name>
                    <FollowingDate>
                      Following since {Time.format(item?.since, TIME_FORMAT)}{" "}
                    </FollowingDate>
                  </NameDetails>
                </FollowingDetails>
              );
            })}
          </Container>
        ) : (
          <FollowingDetails>
            <strong>You are not following any donors or non-profits yet</strong>
          </FollowingDetails>
        )}
        {followersList?.length > 0 &&
          <>
            <hr style={{ marginTop: "40px" }}></hr>
            <FollowersText>{ }&nbsp;Followed by</FollowersText>
            <Container>
              {followersList.map((item: any, index) => {
                return (
                  <FollowingDetails
                    key={index}>
                    <ImageData>
                      <ProfileImage src={item?.imageUrl || img}></ProfileImage>
                    </ImageData>
                    <NameDetails>
                      <Name onClick={() =>
                        item.visibility.toLowerCase() === "public"
                          ? goToDonorProfile(item)
                          : null
                      } >{item?.name}</Name>
                      <FollowingDate>
                        Following since {Time.format(item?.since, TIME_FORMAT)}{" "}
                      </FollowingDate>
                    </NameDetails>
                  </FollowingDetails>
                );
              })}
            </Container>
          </>
        }
      </Tabcontainer>
    </>
  );
};

export default Following;
