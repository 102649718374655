import gql from 'graphql-tag';

const query = gql`
  query organizations {
    organizations {
      _id
      userId
      active
      name
      description
      message
      recurringDonationMessage
      phoneNumber
      email
      address
      city
      state
      zip
      websiteUrl
      stripeConnectId
      logoUrl
      posterUrl
      ein
      headerImageUrl
      footerImageUrl
      signatureImageUrl
      signatureText
      signaturePosition
      stripeConnectId
      status
      donorFees
      donationReceiptText
      meta {
        createdAt
      }
    }
  }
`;

export default query;
