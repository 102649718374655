import gql from "graphql-tag";

const query = gql`
query getFollowingList($donorId: String){
  getFollowingList(donorId: $donorId){
    followerId
    _id
    followingId
    since
    slug
    visibility
    imageUrl
    name
    type
   }
}
`;

export default query;

