import gql from 'graphql-tag';

const mutation = gql`
mutation createDonationFailedTask($executeAt: String, $membershipId: String){
  createDonationFailedTask(executeAt: $executeAt, membershipId: $membershipId){
      _id
  }
}`;

export default mutation;
