import React from "react";
import styled from 'styled-components';
import { Colors } from '../styles/Colors';
import * as Polished from 'polished';
import Icon from "./Icon";
import { transform } from "typescript";

type StyledInputProps = {
  margin?: string;
  padding?: string;
  width: string;
  error?: boolean;
  icon?: any;
}

const InputStyled = styled.input<StyledInputProps>`
  /* background-color: ${Colors.VeryLightBlue}; */
  color: ${Colors.Grey1};
  outline: none;
  border: 0px;
  border-radius: 10px;
  height: 48px;
  width: ${props => props.width};
  // width:270px;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0 15px;
  transition: all 0.2s;
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  border: ${props =>
    props.error
      ? `1px solid ${Colors.Red}`
      : `1px solid ${Colors.Grey5}`};

  ::placeholder {
    color: ${Colors.Grey4};
    // font-family: 'Nunito Sans';
    font-weight: 600;
  }

  &:hover {
    /* background-color: ${Polished.darken(0.01, Colors.VeryLightBlue)}; */
    /* border: ${props =>
    props.error
      ? `1px solid ${Colors.Red}`
      : `1px solid ${Polished.darken(0.01, Colors.VeryLightBlue)}`}; */
  }

  &:focus {
    /* background-color: ${Polished.darken(0.02, Colors.VeryLightBlue)}; */
    /* border: ${props =>
    props.error
      ? `1px solid ${Colors.Red}`
      : `1px solid ${Polished.darken(0.02, Colors.VeryLightBlue)}`}; */
  }
    @media (max-width:767px)
    {
      width:calc(100% - ${props => props.icon?"55px":"30px"});
    }
`;

const IconStyle = styled.div`
   position:absolute;
   top:50%;
   left:10px;
   transform:translateY(-50%);
`;

export type InputProps = {
  autoFocus?: boolean | undefined;
  placeholder?: string;
  value: string;
  defaultValue?: string;
  type?: string;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  margin?: string;
  padding?: string;
  width?: string;
  error?: string | null;
  maxLength?: number;
  icon?: any;
  readOnly?: any;
};

export default function Input({
  autoFocus,
  placeholder,
  value,
  defaultValue,
  type = 'text',
  onChange,
  onBlur,
  onFocus,
  margin,
  padding,
  width = 'fill-available',
  error,
  maxLength,
  readOnly,
  icon
}: InputProps) {
  return (
    <>
      {icon && (
        <IconStyle>
          <Icon
            icon={icon}
            color={Colors.Grey4}
            size={24}
          />
        </IconStyle>
      )}

      <InputStyled
        autoFocus={autoFocus}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        margin={margin}
        padding={padding}
        width={width}
        error={Boolean(error)}
        maxLength={maxLength}
        icon={icon}
        readOnly={readOnly}
      />
    </>
  );
}
