import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import IUser from "@matchstik/models/.dist/interfaces/IUser";
import {
  UserActionCreatorTypes,
  UserActionTypes,
} from "../actions/user.actions";

export enum ErrorKeyEnum {
  Global = "Global",
  UserEmail = "UserEmail",
  ConfirmUserEmail = "ConfirmUserEmail",
  UserFullName = "UserFullName",
  UserPhoneNumber = "UserPhoneNumber",
}

export type ErrorMap = {
  [key in ErrorKeyEnum]?: string;
};

type UserReducerState = {
  loading: boolean;
  errors: ErrorMap;
  userData: IUser | {};
  organization: IOrganization | {};
  donor?: any;
};

export default function reducer(
  state: UserReducerState = {
    loading: true,
    errors: {},
    userData: {},
    organization: {},
    donor: {},
  },
  action: UserActionCreatorTypes
) {
  const { type, payload } = action;

  switch (type) {
    case UserActionTypes.SET_LOADING:
      return { ...state, loading: payload };

    case UserActionTypes.SET_USER:
      return { ...state, userData: payload };
    
    case UserActionTypes.SET_FULL_USER:
      return {...state, userData: payload };

    case UserActionTypes.SET_ORGANIZATION:
      return { ...state, organization: payload };

    /********************************************************************************
     *  Create Stripe Token
     *******************************************************************************/

    case UserActionTypes.CREATE_STRIPE_TOKEN:
      return {
        ...state,
      };
    case UserActionTypes.SET_DONOR_REQUEST:
      return { ...state, donor: payload };
    default:
      return state;
  }
}

/********************************************************************************
 *  Set Error
 *******************************************************************************/

function setError(
  state: UserReducerState,
  { key, errorMsg }: { key: ErrorKeyEnum; errorMsg: string }
): UserReducerState {
  return {
    ...state,
    errors: {
      ...state.errors,
      [key]: errorMsg,
    },
  };
}

/********************************************************************************
 *  Set Loading
 *******************************************************************************/

function setLoading(
  state: UserReducerState,
  { loading }: { loading: boolean }
): UserReducerState {
  return {
    ...state,
    loading,
  };
}
