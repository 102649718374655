import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ModalContainer, ModalContent, ModalHeader } from "./Modal";
import * as AppActions from "../../redux/actions/app.actions";


const Container = styled.div`
  width: 100%;
  padding: 20px 0
`;

const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
`;
const BoldText = styled.span`
  font-weight: bold;
`;

type TextEditorModalProps = {

};

const TextEditorModal: React.FC<TextEditorModalProps> = () => {

  /* Actions */
  const dispatch = useDispatch();
  const popModal = () => dispatch(AppActions.popModal());


  /** Render */
  return (
    <ModalContainer width="550px">
      <ModalHeader title="Donation Receipt Tags" close={popModal} />
      <ModalContent>
        <Container>
          <Text>
            Use these tags in the body of your Donation Receipt Text wherever you want to merge data into your receipt.
          </Text>
          <Text>
            <BoldText>&#123;DonationAmount&#125;</BoldText> = Total amount donated (e.g., $10.75)
          </Text>
          <Text>
            <BoldText>&#123;DonationDate&#125;</BoldText> = Donation Date
          </Text>
          <Text>
            <BoldText>&#123;DonorName&#125;</BoldText> = Donor's name
          </Text>
          <Text>
            <BoldText>&#123;OrganizationEIN&#125;</BoldText> = Organization's EIN
          </Text>
          <Text>
            <BoldText>&#123;OrganizationName&#125;</BoldText> = Organization's name
          </Text>
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default TextEditorModal;
