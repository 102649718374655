import React, { useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../styles/Colors";
import { Title } from "../components/DashboardLayout";
import Icon, { Icons } from "../elements/Icon";
import campaignsSrc from "./../assets/images/campaignsimage.jpg";
import Button, { ButtonTypes, IconPositionTypes } from "../elements/Button";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import GET_LIST_CAMPAIGN from "../graphql/queries/listCampaign.query";
import * as Price from '@matchstik/utils/.dist/price';
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import { MatchstikState } from "../redux/store";
import toast, { Toaster } from "react-hot-toast";
import { successMessages } from "../utils/MessageUtil";
import { DONATION_URL } from "../env";


const Circle = styled.span`
display: flex;
width: 33px;
height: 33px;
background-color: #ff0080;
border-radius: 50%;
line-height: 33px;
}

`;
const CircleInner = styled.span`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
}
`;

type campaignCardActive = {
    active?: boolean;
    isComplete?: boolean;
};

export const CampaignsLayout = styled.div<any>`
    display:flex;
    justify-content:space-between;
    @media (max-width:767px){
    display:block;
    }
    `;
export const LeftLayout = styled.div`
    width:65%;
    @media (max-width:1199px){
        width:60%;   
    }
    @media (max-width:767px){
        width:100%;
        margin:0 0 30px 0;
    }
    `;
export const CampaignsCard = styled.div`

`;
export const CampaignsList = styled.div<campaignCardActive>`
opacity: ${props => props.isComplete && '50%'};
background: #FFFFFF;
border:  ${({ active }) => (active ? '2px solid #ff0080' : '1px solid #c4c4c4')};
box-sizing: border-box;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
border-radius: 10px;
display: flex;
margin:0 0 30px 0;
cursor: pointer;
&:last-child{
 margin:0px;
}
@media (max-width:576px){
    display:block;
  }
`;
export const CampaignsImage = styled.div<any>`
width:150px;
background-image:url(${props => props.posterUrl});
background-size: cover;
background-repeat: no-repeat;
background-position: center center;
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
display: flex;
 @media (max-width:576px){
    width:100%;
    border-bottom-left-radius:0px;
    border-top-right-radius: 10px;
    height:150px;
  }
`;
export const CampaignsDesc = styled.div`
    padding:12px 25px;
    width:calc(100% - 150px);
    display:flex;
    align-items:center;
    @media (max-width:1199px){
        padding:12px;
        width:calc(100% - 150px);
      }
      @media (max-width:576px){
        width:calc(100% - 24px);
      }
`;
export const CampaignsInner = styled.div`
width:100%;
`;

export const CampaignsHeading = styled.div`
font-weight: 600;
font-size: 24px;
line-height: 36px;
display: flex; justify-content: space-between;
color:${Colors.Pink};
margin:0 0 13px 0;
position:relative;
@media (max-width:1199px){
    font-size:18px;
    line-height:27px;
    margin:0 0 8px 0;   
}
`;
export const CampaignsSection = styled.div`
  display:flex;
  justify-content:space-between;
  align-items: flex-end;
  @media (max-width:1199px){
    display:block;
  }
  @media (max-width:767px){
    display:flex;
  }
  @media (max-width:479px){
    display:block;
  }
`;
export const CampaignsProgress = styled.div`
     height:4px;
     background-color:${Colors.Grey5};
     margin:0 0 13px 0;
}
 `;
export const CampaignsProgressBar = styled.div<any>`
     height: 100%;
     font-size: 12px;
     line-height: 20px;
     color: #fff;
     text-align: center;
     background-color:${Colors.Pink}; 
     width:${props => props.progress};
     position:relative;
     &:after{
         content: "";
         background:${Colors.Pink}; 
         position: absolute;
         right: -2px;
         width:8px;
         height:8px;
         border-radius: 50%;
         top: -2px;
     }
 `;

export const ProgressText = styled.span`
       padding: 10,
        color: 'black',
        fontWeight: 900
`;
export const LeftPanel = styled.div`
    flex-wrap:wrap;
    @media (max-width:1199px){
        margin:0 0 13px 0;
    }
    @media (max-width:767px){
        margin:0;
    }
    @media (max-width:479px){
        margin:0 0 13px 0;
    }
`;

export const Amount = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color:${Colors.Black};
    @media (max-width:1199px){
        font-size:14px;
        line-height:20px; 
    }
`;
export const Desc = styled.div`
    font-weight:400;
    font-size: 18px;
    line-height: 27px;
    @media (max-width:1199px){
        font-size:14px;
        line-height:20px; 
    }
`;
export const RightPanel = styled.div`
    display:flex;
    justify-content:space-between;
    white-space: nowrap;
`;
export const Matchers = styled.div`
   margin:0 20px 0 0;
   font-weight: bold;
   font-size: 14px;
   line-height: 17px;
   color:${Colors.Grey2};
   display: flex;
   @media (max-width:1199px){
    font-size:11px;
    line-height:15px; 
  }
`;
export const Donors = styled.div`
font-weight: bold;
font-size: 14px;
line-height: 17px;
color:${Colors.Grey2};
display: flex;
@media (max-width:1199px){
    font-size:11px;
    line-height:15px; 
  }
`;
export const RightLayout = styled.div`
    width:30%;
    @media (max-width:1199px){
        width:35%;   
    }
    @media (max-width:767px){
        width:100%;
    }
    `;
export const CreateButton = styled.div`
      margin:0 0 30px 0;
      position: relative;
    `;

export const CardLayout = styled.div`
        background: #FFFFFF;
        border: 1px solid #F2F2F2;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
    `;
export const CardHeader = styled.div`
    font-size:12px;
    line-height:18px;
    padding: 14px 15px;
    color: ${Colors.Grey1};
    border-bottom: 1px solid ${Colors.Grey6};
    display:flex;
    font-weight:500;
    `;
export const CardBody = styled.div`
    `;
export const CardList = styled.div`
    border: 1px solid #F2F2F2;
    padding:20px;
    display: flex;
    align-items: center;
 `;
export const CardImage = styled.div`
 background: #FFFFFF;
 border: 1px solid #C4C4C4;
 width:78px;
 height:78px;
 border-radius:50%;
 margin-right:18px;
 text-align:center;
 display: flex;
 align-items: center;
 justify-content: center;
 `;
export const ThumbImage = styled.img`
   width: 60px;
   height: 60px;
 `;
export const CardDesc = styled.div`
//  width: calc(100% - 96px);
 width:100%;
 box-sizing:border-box;
 `;
export const CardHeading = styled.div`
     font-weight: 600;
     font-size: 14px;
     color: ${Colors.Grey1};
 `;
export const CardPrice = styled.div`
     font-size: 14px;
     font-weight: 400;
     color: ${Colors.Grey3};
 `;
export const CardProgress = styled.div`
     height:6px;
     background-color:${Colors.Grey5};
}
 `;
export const NoCampaignText = styled.div`
    font-size: 26px;
    font-weight: 600;
    color: #333333;
    border: 1px solid ${Colors.Grey6};;
    padding:100px 15px;
    border-radius: 10px;
    text-align: center;
}
 `;
export const CardProgressBar = styled.div`
     height: 100%;
     font-size: 12px;
     line-height: 20px;
     color: #fff;
     text-align: center;
     background-color:${Colors.Pink}; 
     width:85%;
     position:relative;
     &:after{
         content: "";
         background:${Colors.Pink}; 
         position: absolute;
         right: -2px;
         width: 12px;
         height: 12px;
         border-radius: 50%;
         top: -3px;
     }`;
export const CardProgressValue = styled.div`
     `;

export const IconContainer = styled.div`
     width: max-content;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
     `;

function getEmbedHTML(organizationId: string, text: string, buttonColor: string, color: string) {
    return (`
        <a href="javascript:void(0)" style="text-decoration:none;">
            <img src="/favicon.ico" onerror="window.Matchstik.preload('${organizationId}&&offlineDonation')" style="display:none;"> 
            <div class = "donorModal" style="
            background-color:${Colors.Grey6}; 
            color:${Colors.Grey2}; 
            border-radius: 10px; 
            height:57px; 
            width:100%; 
            display:inline-flex; 
            align-items:center; 
            justify-content:center; 
            font-weight: 500;
            font-size: 16px;
            "
            onClick="window.Matchstik.open('${organizationId}&&offlineDonation${true}')">${text}</div>
        </a>
        `);
}

interface CampaignsListPageProps { }

const CampaignsListPage: React.FC<CampaignsListPageProps> = ({ }) => {
    /* State */
    const appState: any = useSelector((state: MatchstikState) => state.user);
    const organization = appState?.organization;
    const organizationId = organization?._id;
    const [showData, setShowData] = React.useState(false);

    /* Actions */
    const history = useHistory();
    const historyState: any = history.location.state;
    const deleteCampaign = historyState?.deleteCampaign;
    const onCreateNewCampaign = (() => {
        history.push(`campaigns/new`);
    });
    const onCampaignsList = ((campaign: any) => {
        const campaignId = campaign._id;
        history.push({
            pathname: `campaigns/overview`,
            state: { campaignId: campaignId, campaignName: campaign.name, posterUrl: campaign.posterUrl, editCampaign: true, isDefault: campaign.isDefault }
        });
    });

    /* Graphql */
    const { data, loading } = useQuery(GET_LIST_CAMPAIGN, {
        variables: {
            organizationId,
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setShowData(true)
        }
    });

    useEffect(() => {
        if (deleteCampaign) {
            toast.success(successMessages.DELETE_CAMPAIGN);
        }
    }, [deleteCampaign])

    const campaignDetail = data?.listCampaign || [];

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `${DONATION_URL}/embed.js`;
        script.async = true;
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        }
    }, []);

    const HTML = getEmbedHTML(organization?._id, "Offline Donation", Colors.Pink, Colors.White);

    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <CampaignsLayout>
                <LeftLayout>
                    <Title>Campaigns</Title>
                    {campaignDetail?.length > 0 ?
                        <CampaignsCard>
                            {campaignDetail?.map((campaign: any, i: any) => {
                                let totalDonationAmount = campaign.totalDonationAmount === null ? "" : campaign.totalDonationAmount;
                                return (
                                    <CampaignsList key={i} onClick={() => onCampaignsList(campaign)} isComplete={campaign.isComplete}>
                                        <CampaignsImage posterUrl={campaign.posterUrl ? campaign.posterUrl : campaignsSrc} />
                                        <CampaignsDesc>
                                            <CampaignsInner>
                                                <CampaignsHeading>{campaign.name}
                                                    {campaign.isComplete && <Button
                                                        type={ButtonTypes.Button}
                                                        text="COMPLETE"
                                                        border="10px"
                                                        width='100px'
                                                        height='30px'
                                                    />}
                                                    {campaign.isDefault &&
                                                        <>
                                                            <p style={{ width: 'min-content', margin: '0' }} data-tip='Your Default Campaign'>
                                                            </p>
                                                            <Circle ><CircleInner> <Icon icon={Icons.Check} size={20} color={Colors.White} /></CircleInner> </Circle>
                                                        </>}
                                                    <ReactTooltip
                                                        place="right"
                                                        type="dark"
                                                        effect="solid"
                                                        clickable={false}
                                                    />
                                                </CampaignsHeading>
                                                {campaign.completed !== -1 && <CampaignsProgress>
                                                    <CampaignsProgressBar progress={campaign.completed + "%"}>
                                                    </CampaignsProgressBar>
                                                </CampaignsProgress>}
                                                <CampaignsSection>
                                                    <LeftPanel>
                                                        <Amount>${Price.output(totalDonationAmount, true)} </Amount>
                                                        {campaign.goal !== null && campaign.goal !== "" && <Desc>raised of ${Price.output(campaign.goal, true)} goal</Desc>}
                                                    </LeftPanel>
                                                    <RightPanel>
                                                        {campaign.totalMatchers ?
                                                            <Matchers><Icon icon={Icons.FireRegular} size={14} color={Colors.Grey2} margin='0px 6px 0px 0px' />{campaign.totalMatchers}x Multiplier</Matchers> : ""}
                                                        <Donors><Icon icon={Icons.HandHoldingHeart} size={14} color={Colors.Grey2} margin='0px 6px 0px 0px' />{campaign.donors} donors </Donors>
                                                    </RightPanel>
                                                </CampaignsSection>
                                            </CampaignsInner>
                                        </CampaignsDesc>
                                    </CampaignsList>
                                );
                            })}
                        </CampaignsCard> :
                        campaignDetail?.length === 0 && showData ?
                            <NoCampaignText>No Campaigns Found </NoCampaignText> : false}
                </LeftLayout>
                <RightLayout>
                    <CreateButton>
                        <Button
                            type={ButtonTypes.Button}
                            text={"Create New Campaign"}
                            border="10px"
                            height="57px"
                            onClick={onCreateNewCampaign}
                            iconPosition={IconPositionTypes.Left}
                            icon={Icons.HandHoldingHeart}
                        />
                    </CreateButton>
                    <CreateButton>
                        <div dangerouslySetInnerHTML={{ __html: HTML }} />
                        <IconContainer>
                            <Icon icon={Icons.HandHoldingUsd} color={Colors.Grey1} size={18} />
                        </IconContainer>
                    </CreateButton>
                    {/* <CardLayout>
                        <CardHeader><Icon icon={Icons.FireRegular} size={12} color={Colors.Grey1} margin='0px 12px 0px 0px' />Current Matchers</CardHeader>
                        <CardBody>
                            <CardList>
                                <CardImage>
                                    {<ThumbImage src={mysterySrc} />}
                                </CardImage>
                                <CardDesc>
                                    <CardHeading>Mystery Ranch</CardHeading>
                                    <CardPrice>$5,000 Matched </CardPrice>
                                    <CardProgress>
                                        <CardProgressBar></CardProgressBar>
                                    </CardProgress>
                                    <CardProgressValue>85% Complete</CardProgressValue>
                                </CardDesc>
                            </CardList>
                            <CardList>
                                <CardImage>
                                    <ThumbImage src={KenyonSrc} />
                                </CardImage>
                                <CardDesc>
                                    <CardHeading>Kenyon Noble</CardHeading>
                                    <CardPrice>$5,000 Matched </CardPrice>
                                    <CardProgress>
                                        <CardProgressBar></CardProgressBar>
                                    </CardProgress>
                                    <CardProgressValue>85% Complete</CardProgressValue>
                                </CardDesc>
                            </CardList>
                        </CardBody>
                    </CardLayout> */}
                </RightLayout>
            </CampaignsLayout>


        </>
    );
};

export default CampaignsListPage;
