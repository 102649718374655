import gql from "graphql-tag";

const query = gql`
query getCampaignBySlug($campaignSlug: String!,$organizationId: String){
    getCampaignBySlug(campaignSlug:$campaignSlug, organizationId:$organizationId){ 
          campaign{
          _id
          name
          goal
          startDate
          endDate
          description
          posterUrl
          meta {
              createdAt
          }
          isDefault
          totalDonationAmount
          donors
          completed
          totalMatched
          totalMatchers
          averageMatch
          gallery
          activeMatchers
          }
    },
  }
`;
export default query;