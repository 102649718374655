import gql from 'graphql-tag';

const query = gql`
query donationGraph($startDate:String,$endDate:String,$custom:Boolean, $dateRange:String){
  donationGraph(startDate:$startDate,endDate:$endDate,custom:$custom,dateRange:$dateRange) {
      time
      amount
}
}
`;

export default query;
