import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../styles/Colors";
import { Title } from "../components/DashboardLayout";
import Icon, { Icons } from "../elements/Icon";
import TOTAL_DONATIONS from "../graphql/queries/totalDonations.query";
import DashboardGraph from "../elements/DashboardGraph";
import * as Price from "@matchstik/utils/.dist/price";
import Button from "../elements/Button";
import DateTimePicker from "react-datetime-picker";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { TabListItemEnum } from "@matchstik/models/.dist/enums/TabListItemEnum";
import toast, { Toaster } from "react-hot-toast";
import { successMessages } from "../utils/MessageUtil";
import { DONATION_URL } from "../env";
import { IconContainer } from "./CampaignsList.page";
import { useSelector } from "react-redux";

export const DashboardLayout = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    display: block;
  }
`;
export const DashboardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .dashboardTitle {
    margin-right: 20px;
  }
  @media (max-width: 479px) {
    display: block;
  }
`;
export const TabsLayout = styled.div`
  width: 100%;
  //  flex: 1 0 100%;
  margin: 0 0 30px 0;
  display: flex;
  flex-wrap: wrap;
`;

export const TabsList = styled.form<{ active?: boolean }>`
  border-radius: 10px;
  padding: 5px 10px;
  transition: all 0.2s;
  font-weight: 400;
  color: ${[(props) => (props.active ? Colors.Pink : Colors.Grey4)]};
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${[(props) => props.active && "rgba(230,22,123,0.1)"]};
  font-weight: ${[(props) => props.active && "600"]};
  &:last-child {
    margin: 0px;
    @media (max-width: 479px) {
      flex-direction: column;
      width: 100%;
      margin: 20px 0 0 0;
    }
  }
  .react-datetime-picker {
    .react-datetime-picker__wrapper {
      padding: 2px 12px;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
    }
  }
`;
export const TabListItem = styled.div`
  margin: 0;
  &.space {
    margin-right: 15px;
  }
`;
export const DateSpace = styled.div`
  margin: 0px 5px;
  display: flex;
  align-items: center;
`;

export const LeftLayout = styled.div`
  width: 65%;
  @media (max-width: 1199px) {
    width: 60%;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin: 0 0 30px 0;
  }
`;
export const TopSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.Grey5};
  padding-bottom: 16px;
  margin-bottom: 20px;
`;
export const MiddleSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;
export const TotalRaised = styled.div`
  width: 55%;
  // border-right: 1px solid ${Colors.Grey5};
`;
export const Goal = styled.div`
  width: 40%;
`;
export const Transactions = styled.div`
  width: 45%;
  border-right: 1px solid ${Colors.Grey5};
`;

export const Donors = styled.div`
  width: 50%;
`;
export const CardTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${Colors.Black};
  text-transform: uppercase;
  @media (max-width: 479px) {
    font-size: 10px;
    line-height: normal;
  }
`;
export const Amount = styled.div`
  font-weight: 600;
  font-size: 42px;
  line-height: 63px;
  color: ${Colors.Pink};
  &.grayColor {
    color: ${Colors.Grey5};
  }
  @media (max-width: 1199px) {
    font-size: 30px;
    line-height: 44px;
  }
  @media (max-width: 479px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
export const CardLayout = styled.div`
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;
export const CardHeader = styled.div`
  font-size: 14px;
  line-height: 21px;
  padding: 14px 15px;
  color: ${Colors.Grey1};
  border-bottom: 1px solid ${Colors.Grey6};
  display: flex;
  font-weight: 400;
  &.bold {
    font-weight: 500;
  }
`;
export const CardBody = styled.div``;
export const RightLayout = styled.div`
  width: 30%;
  @media (max-width: 1199px) {
    width: 35%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const ButtonsLayout = styled.div``;
export const ButtonsInner = styled.div`
  margin: 0 0 20px 0;
  background: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 17px 50px;
  display: flex;
  color: ${Colors.Grey2};
  @media (max-width: 1199px) {
    padding: 13px 15px;
    margin: 0 0 15px 0;
  }
`;
export const CardList = styled.div`
  border: 1px solid #f2f2f2;
  padding: 20px;
  display: flex;
  align-items: center;
`;
export const CardImage = styled.div`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  margin-right: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ThumbImage = styled.img`
  width: 60px;
  height: 60px;
`;
export const CardDesc = styled.div`
  width: calc(100% - 96px);
`;
export const CardHeading = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${Colors.Grey1};
`;
export const CardPrice = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${Colors.Grey3};
  margin: 0 0 8px 0;
`;
export const CardProgress = styled.div`
        height:6px;
        background-color:${Colors.Grey5}; ;
}
    `;
export const CardProgressBar = styled.div`
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: ${Colors.Pink};
  width: 85%;
  position: relative;
  &:after {
    content: "";
    background: ${Colors.Pink};
    position: absolute;
    right: -2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: -3px;
  }
`;
export const CardProgressValue = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 30px;
  color: ${Colors.Pink};
`;

export const DonateButton = styled.div`
  position: relative;
  margin: 20px 0 20px;
  justify-content: center;
  width: 450px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

function getEmbedHTML(
  organizationId: string,
  text: string,
  buttonColor: string,
  color: string
) {
  return `
    <a href="javascript:void(0)" style="text-decoration:none;">
        <img src="/favicon.ico" onerror="window.Matchstik.preload('${organizationId}&&offlineDonation')" style="display:none;"> 
        <div style="
        background-color:${buttonColor}; 
        color:${color}; 
        font-family: sans-serif;
        border-radius: 10px; 
        height:57px; 
        width:100%; 
        display:inline-flex; 
        align-items:center; 
        justify-content:center; 
        font-weight: 600;
        font-size: 15px;
        "
        onClick="window.Matchstik.open('${organizationId}&&offlineDonation')">${text}</div>
    </a>
    `;
}

/* Handle Week Dates */
const MILLISECOND = 1;
const SECOND = MILLISECOND * 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const todayStartDate = moment().startOf("day").unix() * SECOND;
const allEndDate = moment().endOf("day").unix() * SECOND;
const oneWeekStartDate =
  moment().subtract(7, "days").startOf("day").unix() * SECOND;
const monthFirstDay =
  moment().subtract(1, "month").startOf("day").unix() * SECOND;
const monthTillFirstDay = moment().startOf("month").unix() * SECOND;
const yearTillFirstDay = moment().startOf("year").unix() * SECOND;
const yearFirstDay =
  moment().subtract(1, "year").startOf("day").unix() * SECOND;
const minDateValue = 946684800000;

interface DashboardPageProps { }

const DashboardPage: React.FC<DashboardPageProps> = () => {
  const organization = useSelector((state: any) => state.user.organization);

  /* Handle Week Filters */
  const tabState = window.sessionStorage.getItem("tabsState");

  /* State */
  const [applyCustomDate, setApplyCustomDate] = useState(false);
  const [startDate, setStartDate] = useState(oneWeekStartDate.toString());
  const [endDate, setEndDate] = useState(allEndDate.toString());
  const [tabsState, setTabsState] = useState(
    tabState ? tabState : TabListItemEnum.OneWeek
  );
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());
  const [custom, setCustom] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSucessfullyUpdated, setIsSucessfullyUpdated] = useState(false);

  /* CUSTOM DATE */
  const selectStartDate =
    customStartDate && moment(customStartDate).startOf("day").unix() * SECOND;
  const selectEndDate =
    customEndDate && moment(customEndDate).endOf("day").unix() * SECOND;

  /* Action */
  const onAllTimeButton = () => {
    setTabsState(TabListItemEnum.AllTime);
    setStartDate("");
    setEndDate("");
  };
  const onTodayButton = () => {
    setTabsState(TabListItemEnum.Today);
    setStartDate(todayStartDate.toString());
    setEndDate(allEndDate.toString());
  };
  const onOneWeekButton = () => {
    setTabsState(TabListItemEnum.OneWeek);
    setStartDate(oneWeekStartDate.toString());
    setEndDate(allEndDate.toString());
  };
  const onOneMonthButton = () => {
    setTabsState(TabListItemEnum.OneMonth);
    setStartDate(monthFirstDay.toString());
    setEndDate(allEndDate.toString());
  };
  const onMonthTillDateButton = () => {
    setTabsState(TabListItemEnum.MonthTillDate);
    setStartDate(monthTillFirstDay.toString());
    setEndDate(allEndDate.toString());
  };
  const onYearTillDateButton = () => {
    setTabsState(TabListItemEnum.YearTillDate);
    setStartDate(yearTillFirstDay.toString());
    setEndDate(allEndDate.toString());
  };
  const onOneYearButton = () => {
    setTabsState(TabListItemEnum.OneYear);
    setStartDate(yearFirstDay.toString());
    setEndDate(allEndDate.toString());
  };
  const onApplyCustomDate = (e: any) => {
    e.preventDefault();
    setApplyCustomDate(true);
    setIsSucessfullyUpdated(true);
    setLoading(true);
    setCustom(true);
    setStartDate(selectStartDate.toString());
    setEndDate(selectEndDate.toString());
  };
  const onCustomDate = () => {
    setIsSucessfullyUpdated(false);
    setApplyCustomDate(false);
    setTabsState(TabListItemEnum.Custom);
    setCustomStartDate(new Date());
    setCustomEndDate(new Date());
  };
  const onChangeStartDate = (value: any) => {
    setIsSucessfullyUpdated(false);
    setCustomStartDate(value);
  };
  const onChangeEndDate = (value: any) => {
    setIsSucessfullyUpdated(false);
    setCustomEndDate(value);
  };

  /* Hooks */
  const { data } = useQuery(TOTAL_DONATIONS, {
    fetchPolicy: "network-only",
    variables: { startDate: startDate, endDate: endDate },
    onCompleted: (data) => {
      setIsSucessfullyUpdated(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      sessionStorage.setItem("tabsState", tabsState);
      sessionStorage.setItem("startDate", startDate);
      sessionStorage.setItem("endDate", endDate);
    },
  });

  /* GraphQL */
  const totalDonations = data?.totalDonations || {};
  const totalDonationAmount =
    totalDonations?.totalDonationAmount !== undefined
      ? totalDonations?.totalDonationAmount
      : 0;

  useEffect(() => {
    const startValue = sessionStorage.getItem("startDate");
    const endValue = sessionStorage.getItem("endDate");
    const tabState = sessionStorage.getItem("tabsState");
    if (tabState === "All Time") {
      setStartDate("");
      setEndDate("");
    }
    if (tabState) {
      setApplyCustomDate(tabState === "Custom");
    }
    if (startValue && endValue) {
      setStartDate(startValue.toString());
      setCustomStartDate(new Date(Number(startValue)));
      setEndDate(endValue.toString());
      setCustomEndDate(new Date(Number(endValue)));
    }
  }, []);

  useEffect(() => {
    const clientUser = localStorage.getItem("clientUser");
    if (clientUser) {
      toast.success(successMessages.LOGIN);
    }
    localStorage.setItem("clientUser", "");
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${DONATION_URL}/embed.js`;
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const HTML = getEmbedHTML(
    organization?._id,
    "Offline Donation",
    Colors.Pink,
    Colors.White
  );

  /* Render */

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <DashboardTitle>
        <Title className="dashboardTitle">Dashboard</Title>
        <DonateButton>
          <div dangerouslySetInnerHTML={{ __html: HTML }} />
          <IconContainer>
            <Icon icon={Icons.HandHoldingUsd} color={Colors.White} size={18} />
          </IconContainer>
        </DonateButton>
      </DashboardTitle>
      <DashboardLayout>
        <TabsLayout>
          <TabsList
            onClick={onAllTimeButton}
            active={tabsState === TabListItemEnum.AllTime}>
            <TabListItem>All Time</TabListItem>
          </TabsList>
          <TabsList
            onClick={onTodayButton}
            active={tabsState === TabListItemEnum.Today}
          >
            <TabListItem>Today</TabListItem>
          </TabsList>
          <TabsList
            onClick={onOneWeekButton}
            active={tabsState === TabListItemEnum.OneWeek}
          >
            <TabListItem>1W</TabListItem>
          </TabsList>
          <TabsList
            onClick={onOneMonthButton}
            active={tabsState === TabListItemEnum.OneMonth}
          >
            <TabListItem>1M</TabListItem>
          </TabsList>
          <TabsList
            onClick={onMonthTillDateButton}
            active={tabsState === TabListItemEnum.MonthTillDate}
          >
            <TabListItem>MTD</TabListItem>
          </TabsList>
          <TabsList
            onClick={onYearTillDateButton}
            active={tabsState === TabListItemEnum.YearTillDate}
          >
            <TabListItem>YTD</TabListItem>
          </TabsList>
          <TabsList
            onClick={onOneYearButton}
            active={tabsState === TabListItemEnum.OneYear}
          >
            <TabListItem>1Y</TabListItem>
          </TabsList>
          <TabsList
            onSubmit={(e) => onApplyCustomDate(e)}
            active={applyCustomDate && tabsState === TabListItemEnum.Custom}
          >
            <TabListItem className="space" onClick={onCustomDate}>
              Custom
            </TabListItem>
            {tabsState === TabListItemEnum.Custom && (
              <>
                <DateTimePicker
                  className="showDate"
                  value={customStartDate}
                  format="MM-dd-y"
                  onChange={onChangeStartDate}
                  clearIcon={null}
                  disableClock
                  maxDate={customEndDate}
                  minDate={new Date(minDateValue)}
                  required
                />
                <DateSpace>-</DateSpace>
                <DateTimePicker
                  className="showDate"
                  value={customEndDate}
                  format="MM-dd-y"
                  onChange={onChangeEndDate}
                  clearIcon={null}
                  disableClock
                  maxDate={new Date()}
                  minDate={customStartDate}
                  required
                />
                <Button
                  type="submit"
                  text={"Apply"}
                  border="10px"
                  width="80px"
                  height="34px"
                  margin="0 0 0 10px"
                  loading={loading}
                  style={
                    isSucessfullyUpdated
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                />
              </>
            )}
          </TabsList>
        </TabsLayout>
        <LeftLayout>
          <TopSection>
            <TotalRaised>
              <CardTitle>Total Raised</CardTitle>
              <Amount>${Price.output(totalDonationAmount, true)}</Amount>
            </TotalRaised>
            {/* <Goal>
                    <CardTitle>Goal</CardTitle>
                    <Amount className="grayColor">$750,000</Amount>
                </Goal> */}
          </TopSection>
          <MiddleSection>
            <Transactions>
              <CardTitle>Transactions</CardTitle>
              <Amount>{totalDonations.totalTransactions}</Amount>
            </Transactions>
            {/* <Matchers>
                            <CardTitle>Matchers</CardTitle>
                            <Amount>0</Amount>
                        </Matchers> */}
            <Donors>
              <CardTitle>Donors</CardTitle>
              <Amount>{totalDonations.totalDonors}</Amount>
            </Donors>
          </MiddleSection>
          <CardLayout>
            <CardHeader>
              {" "}
              <Icon
                icon={Icons.ChartLine}
                size={14}
                color={Colors.Grey1}
                margin="0px 7px 0px 0px"
              />
              Stats - {tabsState}
            </CardHeader>
            <CardBody>
              <DashboardGraph
                startDate={startDate}
                endDate={endDate}
                custom={custom}
                dateRange={tabsState}
              />
            </CardBody>
          </CardLayout>
        </LeftLayout>
      </DashboardLayout>
    </>
  );
};

export default DashboardPage;
