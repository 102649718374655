import React from "react";
import styled from "styled-components";
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSelector, useDispatch } from "react-redux";
import * as AppActions from "../../redux/actions/app.actions";
import Loader, { LoaderSizes } from "../../elements/Loader";
import { Colors } from "../../styles/Colors";
import { MatchstikState } from "../../redux/store";
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from "./Modal";
import GET_DONATION from '../../graphql/queries/donation.query';
import REFUND_DONATION from '../../graphql/mutations/refundDonation.mutation';
import BackButton from '../../elements/BackButton';
import Button, { ButtonTypes } from '../../elements/Button';
import toast, { Toaster } from 'react-hot-toast';
import { successMessages } from '../../utils/MessageUtil';

const Container = styled.div`
  width: 350px;
  @media (max-width:640px){
  width:100%;
  }
`;

const LoaderContainer = styled.div`
  width: 350px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfirmText = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Grey1};
  padding: 30px 0px 10px;
`;

const Spacer = styled.div`
  height: 20px;
`;

const SubText = styled.div`
  font-size: 1.2rem;
  color: ${Colors.Grey2};
  padding-bottom: 20px;
`;

type RefundDonationModalProps = {}

const RefundDonationModal: React.FC<RefundDonationModalProps> = () => {
  /* State */
  const appState = useSelector((state: MatchstikState) => state.app);
  const { donationId } = appState;

  /* Actions */
  const dispatch = useDispatch();
  const popModal = () => dispatch(AppActions.popModal());

  /* GraphQL */
  const { data, loading } = useQuery(GET_DONATION, {
    variables: {
      donationId,
    },
  });

  const [refundDonation, { loading: loadingRefund }] = useMutation(REFUND_DONATION, {
    refetchQueries: [{ query: GET_DONATION, variables: { donationId } }],
    awaitRefetchQueries: true,
    onCompleted(data) {
      toast.success(successMessages.REFUND_DONATION_SUCCESS);
      popModal();
    },
    onError(error) {
      // TODO: handle Error
      console.log('Error', error);
    }
  })

  /** Render */
  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <ModalContainer width="414px">
        <ModalHeader title={`Donation ${donationId}`} close={popModal} />
        <ModalContent>
          {loading ? (
            <ModalContent>
              <LoaderContainer>
                <Loader size={LoaderSizes.Large} />
              </LoaderContainer>
            </ModalContent>
          ) : (
            <Container>
              <BackButton onClick={popModal} />
              <ConfirmText>
                Are you sure you want to refund this donation?
              </ConfirmText>
              <SubText>
                {`The card:
                ${data?.donation?.stripeCharge?.brand?.toUpperCase()}
                ${data?.donation?.stripeCharge?.last4} will be refunded the full donation amount excluding payment processing fees.`}
              </SubText>
              <Button
                type={ButtonTypes.Submit}
                text={'Confirm Refund'}
                onClick={() => refundDonation({
                  variables: {
                    donationId,
                  },
                })}
                loading={loadingRefund}
              />
            </Container>
          )}
        </ModalContent>
      </ModalContainer>
    </>
  );
};

export default RefundDonationModal;
