import gql from 'graphql-tag';

const mutation = gql`
  mutation disableOrganization($organizationId: String!,$status: String!) {
    disableOrganization(organizationId: $organizationId, status:$status){
      _id
    }
  }
`;

export default mutation;