import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import * as AppActions from "../../redux/actions/app.actions";
import Icon, { Icons } from "../../elements/Icon";
import { Colors } from "../../styles/Colors";
import { ModalContainer } from "./Modal";
import {SubscriptionFreeTrial} from "@matchstik/models/.dist/enums/SubscriptionEnums";

const Content = styled.div`
  display: flex;
  width: 400px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  flex-direction: column;
  @media (max-width:767px){
    width: calc(100% - 40px);
  }

`;

const Heading = styled.h2``;

const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
`;

const Link = styled.a`
  font-size: 18px;
  font-weight: 400;
  color: ${Colors.Pink};
  text-decoration-line: underline;
  margin: 20px;
  cursor: pointer;
`;

type FirstLoginModalProps = {};

const FirstLoginModal: React.FC<FirstLoginModalProps> = () => {
  /* State */

  /* Hooks */
  const dispatch = useDispatch();

  /* Actions */

  const popModal = () => dispatch(AppActions.popModal());

  /** Render */
  return (
    <ModalContainer width="440px">
      <Content>
        <Icon icon={Icons.Settings} size="50" margin="20px 0 0 0" />
        <Heading>Let’s Get You Set Up</Heading>
        <Text>
          Your first{" "}
          <span style={{ fontWeight: "bold" }}>{SubscriptionFreeTrial.FreeTrialDays} days are free</span>! Set up
          your billing information and connect to Stripe to get started.
        </Text>

        <Link
          onClick={() => {
            // will refactor it
            popModal();
            popModal();
          }}
        >
          Set Up Billing Information
        </Link>
      </Content>
    </ModalContainer>
  );
};

export default FirstLoginModal;
