import gql from "graphql-tag";

const query = gql`
query recentDonation{
  recentDonation{
        amount
        meta {
            createdAt
        },
        organizationName
        organizationDescription
        organizationStub
        impact
        organizationId
        userFirstName
        userLastName
        donorId
        visibility
        photoUrl
        slug
        message
    }
}
`;

export default query;
