import React, { Fragment, useEffect, useState } from "react";
import DonationAndMatches from "./modal/DonationAndMatches";
import DonorProfileLayout from "./modal/DonorProfileLayout";
import { ContentContainer } from "../pages/Donorprofile";

type BeTheSparkProp = {
    donorId: String;
};

const BeTheSpark: React.FC<BeTheSparkProp> = ({ donorId }) => {

    return (
        <>
         <DonorProfileLayout />
        <ContentContainer>
           <h1>Be The Spark</h1>
            <DonationAndMatches donorId={donorId} />
        </ContentContainer>
        </>
    )
}

export default BeTheSpark;