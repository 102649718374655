import Joi from "@hapi/joi";

// User
export const user = {
  email: () => Joi.string().email({ tlds: { allow: false } }),
  password: () => Joi.string(),
  confirmPassword: () => Joi.string().required().valid(Joi.ref('password'))
}

// Organization
// TODO: improve the validation (ex. phone number can only consist of numbers and cannot exceed a certain length)


export const organization = {
  name: () => Joi.string().required(),
  email: () => Joi.string().email({ tlds: { allow: false } }).required(),
  phoneNumber: () => Joi.string().min(10).optional().allow(""),
  description: () => Joi.string().optional().allow(""),
  message: () => Joi.string().optional().allow(""),
  address: () => Joi.string().optional().allow(""),
  city: () => Joi.string().optional().allow(""),
  zip: () => Joi.string().optional().allow(""),
  state: () => Joi.string().optional().allow(""),
  websiteUrl: () => Joi.string().uri({ allowRelative: true }).optional().allow(""),
  logoUrl: () => Joi.string().optional().allow(""),
  posterUrl: () => Joi.string().optional().allow(""),
  ein: () => Joi.string().optional().allow(""),
  headerImageUrl: () => Joi.string().optional().allow(""),
  footerImageUrl: () => Joi.string().optional().allow(""),
  signatureImageUrl: () => Joi.string().optional().allow(""),
  signatureText: () => Joi.string().optional().allow(""),
  signaturePosition: () => Joi.string().optional().allow(""),
  presetDonations: () => Joi.array().items(Joi.number()).optional().default([]),
  donationReceiptText: () => Joi.string().optional().allow(""),
  startDate: () => Joi.object().required(),
  matchAmount: () => Joi.string().required(),
  timeFrame: () => Joi.string().required(),
  endDate: () => Joi.alternatives().conditional('timeFrame', { is: "Match will end on a specific date", then: Joi.object().required(), otherwise: Joi.object().optional().allow("") }),
  matchPosterUrl: () => Joi.string().required(),
  youtubeLink: () => Joi.string().regex(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/).optional().allow(""),
  vimeoLink: () => Joi.string().regex(/^(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/).optional().allow(""),
}
export const donor = { 
  firstName: () => Joi.string().required(),
  lastName: () => Joi.string().required(),
  email: () => Joi.string().email({ tlds: { allow: false } }).required(),
  phoneNumber: () => Joi.string().min(10).required(),
  cities: () => Joi.string().required(),
  state: () => Joi.string().required(),
  statement: () => Joi.string().required(),
  slug: () => Joi.string().regex(/^[a-zA-Z0-9,_-]*$/).required(),
  visibility: () => Joi.string().allow("").default("Public"),
  photoUrl: () => Joi.string().allow("").optional(),
}