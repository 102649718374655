import React, { Fragment, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/client";
import { Colors } from "../styles/Colors";
import Loader, { LoaderSizes } from "../elements/Loader";
import ScrollTable, {
  ScrollTableBody,
  ScrollTableBodyCell,
  ScrollTableBodyRow,
  ScrollTableHeader,
  ScrollTableHeaderCell,
  ScrollTableNoContent,
  ScrollTableRow
} from "./ScrollTable";
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import { useDispatch, useSelector } from "react-redux";
import * as AppActions from "../redux/actions/app.actions";
import LIST_ORGANIZATIONS from "../graphql/queries/listOrganizations.query";
import LOGIN_TO_ORGANIZATION from "../graphql/mutations/loginToOrganization.mutation";
import DISABLE_ORGANIZATION from "../graphql/mutations/disableOrganization.mutation";
import DELETE_ORGANIZATION from "../graphql/mutations/deleteOrganization.mutation";
import * as Auth from "../utils/Auth";
import { OrganizationStatusEnum } from "@matchstik/models/.dist/enums/OrganizationStatusEnum";
import { isSuperUser } from "@matchstik/models/.dist/utils/SuperUserUtil";
import { successMessages, errorMessages } from "../utils/MessageUtil";
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from "react-router-dom";

const Container = styled.div`
  height: fit-content;
  max-height: 100%;
  max-width: 100%;
  padding: 0 5px 30px;
`;

const NoContentHead = styled.span`
  font-weight: 600;
  font-size: 1.8rem;
  color: ${Colors.Grey1};
  padding: 10px 0px;
  display:block;
  text-align: center;
`;

const NoContentBody = styled.td`
  font-weight: 500;
  font-size: 1.8rem;
  color: ${Colors.Grey3};
  padding: 10px 25px;
  line-height: 1.42857143;
  vertical-align: top;
  display: table-cell;
  text-align: center;
  border-top: 2px solid #F2F2F2;
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.White};
  border-radius: 0px 0px 10px 10px;
  padding: 10px 0px;
`;

const LoginButton = styled.div`
  color: ${Colors.Pink};
  font-weight: 600;
  margin-right: 15px;
  display: inline-block;
  &:hover {
    cursor: pointer;
  }
`;


type TableHeaderProps = {
  organizations: IOrganization[];
};

const TableHeader: React.FC<TableHeaderProps> = ({ organizations }) => (
  <ScrollTableHeader>
    <ScrollTableRow>
      <ScrollTableHeaderCell width={organizations?.length > 0 ? "150px" : ""}>Organization ID</ScrollTableHeaderCell>
      <ScrollTableHeaderCell width={organizations?.length > 0 ? "150px" : ""}>Name</ScrollTableHeaderCell>
      <ScrollTableHeaderCell width={organizations?.length > 0 ? "150px" : ""}>Email</ScrollTableHeaderCell>
      <ScrollTableHeaderCell width={organizations?.length > 0 ? "150px" : ""}>Phone Number</ScrollTableHeaderCell>
      <ScrollTableHeaderCell width={organizations?.length > 0 ? "150px" : ""}>Stripe ID</ScrollTableHeaderCell>
      <ScrollTableHeaderCell width={organizations?.length > 0 ? "150px" : ""}>Created At</ScrollTableHeaderCell>
      <ScrollTableHeaderCell width={organizations?.length > 0 ? "200px" : ""}>Actions</ScrollTableHeaderCell>
    </ScrollTableRow>
  </ScrollTableHeader>
);

type TableRowsProps = {
  organizations: IOrganization[];
};

const TableRows: React.FC<TableRowsProps> = ({ organizations }) => {
  const organizationId = useSelector((state: any) => state.user.organization._id);
  /**hooks */
  const [isEnable, setIsEnable] = useState(false);
  /* Actions */
  const dispatch = useDispatch();

  const [loginToOrganization] = useMutation(LOGIN_TO_ORGANIZATION, {
    onCompleted: ({ loginToOrganization }) => {
      if (loginToOrganization) {
        Auth.setToken(loginToOrganization.token);
        localStorage.setItem("clientUser", "true");
        window.location.href = '/dashboard/home';
      }
    },
  });

  const [disableOrganization, { loading: disableOrgLoading }] = useMutation(
    DISABLE_ORGANIZATION,
    {
      refetchQueries: [{ query: LIST_ORGANIZATIONS }],
      awaitRefetchQueries: true,
      onCompleted: () => {
        if (isEnable) {
          toast.success(successMessages.DISABLE_ORG);
        }
        else {
          toast.success(successMessages.ENABLE_ORG);
        }
        dispatch(AppActions.popModal());
      },
    }
  );

  const [deleteOrganization, { loading: deleteOrgLoading }] = useMutation(
    DELETE_ORGANIZATION,
    {
      refetchQueries: () => [
        {
          query: LIST_ORGANIZATIONS,
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        toast.success(successMessages.DELETE_ORG);
        dispatch(AppActions.popModal());
      },
    }
  );

  return (
    <Fragment>
      {organizations.map((organization: IOrganization, index: number) => {
        const { _id, name, email, phoneNumber, stripeConnectId, meta } =
          organization;

        const createdAt = meta?.createdAt ?? null;
        const isEnabled = organization.status === OrganizationStatusEnum.Active;
        let dateAndTime: string = "Unknown";

        if (createdAt) {
          dateAndTime = moment(parseInt(createdAt as any)).format(
            "MMM DD, YYYY"
          );
        }

        const onLoginClick = () => {
          loginToOrganization({
            variables: {
              organizationId: organization._id as string,
            },
          });
        };

        const onDisableClick = () => {
          setIsEnable(isEnabled);
          dispatch(
            AppActions.pushModalConfirmAction({
              title: `${isEnabled ? "Disable" : "Enable"} ${name} ?`,
              message: `Are you sure you want to ${isEnabled ? "disable" : "enable"
                }  ${name} ?`,
              loading: disableOrgLoading,

              confirm: () =>
                disableOrganization({
                  variables: {
                    organizationId: organization._id as string,
                    status: isEnabled
                      ? OrganizationStatusEnum.Disabled
                      : OrganizationStatusEnum.Active,
                  },
                }),
              cancel: () => { },
            })
          );
        };

        const onDeleteClick = () => {
          toast.remove();
          if (organizationId === organization._id) {
            toast.error(errorMessages.DELETE_ORG);
            dispatch(AppActions.popModal());
          }
          else {
          dispatch(
            AppActions.pushModalConfirmAction({
              title: `Delete ${name} ?`,
              message: `Are you sure you want to delete ${name} ?`,
              loading: deleteOrgLoading,
              confirm: () => 
                  deleteOrganization({
                    variables: {
                      organizationId: organization._id as string,
                    },
                  }),
              cancel: () => { },
            })
          );}
        };

        return (
          <ScrollTableBodyRow key={index}>
            <ScrollTableBodyCell data-label="Organization ID" width="150px">{_id}</ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Name" width="150px">{name}</ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Email" width="150px">{email}</ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Phone Number" width="150px">{phoneNumber}</ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Stripe ID" width="150px">
              {stripeConnectId}
            </ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Created At" width="150px">{dateAndTime}</ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Actions" width="200px">
              <LoginButton onClick={onLoginClick}>Login</LoginButton>
              <LoginButton onClick={onDisableClick}>
                {isEnabled ? "Disable" : "Enable"}
              </LoginButton>
              {!isSuperUser(organization?.email as any) && (
                <LoginButton onClick={onDeleteClick}>Delete</LoginButton>
              )}
            </ScrollTableBodyCell>
          </ScrollTableBodyRow>
        );
      })}
    </Fragment>
  );
};

type OrganizationsTableProps = {};

const OrganizationsTable: React.FC<OrganizationsTableProps> = ({ }) => {
  /** State **/
  const scrollContainer = React.useRef<any>(null);

  /** GraphQL **/
  const { data } = useQuery(LIST_ORGANIZATIONS);

  const organizations = data ? data.organizations : [];

  /** Render **/
  if (organizations.length <= 0) {
    return (
      <Container>
        <ScrollTable>
          <TableHeader organizations={organizations} />
          <ScrollTableBody>
            <ScrollTableNoContent>
              <NoContentBody colSpan={7}>
                <NoContentHead>No organizations yet</NoContentHead>
                Organizations will show up here as they are created
              </NoContentBody>
            </ScrollTableNoContent>
          </ScrollTableBody>
        </ScrollTable>
      </Container>
    );
  }

  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <Fragment>
        {organizations?.length > 0 ? (
          <Container>
            <ScrollTable>
              {(loading: boolean) => {
                return (
                  <Fragment>
                    <TableHeader organizations={organizations} />
                    <ScrollTableBody ref={scrollContainer}>
                      <TableRows organizations={organizations} />
                      {loading && (
                        <LoaderContainer>
                          <Loader size={LoaderSizes.Large} color={Colors.Pink} />
                        </LoaderContainer>
                      )}
                    </ScrollTableBody>
                  </Fragment>
                );
              }}
            </ScrollTable>
          </Container>
        ) : (
          <div />
        )}
      </Fragment>
    </>
  );
};

export default OrganizationsTable;
