import gql from "graphql-tag";

const mutation = gql`
query getSupportingOrgList($slug:String){
    getSupportingOrgList(slug:$slug){
      

    organizationId
		since
		donatedAmount
		totalImpact 
         organization{
           _id
           description
           name
           logoUrl
           city
           state
           logoUrl
           organizationStub
        }
    }
}




`;

export default mutation;

