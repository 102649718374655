import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSelector, useDispatch } from "react-redux";
import * as AppActions from "../../redux/actions/app.actions";
import Icon, { Icons } from "../../elements/Icon";
import Loader, { LoaderSizes } from "../../elements/Loader";
import { Colors } from "../../styles/Colors";
import { MatchstikState } from "../../redux/store";
import * as Price from "@matchstik/utils/.dist/price";
import * as Polished from 'polished'
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from "./Modal";
import { DonorTypeEnum } from "@matchstik/models/.dist/enums/DonorTypeEnum";
import GET_MEMBERSHIP from '../../graphql/queries/membership.query';
import { ModalTypes } from './Modal';

const LoaderContainer = styled.div`
  width: 350px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 350px;
  overflow-y:scroll !important;
  max-height:70vh;
&::-webkit-scrollbar {
  width: 10px;
}
&::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
&::-webkit-scrollbar-thumb {
  background: #888; 
}
&::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
`;

type SectionProps = {
  noMargin?: boolean;
}

const Section = styled.div<SectionProps>`
  margin-top: ${(props) => (props.noMargin ? 15 : "30px")};
`;

const Label = styled.div`
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${Colors.Grey3};
  margin-bottom: 15px;
`;

const Donor = styled.div`
  display: flex;
`;

const DonorIcon = styled.div`
  background-color: ${Colors.Pink};
  height: 54px;
  width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const DonorInfo = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const DonorName = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Grey1};
`;

const DonorValue = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${Colors.Grey3};
`;

const Row = styled.div`
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 15px;
`;

const Key = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${Colors.Grey2};
  margin-right: 20px;
`;

const Value = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Grey1};
`;


const ActionButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${Colors.White};
  transition: all 0.1s;
  padding: 10px 10px 10px 0px;
  border-radius: 2px;
  

  &:hover {
    cursor: pointer;
    background-color: ${Polished.darken(0.025, Colors.White)};
  }
`;

const ActionIcon = styled.div`
  background-color: ${Colors.Pink};
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const ActionText = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Grey2};
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const RefundedText = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Red};
  padding: 10px 10px 10px 0px;
`;


type MembershipDetailsModalProps = {}

const MembershipDetailsModal: React.FC<MembershipDetailsModalProps> = () => {
  /* State */
  const appState = useSelector((state: MatchstikState) => state.app);
  const { membershipId } = appState;

  /* Actions */
  const dispatch = useDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  const pushCancelMembershipModal = () => dispatch(AppActions.pushModal(ModalTypes.CancelMembership));
  const pushUpdatePaymentModal = () => dispatch(AppActions.pushModal(ModalTypes.UpdatePayment));
  const pushSuccessDonationModal = () => dispatch(AppActions.pushModal(ModalTypes.CreateFailedTaskDonation));


  /* GraphQL */
  const { data, loading } = useQuery(GET_MEMBERSHIP, {
    variables: {
      membershipId,
    },
  });
  
  useEffect(() => {
    if(data?.membership){
        const membership = data.membership
        dispatch(AppActions.setSelectedMembership({
            membershipId,
            stripeCustomerId: membership.stripeCustomerId,
            paymentStatus: membership.status,
        }));
    }
  }, [loading])

  /** Render */
  return (
    <ModalContainer width="425px" >
      <ModalHeader title={`Membership`} close={popModal} />
      {(() => {
        if (loading) return (
          <ModalContent>
            <LoaderContainer>
              <Loader size={LoaderSizes.Large} />
            </LoaderContainer>
          </ModalContent>
        );

        const membership = data?.membership || {};

        const nextDonationFormatted = moment(parseInt(membership.nextDonationAt as any)).format(
          "MMM DD, YYYY h:mma"
        );

        const createdAtFormatted = moment(parseInt(membership.meta.createdAt as any)).format(
          "MMM DD, YYYY h:mma"
        );

        const cancelledAtFormatted = moment(parseInt(membership.cancelledAt as any)).format(
          "MMM DD, YYYY h:mma"
        );

        return (
          <Fragment>
            <ModalContent>
              <Container>
                <Section noMargin>
                  <Label>Membership</Label>
                  <Donor>
                    <DonorIcon>
                      <Icon
                        icon={
                          membership.donorType === DonorTypeEnum.Individual
                            ? Icons.UserSolid
                            : Icons.UsersSolid
                        }
                        color={Colors.White}
                        size={24}
                      />
                    </DonorIcon>
                    {membership.anonymous ? (
                      <DonorInfo>
                        <DonorName>Anonymous Membership</DonorName>
                      </DonorInfo>
                    ) : (
                      <DonorInfo>
                        <DonorName>{membership.name}</DonorName>
                        <DonorValue>{membership.email}</DonorValue>
                        <DonorValue>
                          {membership.phoneNumber || membership.url}
                        </DonorValue>
                      </DonorInfo>
                    )}
                  </Donor>
                </Section>
                <Section>
                  <Label>MEMBERSHIP DETAILS</Label>
                  <Row>
                    <Key>Monthly Donation:</Key>
                    <Value>${Price.output(membership.transactionDetails?.clientAmount || membership.amount, true)}</Value>
                  </Row>
                  <Row>
                    <Key>Fees Covered:</Key>
                    <Value>{membership.coverFees ? "Yes" : "No"}</Value>
                  </Row>
                  <Row>
                    <Key>Monthly Payment:</Key>
                    <Value>${Price.output(membership.amount, true)}</Value>
                  </Row>
                  <Row>
                    <Key>Member Since:</Key>
                    <Value>{createdAtFormatted}</Value>
                  </Row>
                  <Row>
                    <Key>Next Payment At:</Key>
                    <Value>{nextDonationFormatted}</Value>
                  </Row>
                  <Row>
                    <Key>Total Donation Value:</Key>
                    <Value>${Price.output(membership.totalDonationValue, true)}</Value>
                  </Row>
                  <Row>
                    <Key>Number of Donations:</Key>
                    <Value>{membership.numberOfDonations}</Value>
                  </Row>
                  {!membership.active && (
                    <Row>
                      <Key>Cancelled At:</Key>
                      <Value>{cancelledAtFormatted}</Value>
                    </Row>
                  )}
                  <Row>
                    <Key>Payment Status:</Key>
                    <Value>{membership.status}  {membership.failReason && `/ ${membership.failReason}`}</Value>
                  </Row>
                </Section>
                <Section>
                  <Label>OPTIONS</Label>
                  {membership.status === "Failed" && <ActionButton
                    onClick={() => pushSuccessDonationModal()}
                  >
                    <ActionIcon>
                      <Icon
                        icon={Icons.Donation}
                        color={Colors.White}
                        size={14}
                      />
                    </ActionIcon>
                    <ActionText>Process Failed Donation</ActionText>
                  </ActionButton>}
                 {membership.active && <ActionButton
                    onClick={() => pushUpdatePaymentModal()}
                  >
                    <ActionIcon>
                      <Icon
                        icon={Icons.Edit}
                        color={Colors.White}
                        size={14}
                      />
                    </ActionIcon>
                    <ActionText>Update Payment</ActionText>
                  </ActionButton>}
                  {!membership.active ? (
                    <RefundedText>This membership has been cancelled</RefundedText>
                  ) : (
                    <ActionButton
                      onClick={() => pushCancelMembershipModal()}
                    >
                      <ActionIcon>
                        <Icon
                          icon={Icons.Cancel}
                          color={Colors.White}
                          size={14}
                        />
                      </ActionIcon>
                      <ActionText>Cancel Membership</ActionText>
                    </ActionButton>
                  )}
                </Section>
              </Container>
            </ModalContent>
          </Fragment>
        );
      })()}
    </ModalContainer>
  );
};

export default MembershipDetailsModal;
