import React from 'react';
import { Title } from '../components/DashboardLayout';
import EmbedButtonCreator from '../components/EmbedButtonCreator';

type InstallButtonPageProps = {};

const InstallButtonPage: React.FC<InstallButtonPageProps> = ({}) => {
  return (
    <>
      <Title>Install Button</Title>
      <EmbedButtonCreator />
    </>
  );
};

export default InstallButtonPage;
