import gql from "graphql-tag";

const mutation = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        _id
        firstName
        lastName
        email
        createdAt
        organizationType
        
      }
      donors{
        _id
        slug
        phoneNumber
    }
      token
    }
  }
`;

export default mutation;
