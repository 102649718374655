import React from 'react'
import styled from 'styled-components'



const Container = styled.div`
// display: flex;
 font-weight: bold;
// justify-content: center;
// line-height: 200px;
// position: relative;
// right: 20%;
`
const NoData = ({ msg = "No Data" }) => {


    return (

        <Container>{msg}</Container>
    )
}

export default NoData