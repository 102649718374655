import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../styles/Colors";
import { Title } from "../components/DashboardLayout";
import DonationsTable from "../components/DonationsTable";
import MembershipsTable from "../components/MembershipsTable";
import DateTimePicker from 'react-datetime-picker';
import Button, { ButtonTypes, IconPositionTypes } from "../elements/Button";
import moment from "moment";
import Loader, { LoaderSizes } from '../elements/Loader';
import { TabsLayout, TabsList, TabListItem, DateSpace, DashboardTitle } from "../pages/Dashboard.page";
import { TabListItemEnum } from "@matchstik/models/.dist/enums/TabListItemEnum";
import Icon, { Icons } from "../elements/Icon";
import { DonateButton } from './Dashboard.page';
import { DONATION_URL } from "../env";
import { useSelector } from "react-redux";
import { IconContainer } from "./CampaignsList.page";

const LoaderDonationPage = styled.div`
display: flex;
text-align:center;
justify-content:center;
`;

const SubNav = styled.div`
  display: flex;
  margin: -10px 0px 30px;
  z-index: 1;
`;

const SubNavItem = styled.div<{ active: boolean }>`
  font-weight: 700;
  size: 1.8rem;
  transition: all 0.2s;
  color: ${[(props) => (props.active ? Colors.Blue : Colors.Grey4)]};
  margin-right: 20px;
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue};
  }
`;

function getEmbedHTML(organizationId: string, text: string, buttonColor: string, color: string) {
  return (`
  <a href="javascript:void(0)" style="text-decoration:none;">
      <img src="/favicon.ico" onerror="window.Matchstik.preload('${organizationId}&&offlineDonation')" style="display:none;"> 
      <div style="
      background-color:${buttonColor}; 
      color:${color}; 
      font-family: sans-serif;
      border-radius: 10px; 
      height:57px; 
      width:100%; 
      display:inline-flex; 
      align-items:center; 
      justify-content:center; 
      font-weight: 600;
      font-size: 15px;
      "
      onClick="window.Matchstik.open('${organizationId}&&offlineDonation${true}')">${text}</div>
  </a>
  `);
}


enum PageStateEnum {
  Donations = "Donations",
  Memberships = "Memberships",
}

/* Handle Week Dates */
const MILLISECOND = 1;
const SECOND = MILLISECOND * 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const todayStartDate = moment().startOf('day').unix() * SECOND;
const allEndDate = moment().endOf('day').unix() * SECOND;
const oneWeekStartDate = moment().subtract(7, "days").startOf("day").unix() * SECOND;
const monthFirstDay = moment().subtract(1, "month").startOf("day").unix() * SECOND;
const monthTillFirstDay = moment().startOf('month').unix() * SECOND;
const yearTillFirstDay = moment().startOf('year').unix() * SECOND;
const yearFirstDay = moment().subtract(1, "year").startOf("day").unix() * SECOND
const minDateValue = 946684800000;


interface DonationsPageProps { }

const DonationsPage: React.FC<DonationsPageProps> = () => {
  /* Handle Week Filters */
  const organization = useSelector((state: any) => state.user.organization);
  const tabState = window.sessionStorage.getItem("tabsState");

  /* State */
  const [pageState, setPageState] = React.useState(PageStateEnum.Donations);
  const [applyCustomDate, setApplyCustomDate] = useState(false);
  const [startDate, setStartDate] = useState(oneWeekStartDate.toString());
  const [endDate, setEndDate] = useState(allEndDate.toString());
  const [tabsState, setTabsState] = useState(tabState ? tabState : TabListItemEnum.OneWeek);
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [isSucessfullyUpdated, setIsSucessfullyUpdated] = useState(false);

  /* CUSTOM DATE */
  const selectStartDate = customStartDate && moment(customStartDate).startOf('day').unix() * SECOND;
  const selectEndDate = customEndDate && moment(customEndDate).endOf('day').unix() * SECOND;

  /* Action */

  const onAllTimeButton = (() => {
    setTabsState(TabListItemEnum.AllTime);
    setLoading(true)
    setTimeout(() => {
      setStartDate("");
      setEndDate("");
      setLoading(false)
    }, 1000);

  });
  const onTodayButton = (() => {
    setTabsState(TabListItemEnum.Today);
    setLoading(true)
    setTimeout(() => {
      setStartDate(todayStartDate.toString());
      setEndDate(allEndDate.toString());
      setLoading(false)
    }, 1000)

  });
  const onOneWeekButton = (() => {
    setTabsState(TabListItemEnum.OneWeek);
    setLoading(true)
    setTimeout(() => {
      setStartDate(oneWeekStartDate.toString());
      setEndDate(allEndDate.toString());
      setLoading(false)
    }, 1000)
  });
  const onOneMonthButton = (() => {
    setTabsState(TabListItemEnum.OneMonth);
    setLoading(true)
    setTimeout(() => {
      setStartDate(monthFirstDay.toString());
      setEndDate(allEndDate.toString());
      setLoading(false)
    }, 1000)
  });
  const onMonthTillDateButton = (() => {
    setTabsState(TabListItemEnum.MonthTillDate);
    setLoading(true)
    setTimeout(() => {
      setStartDate(monthTillFirstDay.toString());
      setEndDate(allEndDate.toString());
      setLoading(false)
    }, 1000)
  });
  const onYearTillDateButton = (() => {
    setTabsState(TabListItemEnum.YearTillDate);
    setLoading(true)
    setTimeout(() => {
      setStartDate(yearTillFirstDay.toString());
      setEndDate(allEndDate.toString());
      setLoading(false)
    }, 1000)
  });
  const onOneYearButton = (() => {
    setTabsState(TabListItemEnum.OneYear);
    setLoading(true)
    setTimeout(() => {
      setStartDate(yearFirstDay.toString());
      setEndDate(allEndDate.toString());
      setLoading(false)
    }, 1000)
  });
  const onApplyCustomDate = ((e: any) => {
    e.preventDefault()
    setLoading(true);
    setApplyCustomDate(true);
    setIsSucessfullyUpdated(true);
    setTimeout(() => {
      setStartDate(selectStartDate.toString());
      setEndDate(selectEndDate.toString());
      setLoading(false)
    }, 1000)
  });
  const onCustomDate = (() => {
    setApplyCustomDate(false);
    setIsSucessfullyUpdated(false);
    setTabsState(TabListItemEnum.Custom);
    setTimeout(() => {
      setCustomStartDate(new Date());
      setCustomEndDate(new Date());
    }, 1000)
  });
  const onChangeStartDate = ((value: any) => {
    setIsSucessfullyUpdated(false);
    setCustomStartDate(value);
  });
  const onChangeEndDate = ((value: any) => {
    setIsSucessfullyUpdated(false);
    setCustomEndDate(value);
  });



  /* Render */

  /* Handle Week Filters */
  useEffect(() => {
    const startValue = sessionStorage.getItem("startDate");
    const endValue = sessionStorage.getItem("endDate");
    const tabState = sessionStorage.getItem("tabsState")
    if (tabState === "All Time") {
      setStartDate("");
      setEndDate("");
    }
    if (tabState) {
      setApplyCustomDate(tabState === "Custom")
    }
    if (startValue && endValue) {
      setStartDate(startValue.toString());
      setCustomStartDate(new Date((Number(startValue))));
      setEndDate(endValue.toString());
      setCustomEndDate(new Date((Number(endValue))));
    }
    setIsSucessfullyUpdated(true);

  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${DONATION_URL}/embed.js`;
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    }
  }, []);

  const HTML = getEmbedHTML(organization?._id, "Offline Donation", Colors.Pink, Colors.White);

  return (
    <>
      <DashboardTitle>
        <Title className="dashboardTitle">Overview</Title>

        <DonateButton>
          <div dangerouslySetInnerHTML={{ __html: HTML }} />
          <IconContainer>
            <Icon icon={Icons.HandHoldingUsd} color={Colors.White} size={18} />
          </IconContainer>
        </DonateButton>
      </DashboardTitle>
      <SubNav>
        <SubNavItem
          onClick={() => setPageState(PageStateEnum.Donations)}
          active={pageState === PageStateEnum.Donations}
        >
          {PageStateEnum.Donations}
        </SubNavItem>
        <SubNavItem
          onClick={() => setPageState(PageStateEnum.Memberships)}
          active={pageState === PageStateEnum.Memberships}
        >
          {PageStateEnum.Memberships}
        </SubNavItem>
      </SubNav>
      {pageState === PageStateEnum.Donations &&
        <TabsLayout>
          <TabsList
            onClick={onAllTimeButton}
            active={tabsState === TabListItemEnum.AllTime}>
            <TabListItem>All Time</TabListItem>
          </TabsList>
          <TabsList
            onClick={onTodayButton}
            active={tabsState === TabListItemEnum.Today}>
            <TabListItem>Today</TabListItem>
          </TabsList>
          <TabsList
            onClick={onOneWeekButton}
            active={tabsState === TabListItemEnum.OneWeek}>
            <TabListItem>1W</TabListItem>
          </TabsList>
          <TabsList
            onClick={onOneMonthButton}
            active={tabsState === TabListItemEnum.OneMonth}>
            <TabListItem>1M</TabListItem>
          </TabsList>
          <TabsList
            onClick={onMonthTillDateButton}
            active={tabsState === TabListItemEnum.MonthTillDate}>
            <TabListItem>MTD</TabListItem>
          </TabsList>
          <TabsList
            onClick={onYearTillDateButton}
            active={tabsState === TabListItemEnum.YearTillDate}>
            <TabListItem>YTD</TabListItem>
          </TabsList>
          <TabsList
            onClick={onOneYearButton}
            active={tabsState === TabListItemEnum.OneYear}>
            <TabListItem>1Y</TabListItem>
          </TabsList>
          <TabsList
            onSubmit={(e) => onApplyCustomDate(e)}
            active={applyCustomDate && tabsState === TabListItemEnum.Custom}
          >
            <TabListItem className="space" onClick={onCustomDate}>Custom</TabListItem>
            {tabsState === TabListItemEnum.Custom &&
              <>
                <DateTimePicker
                  className="showDate"
                  value={customStartDate}
                  format="MM-dd-y"
                  onChange={onChangeStartDate}
                  clearIcon={null}
                  disableClock
                  maxDate={customEndDate}
                  minDate={new Date(minDateValue)}
                />
                <DateSpace>-</DateSpace>
                <DateTimePicker
                  className="showDate"
                  value={customEndDate}
                  format="MM-dd-y"
                  onChange={onChangeEndDate}
                  clearIcon={null}
                  disableClock
                  maxDate={new Date()}
                  minDate={customStartDate}
                />
                <Button
                  type="submit"
                  text={"Apply"}
                  border="10px"
                  width='80px'
                  height='34px'
                  margin='0 0 0 10px'
                  loading={loading}
                  style={isSucessfullyUpdated ? { pointerEvents: "none", opacity: "0.4" } : {}}
                />
              </>}
          </TabsList>
        </TabsLayout>}
      {(() => {
        if (loading)
          return <LoaderDonationPage> <Loader size={LoaderSizes.Small} />
          </LoaderDonationPage>
        switch (pageState) {
          case PageStateEnum.Donations:
            return <DonationsTable startDate={startDate} endDate={endDate} tabsState={tabsState} />;
          case PageStateEnum.Memberships:
            return <MembershipsTable />;
          default:
            return <DonationsTable startDate={startDate} endDate={endDate} tabsState={tabsState} />;
        }
      })()}
    </>
  );
};

export default DonationsPage;
