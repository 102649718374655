import React, { Fragment } from "react";
import styled from "styled-components";
import { Colors } from "../styles/Colors";
import * as Polished from "polished";
import Loader, { LoaderSizes } from "./Loader";
import Icon, { Icons } from "./Icon";

// import Icon from "./Icon";

export enum ButtonTypes {
  Submit = "Submit",
  Button = "Button",
  Warning = "Warning",
}

export enum IconPositionTypes {
  Right = "Right",
  Left = "Left",
}

export enum ButtonVariants {
  Outlined = "Outlined",
  Filled = "Filled",
}

export enum ButtonStates {
  Active = "Active",
  Inactive = "Inactive",
}

type StyledButtonProps = {
  type: string;
  onClick?: any;
  margin?: string;
  opacity?: string;
  padding?: string;
  width?: string;
  height?: string;
  variant?: string;
  state: string;
  border?: string;
  disabled?: boolean;
};

const StyledButton = styled.div<StyledButtonProps>`
  position: relative;
  height: ${(props) => (props.height ? props.height : "40px")};
  width: ${(props) => props.width};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(props) => {
    if (props.variant === ButtonVariants.Outlined) {
      return Colors.Pink;
    } else if (props.variant === ButtonVariants.Filled) {
      return Colors.White;
    }
  }};
  background-color: ${(props) => {
    if (props.variant === ButtonVariants.Filled) {
      switch (props.type) {
        case ButtonTypes.Submit: {
          return Colors.Pink;
        }
        case ButtonTypes.Warning: {
          return Colors.Red;
        }
        default: {
          return Colors.Pink;
        }
      }
    } else if (props.variant === ButtonVariants.Outlined) {
      if (props.state === ButtonStates.Active) {
        return "#E6167B0D";
      }
      return Colors.White;
    }
  }};
  border-radius: ${(props: any) => (props.border ? props.border : "10px")};
  border: ${(props) => {
    if (props.variant === ButtonVariants.Outlined) {
      return `1px solid ${Colors.Pink}`;
    } else if (props.variant === ButtonVariants.Outlined) {
      return Colors.White;
    }
  }};

  margin: ${(props: any) => (Boolean(props.margin) ? props.margin : "0px")};
  opacity: ${(props: any) => (props.opacity ? props.opacity : "auto")};
  padding: ${(props: any) => (Boolean(props.padding) ? props.padding : "0px")};
  transition: all 0.2s;

  &:hover {
    cursor: ${(props) => (props.onClick ? "pointer" : null)};
    background-color: ${(props) => {
      if (props.variant === ButtonVariants.Filled) {
        switch (props.type) {
          case ButtonTypes.Submit: {
            return Colors.Pink;
          }
          case ButtonTypes.Warning: {
            return Colors.Red;
          }
          default: {
            return Colors.Pink;
          }
        }
      } else if (props.variant === ButtonVariants.Outlined) {
        return Colors.Grey6;
      }
    }};
    /* 
    color: ${(props) => {
      if (props.variant === ButtonVariants.Outlined) {
        return Colors.White;
      } else if (props.variant === ButtonVariants.Filled) {
        return Colors.Pink;
      }
    }}; */
  }

  &:active {
    /* top: ${(props) => (props.onClick ? "1px" : null)}; */
    cursor: ${(props) => (props.onClick ? "pointer" : null)};
    background-color: ${(props) => {
      if (props.onClick && props.type === ButtonTypes.Submit) {
        return Polished.darken(0.025, Colors.Pink);
      }

      if (props.onClick && props.type === ButtonTypes.Warning) {
        return Polished.darken(0.025, Colors.Red);
      }
    }};
  }
`;

type TextContainerProps = {
  type?: string;
  icon?: boolean;
  variant?: string;
  style?: any;
};

const TextContainer = styled.button<TextContainerProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  background: none;
  border: none;
  color: ${Colors.White};
  cursor: pointer;
  &:focus-visible {
    outline: none;
  }
`;

type TextProps = {
  type: string;
  icon: boolean;
  variant: string;
  style?: any;
};

const Text = styled.span<TextProps>`
  // margin-left: ${(props: any) => (props.icon ? "10px" : 0)};
  font-size: 1.4rem;
  font-weight: 700;
`;

type IconProps = {
  position: string;
};

const IconContainer = styled.div<IconProps>`
  display: flex;
  justify-content: center;
  position: absolute;
  right: ${(props: any) =>props.position === IconPositionTypes.Right ? "6%" : "auto"};
  left: ${(props: any) => props.position === IconPositionTypes.Left ? "10%" : "auto"};
  top: 1px;
`;

export type ButtonProps = {
  type: string;
  state?: string;
  text: string;
  onClick?: any;
  icon?: any;
  margin?: string;
  opacity?: string;
  padding?: string;
  width?: string;
  height?: string;
  loading?: boolean| null;
  variant?: string;
  border?:string;
  style?: any;
  iconPosition?:string;
};

export default function Button({
  type = ButtonTypes.Button,
  state = ButtonStates.Inactive,
  variant = ButtonVariants.Filled,
  text,
  onClick = () => {},
  icon,
  margin,
  padding,
  width = "100%",
  height,
  loading = false,
  border,
  style,
  opacity,
  iconPosition =IconPositionTypes.Right,
}: ButtonProps) {
  return (
    <StyledButton
      type={type}
      onClick={loading ? null : () => onClick()}
      margin={margin}
      padding={padding}
      width={width}
      height={height}
      variant={variant}
      state={state}
      border={border}
      style={style}
      opacity={opacity}
    >
      {(() => {
        return (
          <Fragment>
            {/* {icon && (
              <Icon
                icon={icon}
                color={Colors.Palette.Orange}
                margin="0 10px 0 0"
              />
            )} */}
            {(() => {
              if (loading) {
                return <Loader size={LoaderSizes.Small} color={Colors.White} />;
              }

              return (
                <TextContainer  type={type as any} >
                  <Text type={type} icon={Boolean(icon)} variant={variant}>
                    {text}
                  </Text>
                  {icon && (
                    <IconContainer position={iconPosition}>
                      <Icon
                        icon={icon} size={16}
                        color={
                          variant === ButtonVariants.Outlined
                            ? Colors.Pink
                            : Colors.White
                        }
                      />
                    </IconContainer>
                  )}
                </TextContainer>
              );
            })()}
          </Fragment>
        );
      })()}
    </StyledButton>
  );
}
