import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import * as AppActions from "../../redux/actions/app.actions";
import Icon, { Icons } from "../../elements/Icon";
import { Colors } from "../../styles/Colors";
import { ModalContainer } from "./Modal";
import { CancelIcon } from '../../pages/NewCampaigns.page';
import ReactPlayer from "react-player";
import Loader, { LoaderSizes } from '../../elements/Loader';

const Content = styled.div`
  padding: 20px;
  height: 100%;
  > div{
    width: 100%!important;
  }
`;

export const PosterImage = styled.img`
max-width : 100%;
height: calc(100% - 40px);
object-fit: contain;
`;

// export const VideoIcon = styled.button<any>`
// position: absolute;
// top: 50%;
// left: 50%;
// height: 60px;
// width: 60px;
// background: rgb(20 21 22 / 45%);
// border: none;
// border-radius: 50%;
// transform: translate(-50%,-50%);
// // opacity: ${(props) => (props.playing ? "0" : "1")};
// `;


const Iframe = styled.div`
  width: 100%;
  height: calc(100vh - 345px);
  iframe{
   width:100%;  
   height: 100%;
   border: 1px solid #f2f2f2;
  }
`;


type PreviewImageModalProps = {};

const PreviewImageModal: React.FC<PreviewImageModalProps> = () => {
  const image = sessionStorage.getItem("image");
  const youtubeVideo = sessionStorage.getItem("YoutubeVideo");
  const vimeoVideo = sessionStorage.getItem("VimeoVideo");

  /* State */
  const [playing, setPlaying] = useState(true);

  /* Hooks */
  const dispatch = useDispatch();
  const popModal = () => dispatch(AppActions.popModal());

  /* Actions */
  const onCancel = ((index: number) => {
    popModal();
  });

  const onPlayingVideo = (() => {
    
    if(playing){
      setPlaying(false);
    }
    else{
      setPlaying(true);
    }
   
  });

  /** Render */
  return (
    <ModalContainer width={image ? "550px" : "60%"} height={image ? "550px" : "70vh"}>
      <CancelIcon>
        <Icon icon={Icons.Cancel} size={16} color={Colors.Grey2} hoverColor={Colors.Pink} onClick={onCancel} />
      </CancelIcon>
      <Content>
        {image && <PosterImage src={image} />}
        {youtubeVideo && <ReactPlayer url={youtubeVideo} playing={true} width={"100%"} height={"65vh"} />}
        <>  
       {vimeoVideo && <ReactPlayer url={vimeoVideo} playing={playing} width={"100%"} height={"65vh"}/>}
       {/* {vimeoVideo && <VideoIcon playing={playing}>
            {playing? (
              <Icon icon={Icons.Pause} size={25} onClick={() => setPlaying(false)}/>
            ) : (
              <Icon icon={Icons.Play} size={25} onClick={() => setPlaying(true)}/>
            )}
        </VideoIcon>} */}
        </>
      </Content>
    </ModalContainer>
  );
};

export default PreviewImageModal;
