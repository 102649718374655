import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../../styles/Colors";
import Icon, { Icons } from "../../elements/Icon";
import { useMutation } from "@apollo/react-hooks";
import campaignsSrc from "../../assets/images/campaignsimage.jpg";
import { useQuery } from "@apollo/client";
import GET_LIST_CAMPAIGN from "../../graphql/queries/listCampaign.query";
import { ModalContainer, ModalContent, ModalHeader } from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import * as AppActions from "../../redux/actions/app.actions";
import * as Price from "@matchstik/utils/.dist/price";
import Button, { ButtonTypes } from "../../elements/Button";
import DEFAULT_CAMPAIGN from "../../graphql/mutations/defaultCampaign.mutation";
import {
  CampaignsDesc, CampaignsImage,
  CampaignsInner, CampaignsHeading, LeftPanel, Desc, Amount,
  Donors, CampaignsProgress, CampaignsProgressBar, CampaignsList,
} from "../../pages/CampaignsList.page";
import * as ErrorUtil from "../../utils/ErrorUtil";
import { MatchstikState } from "../../redux/store";
import { Matchers } from "../../pages/CampaignsList.page";
import toast, { Toaster } from 'react-hot-toast';
import { successMessages } from '../../utils/MessageUtil';
import DELETE_CAMPAIGN from "../../graphql/mutations/deleteCampaign.mutation";

const ErrorTexts = styled.div`
  color: ${Colors.Red};
  font-size: 1.2rem;
  font-weight: 600;
  margin: 10px 0;
  text-align: left;
`;

export const CampaignsSection = styled.div`

`;
export const RightPanel = styled.div`
    display:flex;
    justify-content:space-between;
    white-space: nowrap;
    margin-top:10px;
`;

type CampaignActiveModalProps = {

};

const CampaignActiveModal: React.FC<CampaignActiveModalProps> = () => {
  /* State */
  const appState: any = useSelector((state: MatchstikState) => state.user);
  const { deleteCampaign, isDefault } = useSelector((state: MatchstikState) => state.app.deleteCampaignAction);

  const organization = appState?.organization;
  const organizationId = organization?._id;
  const [activeCampaignId, setActive] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonClick, setButtonClick] = useState(false);

  /* Actions */
  const dispatch = useDispatch();
  const history = useHistory();
  const historyState: any = history.location.state;
  const campaignId = historyState?.campaignId;

  const onCloseModal = () => {
    dispatch(AppActions.setCancelEndDate(true));
    dispatch(AppActions.popModal());
  };

  /* Query Campaign List */
  const { data } = useQuery(GET_LIST_CAMPAIGN, {
    variables: {
      organizationId,
    },
    fetchPolicy: "network-only",
  });

  //Mutation for Default Campaign
  const [onDefaultCampaign, { loading: savingStripeConnectId }] = useMutation(
    DEFAULT_CAMPAIGN,
    {
      onCompleted: async () => {
        setButtonClick(false);
        if (!deleteCampaign) {
          toast.success(successMessages.SET_DEFAULT_CAMPAIGN);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setError("");
        dispatch(AppActions.setDefaultCampaign(true));
        dispatch(AppActions.setCancelEndDate(false));
        dispatch(AppActions.popModal());
      },
      onError: async (error: any) => {
        const errorMsg = ErrorUtil.getErrorMessage(error);
        setError(errorMsg);
        setLoading(false);
      }
    },
  );

  const [deleteCampaignMutation, { loading: updateLoading }] = useMutation(
    DELETE_CAMPAIGN,
    {
      variables: {
        campaignId,
        "selectedCampaignId": activeCampaignId
      },
      onCompleted: async () => {
        history.push({
          pathname: `/dashboard/campaigns`,
          state: { deleteCampaign: true }
        });
        dispatch(AppActions.popModal());
      },
    }
  );

  const updateDefaultCampaign = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    setLoading(true);
    setButtonClick(true);
    setError("");
    onDefaultCampaign({
      variables: {
        campaignId: activeCampaignId
      }
    });
  };

  const updateCampaignData = (event?: React.FormEvent<HTMLFormElement>) => {
    if (isDefault) {
      onDefaultCampaign({
        variables: {
          campaignId: activeCampaignId
        }
      });
    }
    else {
      if (activeCampaignId) {
        deleteCampaignMutation();
      }
    else {
      setButtonClick(true);
     }
     }
  };

const campaignDetail = data?.listCampaign || [];
const filteredData = campaignDetail?.filter((campaign: any) => campaign._id !== campaignId && campaign.active);

return (
  <div>
    <Toaster
      position="top-right"
      reverseOrder={false}
    />
    <ModalContainer width="550px">
      <ModalHeader title="Currently Active Campaigns" close={onCloseModal} />
      <ModalContent padding={deleteCampaign && !isDefault ? "30px" : ""}>
        <div style={{ height: "400px", overflow: "auto" }}>
          {!deleteCampaign || isDefault && <h3>You are ending your current default campaign. Please select a new default campaign.</h3>}
          {deleteCampaign && <h3>There are donations and/or matches associated with this campaign. Which campaign should they be moved to?</h3>}
          {filteredData?.map((campaign: any) => {
            let totalDonationAmount = campaign.totalDonationAmount === null ? "" : campaign.totalDonationAmount;
            return (
              <CampaignsList active={activeCampaignId === campaign._id} onClick={() => setActive(campaign._id)}>
                <CampaignsImage posterUrl={campaign.posterUrl ? campaign.posterUrl : campaignsSrc} />
                <CampaignsDesc>
                  <CampaignsInner>
                    <CampaignsHeading>{campaign.name}</CampaignsHeading>
                    {campaign.completed !== -1 && <CampaignsProgress>
                      <CampaignsProgressBar progress={campaign.completed + "%"} >
                      </CampaignsProgressBar>
                    </CampaignsProgress>}
                    <CampaignsSection>
                      <LeftPanel>
                        <Amount>${Price.output(totalDonationAmount, true)} </Amount>
                        {campaign.goal !== null && campaign.goal !== "" && <Desc>raised of ${Price.output(campaign.goal, true)} goal</Desc>}
                      </LeftPanel>
                      <RightPanel>
                        {campaign.totalMatchers ?
                          <Matchers><Icon icon={Icons.FireRegular} size={14} color={Colors.Grey2} margin='0px 6px 0px 0px' />{campaign.totalMatchers}x Multiplier </Matchers> : ""}
                        <Donors><Icon icon={Icons.HandHoldingHeart} size={14} color={Colors.Grey2} margin='0px 6px 0px 0px' />{campaign.donors} donors</Donors>
                      </RightPanel>
                    </CampaignsSection>
                  </CampaignsInner>
                </CampaignsDesc>
              </CampaignsList>
            );
          })}
        </div>
        <div style={{ margin: "15px 0 0" }}>
          {!activeCampaignId && buttonClick && <ErrorTexts>Please select any active campaign</ErrorTexts>}
          <Button
            type={ButtonTypes.Button}
            text={deleteCampaign ? "Delete Campaign" : "Set Default Campaign"}
            border="7px"
            width="190px"
            height="53px"
            loading={loading}
            onClick={deleteCampaign ? updateCampaignData : updateDefaultCampaign}
          />
        </div>
      </ModalContent>
    </ModalContainer>
  </div>

);
};



export default CampaignActiveModal;
