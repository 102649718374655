import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import DateTimePicker from 'react-datetime-picker';
import styled from 'styled-components';
import { Title } from '../components/DashboardLayout';
import Button, { ButtonTypes } from '../elements/Button';
import { useHistory } from "react-router-dom";
import Joi, { boolean } from "@hapi/joi";
import * as Schema from "../utils/Schema";
import GET_CAMPAIGN from "../graphql/queries/campaign.query";
import GET_LIST_CAMPAIGN from "../graphql/queries/listCampaign.query";
import { useQuery } from "@apollo/client";
import Label from '../elements/Label';
import LabeledInput from "../elements/LabeledInput";
import TextArea from '../elements/TextArea';
import CREATE_CAMPAIGN_METHOD from '../graphql/mutations/createCampaign.mutation';
import UPDATE_CAMPAIGN from '../graphql/mutations/updateCampaign.mutation';
import makeEventHandler from '../utils/makeEventHandler';
import moment from 'moment';
import ErrorText from '../elements/ErrorText';
import * as ErrorUtil from "../utils/ErrorUtil";
import defaultImg from '../assets/images/defaultCampaignImg.jpg';
import UPLOAD_FILES from '../graphql/mutations/uploadFiles.mutation';
import Dropzone from "react-dropzone";
import Loader, { LoaderSizes } from '../elements/Loader';
import { Colors } from '../styles/Colors';
import Icon, { Icons } from '../elements/Icon';
import * as Price from '@matchstik/utils/.dist/price';
import { useDispatch, useSelector } from 'react-redux';
import * as AppActions from "../redux/actions/app.actions";
import { ModalTypes } from '../components/modal/Modal';
import Checkbox from "../elements/CheckBox";
import { MatchstikState } from "../redux/store";
import toast, { Toaster } from 'react-hot-toast';
import { successMessages } from '../utils/MessageUtil';
import { DashboardTitle } from './Dashboard.page';
import ReactTooltip from "react-tooltip";

const schema = Joi.object({
  name: Schema.organization.name().error(([error]) => {
    const message = "Campaign name is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  startDate: Schema.organization.startDate().error(([error]) => {
    const message = "Campaign start date is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  youtubeLink: Schema.organization.youtubeLink().error(([error]) => {
    const message = "Please fill valid youtube url";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  vimeoLink: Schema.organization.vimeoLink().error(([error]) => {
    const message = "Please fill valid vimeo url";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
});

export const DashboardLayout = styled.div`
    display:flex;
    justify-content:space-between;
    @media (max-width:767px){
    display:block;
    }
    `;
export const LeftLayout = styled.div`
    width:100%;
    @media (max-width:767px){
        width:100%;
        margin:0 0 30px 0;
    }
    `;

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const MediaDetails = styled.div<any>`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 865px;
  gap: 15px;
  margin-bottom:${props => props?.margin && '30px'};
  @media (max-width:479px){
    display:block;
    width:100%;
   }
`;
export const StyleCampaignDetails = styled.div`
  display: flex;
  position:relative;
  @media (max-width:767px){
   display:block;
  }
  .showDate{
    width:188px;
    margin:0 15px 0 0;
    @media (max-width:767px){
      width:100%;
      margin:0;
    }
    .react-datetime-picker__wrapper{
     padding:6px 12px;
     border:1px solid #E0E0E0;
     border-radius:10px;
     font-size: 15px;
     height: 50px;
    }
    &:last-child{
      margin:0px;
    }
  }
`;
export const ImagePreview = styled.div`
width: 235px;
height: 235px;
border: 1px solid #E0E0E0;
border-radius: 10px;
position:relative;
@media (max-width:479px){
  margin:0 auto 30px;
  width:100%;
  height:300px;
 }
`;
export const ThumbNail = styled.div`
width: calc(100% - 30px);
height: calc(100% - 30px);
display: flex;
align-items: center;
justify-content: center;
margin: 15px;
overflow: hidden;
`;
export const DefaultImage = styled.img`
width:77px;
`;
export const PosterImage = styled.img`
max-width: 100%;
height: 100%;
object-fit: contain;
`;

export const Spacer = styled.div`
  height: 30px;
`;
export const CampaignMediaContent = styled.div`
 display:flex;
 align-items:center;
 width: calc(100% - 275px);
 @media (max-width:479px){
  width: calc(100% - 0px);
 }
`;
export const ThumbHeading = styled.h3`
margin: 0 0 10px 0;
font-weight: 500;
font-size: 14px;
line-height: 21px;
color: ${Colors.Grey2};
`;
export const ThumbParagraph = styled.p`
margin: 0 0 25px 0;
color: ${Colors.Grey4};
font-weight: 500;
font-size: 11px;
line-height: 16px;
`;
export const DateError = styled.div`
display: block;
`;

export const ContentInner = styled.div` `;

enum ErrorKeyEnum {
  Name = "name",
  StartDate = "startDate",
  YoutubeLink = "youtubeLink",
  VimeoLink = "vimeoLink"
}

export const DateContainer = styled.div`
   position:relative;
   @media (max-width:767px){
    margin:0 0 30px 0;
    &:last-child{
     margin:0px;
    }
  } 
`;

export const CancelIcon = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  width:25px;
  height:25px;
  border: 1px solid #f2f2f2;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  line-height: 25px;
`;

export const DatePlaceHolder = styled.div<any>` 
width: auto;
height: 43px;
position: absolute;
top: 50%;
left: 13px;
background: ${props => props && 'white'};
place-items: center;
display: flex;
opacity:${props => props?.date ? '0' : '1'};
color: ${Colors.Grey4};
font-weight: 600;
font-size: 1.3rem;
text-align: left;
transform: translateY(-50%);
`;

export const ClearDate = styled.div<any>` 
opacity:${props => props?.endDate ? '1' : '0'};
position: absolute;
top: 15px;
right: 70px;
@media (max-width:767px){
  right: 60px;
}
`;

export const SmallText = styled.div`
font-weight: 500;
font-size: 14px;
color: ${Colors.Grey2};
margin-left: 10px;
`;

export const DefaultCheckbox = styled.div`
display: flex;
`;

export const StyleVideos = styled.div`
display: flex;
position:relative;
width: 715px;
@media (max-width:767px){
  width: 100%;
 display:block;
}
`;

const LabelContainer = styled.div`
display: flex;
`;

type NewCampaignPageProps = {};

const NewCampaignPage: React.FC<NewCampaignPageProps> = ({ }) => {
  const defaultCampaign = useSelector((state: MatchstikState) => state.app.defaultCampaign);
  const selecteDateState = useSelector((state: MatchstikState) => state.app.showEndDate);
  const {organizationStub, _id} = useSelector((state: any) => state.user.organization);

  /*State */
  const [name, setName] = useState("");
  const [goal, setGoal] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [description, setDescription] = useState("");
  const [posterUrl, setPosterUrl] = useState("");
  const [posterUrlArray, setPosterUrlArray] = useState([] as any);
  const [isPosterUrl, setIsPosterUrl] = useState(false);
  const [campaignSlug, setCampaignSlug] = useState("");
  const [isGeneralFundraiser, setGeneralFundraiser] = useState();
  const [youtubeLink, setYoutubeLink] = useState("");
  const [vimeoLink, setVimeoLink] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [prevLoaded, setPrevLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [allDefault, setAllDefault] = useState([]);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [isDefault, setDefault] = useState(false);
  const [activeCampaign, setActiveCampaign] = useState(false);
  const [dataLength, setDataLength] = useState([]);
  const [selectedEndDateValue, setSelectedEndDateValue] = useState();
  const [selectedStartDateValue, setSelectedStartDateValue] = useState();
  const [fieldErrors, setFieldErrorsInternal] = useState({
    [ErrorKeyEnum.Name]: null,
    [ErrorKeyEnum.StartDate]: null,
    [ErrorKeyEnum.YoutubeLink]: null,
    [ErrorKeyEnum.VimeoLink]: null,
  });
  const currentDate = moment(new Date()).format('MM/DD/YYYY');


  /* Actions */
  const eventHandler = makeEventHandler(() => setError(""));
  const setFieldErrors = (field: string, message: string | null) => {
    const newFieldErrors: any = {
      [field]: message,
    };
    setFieldErrorsInternal(newFieldErrors);
  };
  const onChangeName = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.Name, null);
    setName(value);
  });
  const onChangeGoal = eventHandler((value: string) => {
    const goalValue = /^[0-9\b]+$/;
    if (value.match(goalValue) || value === "") {
      setGoal(value);
    }

  });

  const onChangeStartDate = ((value: any) => {
    let selectedStartDate = moment(value).format('MM/DD/YYYY');
    let selectedEndDate = moment(showEndDate).format('MM/DD/YYYY');

    // Startdate should always greater than and equal to current date
    if (selectedStartDate >= currentDate) {
      // check if campagain is default and selected value is greater then currenct date
      if (showCheckbox && !defaultCampaign && selectedStartDate > currentDate) {
        setSelectedStartDateValue(value)
        dispatch(AppActions.pushModal(ModalTypes.CampaignActiveModal))
        dispatch(AppActions.setCancelEndDate(true));
      }
      else {
        setStartDate(value);
        // When startdate greater than end then we are set the enddate to blank
        if (selectedStartDate > selectedEndDate) {
          setEndDate(undefined);
        }
      }
    }
  });
  const onChangeEndDate = ((value: any) => {
    let selectedEndDate = moment(value).format('MM/DD/YYYY');
    // Endate should be always greater than and equal to current date
    if (selectedEndDate >= currentDate) {
      // check if campagain is default and selected value is equal to currenct date
      if (showCheckbox && !defaultCampaign && selectedEndDate === currentDate) {
        setSelectedEndDateValue(value);
        dispatch(AppActions.pushModal(ModalTypes.CampaignActiveModal))
        dispatch(AppActions.setCancelEndDate(true));
      }
      else {
        setEndDate(value);
      }
    }

  });

  const onChangeDescription = eventHandler((value: string) => {
    setDescription(value);
  });
  const onChangeCheckbox = ((value: boolean) => {
    setDefault(value);
  });

  const onYoutubeLink = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.YoutubeLink, null);
    setYoutubeLink(value);
  });

  const onVimeoLink = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.VimeoLink, null);
    setVimeoLink(value);
  });

  const onCancel = ((index: number) => {
    setPosterUrl("");
  });

  const onGalleryCancel = ((index: number) => {
    const array = [...posterUrlArray];
    array.splice(index, 1)
    setPosterUrlArray(array);
  });

  const onClearEndDate = (() => {
    setEndDate(undefined);
  });

  const dispatch = useDispatch()

  const onMediaDrop = (mediaFiles: any) => {
    setIsPosterUrl(true);
    setLoaded(true);
    uploadFiles({
      variables: {
        files: mediaFiles[0],
      },
    });
  }

  const onGalleryDrop = (galleryFiles: any) => {
    setIsPosterUrl(false);
    setPrevLoaded(true);
    uploadFiles({
      variables: {
        files: galleryFiles[0],
      },
    });
  }

  const onChangeCampaignStub = eventHandler((value: string) => {
    const regex = /^[a-zA-Z0-9]{0,20}$/;
    const isValid = regex.test(value);
    if (isValid) {
      setCampaignSlug(value);
    }
  });

  const history = useHistory();
  const historyState: any = history.location.state
  const campaignId = historyState?.campaignId
  const duration = "All Time"

  /** GraphQL */
  const [uploadFiles] = useMutation(UPLOAD_FILES, {
    onCompleted: async (data: any) => {

      if (isPosterUrl) {
        setPosterUrl(data?.uploadFiles[0].url);
      }
      else {
        posterUrlArray.push(data?.uploadFiles[0].url)
        setPosterUrlArray(posterUrlArray);
      }
      setLoaded(false);
      setPrevLoaded(false);
    },
  });

  const { data } = useQuery(GET_CAMPAIGN, {
    fetchPolicy: "network-only",
    skip: !campaignId,
    variables: {
      "organizationId":  _id,
      campaignId,
      startDate: "",
      endDate: "",
      custom: false,
      dateRange:""
    },
    onCompleted: (data: any) => {
      const campaigns = data?.campaign?.campaign
      if (campaigns) {
        setName(campaigns.name || "");
        setGoal(campaigns.goal && (Price.output(campaigns.goal)).toString());
        setStartDate(
          campaigns.startDate &&
          new Date(Number(campaigns.startDate)) || ""
        );

        setEndDate(
          campaigns.endDate &&
          new Date(Number(campaigns.endDate)) || ""
        );
        setDescription(campaigns.description || "");
        setPosterUrl(campaigns.posterUrl || "");
        setCampaignSlug(campaigns.campaignSlug || "");
        setYoutubeLink(campaigns.youtubeLink || "");
        setVimeoLink(campaigns.vimeoLink || "");
        setPosterUrlArray(campaigns.gallery || []);
        setShowCheckbox(campaigns.isDefault);
        setDefault(campaigns.isDefault);
        setActiveCampaign(campaigns.active);
        setGeneralFundraiser(campaigns.isGeneralFundraiser)
      }
    }
  });

  const [onCreateCampaignSubmit] = useMutation(CREATE_CAMPAIGN_METHOD, {
    refetchQueries: [{ query: GET_LIST_CAMPAIGN }],
    awaitRefetchQueries: true,
    onCompleted: async () => {
      toast.success(successMessages.CAMPAIGN_SUCCESS);
      setTimeout(() => {
        setLoading(false);
        history.push(`/dashboard/campaigns`);
      }, 2000);
    },
    onError: async (error: any) => {
      const errorMsg = ErrorUtil.getErrorMessage(error);
      setError(errorMsg);
    },
  })

  const newSelectStartDate = startDate && moment(startDate).format('MM/DD/YYYY');
  const selectStartDate = newSelectStartDate && new Date(newSelectStartDate).getTime();
  const showStartDate = selectStartDate ? selectStartDate : "";
  const newSelectEndDate = endDate && moment(endDate).format('MM-DD-YYYY');
  const selectEndDate = newSelectEndDate && new Date(newSelectEndDate).getTime();
  const showEndDate = selectEndDate ? selectEndDate : "";


  const onCreateCampaign = async () => {
    const params = schema.validate({
      name,
      startDate,
      youtubeLink,
      vimeoLink
    });
    const { error: schemaError } = params;
    if (schemaError) {
      const { field, message } = JSON.parse(schemaError.message);
      setFieldErrors(field, message);
      return;
    }

    // var url = $('#youTubeUrl').val();
    if (youtubeLink != undefined || youtubeLink != '') {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = youtubeLink.match(regExp);
      if (match && match[2].length == 11) {
        // Do anything for being valid
        // if need to change the url to embed url then use below line
        setYoutubeLink('https://www.youtube.com/embed/' + match[2] + '?autoplay=0');
      }
      else {
        // alert("what is this")
        setError("what is this");
        // Do anything for not being valid
      }
    }

    setError("");
    onCreateCampaignSubmit({
      variables: {
        params: {
          "name": name,
          "goal": goal ? (Number(goal) * 100).toString() : "",
          "startDate": showStartDate.toString(),
          "endDate": showEndDate.toString(),
          "description": description,
          "posterUrl": posterUrl,
          "isDefault": isDefault,
          "gallery": posterUrlArray,
          "youtubeLink": youtubeLink,
          "vimeoLink": vimeoLink
        }
      }
    })
    setLoading(true);
  };

  const [updateCampaignMutation] = useMutation(
    UPDATE_CAMPAIGN,
    {
      refetchQueries: [{
        query: GET_LIST_CAMPAIGN, variables: {
          campaignId
        },
      }],
      awaitRefetchQueries: true,
      onCompleted: async () => {
        toast.success(successMessages.CAMPAIGN_UPDATE);
        dispatch(AppActions.setDefaultCampaign(false));
        setError("");
        setTimeout(() => {
          setLoading(false);
          history.push(`/dashboard/campaigns`);
        }, 2000);
      },
      onError: async (error: any) => {
        const errorMsg = ErrorUtil.getErrorMessage(error);
        setError(errorMsg);
      },
    }
  );

  const updateCampaign = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    const params = schema.validate({
      name,
      startDate,
      youtubeLink,
      vimeoLink
    });
    const { error: schemaError } = params;
    if (schemaError) {
      const { field, message } = JSON.parse(schemaError.message);
      setFieldErrors(field, message);
      return;
    }

    // var url = $('#youTubeUrl').val();
    if (youtubeLink !== '') {

      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = youtubeLink.match(regExp);
      if (match && match[2].length == 11) {
        // Do anything for being valid
        // if need to change the url to embed url then use below line
        setYoutubeLink('https://www.youtube.com/embed/' + match[2] + '?autoplay=0');
      }
      else {
        // alert("what is this")
        setError("what is this");
        // Do anything for not being valid
      }
    }


    setError("");
    updateCampaignMutation({
      variables: {
        params: {
          "_id": campaignId,
          "name": name,
          "goal": goal ? (Number(goal) * 100).toString() : "",
          "startDate": showStartDate.toString(),
          "endDate": showEndDate.toString(),
          "description": description,
          "posterUrl": posterUrl,
          "isDefault": defaultCampaign ? false : isDefault,
          "gallery": posterUrlArray,
          "campaignSlug": campaignSlug,
          "youtubeLink": youtubeLink,
          "vimeoLink": vimeoLink
        }
      }
    });
    setLoading(true);
  };

  /* Graphql */
  const { data: campaignList } = useQuery(GET_LIST_CAMPAIGN, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const campaignDetail = data?.listCampaign || [];
      const allDefault = campaignDetail.filter((item: any) => item.isDefault === true);
      if (campaignDetail) {
        setDataLength(campaignDetail);
        setAllDefault(allDefault);
      }
    }
  });

  useEffect(() => {
    const pathName = history.location.pathname
    if (!campaignId && pathName === "/dashboard/campaigns/edit") {
      history.push({ pathname: `/dashboard/campaigns` });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!selecteDateState && selectedEndDateValue) {
        setEndDate(selectedEndDateValue);
      }
    }, 0);
  }, [selecteDateState, selectedEndDateValue]);

  useEffect(() => {
    setTimeout(() => {
      if (!selecteDateState && selectedStartDateValue) {
        setStartDate(selectedStartDateValue)
      }
    }, 0);
  }, [selecteDateState, selectedStartDateValue]);


  const campaignsData = data?.campaign?.campaign
  const getStartDate = campaignsData?.startDate && new Date(Number(campaignsData?.startDate)) || "";

  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <DashboardTitle>
        <Title className="dashboardTitle">{campaignId ? "Edit Campaign" : "New Campaign"}</Title>
      </DashboardTitle>
      <DashboardLayout>
        <LeftLayout>
          <Content>
            <LabeledInput
              label="CAMPAIGN NAME"
              placeholder="Campaign Name"
              value={name}
              type="text"
              width="670px"
              onChange={onChangeName}
              error={fieldErrors[ErrorKeyEnum.Name]}
            />
            <Spacer />
            <Label text="CAMPAIGN DETAILS"></Label>
            <StyleCampaignDetails>
              <LabeledInput
                icon={Icons.UsdCircle}
                placeholder="Campaign Goal"
                value={goal}
                type="text"
                onChange={onChangeGoal}
                width="235px"
                margin=' 0 15px 0 0'
                labelInputwidth="auto"
                padding="0 15px 0 40px"
              />
              {!isGeneralFundraiser &&
              <>
                <DateContainer>
                  <DateError>
                    <DateTimePicker
                      className="showDate"
                      value={startDate}
                      format="MM-dd-y"
                      onChange={onChangeStartDate}
                      clearIcon={null}
                      disableClock
                      minDate={campaignId && getStartDate <= new Date() ? getStartDate : new Date()}
                    />
                    {!startDate ? <ErrorText margin="5px 0 0 0 " align='left'>{fieldErrors[ErrorKeyEnum.StartDate]}</ErrorText> : ""}
                  </DateError>
                  <DatePlaceHolder date={startDate}>Campaign Start Date</DatePlaceHolder>
                </DateContainer>
                <DateContainer>
                  <DateTimePicker
                    className="showDate"
                    value={endDate}
                    format="MM-dd-y"
                    onChange={onChangeEndDate}
                    clearIcon={null}
                    disableClock
                    minDate={startDate ? startDate : new Date()}
                  />
                  <DatePlaceHolder date={endDate} isDefault={isDefault}>Campaign End Date </DatePlaceHolder>
                  <ClearDate endDate={endDate}><Icon icon={Icons.Cancel} size={14} color={Colors.Grey2} hoverColor={Colors.Pink} onClick={onClearEndDate} /></ClearDate >
                </DateContainer></>}
            </StyleCampaignDetails>
            <Spacer />
            <TextArea
              placeholder="Campaign Overview"
              value={description}
              width="670px"
              onChange={onChangeDescription}
            />
            <Spacer />
            <MediaDetails>
              <ImagePreview>
                <ThumbNail>
                  {loaded && <Loader size={LoaderSizes.Small} color={Colors.Pink} />}
                  {posterUrl && !loaded && <PosterImage src={posterUrl} />}
                  {!posterUrl && !loaded && <DefaultImage src={defaultImg} />}
                </ThumbNail>
                {posterUrl && !loaded &&
                  <CancelIcon>
                    <Icon icon={Icons.Cancel} size={14} color={Colors.Grey2} hoverColor={Colors.Pink} onClick={onCancel} />
                  </CancelIcon>}
              </ImagePreview>
              <CampaignMediaContent>
                <ContentInner>
                  <Label text={'CAMPAIGN FEATURE IMAGE'}></Label>
                  <ThumbHeading>Choose an image to represent your campaign.</ThumbHeading>
                  <ThumbParagraph style={{ color: "#BDBDBD" }}>Preferred image size is XXX x XXX, JPG or PNG format.</ThumbParagraph>
                  <Dropzone onDrop={onMediaDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} style={{ width: "126px" }}>
                        <input {...getInputProps()} />
                        <Button
                          type={ButtonTypes.Button}
                          text={"Add Media"}
                          border="10px"
                          width='126px'
                        />
                      </div>
                    )}
                  </Dropzone>
                </ContentInner>
              </CampaignMediaContent>
            </MediaDetails>
            <Spacer />
            <MediaDetails>
              {posterUrlArray.length === 0 && <ImagePreview>
                <ThumbNail>
                  {!prevLoaded && <DefaultImage src={defaultImg} />}
                </ThumbNail>
              </ImagePreview>}
              {posterUrlArray.length > 0 && posterUrlArray.map((image: any, index: number) => {
                return (
                  <ImagePreview>
                    <ThumbNail>
                      <PosterImage src={image} />
                    </ThumbNail>
                    <CancelIcon>
                      <Icon icon={Icons.Cancel} size={14} color={Colors.Grey2} hoverColor={Colors.Pink} onClick={() => onGalleryCancel(index)} />
                    </CancelIcon>
                  </ImagePreview>
                )
              })}
              <CampaignMediaContent>
                <ContentInner>
                  <Label text={'ADDITIONAL IMAGES'}></Label>
                  <ThumbHeading>Upload additional images for your campaign.</ThumbHeading>
                  <ThumbParagraph style={{ color: "#BDBDBD" }}>Preferred image size is XXX x XXX, JPG or PNG format.</ThumbParagraph>
                  <Dropzone onDrop={onGalleryDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} style={{ width: "126px" }} >
                        <input {...getInputProps()} />
                        <Button
                          type={ButtonTypes.Button}
                          text={"Add Media"}
                          border="10px"
                          width='126px'
                          loading={prevLoaded}
                        />
                      </div>
                    )}
                  </Dropzone>
                </ContentInner>
              </CampaignMediaContent>
            </MediaDetails>
            <Spacer />
        {campaignId && <>  <LabelContainer>
            <Label text="CAMPAIGN SLUG" ></Label>
            <p style={{ width: 'min-content', margin: '0' }} data-tip={`The custom slug for your campaign on your organization's public profile page (e.g., https://app.matchstik.org/org/${organizationStub || _id}/${campaignSlug || campaignId})`}>
              <Icon margin={"0 6px 8px"} icon={Icons.infoRegular} color={Colors.Grey1} />
            </p>
          </LabelContainer>
            <LabeledInput
                placeholder="Campaign Slug"
                value={campaignSlug}
                type="text"
                width="670px"
                onChange={onChangeCampaignStub}
              />
              <Spacer /></>}
            <StyleVideos>
              <LabeledInput
                label="YOUTUBE VIDEO"
                placeholder="YouTube Video"
                value={youtubeLink}
                type="text"
                width="310px"
                onChange={onYoutubeLink}
                error={fieldErrors[ErrorKeyEnum.YoutubeLink]}
              />
              <LabeledInput
                label="VIMEO VIDEO"
                placeholder="Vimeo Video"
                value={vimeoLink}
                type="text"
                width="310px"
                onChange={onVimeoLink}
                error={fieldErrors[ErrorKeyEnum.VimeoLink]}
              />
            </StyleVideos>
            <Spacer />
            {!showCheckbox && activeCampaign && <> <DefaultCheckbox>
              <Checkbox
                active={isDefault}
                onChange={onChangeCheckbox}
              />
              <SmallText>
                Make this campaign our default
              </SmallText>
            </DefaultCheckbox>
            <Spacer /></>}
            {error && <ErrorText margin='60px 0' align="left">{error}</ErrorText>}
            <Button
              type={ButtonTypes.Button}
              text={campaignId ? "Save Updates" : "Create New Campaign"}
              border="10px"
              width='316px'
              height='57px'
              onClick={campaignId ? updateCampaign : onCreateCampaign}
              loading={loading}
              icon={Icons.RightChevron}
            />
            <ReactTooltip
            place="top"
            type="dark"
            effect="solid"
            clickable = {false}
          />
          </Content>
        </LeftLayout>
      </DashboardLayout>
    </>
  );
};
export default NewCampaignPage;
