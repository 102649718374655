import gql from 'graphql-tag';

const query = gql`
  query platformSettings {
    platformSettings {
      stripeClientId
      stripeRedirectUrl
    }
  }
`;

export default query;
