import "./styles/index.css";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import client from './graphql/client';
import store from './redux/store';
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "./env";
import { Elements } from "@stripe/react-stripe-js";
// import { AudioPlayerProvider } from "react-use-audio-player";
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
const fonts = [
  {
    cssSrc: "https://use.typekit.net/suf4knx.css",
    family: "neue-haas-grotesk-display",
  },
];

ReactDOM.render(
  <ApolloProvider client={client as any}>
    <ReduxProvider store={store}>
      <BrowserRouter>
      <Elements stripe={stripePromise} options={{ fonts }}>
        {/* <AudioPlayerProvider> */}
          <App />
        {/* </AudioPlayerProvider> */}
        </Elements>
      </BrowserRouter>
    </ReduxProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
