

import gql from 'graphql-tag';
const mutation = gql`
    mutation createFollow($params: FollowInput!){
        createFollow(params: $params){
            message
        }
    }
`;

export default mutation;
