import gql from 'graphql-tag';

const query = gql`
  query donation($donationId: String!) {
    donation(donationId: $donationId) {
      _id
      organizationId
      donorType
      email
      name
      phoneNumber
      url
      address{
        state
        city
        zip
        streetAddress
      }
      amount
      coverFees
      message
      anonymous
      stripeChargeId
      refunded
      refundedAt
      type
      membershipId
      stripeCharge {
        brand
        last4
      }
      transactionDetails {
        totalAmount
        stripeFee
        appFee
        clientAmount
      }
      meta {
        createdAt
      }
    }
  }
`;

export default query;
