import React from 'react';
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip, AreaChart, Area, ResponsiveContainer
} from 'recharts';
import styled from "styled-components";
import { Colors } from "../styles/Colors";
import { useQuery } from '@apollo/client';
import * as Price from '@matchstik/utils/.dist/price';
import GET_DONATION_GRAPH from '../graphql/queries/donationGraph.query';
import moment from 'moment';

const Container = styled.div`
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
padding: 10px;
width: 100%;
height: 400px;
background-color: #fff;
`;
type DashboardGraphProps = {
    campaignGraph?: any;
    startDate?: any;
    endDate?: string;
    custom?: boolean;
    dateRange?:string;
};

const CustomTooltip = ({ active, payload }: any) => {
    const CustomTooltip = styled.div`
        background: rgb(255, 255, 255);
        padding: 5px;
        border-radius: 10px;
        box-shadow: rgb(0 0 0 / 20%) 0px 0px 3px;
        .Time{
         margin:0px;
        }
        .intro{
            margin:0px;
           }
  `;
    if (active && payload && payload.length) {
        let detail = payload[0].payload;
        return (
            <CustomTooltip className="custom-tooltip" >
                <p className="Time">{`Day: ${detail.Time}`}</p>
                <p className="intro">{`${Object.keys(detail)[1]} : $${detail.Amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</p>
            </CustomTooltip>
        );
    }
    return null;
};


const DashboardGraph: React.FC<DashboardGraphProps> = ({ campaignGraph, startDate, endDate, custom, dateRange }) => {

    /* GraphQL */
    const campaignGraphData = campaignGraph
    const { data } = useQuery(GET_DONATION_GRAPH, {
        variables: { startDate: startDate, endDate: endDate, custom: custom , dateRange},
        fetchPolicy: "network-only"
    });

    const graphDetails = data?.donationGraph?.map((modal: any) => {
        let amount = Price.output(modal?.amount);
        return {
            Time: moment(modal?.time as any).format("MMM DD, YYYY"),
            Amount: amount
        }
    });


    return (
        <>
            <Container>
                <ResponsiveContainer>
                    <AreaChart
                        width={730}
                        height={250}
                        data={campaignGraphData ? campaignGraphData : graphDetails}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey={"Time"} minTickGap={6} />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Area type="monotone" dataKey={"Amount"} stroke={Colors.Pink} fill={Colors.Pink} />

                    </AreaChart>
                </ResponsiveContainer>
            </Container>
        </>
    );
};

export default DashboardGraph;