import React, { useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Colors } from '../styles/Colors';


const ScrollTableResponsive = styled.div`
  min-height: .01%;
  overflow-x: auto;
  box-shadow: 0px 4px 16px rgb(17 0 40 / 5%);
  &::-webkit-scrollbar{
    display:block
  }
`;

export const ScrollTableContainer = styled.table`
  border-radius: 10px;
  border: 1px solid ${Colors.Grey6};
  position: relative;
  width: 100%;
  max-width: 100%;
  display: table;
  @media (max-width:767px)
  {
    border:0px solid ${Colors.Grey6};
    min-width: auto;
  } 
`;

export const ScrollTableHeader = styled.thead`
  z-index: 9;
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  background-color: ${Colors.White};
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid ${Colors.Grey6};
  @media (max-width:767px)
  {
   display:none;
  }
`;
export const ScrollTableRow = styled.tr`
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
`;

type ScrollTableHeaderCells = {
  flex?: string
  justify?: string
  width?:string
};

export const ScrollTableHeaderCell = styled.th<ScrollTableHeaderCells>`
  // div {
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  // }
  color: ${Colors.Grey3};
  font-size: 1.2rem;
  font-weight: 600;
  padding: 10px 25px;
  line-height: 1.42857143;
  vertical-align: middle;
  // border-top: 1px solid #ddd;
  display: table-cell;
  text-align: left;
  min-width: ${props => props.width};
`;

export const ScrollTableBody = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
  background-color: ${Colors.White};
`;

type ScrollTableBodyRowProps = {
  height?: string
  onClick?: Function
}

export const ScrollTableBodyRow = styled.tr<ScrollTableBodyRowProps>`
  transition: all 0.2s;
  border-bottom: 1px inset ${Colors.Grey6};
  background-color: ${Colors.White};
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  &:hover {
    cursor: ${props => (props.onClick ? 'pointer' : null)};
    background-color: ${props => (props.onClick ? rgba(Colors.Grey1, 0.03) : null)};
  }
  @media (max-width:767px)
  {
    display:block;
    border-bottom:30px solid #F8F8F8;
    padding:10px 15px;
  }
`;

type ScrollTableBodyCellProps = {
  flex?: string
  justify?: string
  width?:string
}

export const ScrollTableBodyCell = styled.td<ScrollTableBodyCellProps>`
  color: ${Colors.Grey1};
  font-size: 1.4rem;
  font-weight: 500;
  min-width: ${props => props.width};
  padding: 10px 25px;
  line-height: 1.42857143;
  vertical-align: middle;
  border-top: 1px solid #ddd;
  display: table-cell;
  text-align: left;
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width:767px)
  {
    padding: 15px 15px 15px 50%;
    border-bottom: 1px solid #f2f2f2;
    font-size:14px;
    min-width:auto;
    width: auto;
    display: block;
    border-top: 0px;
    min-height: 30px;
    &:last-child{
      border-bottom: 0px solid #f2f2f2;   
    }
    &:before{
      content: attr(data-label);
      text-transform: capitalize;
      font-weight: bold;
      position: absolute;
      left:15px;
      font-size: 14px;
      max-width:170px;
      line-height: normal;
    }
  }
`;

export const ScrollTableNoContent = styled.tr`
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
`;

export const ScrollTableSpace = styled.div`
  width: 20px;
`;

type ScrollTableProps = {
  children?: React.ReactNode;
};

const ScrollTable: React.FC<ScrollTableProps> = ({
  children,
}) => {
  const [fetching, setFetching] = useState(false);

  return (
    <ScrollTableResponsive>
      <ScrollTableContainer
      >
        {typeof children === 'function' ? children(fetching) : children}
      </ScrollTableContainer>
    </ScrollTableResponsive>
  );
};

export default ScrollTable;
