import client from "../graphql/client";

const MATCHSTIK_AUTH_TOKEN = "MATCHSTIK_AUTH_TOKEN";

export function setToken(token: string) {
  const t = {
    value: token,
    ttl: Date.now() + 1000 * 60 * 30
  };
  return localStorage.setItem(MATCHSTIK_AUTH_TOKEN, JSON.stringify(t));
}

export function getToken(): string | null {
  const t = localStorage.getItem(MATCHSTIK_AUTH_TOKEN);
  
  if (!t) return null;

  try {
    const item = JSON.parse(t);
    if (Date.now() > item.ttl) {
      localStorage.removeItem(MATCHSTIK_AUTH_TOKEN);
      return null;
    }
  
    item.ttl = Date.now() + 1000 * 60 * 30;
    localStorage.setItem(MATCHSTIK_AUTH_TOKEN, JSON.stringify(item));

    return item.value;
  }
  catch (ex) {
    localStorage.removeItem(MATCHSTIK_AUTH_TOKEN);
    return null;
  }
}

export async function logout() {
  localStorage.clear();
  // persistor.pause();
  // persistor.purge();
  client.resetStore();
  window.location.href = '/';
}
