 import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Icon, { Icons } from "../../elements/Icon";
import { Colors } from "../../styles/Colors";
import * as Polished from "polished";
import { MatchstikState } from "../../redux/store";
import { FadeIn, CardPop } from "../../elements/Motion";
import DonationDetailsModal from "./DonationDetailsModal";
import RefundDonationModal from "./RefundDonationModal";
import MembershipDetailsModal from "./MembershipDetailsModal";
import CancelMembershipModal from "./CancelMembershipModal";
import FirstLoginModal from "./FirstLoginModal";
import ConfirmActionModal from "./ConfirmActionModal";
import ConnectStripeModal from "./ConnectStripeModal";
import TextEditorModal from "./TextEditorModal";
import UpdatePaymentModal from "./UpdatePaymentModal";
import CreateFailedTaskDonationModal from "./CreateFailedTaskDonationModal";
import CampaignActiveModal from "./CampaignActiveModal";
import ActiveMatchesModal from "./ActiveMatchesModal";
import PreviewImageModal from "./PreviewImageModal";


export enum ModalTypes {
  DonationDetails = "DonationDetails",
  SaveChanges = "SaveChanges",
  ConfirmAction = "ConfirmAction",
  ImageCropper = "ImageCropper",
  RefundDonation = "RefundDonation",
  MembershipDetails = "MembershipDetails",
  CancelMembership = "CancelMembership",
  FirstLogin = "FirstLogin",
  ConnectStripe = 'ConnectStripe',
  TextEditor = 'TextEditor',
  UpdatePayment = 'UpdatePayment',
  CreateFailedTaskDonation = "CreateFailedTaskDonation",
  CampaignActiveModal = "CampaignActiveModal",
  ActiveMatchesModal = "ActiveMatchesModal",
  PreviewImageModal = "PreviewImageModal",
}

export const urlSafeModalTypes = {};

export enum ModalPositions {
  Top = "Top",
  Center = "Center",
}

const positions = {
  [ModalTypes.DonationDetails]: ModalPositions.Top,
  [ModalTypes.SaveChanges]: ModalPositions.Top,
  [ModalTypes.ConfirmAction]: ModalPositions.Top,
  [ModalTypes.ImageCropper]: ModalPositions.Top,
  [ModalTypes.RefundDonation]: ModalPositions.Top,
  [ModalTypes.MembershipDetails]: ModalPositions.Top,
  [ModalTypes.CancelMembership]: ModalPositions.Top,
  [ModalTypes.FirstLogin]: ModalPositions.Top,
  [ModalTypes.ConnectStripe]: ModalPositions.Top,
  [ModalTypes.TextEditor]: ModalPositions.Top,
  [ModalTypes.UpdatePayment]: ModalPositions.Top,
  [ModalTypes.CreateFailedTaskDonation]: ModalPositions.Top,
  [ModalTypes.CampaignActiveModal]: ModalPositions.Top,
  [ModalTypes.ActiveMatchesModal]: ModalPositions.Top,
  [ModalTypes.PreviewImageModal]: ModalPositions.Top,
 
};

/********************************************************************************
 *  Modal
 *******************************************************************************/

type ContainerProps = {
  isTop: boolean;
};

const Container = styled(FadeIn)<ContainerProps>`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: ${Polished.rgba(Colors.Black, 0.4)};
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.isTop ? "flex-start" : "center")};
  // padding: ${(props) => (props.isTop ? "100px 0" : "0")};
  align-items: center;
  z-index: 900;
  overflow-y: scroll;
`;
const InnerContainer = styled.div`
width: 90%;
  > div {
   width:100%;
  }
`;
type ModalProps = {};

const Modal: React.FC<ModalProps> = () => {
  /* State */
  const appState = useSelector((state: MatchstikState) => state.app);
  const { modals } = appState;

  /** Render */
  if (!modals.length) return null;

  const isTop =
    positions[[...modals].pop() as ModalTypes] === ModalPositions.Top;

  return (
    <Container duration={0.1} isTop={isTop}>
      <InnerContainer>
      {modals.map((modal, index) => {
        return (
          <RenderModal
            key={index}
            modal={modal}
            active={index === modals.length - 1}
          />
        );
      })}
      </InnerContainer>
    </Container>
  );
};

export default Modal;

/********************************************************************************
 *  Render Modal
 *******************************************************************************/

type RenderModalContainerProps = {
  active: boolean;
};

const RenderModalContainer = styled.div<RenderModalContainerProps>`
  position: ${(props) => (props.active ? null : "absolute")};
  top: ${(props) => (props.active ? null : "-10000000px")};
  left: ${(props) => (props.active ? null : "-10000000px")};
`;

type RenderModalProps = {
  modal: ModalTypes;
  active: boolean;
};

const RenderModal: React.FC<RenderModalProps> = ({ modal, active }) => {
  return (
    <RenderModalContainer active={active}>
      {(() => {
        switch (modal) {
          case ModalTypes.DonationDetails:
            return <DonationDetailsModal />;
          case ModalTypes.RefundDonation:
            return <RefundDonationModal />;
          case ModalTypes.MembershipDetails:
            return <MembershipDetailsModal />;
          case ModalTypes.CancelMembership:
            return <CancelMembershipModal />;
          case ModalTypes.FirstLogin:
            return <FirstLoginModal />;
            case ModalTypes.ConfirmAction:
            return <ConfirmActionModal />;
            case ModalTypes.TextEditor:
              return <TextEditorModal />;
            case ModalTypes.UpdatePayment:
              return <UpdatePaymentModal />;
            case ModalTypes.CreateFailedTaskDonation:
              return <CreateFailedTaskDonationModal />;
            case ModalTypes.CampaignActiveModal:
              return <CampaignActiveModal />;
            case ModalTypes.ActiveMatchesModal:
              return <ActiveMatchesModal />;
              case ModalTypes.PreviewImageModal:
              return <PreviewImageModal />;       
            case ModalTypes.ConnectStripe:
              return <ConnectStripeModal />;
        
          default:
            return <div />;
        }
      })()}
    </RenderModalContainer>
  );
};

/********************************************************************************
 *  Exported Modal Styles - for use in modal implmentations
 *******************************************************************************/

type ModalContainerProps = {
  height?: string;
  width?: string;
};

export const ModalContainer = styled(CardPop)<ModalContainerProps>`
  position: relative;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-radius: 10px;
  background-color: ${Colors.White};
  overflow: visible;
  margin:0 auto;
  @media (max-width:640px){
  width:100%;
  height:100%;
  }
  
`;

const ModalHeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: ${Colors.White};
  border-bottom: 1px solid ${Colors.Grey6};
`;

const ModalTitle = styled.div`
  font-size: 2rem;
  font-weight: 800;
  color: ${Colors.Pink};
  @media (max-width:640px){
    font-size: 1.6rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type ModalHeaderProps = {
  title: string|undefined;
  icon?: any;
  close: Function;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  icon,
  close,
}) => {
  return (
    <ModalHeaderContainer>
      <Row>
        {icon && icon}
        <ModalTitle>{title}</ModalTitle>
      </Row>
      <Icon
        icon={Icons.CancelCircle}
        color={Colors.Grey5}
        hoverColor={Colors.Red}
        size={18}
        onClick={close}
      />
    </ModalHeaderContainer>
  );
};

type ModalContentHeight = {
  height?: string;
  padding?: string;
};

export const ModalContent = styled.div<ModalContentHeight>`
  position: relative;
  padding: ${(props) => props.padding ?  props.padding : "0 30px 30px"};
  /* background-color: ${Colors.WhiteSmoke}; */
  height: ${(props) => props.height};
  max-height: 70vh;
  overflow-y: scroll;
`;

export const ModalFooter = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 59px;
  padding: 0 20px;
  background-color: ${Colors.White};
  border-top: 1px solid ${Colors.Grey6};
  border-radius: 0px 0px 5px 5px;
`;
