import React, { useEffect, useState } from "react";
import { Colors } from "../styles/Colors";
import Icon, { Icons } from "../elements/Icon";
import DashboardGraph from "../elements/DashboardGraph";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import GET_CAMPAIGN from "../graphql/queries/campaign.query";
import * as Price from '@matchstik/utils/.dist/price';
import moment from "moment";
import DateTimePicker from 'react-datetime-picker';
import { TabsLayout, TabsList, TabListItem, DateSpace } from "../pages/Dashboard.page";
import { TabListItemEnum } from "@matchstik/models/.dist/enums/TabListItemEnum";
import Button from "../elements/Button";
import DELETE_CAMPAIGN from "../graphql/mutations/deleteCampaign.mutation";
import logoSrc from "./../assets/images/logo-icon.png";
import { CampaignsLayout, LeftLayout, CardLayout, CardHeader, CardBody, MiddleSection, AverageDonation, Transactions, Donors, CardTitle, Amount, RightLayout, ButtonsLayout, ButtonsInner, MatchLayout, MatchHeader, NavIcon, MatchListing, MatchListItem, Logo } from "../pages/Campaigns.page";
import { ModalTypes } from "./modal/Modal";
import { useDispatch, useSelector } from 'react-redux';
import * as AppActions from "../redux/actions/app.actions";
import { DONATION_URL } from "../env";
import styled from "styled-components";


export const IconContainer = styled.div`
     width: max-content;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    @media (max-width:1199px)
    {left: 15px;}
    `;
     

function getEmbedHTML(organizationId: string, campaignId: string, text: string, buttonColor: string, color: string) {
    return (`
    <a href="javascript:void(0)" style="text-decoration:none;">
        <img src="/favicon.ico" onerror="window.Matchstik.preload('${organizationId}&campaignId${campaignId}&&offlineDonation')" style="display:none;"> 
        <div class = "overviewDonorModal" style="
        background-color:${Colors.Grey6}; 
        color:${Colors.Grey2};
        border-radius: 10px; 
        height:57px; 
        width:100%; 
        display:inline-flex; 
        align-items:center; 
        padding-left: 75px; 
        font-weight: 500;
        font-size: 16px;
        box-sizing: border-box;
        "
        onClick="window.Matchstik.open('${organizationId}&campaignId${campaignId}&&offlineDonation')">${text}</div>
    </a>
    `);
}


/* Handle Week Dates */
const MILLISECOND = 1;
const SECOND = MILLISECOND * 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const todayStartDate = moment().startOf('day').unix() * SECOND;
const allEndDate = moment().endOf('day').unix() * SECOND;
const oneWeekStartDate = moment().subtract(7, "days").startOf("day").unix() * SECOND;
const monthFirstDay = moment().subtract(1, "month").startOf("day").unix() * SECOND;
const monthTillFirstDay = moment().startOf('month').unix() * SECOND;
const yearTillFirstDay = moment().startOf('year').unix() * SECOND;
const yearFirstDay = moment().subtract(1, "year").startOf("day").unix() * SECOND
const minDateValue = 946684800000;


interface CampaignOverviewProps { }

const CampaignOverview: React.FC<CampaignOverviewProps> = () => {
    /* Handle Week Filters */
    const organization = useSelector((state: any) => state.user.organization);
    const tabState = window.sessionStorage.getItem("tabsState");
    /* State */
    const [applyCustomDate, setApplyCustomDate] = useState(false);
    const [startDate, setStartDate] = useState(oneWeekStartDate.toString());
    const [endDate, setEndDate] = useState(allEndDate.toString());
    const [tabsState, setTabsState] = useState(tabState ? tabState : TabListItemEnum.OneWeek);
    const [customStartDate, setCustomStartDate] = useState(new Date());
    const [customEndDate, setCustomEndDate] = useState(new Date());
    const [custom, setCustom] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSucessfullyUpdated, setIsSucessfullyUpdated] = useState(false);

    /* CUSTOM DATE */
    const selectStartDate = customStartDate && moment(customStartDate).startOf('day').unix() * SECOND;
    const selectEndDate = customEndDate && moment(customEndDate).endOf('day').unix() * SECOND;

    /* Action */
    const history = useHistory();
    const dispatch = useDispatch();
    const historyState: any = history.location.state;
    const campaignId = historyState?.campaignId;
    const campaignName = historyState?.campaignName;
    const campaignDef = historyState?.campaignDef;
    const posterUrl = historyState?.posterUrl;
    const isDefault = historyState?.isDefault;
    const onEditCampaign = (() => {
        history.push({
            pathname: `edit`,
            state: { campaignId: campaignId, campaignName: campaignName, posterUrl: posterUrl, campaignGraph: campaignGraph, campaignDef: campaignDef, editCampaign: true }
        });
    });
    const onDeleteCampaign = (() => {
        
        if (totalDonationAmount > "0" || totalMatchers > "0" || isDefault) {
            dispatch(
                AppActions.pushModalConfirmAction({
                  title: `Delete ${campaignName} ?`,
                  message: `Are you sure you want to delete ${campaignName} campaign ?`,
                  confirm: () => {
                    dispatch(AppActions.popModal());
                    dispatch(AppActions.setDeleteCampaign({ deleteCampaign: true, isDefault: isDefault }));
                    dispatch(AppActions.pushModal(ModalTypes.CampaignActiveModal))
                  },
                  cancel: () => { },
                })
              );
        }
        else {
            dispatch(
                AppActions.pushModalConfirmAction({
                  title: `Delete ${campaignName} ?`,
                  message: `Are you sure you want to delete ${campaignName} campaign ?`,
                  confirm: () => {
                    dispatch(AppActions.popModal());
                    deleteCampaignMutation();
                  },
                  cancel: () => { },
                })
              );     
        }

    });
    const onYourPage = (() => {
        history.push({ pathname: `/dashboard/your-page` });
    });

    const onAllTimeButton = (() => {
        setTabsState(TabListItemEnum.AllTime);
        setStartDate("");
        setEndDate("");
    });
    const onTodayButton = (() => {
        setTabsState(TabListItemEnum.Today);
        setStartDate(todayStartDate.toString());
        setEndDate(allEndDate.toString());
    });
    const onOneWeekButton = (() => {
        setTabsState(TabListItemEnum.OneWeek);
        setStartDate(oneWeekStartDate.toString());
        setEndDate(allEndDate.toString());
    });
    const onOneMonthButton = (() => {
        setTabsState(TabListItemEnum.OneMonth);
        setStartDate(monthFirstDay.toString());
        setEndDate(allEndDate.toString());
    });
    const onMonthTillDateButton = (() => {
        setTabsState(TabListItemEnum.MonthTillDate);
        setStartDate(monthTillFirstDay.toString());
        setEndDate(allEndDate.toString());
    });
    const onYearTillDateButton = (() => {
        setTabsState(TabListItemEnum.YearTillDate);
        setStartDate(yearTillFirstDay.toString());
        setEndDate(allEndDate.toString());
    });
    const onOneYearButton = (() => {
        setTabsState(TabListItemEnum.OneYear);
        setStartDate(yearFirstDay.toString());
        setEndDate(allEndDate.toString());
    });
    const onApplyCustomDate = ((e: any) => {
        e.preventDefault();
        setApplyCustomDate(true);
        setLoading(true);
        setCustom(true);
        setStartDate(selectStartDate.toString());
        setEndDate(selectEndDate.toString());
    });
    const onCustomDate = (() => {
        setIsSucessfullyUpdated(false);
        setApplyCustomDate(false);
        setTabsState(TabListItemEnum.Custom);
        setCustomStartDate(new Date());
        setCustomEndDate(new Date());
    });
    const onChangeStartDate = ((value: any) => {
        setIsSucessfullyUpdated(false);
        setCustomStartDate(value);
    });
    const onChangeEndDate = ((value: any) => {
        setIsSucessfullyUpdated(false);
        setCustomEndDate(value);
    });

    /* Handle Week Filters */
    useEffect(() => {
        const startValue = sessionStorage.getItem("startDate");
        const endValue = sessionStorage.getItem("endDate");
        const tabState = sessionStorage.getItem("tabsState")
        if (tabState === "All Time") {
            setStartDate("");
            setEndDate("");
        }
        if (tabState) {
            setApplyCustomDate(tabState === "Custom")
        }
        if (startValue && endValue) {
            setStartDate(startValue.toString());
            setCustomStartDate(new Date((Number(startValue))));
            setEndDate(endValue.toString());
            setCustomEndDate(new Date((Number(endValue))));
        }
    }, []);

    /** GraphQL */

    const [deleteCampaignMutation] = useMutation(
        DELETE_CAMPAIGN,
        {
            variables: {
                campaignId,
                "selectedCampaignId": ""
            },
            onCompleted: async () => {
                history.push({
                    pathname: `/dashboard/campaigns`,
                    state: { deleteCampaign: true }
                });
            },
        }
    );

    const { data } = useQuery(GET_CAMPAIGN, {
        variables: {
            "organizationId":organization._id,
            campaignId,
            startDate,
            endDate,
            custom,
            dateRange:tabsState
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setIsSucessfullyUpdated(true);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            sessionStorage.setItem("tabsState", tabsState);
            sessionStorage.setItem("startDate", startDate);
            sessionStorage.setItem("endDate", endDate);
        },
    });

    const campaigns = data?.campaign.campaign;
    const totalDonationAmount = campaigns?.totalDonationAmount !== undefined ? campaigns?.totalDonationAmount : 0;
    const averageDonation = campaigns?.averageDonation !== undefined ? campaigns?.averageDonation : 0;
    const totalMatched = campaigns?.totalMatched !== undefined ? campaigns?.totalMatched : 0;
    const totalMatchers = campaigns?.totalMatchers !== undefined ? campaigns?.totalMatchers : 0;
    const matchImpact = campaigns?.matchImpact !== undefined ? campaigns?.matchImpact : 0;


    const campaignGraph = data?.campaign.data.map((modal: any) => {
        let amount = Price.output(modal?.amount);
        return {
             Time: moment(modal?.time as any).format("MMM DD, YYYY"),

            // Time: moment(parseInt(modal?.time as any)).format("MMM DD, YYYY"),
            Amount: amount
        }
    });

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `${DONATION_URL}/embed.js`;
        script.async = true;
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        }
    }, []);

    const HTML = getEmbedHTML(organization?._id, campaigns?.active ? campaignId :"", "Offline Donation", Colors.Pink, Colors.White);

    return (
        <>
            <TabsLayout>
                <TabsList
                    onClick={onAllTimeButton}
                    active={tabsState === TabListItemEnum.AllTime}>
                    <TabListItem>All Time</TabListItem>
                </TabsList>
                <TabsList
                    onClick={onTodayButton}
                    active={tabsState === TabListItemEnum.Today}>
                    <TabListItem>Today</TabListItem>
                </TabsList>
                <TabsList
                    onClick={onOneWeekButton}
                    active={tabsState === TabListItemEnum.OneWeek}>
                    <TabListItem>1W</TabListItem>
                </TabsList>
                <TabsList
                    onClick={onOneMonthButton}
                    active={tabsState === TabListItemEnum.OneMonth}>
                    <TabListItem>1M</TabListItem>
                </TabsList>
                <TabsList
                    onClick={onMonthTillDateButton}
                    active={tabsState === TabListItemEnum.MonthTillDate}>
                    <TabListItem>MTD</TabListItem>
                </TabsList>
                <TabsList
                    onClick={onYearTillDateButton}
                    active={tabsState === TabListItemEnum.YearTillDate}>
                    <TabListItem>YTD</TabListItem>
                </TabsList>
                <TabsList
                    onClick={onOneYearButton}
                    active={tabsState === TabListItemEnum.OneYear}>
                    <TabListItem>1Y</TabListItem>
                </TabsList>
                <TabsList
                    onSubmit={(e) => onApplyCustomDate(e)}
                    active={applyCustomDate && tabsState === TabListItemEnum.Custom}
                >
                    <TabListItem className="space" onClick={onCustomDate}>Custom</TabListItem>
                    {tabsState === TabListItemEnum.Custom &&
                        <>
                            <DateTimePicker
                                className="showDate"
                                value={customStartDate}
                                format="MM-dd-y"
                                onChange={onChangeStartDate}
                                clearIcon={null}
                                disableClock
                                maxDate={customEndDate}
                                minDate={new Date(minDateValue)}
                            />
                            <DateSpace>-</DateSpace>
                            <DateTimePicker
                                className="showDate"
                                value={customEndDate}
                                format="MM-dd-y"
                                onChange={onChangeEndDate}
                                clearIcon={null}
                                disableClock
                                maxDate={new Date()}
                                minDate={customStartDate}
                            />
                            <Button
                                type="submit"
                                text={"Apply"}
                                border="10px"
                                width='80px'
                                height='34px'
                                margin='0 0 0 10px'
                                loading={loading}
                                style={isSucessfullyUpdated ? { pointerEvents: "none", opacity: "0.4" } : {}}
                            />
                        </>}
                </TabsList>
            </TabsLayout>
            <CampaignsLayout>
                <LeftLayout>
                    <CardLayout>
                        <CardHeader> <Icon icon={Icons.ChartLine} size={14} color={Colors.Grey1} margin='0px 7px 0px 0px' />Campaign stats - {tabsState}
                        </CardHeader>
                        <CardBody>
                            <DashboardGraph campaignGraph={campaignGraph}/>
                            <MiddleSection>
                                <Transactions>
                                    <CardTitle>Total Raised</CardTitle>
                                    <Amount>${Price.output(totalDonationAmount, true)}</Amount>
                                </Transactions>
                                <Donors>
                                    <CardTitle>Donors</CardTitle>
                                    <Amount>{campaigns?.donors}</Amount>
                                </Donors>
                                <AverageDonation>
                                    <CardTitle>Average Donation</CardTitle>
                                    <Amount>${Price.output(averageDonation, true)}</Amount>
                                </AverageDonation>
                            </MiddleSection>
                        </CardBody>
                    </CardLayout>
                </LeftLayout>
                <RightLayout>
                    <ButtonsLayout>
                        <ButtonsInner onClick={onYourPage}><Icon icon={Icons.ExternalLink} size={18} color={Colors.Grey1} margin='0px 22px 0px 0px' />Go To Your Page</ButtonsInner>
                        <ButtonsInner onClick={onEditCampaign}><Icon icon={Icons.Edit} size={18} color={Colors.Grey1} margin='0px 22px 0px 0px' />Edit Campaign</ButtonsInner>
                        <div style={{ position: 'relative' }}>
                            <div dangerouslySetInnerHTML={{ __html: HTML }} />
                            <IconContainer>
                                <Icon icon={Icons.HandHoldingUsd} color={Colors.Grey1} size={18} />
                            </IconContainer>
                        </div>
                    </ButtonsLayout>
                    <MatchLayout>
                        <MatchHeader><NavIcon><Logo src={logoSrc} /></NavIcon>Matches</MatchHeader>
                        <MatchListing>
                            <MatchListItem>
                                <CardTitle className="Grey3">Total Donations Matched</CardTitle>
                                <Amount>${Price.output(totalMatched, true)}</Amount>
                            </MatchListItem>
                            <MatchListItem>
                                <CardTitle className="Grey3">Total Number of Matches</CardTitle>
                                <Amount>{totalMatchers}</Amount>
                            </MatchListItem>
                            <MatchListItem>
                                <CardTitle className="Grey3">Total Match Impact</CardTitle>
                                <Amount>${Price.output(matchImpact, true)}</Amount>
                            </MatchListItem>
                            <MatchListItem>
                                <CardTitle className="Grey3">Current Multiplier</CardTitle>
                                <Amount>{campaigns?.activeMatchers && campaigns.activeMatchers + 1}</Amount>
                            </MatchListItem>
                        </MatchListing>
                    </MatchLayout>
                    {!campaigns?.isGeneralFundraiser && <ButtonsLayout>
                        <ButtonsInner onClick={onDeleteCampaign}><Icon icon={Icons.DeleteRegular} size={18} color={Colors.Grey1} margin='0px 22px 0px 0px' />Delete Campaign</ButtonsInner>
                    </ButtonsLayout>}
                </RightLayout>
            </CampaignsLayout>
        </>
    );
};

export default CampaignOverview;

