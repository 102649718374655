import gql from "graphql-tag";

const query = gql`
query getDonorAndUserById($slug: String){
  getDonorAndUserById(slug: $slug){
      _id
      slug
      statement
      visibility
      photoUrl
      totalFollower
      totalFollowing
      city
      state
      isFollowing
      raised
      supportingOrgs{
        organizationId
      }
      users {
          _id
          firstName
          lastName   
          email
          phoneNumber
          organizationType
      }
    }
}
`;

export default query;
