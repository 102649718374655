import gql from 'graphql-tag';

const mutation = gql`
mutation updateMatch($params: updateMatchParams){
    updateMatch(params:$params){
      _id
    }
  }
`;

export default mutation;
