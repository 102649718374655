import gql from "graphql-tag";

const query = gql`
query getOrganizationByStub($organizationStub: String){
  getOrganizationByStub(organizationStub: $organizationStub) {
      _id
      userId
      organizationStub
      active
      name
      description
      logoUrl
      posterUrl
      message
      recurringDonationMessage
      phoneNumber
      email
      address
      isFollowing
      city
      state
      zip
      websiteUrl
      buttonText
      buttonColor
      fontColor
      
}
}
`;
export default query;
