import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import * as AppActions from "../../redux/actions/app.actions";
import Icon, { Icons } from "../../elements/Icon";
import { Colors } from "../../styles/Colors";
import { ModalContainer } from "./Modal";
import ConnectStripe from "../ConnectStripe";

const Content = styled.div`
  display: flex;
  width: 400px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  flex-direction: column;
  @media (max-width:767px){
   width:calc(100% - 40px);
  }
`;

const Heading = styled.h2``;

const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
`;

const Link = styled.a`
  font-size: 18px;
  font-weight: 400;
  color: ${Colors.Pink};
  text-decoration-line: underline;
  margin: 20px;
  cursor: pointer;
`;

type ConnectStripeModalProps = {};

const ConnectStripeModal: React.FC<ConnectStripeModalProps> = () => {
  /* State */

  /* Hooks */
  const dispatch = useDispatch();

  /* Actions */

  const popModal = () => dispatch(AppActions.popModal());

  /** Render */
  return (
    <ModalContainer width="440px">
      <Content>
        <Icon icon={Icons.Settings} size="50" margin="20px 0 0 0" />
        <Heading>Now connect your bank account</Heading>
        <Text>
          Your billing information is updated. Now let's connect your bank account to Stripe so you can start collecting donations!
        </Text>
        <ConnectStripe/>
      </Content>
    </ModalContainer>
  );
};

export default ConnectStripeModal;
