import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Title } from "../components/DashboardLayout";
import Joi from "@hapi/joi";
import * as Schema from "../utils/Schema";
import { Colors } from "../styles/Colors";
import SettingsOrganizationProfile from "../components/SettingsOrganizationProfile";
import SettingsBank from "../components/SettingsBank";
import SettingsEmail from "../components/SettingsEmail";
import SettingsBilling from "../components/SettingsBilling";
import { useLocation } from "react-router";
import { GuestSettingsContext } from "./GuestSettings.page";


export enum PageStateEnum {
  OrganizationProfile = "Organization Profile",
  BankSettings = "Bank Settings",
  EmailSettings = "Email Settings",
  BillingSettings = "Billing Information",
}

const schema = Joi.object({
  name: Schema.organization.name().error(([error]) => {
    const message = "Organization name is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  email: Schema.organization.email().error(([error]) => {
    const message = "Organization email is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  phoneNumber: Schema.organization.phoneNumber().error(([error]) => {
    const message = "Organiztion phone number is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  description: Schema.organization.description().error(([error]) => {
    const message = "Organization description is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  message: Schema.organization.message().error(([error]) => {
    const message = "Organization message is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  address: Schema.organization.address().error(([error]) => {
    const message = "Organization address is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  logoUrl: Schema.organization.logoUrl().error(([error]) => {
    const message = "Organization logo is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  posterUrl: Schema.organization.posterUrl().error(([error]) => {
    const message = "Organization poster image is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  ein: Schema.organization.ein().error(([error]) => {
    const message = "Email EIN number is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  headerImageUrl: Schema.organization.headerImageUrl().error(([error]) => {
    const message = "Email header image is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  footerImageUrl: Schema.organization.footerImageUrl().error(([error]) => {
    const message = "Email footer image is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  signatureImageUrl: Schema.organization
    .signatureImageUrl()
    .error(([error]) => {
      const message = "Email signature image is invalid";
      return new Error(
        JSON.stringify({
          field: error.path[0],
          message,
        })
      );
    }),
  signatureText: Schema.organization.signatureText().error(([error]) => {
    const message = "Email signature text is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  signaturePosition: Schema.organization
    .signaturePosition()
    .error(([error]) => {
      const message = "Email signatory position is invalid";
      return new Error(
        JSON.stringify({
          field: error.path[0],
          message,
        })
      );
    }),
  presetDonations: Schema.organization.presetDonations().error(([error]) => {
    const message = "Preset donations are invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
});

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: fill-available;
  height: 100%;
  padding: 0 0 100px;
`;

const SubNav = styled.div`
  display: flex;
  margin: -10px 0px 30px;
  z-index: 1;
  @media (max-width:576px){
    display:block;
  }
`;

const SubNavItem = styled.div<{ active: boolean }>`
  font-weight: 700;
  size: 1.8rem;
  transition: all 0.2s;
  color: ${[(props) => (props.active ? Colors.Blue : Colors.Grey4)]};
  margin-right: 20px;
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue};
  }
  @media (max-width:576px){
    margin-right:0px;
    padding:10px 0;
  }
`;

type SettingsPageProps = {};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SettingsPage: React.FC<SettingsPageProps> = () => {
  /* State */
  let query = useQuery();
  const stripeConnectCode = query.get("code");
  const [pageState, setPageState] = React.useState(() => {
    if (stripeConnectCode) {
      return PageStateEnum.BankSettings;
    } else {
      return PageStateEnum.OrganizationProfile;
    }
  });
  const [hasBillingInfo, setHasBillingInfo] = useState(false);

  const contextValue = useMemo(
    () => ({ hasBillingInfo, setHasBillingInfo }),
    [hasBillingInfo, setHasBillingInfo]
  );

  /* Render */
  return (
    <Container>
      <Title>Settings</Title>
      <SubNav>
        <SubNavItem
          onClick={() => setPageState(PageStateEnum.OrganizationProfile)}
          active={pageState === PageStateEnum.OrganizationProfile}
        >
          {PageStateEnum.OrganizationProfile}
        </SubNavItem>
        <SubNavItem
          onClick={() => setPageState(PageStateEnum.BankSettings)}
          active={pageState === PageStateEnum.BankSettings}
        >
          {PageStateEnum.BankSettings}
        </SubNavItem>
        <SubNavItem
          onClick={() => setPageState(PageStateEnum.EmailSettings)}
          active={pageState === PageStateEnum.EmailSettings}
        >
          {PageStateEnum.EmailSettings}
        </SubNavItem>
        <SubNavItem
          onClick={() => setPageState(PageStateEnum.BillingSettings)}
          active={pageState === PageStateEnum.BillingSettings}
        >
          {PageStateEnum.BillingSettings}
        </SubNavItem>
      </SubNav>
      <GuestSettingsContext.Provider value={contextValue as any}>
        {(() => {
          switch (pageState) {
            case PageStateEnum.OrganizationProfile:
              return <SettingsOrganizationProfile />;
            case PageStateEnum.BankSettings:
              return <SettingsBank />;
            case PageStateEnum.EmailSettings:
              return <SettingsEmail />;
            case PageStateEnum.BillingSettings:
              return <SettingsBilling />;
            default:
              return <SettingsOrganizationProfile />;
          }
        })()}
      </GuestSettingsContext.Provider>
    </Container>
  );
};

export default SettingsPage;
