import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../styles/Colors";
import Icon, { Icons } from "../elements/Icon";
import { useHistory } from "react-router-dom";
import bannerSrc from "./../assets/images/banner.jpg";
import logoSrc from "./../assets/images/logo-icon.png";
import GET_ORGANIZATION_BY_STUB from "../graphql/queries/getOrganizationByStub.query";
import GET_CAMPAIGN_BY_STUB from "../graphql/queries/getCampaignBySlug.query";
import { useMutation, useQuery } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import { DONATION_URL, WEB_UI_URL } from "../env";
import OrganizationProfileCampaignPage from "./OrganizationProfile-CampaignPage";
import { useDispatch } from "react-redux";
import * as AppActions from "../redux/actions/app.actions";
import { ModalTypes } from "../components/modal/Modal";
import GET_LIST_MATCH from "../graphql/queries/listMatches.query";
import OrganizationCampaignOverview from "./OrganizationProfile-CampaignOverview";
import DonorProfileLayout from "../components/modal/DonorProfileLayout";
import AuthNavigation from "../components/AuthNavigation";
import ORG_LIST_FOLLOW_UNFOLLOW from "../graphql/mutations/nonProfitOrgsFollow.mutation";

type campaign = {
  active?: any;
};
const CommonWrapper = styled.div`
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
`;
const Organization = styled.div`
  width: 100%;
  display: block;
`;

type BannerProps = {
  posterUrl?: string;
};

const Banner = styled.div<BannerProps>`
  background-image: url(${(props) => props.posterUrl});
  height: 492px;
  background-size: cover;
  background-position: center center;
  @media (max-width: 991px) {
    height: 300px;
  }
`;

const AboutProfile = styled.div`
  padding: 0;
  margin: -125px 0 30px 0;
`;
const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 991px) {
    display: block;
  }
`;
export const LeftSection = styled.div`
  width: 67%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const ProfileThumb = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 80px;
  height: 80px;
  border: 1px solid #e0e0e0;
  background: ${Colors.White};
  border-radius: 100px;
  justify-content: center;
  margin-right: 40px;
  @media (max-width: 479px) {
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }
`;
const Logo = styled.img`
  display: flex;
  width: 80px;
  @media (max-width: 479px) {
    width: 60px;
  }
`;
const ProfileDesc = styled.div`
  width: calc(100% - 140px);
`;

const Tabsfollow = styled.div`
  display: flex;
  align-items: center;
`;
const ProfileTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 42px;
  margin: 0 0 10px 0;
  color: ${Colors.White};
  @media (max-width: 1199px) {
    font-size: 30px;
    line-height: 20px;
  }
  @media (max-width: 991px) {
    font-size: 30px;
    line-height: 20px;
  }
  @media (max-width: 640px) {
    font-size: 20px;
    line-height: normal;
    margin: 0 0 5px 0;
  }
  @media (max-width: 479px) {
    font-size: 17px;
  }
`;
const ProfileInfo = styled.div`
  color: ${Colors.White};
  opacity: 0.8;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  display: inline-block;
  margin: 0 25px 0 0;
  @media (max-width: 640px) {
    font-size: 15px;
    line-height: 24px;
  }
`;
const ProFollow = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  display: inline-block;
  padding: 9px 24px;
  font-size: 14px;
  line-height: 17px;
  color: ${Colors.White};
  font-weight: bold;
  @media (max-width: 479px) {
    padding: 9px 15px;
  }
`;
const ProfileList = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  padding: 9px;
  display: inline-block;
  margin-bottom: 50px;
  @media (max-width: 640px) {
    margin-bottom: 20px;
  }
`;
const ProfileListInner = styled.div`
  display: flex;
`;

const ProListItem = styled.div<campaign>`
  padding: 6px 30px;
  color: ${Colors.Grey2};
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  border-right: 1px solid #f2f2f2;
  cursor: pointer;

  &:last-child {
    border-right: 0px;
  }
  ${({ active }) =>
    active &&
    `
  color: #ff0080;
`}
  @media (max-width:640px) {
    padding: 6px 20px;
    font-size: 12px;
  }
  @media (max-width: 479px) {
    padding: 6px 10px;
    font-size: 10px;
  }
`;
export const AboutSection = styled.div`
  margin-bottom: 30px;
`;
export const CommonTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: ${Colors.Black};
  margin-bottom: 15px;
  line-height: 38px;
  display: inline-block;
  @media (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
export const SeeAll = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.Pink};
  display: inline-block;
  margin: 0 0 0 10px;
  cursor: pointer;
`;

export const AboutDesc = styled.div`
  font-weight: normal;
  font-size: 16px;
  color: ${Colors.Grey1};
  margin-bottom: 20px;
  line-height: 160%;
  white-space: pre-wrap;
  &:last-child {
    margin-bottom: 0px;
  }
  @media (max-width: 991px) {
    font-size: 14px;
  }
`;
export const ContactSection = styled.div`
  margin-bottom: 30px;
`;
export const OrderedList = styled.div``;
export const Ellipse = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const ConListItem = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  margin: 0 0 8px 0;
  color: ${Colors.Grey2};
  display: flex;
  &:last-child {
    margin: 0px;
  }
`;
export const BiggestSupporters = styled.div`
  margin-bottom: 30px;
`;
export const CardBody = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const CardList = styled.div<any>`
  opacity: ${(props) => props.active && "50%"};
  display: flex;
  align-items: center;
  width: 50%;
  flex: 1 0 50%;
  margin: 0 0 40px 0;
  @media (max-width: 640px) {
    width: 100%;
    flex: 1 0 100%;
  }
`;
export const CardImage = styled.div`
  background: #ffffff;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #e0e0e0;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const ThumbImage = styled.img`
  width: 100px;
  height: 100px;
`;
export const CardDesc = styled.div`
  width: calc(100% - 118px);
`;
export const CardHeading = styled.div`
  font-weight: 600;
  font-size: 24px;
  color: ${Colors.Grey1};
  line-height: 38px;
  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 24px;
  }
`;
export const CardPrice = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${Colors.Grey3};
  @media (max-width: 991px) {
    font-size: 14px;
  }
`;

export const CardProgressValue = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  color: ${Colors.Pink};
  @media (max-width: 991px) {
    line-height: 30px;
  }
`;
const AdditionalInformation = styled.div`
  margin-bottom: 30px;
`;
const InfoList = styled.div`
  display: flex;
  margin-bottom: 15px;
`;
const InfoText = styled.div`
  margin-right: 15px;
  font-size: 16px;
  line-height: 160%;
  color: ${Colors.Black};
  min-width: 100px;
  @media (max-width: 640px) {
    font-size: 14px;
  }
`;
const InfoValue = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 160%;
  color: ${Colors.Black};
  @media (max-width: 640px) {
    font-size: 14px;
  }
`;
export const RightSection = styled.div`
  width: 30%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const InnerSection = styled.div`
  width: calc(100% - 50px);
  background: ${Colors.White};
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 25px;
  padding: 15px 25px;
  margin: 0 0 25px 0;
  margin-top: 80px;
`;

export const DonateButton = styled.div`
  margin: 20px 0 20px;
  justify-content: center;
`;

export const DonateInfo = styled.div`
  margin-bottom: 30px;
`;

export const DonateTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.Grey1};
`;
export const DonateValue = styled.div`
  font-weight: bold;
  font-size: 50px;
  line-height: normal;
  color: ${Colors.Grey1};
  @media (max-width: 640px) {
    font-size: 35px;
  }
`;
export const DonateDesc = styled.div`
  line-height: 169%;
  font-weight: normal;
  font-size: 18px;
  @media (max-width: 640px) {
    font-size: 16px;
    line-height: 140%;
  }
`;
const RecentDonations = styled.div``;
const RecentHeading = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px
  color:${Colors.Grey1};
  margin-bottom:15px;
  display:inline-block;
  `;
const RecentList = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 15px 0;
`;
const RecentThumb = styled.div`
  margin-right: 15px;
`;
const ThumbProfile = styled.img`
  width: 45px;
  height: 45px;
  background: #eee;
  border-radius: 50%;
`;
const RecentDesc = styled.div``;
const RecentTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin: 0 0 4px 0;
  color: ${Colors.Grey1};
`;
const SubTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${Colors.Grey1};
`;
const Bold = styled.div`
  font-weight: bold;
  display: inline-block;
`;
const SocialMedia = styled.div``;
const SocialTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: ${Colors.Grey3};
  text-align: center;
  margin: 0 0 10px 0;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;
const SocialListing = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 200px;
  margin: 0 auto;
`;
const SocialList = styled.div`
margin: 0 45px 0 0;
  &:last-child{
  margin::0;
  }
`;

export const FollowButton = styled.button`
  border-radius: 25px;
  background: ${Colors.Pink};
  color: ${Colors.White};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  width: 100px;
  padding: 8px 23px;
  transition: all 0.4s;
  cursor: pointer;
  &:hover {
    // background: ${Colors.Pink};
    // color: ${Colors.White};
  }
`;

function getEmbedHTML(
  organizationId: string,
  activeCampaign: string,
  text: string,
  buttonColor: string,
  color: string,
  donorId?: any
) {
  let url = `${organizationId}`;
  if (activeCampaign) {
    url = `${url}&campaignId=${activeCampaign}`;
  }
  if (donorId) {
    url = `${url}&donorId=${donorId}`;
  }
  return `
    <a href="javascript:void(0)" style="text-decoration:none;">
      <img src="/favicon.ico" onerror="window.Matchstik.preload('${url}')" style="display:none;"> 
      <div style="
        background-color:${buttonColor}; 
        color:${color}; 
        font-family: sans-serif;
        border-radius: 100px; 
        height:50px; 
        width:100%; 
        display:inline-flex; 
        align-items:center; 
        justify-content:center; 
        font-weight: 600;
        font-size: 14px;
      "
      onClick="window.parent.Matchstik.open('${url}')">${text}</div>
    </a>
  `;
}

type OrganizationProfilePageProps = {};

const OrganizationProfilePage: React.FC<OrganizationProfilePageProps> = () => {
  /* State */
  const history = useHistory();

  /*Action*/
  const dispatch = useDispatch();
  const onMatchName = () => {
    sessionStorage.setItem("activeCampaign", activeCampaign);
    dispatch(AppActions.pushModal(ModalTypes.ActiveMatchesModal));
  };

  /* Hooks */
  let cleanUrl = window.location.href.split("?")[0];
  let organizationStub = cleanUrl.split("/")[4];
  let campaignSlug = cleanUrl.split("/")[5];
  const loginDonorId = localStorage.getItem("donorData");
  const tabs = ["Overview", "Campaigns"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [listMatches, setListMatches] = useState([]);
  const [activeCampaign, setActiveCampaign] = useState("");
  const [showCampaignSlug, setShowCampaignSlug] = useState(true);
  const [campaignSlugDetail, setCampaignSlugDetail] = useState([]);
  const [activeCampaignSlug, setActiveCampaignSlug] = useState("");
  const [organizationStubDetail, setOrganizationStubDetail] = useState<any>([]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${DONATION_URL}/embed.js`;
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  /* GraphQL */

  /* Get Organization By Stub */
  const [organizationStubs] = useLazyQuery(GET_ORGANIZATION_BY_STUB, {
    variables: {
      organizationStub: organizationStub,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setOrganizationStubDetail(data?.getOrganizationByStub || null);
      if (data.getOrganizationByStub === null) {
        history.push("/landing");
      } else if (campaignSlug) {
        getCampaignSlugQuery();
      }
    },
  });
  const address =
    organizationStubDetail?.address && organizationStubDetail?.address;
  const city = organizationStubDetail?.city && organizationStubDetail?.city;
  const state = organizationStubDetail?.state && organizationStubDetail?.state;
  const zip = organizationStubDetail?.zip && organizationStubDetail?.zip;
  const HTML = getEmbedHTML(
    organizationStubDetail?._id,
    activeCampaign,
    organizationStubDetail?.buttonText || "Donate Now",
    Colors.Pink,
    Colors.White,
    loginDonorId
  );
  const organizationId = organizationStubDetail?._id;

  /* Get Campaign By Stub */
  const [getCampaignSlugQuery] = useLazyQuery(GET_CAMPAIGN_BY_STUB, {
    variables: {
      organizationId,
      campaignSlug,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.getCampaignBySlug.campaign === null) {
        history.push("/landing");
      } else if (showCampaignSlug) {
        setActiveTab("Campaigns");
        setCampaignSlugDetail(data?.getCampaignBySlug?.campaign);
        setActiveCampaign(data?.getCampaignBySlug?.campaign._id);
      }
    },
  });

  const [followUnfollowOrg, { loading: updateLoading }] = useMutation(
    ORG_LIST_FOLLOW_UNFOLLOW,
    {
      onCompleted: async () => {
        organizationStubs();
      },
      onError: (error) => {},
    }
  );

  useEffect(() => {
    organizationStubs();
  }, []);

  const followOrg = (orgId: any) => {
    followUnfollowOrg({
      variables: {
        organizationId: orgId,
      },
    });
  };

  /* Get List Match */
  const { data: matchDetails } = useQuery(GET_LIST_MATCH, {
    variables: {
      campaignId: activeCampaign,
      onlyActive: true,
      sortByAmount: true,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (activeCampaign) {
        setListMatches(matchDetails?.listMatch?.slice(0, 3));
      }
    },
  });
  const moreListMatches =
    matchDetails?.listMatch?.length > 3 && matchDetails?.listMatch?.length - 3;

  // Function calls

  const setActiveTabValues = (type: any) => {
    setActiveCampaignSlug("");
    setShowCampaignSlug(false);
    setListMatches([]);
    setCampaignSlugDetail([]);
    setActiveCampaign("");
    getSelectedTabDate();
    setActiveTab(type);
    if (type !== "Campaigns")
      window.history.replaceState(
        null,
        "Matchstik",
        "/org/" + organizationStub
      );
  };

  const getSelectedTabDate = () => {
    if (activeTab === "Overview") {
      return (
        <>
          <AboutSection>
            <CommonTitle>About {organizationStubDetail?.name} </CommonTitle>
            <AboutDesc>{organizationStubDetail?.description}</AboutDesc>
          </AboutSection>
          <ContactSection>
            <CommonTitle>Contact Info</CommonTitle>
            <OrderedList>
              {address || city || state || zip ? (
                <ConListItem>
                  <Icon
                    icon={Icons.mapPinLight}
                    color={Colors.Grey2}
                    size={16}
                    margin="0px 10px 0px 0"
                  />
                  {address && address + ", "}
                  {city && city + ", "}
                  {state && state + ", "}
                  {zip}
                </ConListItem>
              ) : (
                ""
              )}

              {organizationStubDetail?.phoneNumber && (
                <ConListItem>
                  <Icon
                    icon={Icons.Phone}
                    color={Colors.Grey2}
                    size={16}
                    margin="0px 10px 0px 0"
                  />
                  {organizationStubDetail?.phoneNumber.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    "($1) $2-$3"
                  )}
                </ConListItem>
              )}
              {organizationStubDetail?.email && (
                <ConListItem>
                  <Icon
                    icon={Icons.At}
                    color={Colors.Grey2}
                    size={16}
                    margin="0px 10px 0px 0"
                  />
                  {organizationStubDetail?.email}
                </ConListItem>
              )}
              {organizationStubDetail?.websiteUrl && (
                <ConListItem>
                  <Icon
                    icon={Icons.Globe}
                    color={Colors.Grey2}
                    size={16}
                    margin="0px 10px 0px 0"
                  />
                  <Ellipse><a href={organizationStubDetail?.websiteUrl} target="_blank">{organizationStubDetail?.websiteUrl}</a></Ellipse>
                </ConListItem>
              )}
            </OrderedList>
          </ContactSection>
        </>
      );
    } else if (activeTab === "Campaigns") {
      if (activeCampaign && campaignSlugDetail) {
        window.history.replaceState(
          null,
          "Matchstik",
          "/org/" +
            organizationStub +
            "/" +
            (activeCampaignSlug || activeCampaign)
        );
        return (
          <OrganizationCampaignOverview
            organizationId={organizationId}
            activeCampaign={activeCampaign}
            setActiveCampaign={setActiveCampaign}
            setActiveTab={setActiveTab}
            setListMatches={setListMatches}
            setShowCampaignSlug={setShowCampaignSlug}
            organizationStubDetail={organizationStubDetail}
            setActiveCampaignSlug={setActiveCampaignSlug}
          />
        );
      } else {
        window.history.replaceState(
          null,
          "Matchstik",
          "/org/" + organizationStub
        );
        return (
          <OrganizationProfileCampaignPage
            organizationId={organizationId}
            setActiveCampaign={setActiveCampaign}
          ></OrganizationProfileCampaignPage>
        );
      }
    }
  };

  const redirectUrl = (navigationUrl: any) => {
    let socialUrl;
    if (activeCampaignSlug || activeCampaign) {
      socialUrl = encodeURIComponent(
        `${WEB_UI_URL}/org/${
          organizationStubDetail.organizationStub || organizationStubDetail?._id
        }/${activeCampaignSlug || activeCampaign}`
      );
      window.open(`${navigationUrl}${socialUrl}`, "_blank");
    } else {
      socialUrl = encodeURIComponent(
        `${WEB_UI_URL}/org/${
          organizationStubDetail.organizationStub || organizationStubDetail?._id
        }`
      );
      window.open(`${navigationUrl}${socialUrl}`, "_blank");
    }
  };

  return (
    <Organization>
      {localStorage.length > 0 ? <DonorProfileLayout /> : <AuthNavigation />}
      <Banner
        posterUrl={
          organizationStubDetail?.posterUrl
            ? organizationStubDetail?.posterUrl
            : bannerSrc
        }
      ></Banner>
      <AboutProfile>
        <CommonWrapper>
          <MainWrapper>
            <LeftSection>
              <ProfileSection>
                <ProfileThumb>
                  <Logo
                    src={
                      organizationStubDetail?.logoUrl
                        ? organizationStubDetail?.logoUrl
                        : logoSrc
                    }
                  />
                </ProfileThumb>
                <ProfileDesc>
                  <ProfileTitle>{organizationStubDetail?.name}</ProfileTitle>
                  <Tabsfollow>
                    {city || state ? (
                      <ProfileInfo>
                        {city && city + ","} {state}
                      </ProfileInfo>
                    ) : (
                      ""
                    )}
                    <FollowButton onClick={() => followOrg(organizationId)}>
                      {organizationStubDetail?.isFollowing
                        ? "Unfollow"
                        : "Follow"}
                    </FollowButton>

                    {/* ) : ( */}
                    {/* "" */}
                    {/* )} */}
                  </Tabsfollow>
                </ProfileDesc>
              </ProfileSection>
              <ProfileList>
                <ProfileListInner>
                  {tabs?.map((tab, i) => {
                    return (
                      <ProListItem
                        key={i}
                        active={activeTab === tab}
                        onClick={() => setActiveTabValues(tab)}
                      >
                        {tab}
                      </ProListItem>
                    );
                  })}
                </ProfileListInner>
              </ProfileList>

              {getSelectedTabDate()}

              {/* <BiggestSupporters>
                <CommonTitle>Our Biggest Supporters <SeeAll>See All</SeeAll></CommonTitle>
                <CardBody>
                  <CardList>
                    <CardImage>
                      <ThumbImage src={mysterySrc} />
                    </CardImage>
                    <CardDesc>
                      <CardHeading>Mystery Ranch</CardHeading>
                      <CardPrice>$5,000 Matched </CardPrice>
                      <CardProgressValue>85% Complete</CardProgressValue>
                    </CardDesc>
                  </CardList>
                  <CardList>
                    <CardImage>
                      <ThumbImage src={KenyonSrc} />
                    </CardImage>
                    <CardDesc>
                      <CardHeading>Kenyon Noble</CardHeading>
                      <CardPrice>$5,000 Matched </CardPrice>
                      <CardProgressValue>85% Complete</CardProgressValue>
                    </CardDesc>
                  </CardList>
                  <CardList>
                    <CardImage>
                      <ThumbImage src={mysterySrc} />
                    </CardImage>
                    <CardDesc>
                      <CardHeading>Mystery Ranch</CardHeading>
                      <CardPrice>$5,000 Matched </CardPrice>
                      <CardProgressValue>85% Complete</CardProgressValue>
                    </CardDesc>
                  </CardList>
                  <CardList>
                    <CardImage>
                      <ThumbImage src={mysterySrc} />
                    </CardImage>
                    <CardDesc>
                      <CardHeading>Mystery Ranch</CardHeading>
                      <CardPrice>$5,000 Matched </CardPrice>
                      <CardProgressValue>85% Complete</CardProgressValue>
                    </CardDesc>
                  </CardList>
                </CardBody>
              </BiggestSupporters> */}
              {/* <AdditionalInformation>
                <CommonTitle>Additional Information</CommonTitle>
                <InfoList>
                  <InfoText>Ruling Year</InfoText>
                  <InfoValue>2002</InfoValue>
                </InfoList>
                <InfoList>
                  <InfoText>Executive Director</InfoText>
                  <InfoValue>Mr. Robin Hill</InfoValue>
                </InfoList>
                <InfoList>
                  <InfoText>Associate Director</InfoText>
                  <InfoValue>Katie DiBerardinis</InfoValue>
                </InfoList>
                <InfoList>
                  <InfoText>EIN</InfoText>
                  <InfoValue>81-0543203</InfoValue>
                </InfoList>
                <InfoList>
                  <InfoText>Cause Area</InfoText>
                  <InfoValue>Adult, Child Matching Programs (O30), Other Youth Development N.E.C. (O99)</InfoValue>
                </InfoList>
                <InfoList>
                  <InfoText>2019 Revenue</InfoText>
                  <InfoValue>$1,744,936</InfoValue>
                </InfoList>
                <InfoList>
                  <InfoText>2019 Expenses</InfoText>
                  <InfoValue>$997,115</InfoValue>
                </InfoList>
              </AdditionalInformation> */}
            </LeftSection>
            <RightSection>
              <InnerSection>
                <DonateButton>
                  <div dangerouslySetInnerHTML={{ __html: HTML }} />
                </DonateButton>
                {listMatches.length > 0 && (
                  <DonateInfo>
                    <DonateTitle>Your donation will be multiplied</DonateTitle>
                    <DonateValue>
                      {matchDetails?.listMatch.length + 1}x{" "}
                    </DonateValue>
                    <DonateDesc>
                      Thanks to generous matchers
                      <br></br>
                      {listMatches?.map((match: any, index: number) => {
                        return (
                          <>
                            <span
                              key={index}
                              style={{ color: "navy", cursor: "pointer" }}
                              onClick={onMatchName}
                            >
                              {match.name}
                            </span>
                            {listMatches.length - 1 !== index ? "," : ""}&nbsp;
                          </>
                        );
                      })}
                      {moreListMatches !== false && (
                        <>
                          and{" "}
                          <span
                            style={{ color: "navy", cursor: "pointer" }}
                            onClick={onMatchName}
                          >
                            {moreListMatches} others.
                          </span>
                        </>
                      )}
                    </DonateDesc>
                  </DonateInfo>
                )}
                {/* <RecentDonations>
                  <RecentHeading>Recent donations<SeeAll>See All</SeeAll></RecentHeading>
                  <RecentList>
                    <RecentThumb>
                      <ThumbProfile src={DonateSrc} />
                    </RecentThumb>
                    <RecentDesc>
                      <RecentTitle>Gloria Webb</RecentTitle>
                      <SubTitle><Bold>$24</Bold> with an impact of <Bold>$528</Bold></SubTitle>
                    </RecentDesc>
                  </RecentList>
                  <RecentList>
                    <RecentThumb>
                      <ThumbProfile src={DonateSrc} />
                    </RecentThumb>
                    <RecentDesc>
                      <RecentTitle>Irma Robertson</RecentTitle>
                      <SubTitle><Bold>$105</Bold> with an impact of <Bold>$2,310</Bold></SubTitle>
                    </RecentDesc>
                  </RecentList>
                  <RecentList>
                    <RecentThumb>
                      <ThumbProfile src={DonateSrc} />
                    </RecentThumb>
                    <RecentDesc>
                      <RecentTitle>Regina Cooper</RecentTitle>
                      <SubTitle><Bold>$49</Bold> with an impact of <Bold>$1,078</Bold></SubTitle>
                    </RecentDesc>
                  </RecentList>
                  <RecentList>
                    <RecentThumb>
                      <ThumbProfile src={DonateSrc} />
                    </RecentThumb>
                    <RecentDesc>
                      <RecentTitle>Floyd Black</RecentTitle>
                      <SubTitle><Bold>$15</Bold> with an impact of <Bold>$330</Bold></SubTitle>
                    </RecentDesc>
                  </RecentList>
                  <RecentList>
                    <RecentThumb>
                      <ThumbProfile src={DonateSrc} />
                    </RecentThumb>
                    <RecentDesc>
                      <RecentTitle>Pat Fisher</RecentTitle>
                      <SubTitle><Bold>$75</Bold> with an impact of <Bold>$1,650</Bold></SubTitle>
                    </RecentDesc>
                  </RecentList>

                </RecentDonations> */}
              </InnerSection>
              {organizationStubDetail || activeCampaignSlug ? (
                <SocialMedia>
                  <SocialTitle>Share on social media</SocialTitle>
                  <SocialListing>
                    <SocialList>
                      <Icon
                        icon={Icons.FacebookSquare}
                        color={Colors.Grey4}
                        size={40}
                        hoverColor={Colors.Pink}
                        onClick={() =>
                          redirectUrl(
                            `https://www.facebook.com/sharer/sharer.php?u=`
                          )
                        }
                      />
                    </SocialList>
                    <SocialList>
                      <Icon
                        icon={Icons.TwitterSquare}
                        color={Colors.Grey4}
                        size={40}
                        hoverColor={Colors.Pink}
                        onClick={() =>
                          redirectUrl(`https://twitter.com/intent/tweet?url=`)
                        }
                      />
                    </SocialList>
                    <SocialList>
                      <Icon
                        icon={Icons.Linkedin}
                        color={Colors.Grey4}
                        size={40}
                        hoverColor={Colors.Pink}
                        onClick={() =>
                          redirectUrl(
                            `https://linkedin.com/sharing/share-offsite?url=`
                          )
                        }
                      />
                    </SocialList>
                  </SocialListing>
                </SocialMedia>
              ) : (
                ""
              )}
            </RightSection>
          </MainWrapper>
        </CommonWrapper>
      </AboutProfile>
    </Organization>
  );
};

export default OrganizationProfilePage;
