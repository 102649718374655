import React, { useEffect, useState } from "react";
import styled from "styled-components";
import avatarImg from "../assets/images/logo-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as UserActions from "../redux/actions/user.actions";
import { MatchstikState } from "../redux/store";
import SUPPORTING_CAUSES from "../graphql/queries/SupportedCauses.query";
import * as Price from "@matchstik/utils/.dist/price";
import NoData from "../components/NoData";
import { useLazyQuery } from "@apollo/client";

const TabContainer = styled.div`
margin:30px 0;
`;
const Content = styled.div`
  margin-bottom: 20px;
`;

const Logo = styled.div<any>`
width: 80px;
height: 80px;
// min-width: 80px;
// min-height: 80px;
// max-width: 80px;
// max-height: 80px;
border-radius: 50%;
display: flex;
align-items: center;
z-index: 0;
justify-content: center;
border: 1px solid #e0e0e0;
position: relative;
box-sizing: border-box;
flex-shrink: 0;
-webkit-user-select: none;
overflow: hidden;
@media screen and (max-width: 767px) {
  width: 50px;
  height: 50px;
}

`;

const SupportedCausesDetails = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    column-gap: 10px;
    align-items: flex-start;
  }
`;

const Details = styled.div`
  // display: flex;
  // justify-content: space-between;
`;
const OrgName = styled.div``;
const Name = styled.div`
cursor: pointer;
  font-size: 18px;
  font-weight: bold;
`;
const NameDetails = styled.div`
  margin-top: 10px;
  width: 100%;
  @media screen and (max-width: 767px) {
    margin-top: 0;
    }
`;

const Amount = styled.div`
  color: #333333;
`;

const Text = styled.div`
  color: #828282;
`;

export const Image = styled.img`
position: absolute;
min-height: 100%;
min-width: 100%;
height: auto;
width: auto;
`;

const Description = styled.div`
  margin-top: 10px;
  color: #333333;
  // overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  // -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
`;
const DonationStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

type SupportedCausesProps = {
  donorId: String;
};

const SupportedCauses: React.FC<SupportedCausesProps> = ({ donorId }) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const getUser = () => dispatch(UserActions.getUser());
  const userState: any = useSelector((state: MatchstikState) => state.user);
  const { userData: user } = userState;
  const [supportingOrgData, setSupportingOrgData] = useState([]);
  const [readMoreIndex, setReadMoreIndex] = useState([] as any);
  const [supportingOrgs] = useLazyQuery(SUPPORTING_CAUSES, {
    variables: {
      userId: user?.userId,
      slug: donorId,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setSupportingOrgData(data?.getSupportingOrgList);
      setReadMoreIndex(new Array(data?.getSupportingOrgList.length).fill(true))
    },
  });

  const onOrgClick = (item: any) => {
    let slugUpdate = item?.organization?.organizationStub
      ? item?.organization?.organizationStub
      : item?.organizationId;
    history.push("/org/" + slugUpdate);
  };

  const handleReadMore = (item: any, index: any) => {
    setSupportingOrgData((prevOrgs: any) => {
      return prevOrgs.map((org: any) => {
        if (org.organizationId === item.organizationId) {
          org.readMoreEnabled = !org.readMoreEnabled
        }
        return org
      })
    })
  }

  useEffect(() => {
    supportingOrgs();
    getUser();
  }, []);

  return (
    <>
      {supportingOrgData.length > 0 ? (
        <TabContainer>
          {supportingOrgData.map((item: any, index: any) => {
            // let descriptionLength = item?.organization?.description.slice(0, 300);
            const maxLength = 300
            const isReadMoreEnabled = item?.readMoreEnabled || false
            return (
              <Content key={index}>
                <SupportedCausesDetails>
                  <Logo>
                    <Image src={item?.organization?.logoUrl || avatarImg} />
                  </Logo>
                  <NameDetails>
                    <Details>
                      <OrgName>
                        <DonationStatus>
                          <div>
                            <Name onClick={(e) => onOrgClick(item)} >{item?.organization?.name}</Name>
                            <Text>
                              {item?.organization?.city &&
                                item?.organization?.state
                                ? item?.organization?.city +
                                ", " +
                                item?.organization?.state
                                : "----"}
                            </Text>
                          </div>

                          <Amount>
                            Donated
                            <strong>
                              {" "}
                              &nbsp; $
                              {Price.output(item?.donatedAmount || 0, true)}
                            </strong>
                            &nbsp; with an impact of &nbsp;
                            <strong>
                              ${Price.output(item?.totalImpact || 0, true)}
                            </strong>
                          </Amount>
                        </DonationStatus>
                      </OrgName>
                    </Details>
                    <Description >
                      {!isReadMoreEnabled ? item?.organization?.description.slice(0, maxLength) : item?.organization?.description}
                      <span key={index} onClick={(e) => handleReadMore(item, index)} style={{ color: "#E6167B", cursor: "pointer" }} >
                        {(item?.organization?.description.length > maxLength && !isReadMoreEnabled) && " ...read more"}
                        {(item?.organization?.description.length > maxLength && isReadMoreEnabled) && " ...read less"}
                      </span>
                      {/* {readMoreIndex[index] === true ? descriptionLength : item?.organization?.description}
                      <span key={index} onClick={(e) => handleReadMore(item, index)} style={{ color: "#E6167B", cursor: "pointer" }} >
                        {descriptionLength.length > 30 && readMoreIndex[index] === true && "...read more"}
                        {readMoreIndex[index] === false && " read less"}
                      </span> */}
                    </Description>
                  </NameDetails>
                </SupportedCausesDetails>
              </Content>
            );
          })}
        </TabContainer>
      ) : (
        <TabContainer>
          <NoData msg="You are not supporting any causes yet. Get started on the Browse Non-Profits screen!" />
        </TabContainer>
      )}
    </>
  );
};

export default SupportedCauses;
