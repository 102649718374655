import React, { useState } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import Button, { ButtonTypes } from "../elements/Button";
import { Colors } from "../styles/Colors";
import VERIFY_EMAIL from "../graphql/mutations/verifyEmail.mutation";
import SEND_USER_VERIFICATION_EMAIL from "../graphql/mutations/sendUserEmailVerification.mutation";
import * as ErrorUtil from "../utils/ErrorUtil";
import makeEventHandler from "../utils/makeEventHandler";
import Joi from "@hapi/joi";
import * as Polished from "polished";
import AuthLayout, {
  Content,
  Row,
  Footer,
  Text,
} from "../components/AuthLayout";
import LabeledInput from "../elements/LabeledInput";
import { useDispatch, useSelector } from "react-redux";
import * as UserActions from "../redux/actions/user.actions";
import { MatchstikState } from "../redux/store";
type ResendEmailTextProps = {
  cursor: string;
};

const ResendEmailText = styled.div<ResendEmailTextProps>`
  color: ${Colors.Pink};
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  cursor: ${(props) => props.cursor};

  &:hover {
    color: ${Polished.lighten(0.025, Colors.Pink)};
  }
`;

type VerifyEmailPageProps = {
  history: any;
};

const schema = Joi.object({
  verifyEmailCode: Joi.string()
    .required()
    .error(([error]) => {
      const message = "Verification code is required";
      return new Error(
        JSON.stringify({
          field: error.path[0],
          message,
        })
      );
    }),
});

enum ErrorKeyEnum {
  VerifyEmailCode = "verifyEmailCode",
}

enum ResendTextEnum {
  Resend = "Resend",
  Sending = "Sending Email...",
  Sent = "Verification Email Sent!",
}

//TODO: check to prevent redoing if email has already been verified on backend
// route protection and login redirects if user has not verified their email
const VerifyEmailPage: React.FC<VerifyEmailPageProps> = ({ history }) => {
  /** State */
  const [verifyEmailCode, setVerifyEmailCode] = useState("");
  const [resendText, setResendText] = useState(ResendTextEnum.Resend);
  const [fieldErrors, setFieldErrorsInternal] = useState({
    [ErrorKeyEnum.VerifyEmailCode]: null,
  });
  const dispatch = useDispatch();
  const getUser = () => dispatch(UserActions.getUser());
  const userState: any = useSelector((state: MatchstikState) => state.user);
  const { userData: user } = userState;

  /** Actions */
  const eventHandler = makeEventHandler(() => { });

  const setFieldErrors = (field: string, message: string | null) => {
    const newFieldErrors: any = {
      [field]: message,
    };
    setFieldErrorsInternal(newFieldErrors);
  };

  const onChangeVerifyCode = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.VerifyEmailCode, null);
    setVerifyEmailCode(value);
  });
  /** GraphQL */
  const [verifyEmailMutation, { loading }] = useMutation(VERIFY_EMAIL, {
    onCompleted: ({ verifyEmail }) => {
      localStorage.setItem('userId', verifyEmail?.donors?.userId);
      localStorage.setItem('slug', verifyEmail?.donors?.slug);
      localStorage.setItem('donorData', verifyEmail?.donors?._id)
      if (verifyEmail?.user?.organizationType === "Non-Profit") {
        history.push({
            pathname :"/dashboard/home",
            state: { orgType: verifyEmail?.user?.organizationType },
          });
        // pathname :"/dashboard/overview",
      }
      if (verifyEmail?.user?.organizationType === "Individual") {
        history.push("/donor/" + verifyEmail?.donors?.slug);
      } else {
        history.push({
          pathname :"/dashboard/home",
          state: { orgType: verifyEmail?.user?.organizationType },
        });
      }
    },
    onError: async (error) => {
      const errorMsg = ErrorUtil.getErrorMessage(error);
      setFieldErrors(ErrorKeyEnum.VerifyEmailCode, errorMsg);
    },
  });

  const [sendUserEmailVerification] = useMutation(
    SEND_USER_VERIFICATION_EMAIL,
    {
      onCompleted(data) {
        setResendText(ResendTextEnum.Sent);
      },
      onError(error) {
        const errorMsg = ErrorUtil.getErrorMessage(error);
        setFieldErrors(ErrorKeyEnum.VerifyEmailCode, errorMsg);
        setResendText(ResendTextEnum.Resend);
      },
    }
  );

  const send = () => {
    setResendText(ResendTextEnum.Sending);
    sendUserEmailVerification();
  };

  const verify = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    const params = schema.validate({
      verifyEmailCode,
    });

    const { error: schemaError } = params;

    if (schemaError) {
      const { field, message } = JSON.parse(schemaError.message);
      setFieldErrors(field, message);
      return;
    }

    if (!loading) {
      verifyEmailMutation({
        variables: {
          userId: user.userId,
          verifyEmailCode,
        },
      });
    }
  };

  return (
    <AuthLayout title="Verify email" onSubmit={verify}>
      <Content>
        <Row>
          <Text>
            We've emailed you a 6-digit email verification code. Please check
            the email you registered with and enter the code below to finish
            activating your Matchstik account.
          </Text>
          &nbsp;
        </Row>
        <Row>
          <LabeledInput
            placeholder="Enter your 6-digit email code"
            value={verifyEmailCode}
            onChange={onChangeVerifyCode}
            error={fieldErrors["verifyEmailCode"]}
            type="text"
          />
        </Row>
        <Button
          type={ButtonTypes.Submit}
          onClick={() => verify()}
          text="Verify & Continue"
          loading={loading}
        />
      </Content>
      <Footer>
        <Row>
          <Text>Didn't receive an email?</Text>&nbsp;
          <ResendEmailText
            cursor={resendText === ResendTextEnum.Resend ? "pointer" : ""}
            onClick={() =>
              resendText === ResendTextEnum.Resend ? send() : null
            }
          >
            {resendText}
          </ResendEmailText>
        </Row>
      </Footer>
    </AuthLayout>
  );
};

export default VerifyEmailPage;
