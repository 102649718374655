import { all, takeLatest, put, call, select } from "redux-saga/effects";
import * as UserActions from "../actions/user.actions";
import { UserActionTypes } from "../actions/user.actions";
import client from "../../graphql/client";
import GET_USER, { GET_FULL_USER } from "../../graphql/queries/user.query";
import GET_DONOR_PROFILE from "../../graphql/queries/donorProfile.query";

import IUser from "@matchstik/models/.dist/interfaces/IUser";
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";

export default function* userSaga() {
  try {
    yield all([
      getUserWatch(),
      getDonorWatch(),
      getFullUserWatch(),
    ]);
  } catch (e) {
    console.log(e);
  }
}

interface IUserWithOrg extends IUser {
  organization?: IOrganization;
}

function* getUserWatch() {
  yield takeLatest(UserActionTypes.GET_USER_REQUEST, getUserSaga);
}

function* getDonorWatch() {
  yield takeLatest(UserActionTypes.GET_DONOR_REQUEST, getDonorSaga);
}

function* getFullUserWatch() {
  yield takeLatest(UserActionTypes.GET_FULL_USER_REQUEST, getFullUserSaga);
}

/*================================================================================
Sagas
================================================================================*/

interface UserSuccesType {
  data: {
    user: IUserWithOrg;
  };
}

function* getUserSaga(action: UserActions.GetUserAction) {
  yield put(UserActions.setLoading(true));
  const queryResponse: UserSuccesType = yield call(async () => {
    return await client.query({
      query: GET_USER,
      fetchPolicy:'network-only'
    });
  });

  const user: any | undefined = queryResponse.data.user;
  const organizationData: any | undefined =
    queryResponse.data.user.organization;
  yield put(UserActions.setOrganization(organizationData));
  const { organization, ...userData } = user;
  yield put(UserActions.setUser(userData));
  yield put(UserActions.setLoading(false));
}

interface DonorSuccesType {
  data: any
}


function* getDonorSaga(action: UserActions.GetDonorAction) {
  yield put(UserActions.setLoading(true));
  const donorSlug = localStorage.getItem('donorSlug')
  const queryResponse:  DonorSuccesType = yield call(async () => {
    return await client.query({
      query: GET_DONOR_PROFILE,
      variables: {
        slug: donorSlug
      },
      fetchPolicy:'network-only'
    });
  });
  const donor: any | undefined = queryResponse?.data?.getDonorAndUserById;
  yield put(UserActions.setDonor(donor));
  yield put(UserActions.setLoading(false));
}

interface DonorSuccesType{
  data: any
}

function* getFullUserSaga(action: UserActions.GetFullUserAction) {
  yield put(UserActions.setLoading(true));



  const queryResponse: UserSuccesType = yield call(async() => {
    return await client.query({
      query: GET_FULL_USER,
      fetchPolicy: 'network-only'
    });
  });
  const user: any | undefined = queryResponse.data.user;
  const organizationData: any | undefined =
    queryResponse.data.user.organization;
  yield put(UserActions.setOrganization(organizationData));
  const { organization, ...userData } = user;
  yield put(UserActions.setUser(userData));
  yield put(UserActions.setLoading(false));
}
