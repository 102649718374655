import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UPDATE_DONOR from "../graphql/mutations/updateDonor.mutations";
import { useMutation } from "@apollo/react-hooks";
import Joi from "@hapi/joi";
import Select from "react-select";
import { Colors } from "../styles/Colors";
import Icon, { Icons } from "../elements/Icon";
import AuthLayout, { Row } from "../components/AuthLayout";
import ImageUpload from "../../src/elements/ImageUpload";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.us";
import { useDispatch, useSelector } from "react-redux";
import * as UserActions from "../redux/actions/user.actions";
import { MatchstikState } from "../redux/store";
import Label from "../elements/Label";
import LabeledInput from "../elements/LabeledInput";
import TextArea from "../elements/TextArea";
import Button, { ButtonTypes, IconPositionTypes } from "../elements/Button";
import * as Schema from "../utils/Schema";
import * as ErrorUtil from "../utils/ErrorUtil";
import makeEventHandler from "../utils/makeEventHandler";
import { successMessages } from "../utils/MessageUtil";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router";
import { WEB_UI_URL } from "../env";
import { API_URL } from "../env";
import * as Polished from "polished";
import { IUSstates } from "@matchstik/models/.dist/interfaces/IUSstates";
import ErrorText from "../elements/ErrorText";

enum VisibilityEnum {
  Public = "Public",
  Private = "Private",
}

const UpdateUserProfileConatiner = styled.div`
  // width: 70vw;
  // padding: 20px 30px;
  // margin: 90px 40px 0;
  // box-shadow: 0 0 10px #00000014;
`;
const ProfileName = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: #333333;
`;

const SubNav = styled.div`
  display: flex;
  margin: -10px 0px 30px;
`;
const Alignleft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 100%;
`;

type styledselectprops = {
  hasError: string;
};

const StyledSelect = styled(Select)<styledselectprops>`
  div[class*="-control"] {
    border: ${(props) =>
    props.hasError ? "State is required" : "1px solid #E0E0E0"} !important;
  }
`;

const SubNavItem1 = styled.div<{ active: boolean }>`
  font-weight: 700;
  size: 1.8rem;
  transition: all 0.2s;
  color: ${[(props) => (props.active ? Colors.White : Colors.Grey4)]};
  padding: 10px 50px;
  cursor: pointer;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  background: ${[(props) => (props.active ? Colors.Pink : Colors.Grey6)]};
  display: flex;
  gap: 10px;
  align-items: center;
  svg {
    color: ${[(props) => (props.active ? Colors.White : Colors.Grey4)]};
  }
  @media screen and (max-width: 767px) {
    padding: 8px 40px;
  }
  &:hover {
    // color: ${Colors.Blue};
  }
`;

const SubNavItem2 = styled.div<{ active: boolean }>`
  font-weight: 700;
  size: 1.8rem;
  transition: all 0.2s;
  color: ${[(props) => (props.active ? Colors.White : Colors.Grey4)]};
  padding: 10px 50px;
  cursor: pointer;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background: ${[(props) => (props.active ? Colors.Pink : Colors.Grey6)]};
  display: flex;
  gap: 10px;
  align-items: center;
  svg {
    color: ${[(props) => (props.active ? Colors.White : Colors.Grey4)]};

  }
  @media screen and (max-width: 767px) {
    padding: 8px 40px;
  }

  &:hover {
    // color: ${Colors.Blue};
  }
`;
const ProfileLink = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: #333333;
`;

const ProfileLinkColor = styled.div`
  color: ${Colors.Pink};
  display: inline;
  text-decoration: underline;
  cursor: pointer;
`;

const UserProfile = styled.div<any>`
  border: 1px solid black;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: cover;
  background-image: url(${(props) => props.src});
`;

type PhoneInputProps = {
  margin?: string;
  padding?: string;
  width?: string;
  error?: any;
};

const PhoneInput = styled(Cleave)<PhoneInputProps>`
  color: ${Colors.Grey1};
  outline: none;
  border: 0px;
  border-radius: 10px;
  height: 48px;
  width: ${(props) => props.width};
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0 0 0 10px;
  transition: all 0.2s;
  padding: ${(props) => props.padding};
  border: ${(props) =>
    props.error ? `1px solid ${Colors.Red}` : `1px solid ${Colors.Grey5}`};

  ::placeholder {
    color: ${Colors.Grey4};
    font-weight: 600;
  }

  &:hover {
    /* background-color: ${Polished.darken(0.01, Colors.VeryLightBlue)}; */
    /* border: ${(props) =>
      props.error
        ? `1px solid ${Colors.Red}`
        : `1px solid ${Polished.darken(0.01, Colors.VeryLightBlue)}`}; */
  }

  &:focus {
    /* background-color: ${Polished.darken(0.02, Colors.VeryLightBlue)}; */
    /* border: ${(props) =>
      props.error
        ? `1px solid ${Colors.Red}`
        : `1px solid ${Polished.darken(0.02, Colors.VeryLightBlue)}`}; */
  }
`;

export const ErrorText2 = styled.div`
  color: #ee5050;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  position: absolute;
  bottom: -22px;
  left: 1px;
`;

const colourStyles = {
  menu: (base: any) => ({
    ...base,
    height: "auto",
    position: "absolute",
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
};

export const DropDown = styled.div`
  width: 300px;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const Bottombuttons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const schema = Joi.object({
  firstName: Schema.donor.firstName().error(([error]) => {
    const message = "First Name is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  slug: Schema.donor.slug().error(([error]) => {
    let message = "";
    if (error.code === "string.empty") {
      message = "Slug is required";
    } else {
      message = "Special characters are not allowed";
    }
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  email: Schema.donor.email().error(([error]) => {
    let message = "";
    if (error.code === "string.empty") {
      message = "Email is required";
    } else {
      message = "Email is invalid";
    }
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  phoneNumber: Schema.donor.phoneNumber().error(([error]) => {
    let message = "";
    if (error.code === "string.empty") {
      message = "Phone Number is required";
    } else {
      message = "Phone number is invalid";
    }
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  city: Schema.donor.cities().error(([error]) => {
    const message = "City is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  state: Schema.donor.state().error(([error]) => {
    const message = "State is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  statement: Schema.donor.statement().error(([error]) => {
    const message = "Statement is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  visibility: Schema.donor.visibility(),
  photoUrl: Schema.donor.photoUrl(),
});

enum ErrorKeyEnum {
  FirstName = "firstName",
  LastName = "lastName",
  StateMent = "statement",
  Photo = "photo",
  Email = "email",
  InvalidEmail = "invalidEmail",
  PhoneNumber = "phoneNumber",
  City = "city",
  State = "state",
  slug = "slug",
}

interface UpdateDonorProfileProps {}

const UpdateDonorProfile: React.FC<UpdateDonorProfileProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const historyState: any = history.location.state;
  const fName = historyState?.firstName;
  const lName = historyState?.lastName;
  const statements = historyState?.statement;
  const donorSlug = historyState?.slug;
  const mail = historyState?.email;
  const imageUrl = historyState?.imgUrl;
  const phone = historyState?.phoneNumber;
  const profileVisibility = historyState?.visibility;
  const cityData = historyState?.city;
  const stateData = historyState?.state;
  const getDonor = () => dispatch(UserActions.getDonor());
  const userState: any = useSelector((state: MatchstikState) => state.user);
  const { donor } = userState;
  const [pageState, setPageState] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [statement, setStatement] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [visibility, setVisibility] = React.useState("");
  const [photoUrl, setPhotoUrl] = React.useState("");
  const [city, setCity] = React.useState("");
  const [link, setLink] = React.useState("");
  const [slug, setSlug] = React.useState(donor?.slug);
  const [verifyError, setVerifyError] = useState("" as any);
  const [fieldErrors, setFieldErrorsInternal] = React.useState({
    [ErrorKeyEnum.FirstName]: null,
    [ErrorKeyEnum.LastName]: null,
    [ErrorKeyEnum.Email]: null,
    [ErrorKeyEnum.InvalidEmail]: null,
    [ErrorKeyEnum.PhoneNumber]: null,
    [ErrorKeyEnum.StateMent]: null,
    [ErrorKeyEnum.Photo]: null,
    [ErrorKeyEnum.City]: null,
    [ErrorKeyEnum.State]: null,
    [ErrorKeyEnum.slug]: null,
  });

  const setFieldErrors = (field: string, message: string | null) => {
    const newFieldErrors: any = {
      [field]: message,
    };
    setFieldErrorsInternal(newFieldErrors);
  };

  const [state, setState] = useState({
    label: "",
    value: "",
  });

  useEffect(() => {
    setTimeout(
      () =>
        setState({
          value: stateData,
          label: stateData,
        }),
      500
    );
  }, [stateData]);

  useEffect(() => {
    setFirstName(fName);
    setLastName(lName);
    setStatement(statements);
    setLink(
      WEB_UI_URL + "/" + "donor" + "/" + donor?.slug ||
        API_URL + "/" + "donor" + "/" + donor?.slug
    );
    setSlug(donorSlug);
    setPhotoUrl(imageUrl);
    setPhoneNumber(phone);
    setEmail(mail);
    setCity(cityData);
    setState(stateData);
    setPageState(profileVisibility);
    if (profileVisibility === VisibilityEnum.Private) {
      setVisibility(profileVisibility);
    } else if (profileVisibility === VisibilityEnum.Public) {
      setVisibility(profileVisibility);
    }
    getDonor();
  }, []);

  const onChangePhotoUrl = (value: string) => {
    setPhotoUrl(value);
  };

  const eventHandler = makeEventHandler(() => setError(""));

  const onChangeFirstName = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.FirstName, null);
    setFirstName(value);
  });

  const onChangeLastName = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.LastName, null);
    setLastName(value);
  });

  const onChangePhoneNumber = eventHandler((value: string) => {
    const phone = value?.replace(new RegExp(" ", "g"), "");
    const newPhone = phone?.replace(/^0+/, "");
    setFieldErrors(ErrorKeyEnum.PhoneNumber, null);
    setPhoneNumber(newPhone);
  });

  const onChangeCity = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.City, null);
    setCity(value);
  });

  const onchangeSlug = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.slug, null);
    // setCity(value);
    let newText = value.trim();
    setSlug(newText);
  });

  const [updateDonorMutation] = useMutation(UPDATE_DONOR, {
    variables: {
      params: {
        firstName,
        lastName,
        phoneNumber,
        statement,
        email,
        visibility,
        photoUrl,
        city,
        slug,
        state: state.value,
      },
    },
    onCompleted: async (data) => {
      getDonor();
      setTimeout(() => {
        toast.success(successMessages.DONOR_UPDATE_SUCCESS);
      }, 100);
      dispatch(UserActions.setDonor(data?.updateDonor));
      if (data?.updateDonor?.slug) {
        localStorage.setItem("donorSlug", data?.updateDonor?.slug);
        navigateDonorProfile(data?.updateDonor?.slug);
      }
    },
    onError: async (error) => {
      const errorMsg = ErrorUtil.getErrorMessage(error);
      setError(errorMsg);
      setVerifyError(error.graphQLErrors[0].extensions.code);
      setLoading(false);

    },
  });
  const statesArray = [];

  for (const [propertyKey, propertyValue] of Object.entries(IUSstates)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    statesArray.push({ label: propertyValue, value: propertyKey });
  }

  useEffect(() => {
    setState(state as any);
  }, [state]);

  const navigateDonorProfile = (slug: any) => {
    if (slug) {
      history.push("/" + "donor" + "/" + slug || "/" + "donor" + "/" + slug);
    } else {
      history.push(
        "/" + "donor" + "/" + donor?.slug || "/" + "donor" + "/" + donor?.slug
      );
    }
  };

  const updateDonorProfileData = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    const params = schema.validate({
      firstName,
      phoneNumber,
      email,
      statement,
      visibility,
      photoUrl: photoUrl ? photoUrl : "",
      city,
      slug,
      state: state.value,
    });
    const { error: schemaError } = params;
    if (schemaError) {
      try {
        const { field, message } = JSON.parse(schemaError.message);
        setFieldErrors(field, message);
      } catch (error) {
        console.log("error", error);
      }
      return;
    } else {
      setError("");
      toast.remove();

      updateDonorMutation();
    }
  };

  const updateVisibility = () => {
    setVisibility(VisibilityEnum.Public);
    setPageState(VisibilityEnum.Public);
  };

  const updateVisibilityPrivate = () => {
    setVisibility(VisibilityEnum.Private);
    setPageState(VisibilityEnum.Private);
  };

  const onCancelClick = () => {
    history.push("/donor/" + donor?.slug)
  }
  return (
    <>
      <Toaster position="bottom-left" reverseOrder={false} />
      <UpdateUserProfileConatiner>
        <ProfileName>Profile Visibility</ProfileName>
        <br />
        <SubNav>
          <SubNavItem1
            onClick={() => updateVisibility()}
            active={pageState === VisibilityEnum.Public ? true : false}
          >
            <Icon icon={Icons.EyeSolid} color={Colors.Grey4} size={18} />
            Public
          </SubNavItem1>
          <SubNavItem2
            onClick={() => updateVisibilityPrivate()}
            active={pageState === VisibilityEnum.Private ? true : false}
          >
            <Icon icon={Icons.Unlock} color={Colors.Grey4} size={18} />
            Private
          </SubNavItem2>
        </SubNav>
        <div>
          {" "}
          Profile Link: &nbsp;&nbsp;
          <ProfileLinkColor onClick={() => navigateDonorProfile(donor?.slug)}>
            {link}
          </ProfileLinkColor>
        </div>
        <br />
        <Label text="Slug" />
        <Row>
          <LabeledInput
            placeholder="Slug"
            onChange={onchangeSlug}
            value={slug}
            width="271px"
            error={fieldErrors["slug"]}
          />
        </Row>
        <br />
        <ImageUpload
          imageUrl={photoUrl}
          setImageUrl={onChangePhotoUrl}
          label="Profile Picture"
        />
        <br />
        {/* <br /> */}
        <Label text="First Name" />
        <Row>
          <LabeledInput
            placeholder="First Name"
            onChange={onChangeFirstName}
            value={firstName}
            width="271px"
            error={fieldErrors["firstName"]}
          />
        </Row>

        <Label text="Last Name" />
        <Row>
          <LabeledInput
            placeholder="Last Name"
            onChange={onChangeLastName}
            value={lastName}
            width="271px"
          />
        </Row>

        <Label text="Email Address" />
        <Row>
          <LabeledInput
            placeholder="Email Address"
            onChange={(e: any) => setEmail(e.target.value)}
            value={email}
            width="271px"
            error={
              fieldErrors["email"]
                ? fieldErrors["email"]
                : fieldErrors["invalidEmail"]
            }
          />
        </Row>
        {/* <ProfileLinkColor> Change Email Address </ProfileLinkColor> */}
       {/* <br />
         <br /> */}
        <Label text="Mobile Phone Number" />
        <Row>
          <Alignleft>
            <PhoneInput
              options={{ phone: true, phoneRegionCode: "US" }}
              type="tel"
              placeholder="Enter Phone Number"
              value={phoneNumber}
              onChange={onChangePhoneNumber}
              error={fieldErrors[ErrorKeyEnum.PhoneNumber]}
              width="270px"
            />
            <ErrorText2>{fieldErrors["phoneNumber"]}</ErrorText2>
          </Alignleft>
        </Row>
        {/* <ProfileLinkColor> Change Phone Number </ProfileLinkColor> 
        <br />
        <br />*/}
        <Label text="City" />
        <Row>
          <LabeledInput
            placeholder="City"
            value={city}
            onChange={onChangeCity}
            width="271px"
            error={fieldErrors["city"]}
          />
        </Row>
        <Row>
          <Alignleft>
            <DropDown>
              <Label text="State" />
              <StyledSelect
                hasError={"State is required"}
                maxMenuHeight={150}
                className="test"
                value={state}
                onChange={({ value }: any) => {
                  setState({ label: value, value });
                }}
                placeholder="State"
                options={statesArray}
                styles={colourStyles}
                menuPortalTarget={document.body}
              />
              <ErrorText margin="0 0 0 0">{fieldErrors["state"]}</ErrorText>
            </DropDown>
          </Alignleft>
        </Row>
        <br />
        <Label text="Your Statement" />
        <div>
          <TextArea
            placeholder="Statement"
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              const message = event.currentTarget.value;
              setStatement(message);
            }}
            value={statement}
            width="669px"
            height="105px"
            error={fieldErrors["statement"]}
          />
        </div>
        {verifyError === "422" ? "" : <p style={{ color: "red" }}>{error}</p>}
        <Bottombuttons>
          <Button
            type={ButtonTypes.Submit}
            loading={loading}
            text={"Cancel"}
            margin="25px 0 0"
            width="25%"
            onClick={onCancelClick}
            style={{background:"#BDBDBD"}}
          />
          <Button
            type={ButtonTypes.Submit}
            onClick={updateDonorProfileData}
            loading={loading}
            text="Update"
            margin="25px 0 0"
            width="25%"
          />
        </Bottombuttons>
        <br />
      </UpdateUserProfileConatiner>
    </>
  );
};
export default UpdateDonorProfile;
