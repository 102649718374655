import gql from 'graphql-tag';

const mutation = gql`
mutation verifyEmail($verifyEmailCode: String!){
  verifyEmail(verifyEmailCode:$verifyEmailCode){
     user {
    _id
    firstName
    lastName
    email
    createdAt
    __typename
    organizationType
  }
  donors{
      _id
      firstName
      lastName
      visibility
      supportingOrgs{
          donatedAmount
      }
      slug
      userId
  }
  }
}
`;

export default mutation;
