import React from "react";
import styled from "styled-components";
import { Colors } from '../styles/Colors';
import * as Polished from 'polished';
import Select from 'react-select';
import ErrorText from "./ErrorText";

type StyledSelectProps = {
    margin?: string;
    padding?: string;
    width: string;
    error?: boolean;
    icon?: any;
  }

  const Container = styled.div`
  position: relative;
`;

const StyledSelect = styled(Select)<StyledSelectProps>`
  /* background-color: ${Colors.VeryLightBlue}; */
  color: ${Colors.Grey1};
  outline: none;
  border: 0px;
  border-radius: 10px;
  height: 52px;
  width: ${props => props.width};
  // width:270px;
  font-size: 1.4rem;
  font-weight: 500;
  // padding: 0 15px;
  transition: all 0.2s;
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  border: ${props =>
    props.error && `1px solid ${Colors.Red}`};

  // ::placeholder {
  //   color: ${Colors.Grey4};
  //   font-family: 'Nunito Sans';
  //   font-weight: 600;
  // }

  &:hover {
    /* background-color: ${Polished.darken(0.01, Colors.VeryLightBlue)}; */
    /* border: ${props =>
    props.error
      ? `1px solid ${Colors.Red}`
      : `1px solid ${Polished.darken(0.01, Colors.VeryLightBlue)}`}; */
  }

  &:focus {
    /* background-color: ${Polished.darken(0.02, Colors.VeryLightBlue)}; */
    /* border: ${props =>
    props.error
      ? `1px solid ${Colors.Red}`
      : `1px solid ${Polished.darken(0.02, Colors.VeryLightBlue)}`}; */
  }
    @media (max-width:767px)
    {
      width:calc(100% - ${props => props.icon?"55px":"30px"});
    }
`;

export type SelectProps = {
    placeholder?: string;
    value: any;
    defaultValue?: string;
    onChange?: any;
    options?: any;
    margin?: string;
    padding?: string;
    width?: string;
    error?: string | null;
  };


    export default function Dropdown({
        placeholder,
        value,
        defaultValue,
        onChange,
        options,
        margin,
        padding,
        width = 'fill-available',
        error,
      }: SelectProps) {
        return (
          <Container>  
            <StyledSelect
              placeholder={placeholder}
              value={value}
              defaultValue={defaultValue}
              onChange={onChange}
              options={options}
              margin={margin}
              padding={padding}
              width={width}
              error={Boolean(error)}
              />
              {error && <ErrorText margin= "5px 0">{error}</ErrorText>}
          </Container>
        );
      }