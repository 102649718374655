import React, { useState } from "react";
import { Colors } from '../styles/Colors';
import Icon, { Icons } from '../elements/Icon';
import { useQuery } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import GET_CAMPAIGN from "../graphql/queries/campaign.query";
import GET_LIST_MATCH from "../graphql/queries/listMatches.query";
import { ImagePreview, ThumbNail, MediaDetails, PosterImage } from "../pages/NewCampaigns.page";
import { AboutSection, CommonTitle, AboutDesc, ContactSection, OrderedList, Ellipse, ConListItem, BiggestSupporters, CardList, CardImage, ThumbImage, CardDesc, CardHeading, CardPrice, CardProgressValue, SeeAll } from "../pages/OrganizationProfile.page";
import * as Price from "@matchstik/utils/.dist/price";
import { ModalTypes } from "../components/modal/Modal";
import * as AppActions from '../redux/actions/app.actions';
import styled from 'styled-components';
import Carousel from "../elements/Carousel";
import { NoCampaignText } from "./CampaignsList.page";


const Video = styled.div`
margin-right: 35px;
width: 50%;
cursor: pointer;
@media (max-width:640px){
    width: 100%;
}
`;

const VideoPreview = styled.div`
display: flex;
margin-bottom: 30px;
@media (max-width:640px){
    flex-direction: column;
}
`;

const BackButton = styled.div`
display: flex;
`;

const GoalTitle = styled.div`
font-size: 20px;
margin-bottom: 15px;
color:${Colors.Black};
@media (max-width:640px){
  font-size: 20px;
  line-height: 30px;
}
`;

const Text = styled.div`
  font-size: 1.7rem;
  color: ${Colors.Pink};
  font-weight: 800;
  margin-left: 5px;
  cursor: pointer;
`;

type OrganizationCampaignOverviewProps = {
    activeCampaign: string
    organizationStubDetail: any
    setActiveTab?: any
    setActiveCampaign?: any
    setListMatches?: any
    setShowCampaignSlug?:any
    setActiveCampaignSlug : any
    organizationId? : string;
};


const OrganizationCampaignOverview: React.FC<OrganizationCampaignOverviewProps> = (props) => {

    /* Hooks */
    const campaignId = props.activeCampaign;
    const organizationStubDetail = props.organizationStubDetail;
    const [inActiveMatches, setInActiveMatches] = useState([]);
    const [showButton, setShowButton] = useState("See All");
    const [matchDetails, setMatchDetails] = useState([]);
    const setActiveTab = props.setActiveTab;

    /*Action*/
    const dispatch = useDispatch();
    const onSeeAllClick = (() => {
        const activeMatchDetails = data.listMatch.filter((match: any) => match.active);
        if (showButton === "See All") {
            setMatchDetails(data?.listMatch);
            setShowButton("Show Active");
        }
        else {
            setMatchDetails(activeMatchDetails);
            setShowButton("See All");
        }
    });

    const onImagePreview = ((image: string) => {
        sessionStorage.setItem("YoutubeVideo", "");
        sessionStorage.setItem("VimeoVideo", "");
        sessionStorage.setItem("image", image);
        dispatch(AppActions.pushModal(ModalTypes.PreviewImageModal));
    });

    const onYoutubePreview = ((video: string) => {
        sessionStorage.setItem("image", "");
        sessionStorage.setItem("VimeoVideo", "");
        sessionStorage.setItem("YoutubeVideo", video);
        dispatch(AppActions.pushModal(ModalTypes.PreviewImageModal));
    });

    const onVimeoPreview = ((vimeoVideo: string) => {
        sessionStorage.setItem("image", "");
        sessionStorage.setItem("YoutubeVideo", "");
        sessionStorage.setItem("VimeoVideo", vimeoVideo);
        dispatch(AppActions.pushModal(ModalTypes.PreviewImageModal));
    });
    const goBack = (() => {
        props.setShowCampaignSlug(false);
        props.setListMatches([]);
        props.setActiveCampaign("");
        setActiveTab("Campaigns");
        props.setActiveCampaignSlug("");
    });

    /* GraphQL */

    // Campaign List //
    const { data } = useQuery(GET_LIST_MATCH, {
        variables: {
            campaignId,
            "onlyActive": false,
            "sortByAmount": true
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data) {
                const activeMatchDetails = data.listMatch.filter((match: any) => match.active);
                const inActiveMatchDetails = data.listMatch.filter((match: any) => match.active === false);
                setMatchDetails(activeMatchDetails);
                setInActiveMatches(inActiveMatchDetails);
            }
        }
    });

    // Campaign //
    const { data: campaignData } = useQuery(GET_CAMPAIGN, {
        fetchPolicy: "network-only",
        variables: {
           "organizationId":props.organizationId,
            campaignId,
            startDate: "",
            endDate: "",
            custom: false,
            dateRange:""
        },
        onCompleted: (data) => {
            if (data) {
                props.setActiveCampaignSlug(data.campaign?.campaign.campaignSlug);
            }
        }
    });
    
    const campaigns = campaignData?.campaign?.campaign;
    const address = organizationStubDetail?.address && organizationStubDetail?.address
    const city = organizationStubDetail?.city && organizationStubDetail?.city
    const state = organizationStubDetail?.state && organizationStubDetail?.state
    const zip = organizationStubDetail?.zip && organizationStubDetail?.zip
    const vimeoId = campaigns?.vimeoLink && campaigns?.vimeoLink?.match(/(videos|video|channels|\.com)\/([\d]+)/)[2];
    const regEx = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const matches = campaigns?.youtubeLink && campaigns?.youtubeLink?.match(regEx);
    const videoId = matches ? matches[1] : "";


    return (
        <>
            <BackButton>
                <Icon icon={Icons.BackArrow} size={16} padding="5px" onClick={goBack} />
                <Text onClick={goBack}>GO BACK</Text>
            </BackButton>
            <AboutSection>
                <CommonTitle>{organizationStubDetail?.name} | {campaigns?.name} </CommonTitle>
                {campaigns?.goal && <GoalTitle>Campaign Goal: ${Price.output(campaigns?.goal, true)}</GoalTitle>}
                <AboutDesc>{campaigns?.description}</AboutDesc>
            </AboutSection>

            {campaigns?.gallery !== null && campaigns?.gallery.length > 0 &&
                <>
                    {campaigns?.gallery && campaigns?.gallery.length < 4 &&
                        <MediaDetails margin >
                            {campaigns?.gallery.map((image: any, index: number) => {
                                return (
                                    <ImagePreview key={index} onClick={() => onImagePreview(image)}>
                                        <ThumbNail>
                                            <PosterImage src={image} />
                                        </ThumbNail>
                                    </ImagePreview>
                                )
                            })}
                        </MediaDetails>}

                    {campaigns?.gallery.length > 3 &&
                        <Carousel show={window.innerWidth > 468 ? 3 : 1} >
                            {campaigns?.gallery.length > 0 && campaigns?.gallery.map((image: any, index: number) => {
                                return (
                                    <MediaDetails margin key={index}>
                                        <ImagePreview onClick={() => onImagePreview(image)}>
                                            <ThumbNail>
                                                <PosterImage src={image} />
                                            </ThumbNail>
                                        </ImagePreview>
                                    </MediaDetails>
                                )
                            })}
                        </Carousel>}
                </>}
            {videoId || vimeoId ? <VideoPreview>
                {videoId && <Video >
                    <img src={`https://img.youtube.com/vi/${videoId}/0.jpg`} width="100%" height="auto" onClick={() => onYoutubePreview(campaigns?.youtubeLink)}></img>
                </Video>}
                {vimeoId && <Video>
                    <img src={`https://vumbnail.com/${vimeoId}.jpg`} width="100%" height="auto" onClick={() => onVimeoPreview(campaigns?.vimeoLink)}></img>
                </Video>}
            </VideoPreview> : ""}
            <ContactSection>
                <CommonTitle>Contact Info</CommonTitle>
                <OrderedList>
                    {address || city || state || zip ? <ConListItem>
                        <Icon icon={Icons.mapPinLight} color={Colors.Grey2} size={16} margin="0px 10px 0px 0" />{address && address + ", "}{city && city + ", "}{state && state + ", "}{zip}</ConListItem> : ""}
                    {organizationStubDetail?.phoneNumber && <ConListItem><Icon icon={Icons.Phone} color={Colors.Grey2} size={16} margin="0px 10px 0px 0" />{organizationStubDetail?.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}</ConListItem>}
                    {organizationStubDetail?.email && <ConListItem><Icon icon={Icons.At} color={Colors.Grey2} size={16} margin="0px 10px 0px 0" />{organizationStubDetail?.email}</ConListItem>}
                    {organizationStubDetail?.websiteUrl && <ConListItem><Icon icon={Icons.Globe} color={Colors.Grey2} size={16} margin="0px 10px 0px 0" /><Ellipse>{organizationStubDetail?.websiteUrl}</Ellipse></ConListItem>}
                </OrderedList>
            </ContactSection>
            <BiggestSupporters>
                <CommonTitle>Our Matches <SeeAll onClick={onSeeAllClick}>{inActiveMatches?.length > 0 ? showButton : ""}</SeeAll></CommonTitle>
                {matchDetails && matchDetails.map((match: any, index) => {
                    return (
                        <CardList key={index} active={match.active === false}>
                            <CardImage>
                                <ThumbImage src={match.posterUrl} />
                            </CardImage>
                            <CardDesc>
                                <CardHeading>{match.name}</CardHeading>
                                <CardPrice>${Price.output(match.matchAmount, true)} Matched </CardPrice>
                                <CardProgressValue>{match.completed}% Complete</CardProgressValue>
                            </CardDesc>
                        </CardList>
                    )
                })}
                {matchDetails?.length === 0 && inActiveMatches?.length > 0 && showButton === "See All" ?
                    <NoCampaignText>There are no active matches </NoCampaignText> : false}
                {inActiveMatches?.length === 0 && matchDetails?.length === 0 &&
                    <NoCampaignText>No matches </NoCampaignText>}
            </BiggestSupporters>
        </>
    );
}

export default OrganizationCampaignOverview;
