import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/client";
import Loader, { LoaderSizes } from "../elements/Loader";
import LabeledInput from "../elements/LabeledInput";
import Joi from "@hapi/joi";
import * as Schema from "../utils/Schema";
import * as ErrorUtil from "../utils/ErrorUtil";
import makeEventHandler from "../utils/makeEventHandler";
import ErrorText from "../elements/ErrorText";
import TextArea from "../elements/TextArea";
import Button, { ButtonTypes } from "../elements/Button";
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import ImageUpload from "../elements/ImageUpload";
import GET_USER from "../graphql/queries/user.query";
import UPDATE_ORGANIZATION from "../graphql/mutations/updateOrganization.mutation";
import ReactTooltip from "react-tooltip";
import Icon, { Icons } from "../elements/Icon";
import { Colors } from "../styles/Colors";
import Label from "../elements/Label";
import { useDispatch } from "react-redux";
import * as UserActions from "../redux/actions/user.actions";
import Select from 'react-select';
import { IUSstates } from '@matchstik/models/.dist/interfaces/IUSstates';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';
import * as Polished from 'polished';
import toast, { Toaster } from 'react-hot-toast';
import { successMessages } from '../utils/MessageUtil';

const schema = Joi.object({
  name: Schema.organization.name().error(([error]) => {
    const message = "Organization name is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  email: Schema.organization.email().error(([error]) => {
    const message = "Organization email is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  phoneNumber: Schema.organization.phoneNumber().error(([error]) => {
    const message = "Organiztion phone number is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  description: Schema.organization.description().error(([error]) => {
    const message = "Organization description is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  message: Schema.organization.message().error(([error]) => {
    const message = "Organization message is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  recurringDonationMessage: Schema.organization.message().error(([error]) => {
    const message = "Recurring donation message is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  address: Schema.organization.address().error(([error]) => {
    const message = "Organization address is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  city: Schema.organization.city().error(([error]) => {
    const message = "Organization city is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  zip: Schema.organization.zip().error(([error]) => {
    const message = "Organization zip is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  state: Schema.organization.state().error(([error]) => {
    const message = "Organization state is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  websiteUrl: Schema.organization.websiteUrl().error(([error]) => {
    const message = "Organization websiteUrl is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  logoUrl: Schema.organization.logoUrl().error(([error]) => {
    const message = "Organization logo is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  posterUrl: Schema.organization.posterUrl().error(([error]) => {
    const message = "Organization poster image is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  })
});

const UrlText = styled.a`
  height: fit-content;
  width: 360px;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid ${Colors.Grey5};
  margin: 0 0 15px 0;
  @media (max-width:767px)
  {
    width:calc(100% - 30px);
    word-break:break-word;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spacer = styled.div`
  height: 30px;
`;

const LabelContainer = styled.div`
display: flex;
`;

export const DropDown = styled.div`
  width: 300px;
  @media (max-width:576px)
    {
    width:100%;
    }
`;

type PhoneInputProps = {
  margin?: string;
  padding?: string;
  width?: string;
  error?: any;
}

const PhoneInput = styled(Cleave) <PhoneInputProps>`
  color: ${Colors.Grey1};
  outline: none;
  border: 0px;
  border-radius: 10px;
  height: 48px;
  width: ${props => props.width};
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0 0 0 10px;
  transition: all 0.2s;
  padding: ${props => props.padding};
  border: ${props =>
    props.error
      ? `1px solid ${Colors.Red}`
      : `1px solid ${Colors.Grey5}`};

  ::placeholder {
    color: ${Colors.Grey4};
    // font-family: 'Nunito Sans';
    font-weight: 600;
  }

  &:hover {
    /* background-color: ${Polished.darken(0.01, Colors.VeryLightBlue)}; */
    /* border: ${props =>
    props.error
      ? `1px solid ${Colors.Red}`
      : `1px solid ${Polished.darken(0.01, Colors.VeryLightBlue)}`}; */
  }

  &:focus {
    /* background-color: ${Polished.darken(0.02, Colors.VeryLightBlue)}; */
    /* border: ${props =>
    props.error
      ? `1px solid ${Colors.Red}`
      : `1px solid ${Polished.darken(0.02, Colors.VeryLightBlue)}`}; */
  }

`;

type styledselectprops = {
  hasError: any;
}

const StyledSelect = styled(Select) <styledselectprops>`
div[class*="-control"]{
  border:${props => props.hasError ? "1px solid red" : "1px solid #E0E0E0"} !important;
}`;

const colourStyles = {
  menu: (base: any) => ({
    ...base,
    border: "1px solid #e0e0e0",
    height: "auto",
    position: "absolute",
    top: "2px"
  }),
  menuPortal: (base: any) => ({  // to popout dropdown options outside overflow
    ...base,
    zIndex: 9999
  })
};


enum ErrorKeyEnum {
  Name = "name",
  Email = "email",
  PhoneNumber = "phoneNumber",
  Description = "description",
  Message = "message",
  RecurringDonationMessage = "RecurringDonationMessage",
  Address = "address",
  City = "city",
  State = "state",
  Zip = "zip",
  WebsiteUrl = "websiteUrl",
  LogoUrl = "logoUrl",
  PosterUrl = "posterUrl",
}

type SettingsPageProps = {};

const SettingsPage: React.FC<SettingsPageProps> = ({ }) => {
  const dispatch = useDispatch();
  /* State */
  const [organizationId, setOrganizationId] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [recurringDonationMessage, setRecurringDonationMessage] = React.useState("");
  const [charLimit, setCharLimit] = React.useState(0);
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [logoUrl, setLogoUrl] = React.useState("");
  const [posterUrl, setPosterUrl] = React.useState("");
  const [loaded, setLoaded] = React.useState(false);
  const [organizationStub, setOrganizationStub] = React.useState("");
  const [websiteUrl, setWebsiteUrl] = React.useState("");

  const [error, setError] = React.useState("");
  const [fieldErrors, setFieldErrorsInternal] = React.useState({
    [ErrorKeyEnum.Name]: null,
    [ErrorKeyEnum.Email]: null,
    [ErrorKeyEnum.PhoneNumber]: null,
    [ErrorKeyEnum.Description]: null,
    [ErrorKeyEnum.Message]: null,
    [ErrorKeyEnum.RecurringDonationMessage]: null,
    [ErrorKeyEnum.Address]: null,
    [ErrorKeyEnum.City]: null,
    [ErrorKeyEnum.State]: null,
    [ErrorKeyEnum.Zip]: null,
    [ErrorKeyEnum.WebsiteUrl]: null,
    [ErrorKeyEnum.LogoUrl]: null,
    [ErrorKeyEnum.PosterUrl]: null,
  });

  // Editor states
  /* Actions */
  const eventHandler = makeEventHandler(() => setError(""));

  const setFieldErrors = (field: string, message: string | null) => {
    const newFieldErrors: any = {
      [field]: message,
    };
    setFieldErrorsInternal(newFieldErrors);
  };

  const onChangeName = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.Name, null);
    setName(value);
  });
  const onChangeEmail = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.Email, null);
    setEmail(value);
  });
  const onChangePhoneNumber = eventHandler((value: string) => {
    const phone = value?.replace(new RegExp(' ',"g"),'')
    const newPhone = phone?.replace(/^0+/, '')
    setFieldErrors(ErrorKeyEnum.PhoneNumber, null);
    setPhoneNumber(newPhone);
  });
  const onChangeDescription = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.Description, null);
    setDescription(value);
  });
  const onChangeMessage = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.Message, null);
    setMessage(value);
  });
  const onChangeRecurringDonationMessage = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.RecurringDonationMessage, null);
    setRecurringDonationMessage(value);
  });
  const onChangeAddress = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.Address, null);
    setAddress(value);
  });
  const onChangeCity = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.City, null);
    setCity(value);
  });
  const onChangeZip = eventHandler((value: string) => {
    const zipValue = /^[0-9\b]+$/;
    if (value.match(zipValue) || value === "") {
      setZip(value);
    }
    setFieldErrors(ErrorKeyEnum.Zip, null);
  });
  const onChangeLogoUrl = (value: string) => {
    setFieldErrors(ErrorKeyEnum.LogoUrl, null);
    setLogoUrl(value);
  };
  const onChangePosterUrl = (value: string) => {
    setFieldErrors(ErrorKeyEnum.LogoUrl, null);
    setPosterUrl(value);
  };
  const onChangeWebsiteUrl = eventHandler((value: string) => {
    setFieldErrors(ErrorKeyEnum.WebsiteUrl, null);
    setWebsiteUrl(value);
  });
  const onChangeOrganizationStub = eventHandler((value: string) => {
    const regex = /^[a-z0-9]{0,20}$/;
    const isValid = regex.test(value);
    if (isValid) {
      setOrganizationStub(value);
    }
  });

  //enum to array conversion
  const statesArray = [];
  for (const [propertyKey, propertyValue] of Object.entries(IUSstates)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    statesArray.push({ label: propertyValue, value: propertyKey });
  }

  /* GraphQL */
  const { data, loading } = useQuery(GET_USER, {
    onCompleted: (data) => {
      const organization: IOrganization = data?.user?.organization;
      if (organization) {
        console.log(organization);
        setOrganizationId(organization._id || "")
        setName(organization.name || "");
        setEmail(organization.email || "");
        setPhoneNumber(organization.phoneNumber || "");
        setDescription(organization.description || "");
        setMessage(organization.message || "");
        setRecurringDonationMessage(
          organization.recurringDonationMessage || ""
        );
        setCharLimit(organization?.recurringDonationMessage?.length || 0);
        setAddress(organization?.address || "");
        setCity(organization?.city || "");
        setState(organization?.state || "");
        setZip(organization?.zip || "");
        setWebsiteUrl(organization?.websiteUrl || "");
        setLogoUrl(organization.logoUrl || "");
        setPosterUrl(organization.posterUrl || "");
        setOrganizationStub(organization.organizationStub || "");
        setLoaded(true);
      }
    }
  })

  const [updateOrganizationMutation, { loading: updateLoading }] = useMutation(
    UPDATE_ORGANIZATION,
    {
      variables: {
        organization: {
          name,
          email,
          phoneNumber,
          description,
          message,
          recurringDonationMessage,
          address,
          city,
          state,
          zip,
          websiteUrl,
          logoUrl,
          posterUrl,
          organizationStub
        },
      },
      refetchQueries: [{ query: GET_USER }],
      awaitRefetchQueries: true,
      onCompleted: async () => {
        toast.success(successMessages.UPDATE_ORGANIZATION);
        setError("");
        dispatch(UserActions.getUser());
      },
      onError: async (error: any) => {
        const errorMsg = ErrorUtil.getErrorMessage(error);
        setError(errorMsg);
        if(error){
        toast.error(errorMsg);
        }
      },
    }
  );

  const updateOrganization = (event?: React.FormEvent<HTMLFormElement>) => {  
    if (event) {
      event.preventDefault();
    }
    const params = schema.validate({
      name,
      email,
      phoneNumber,
      description,
      message,
      address,
      city,
      state,
      zip,
      websiteUrl,
      logoUrl,
      posterUrl,
    });

    const { error: schemaError } = params;

    if (schemaError) {
      const { field, message } = JSON.parse(schemaError.message);
      setFieldErrors(field, message);
      return;
    }
    setError("");
    toast.remove();
    updateOrganizationMutation();
  };

  const editorRef = useRef(null);

  type selectedStateType = {
    value: string,
    label: string
  }

  const [selectedState, setSelectedState] = useState<selectedStateType | null>(() => {
    const organization: IOrganization = data?.user?.organization;
    const state = organization.state
    if (state) {
      return {
        label: state,
        value: state
      }
    }
    return null
  });

  useEffect(() => {
    setState(selectedState?.value as any);
  }, [selectedState])

  /* Render */
  return (
    <>
     <Toaster
        position="top-right"
        reverseOrder={false}
      />
    <LoaderContainer>
      {!loaded && <Loader size={LoaderSizes.Large} />}
      {loaded && (
        <Content>
          <LabeledInput
            label="Name"
            placeholder="Enter Name"
            value={name}
            type="text"
            onChange={onChangeName}
            error={fieldErrors[ErrorKeyEnum.Name]}
            width="270px"
          />
          <Spacer />
          <ImageUpload
            imageUrl={logoUrl}
            setImageUrl={onChangeLogoUrl}
            label="Logo"
          />
          <Spacer />
          <ImageUpload
            imageUrl={posterUrl}
            setImageUrl={onChangePosterUrl}
            label="Poster Image"
          />
          <Spacer />
          <TextArea
            label="First Page Donation Message"
            placeholder="Enter a short message to show your donors on the first page of the donation modal"
            value={message}
            width="670px"
            onChange={onChangeMessage}
            error={fieldErrors[ErrorKeyEnum.Message]}
          />
          <Spacer />
          <TextArea
            label={`Recurring Donation Message (${charLimit}/150 characters used)`}
            width="670px"
            placeholder="Enter a short message that helps convince donors to sign up for automatic monthly donations"
            value={recurringDonationMessage}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              if (e.currentTarget.value.length <= 150) {
                setCharLimit(e.currentTarget.value.length);
                onChangeRecurringDonationMessage(e);
              }
            }}
            error={fieldErrors[ErrorKeyEnum.RecurringDonationMessage]}
          />
          <Spacer />
          <LabeledInput
            label="Email Address"
            placeholder="Enter Email Address"
            value={email}
            type="text"
            onChange={onChangeEmail}
            error={fieldErrors[ErrorKeyEnum.Email]}
            width="270px"
          />
          <Spacer />
          <Label text="Phone Number" ></Label>
          <PhoneInput
            options={{ phone: true, phoneRegionCode: 'US' }}
            type="tel"
            placeholder="Enter Phone Number"
            value={phoneNumber}
            onChange={onChangePhoneNumber}
            error={fieldErrors[ErrorKeyEnum.PhoneNumber]}
            width="270px"
          />
          <ErrorText margin="0 0 0 0">{fieldErrors[ErrorKeyEnum.PhoneNumber]}</ErrorText>
          <Spacer />
          <LabeledInput
            label="Address"
            placeholder="Enter Physical Address"
            value={address}
            type="text"
            onChange={onChangeAddress}
            error={fieldErrors[ErrorKeyEnum.Address]}
            width="270px"
          />
          <Spacer />
          <LabeledInput
            type="text"
            label="City"
            placeholder="City"
            value={city}
            width="270px"
            onChange={onChangeCity}
            error={fieldErrors[ErrorKeyEnum.City]}
          />
          <Spacer />
          <DropDown>
            <Label text="State" ></Label>
            <StyledSelect
              hasError={fieldErrors[ErrorKeyEnum.State]}
              maxMenuHeight={150}
              className="test"
              value={selectedState}
              onChange={({ value }: any) => {
                setSelectedState({ label: value, value })
              }}
              placeholder="State"
              options={statesArray}
              styles={colourStyles}
              menuPortalTarget={document.body}
            />
            <ErrorText margin="0 0 0 0">{fieldErrors[ErrorKeyEnum.State]}</ErrorText>
          </DropDown>
          <Spacer />
          <LabeledInput
            label="ZIP"
            placeholder="ZIP"
            value={zip}
            width="270px"
            onChange={onChangeZip}
            error={fieldErrors[ErrorKeyEnum.Zip]}
            maxLength={5}
          />
          <Spacer />
          <LabeledInput
            label="Website URL"
            placeholder="Website URL"
            value={websiteUrl}
            width="270px"
            onChange={onChangeWebsiteUrl}
            error={fieldErrors[ErrorKeyEnum.WebsiteUrl]}
          />
          <Spacer />
          <TextArea
            label="Description"
            placeholder="Organization Description"
            value={description}
            onChange={onChangeDescription}
            error={fieldErrors[ErrorKeyEnum.Description]}
            width="670px"
          />
          <Spacer />
          <LabelContainer>
            <Label text="Profile Page Slug" ></Label>
            <p style={{ width: 'min-content', margin: '0' }} data-tip={`The custom slug for your organization's profile page (e.g., https://app.matchstik.org/org/${organizationStub || organizationId})`}>
              <Icon margin={"0 6px 8px"} icon={Icons.infoRegular} color={Colors.Grey1} />
            </p>
          </LabelContainer>
          <LabeledInput
            placeholder="Profile Page Slug"
            value={organizationStub}
            type="text"
            onChange={onChangeOrganizationStub}
            width="270px"
          />
          <Spacer />
          <Button
            type={ButtonTypes.Submit}
            onClick={() => updateOrganization()}
            loading={loading}
            text="Save Settings"
            margin="0"
            width="300px"
          />
          <ReactTooltip
            place="top"
            type="dark"
            effect="solid"
            clickable = {false}
          />
        </Content>
      )}

    </LoaderContainer>
    </>
  );
};

export default SettingsPage;
