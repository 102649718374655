import gql from 'graphql-tag';

const query = gql`
  query User {
    user {
      emailVerifiedAt
      organization {
        _id
        userId
        active
        name
        description
        message
        recurringDonationMessage
        phoneNumber
        email
        address
        city
        state
        zip
        websiteUrl
        logoUrl
        posterUrl
        ein
        headerImageUrl
        footerImageUrl
        signatureImageUrl
        signatureText
        signaturePosition
        presetDonations
        hasActiveSubscription
        needSubscription
        donationReceiptText
        organizationStub
        buttonText
        buttonColor
        fontColor
        donorFees
        supportingOrg{
          organizationName
          orgId
        }
       
      }
    }
  }
`;



export const GET_FULL_USER = gql`
  query User {
    user {
      emailVerifiedAt
      organization {
        _id
        userId
        active
        name
        description
        message
        recurringDonationMessage
        phoneNumber
        email
        address
        city
        state
        zip
        websiteUrl
        stripeConnectId
        logoUrl
        posterUrl
        ein
        headerImageUrl
        footerImageUrl
        signatureImageUrl
        signatureText
        signaturePosition
        stripeConnectId
        presetDonations
        hasActiveSubscription
        needSubscription
        donationReceiptText
        organizationStub
        buttonText
        buttonColor
        fontColor
        donorFees
        supportingOrg{
          organizationName
          orgId
        }
        subscription{
            _id
            subscriptionPlan
            cycleEndAt
            cycleStartAt
            state
        }
      }
    }
  }
`;

export default query;
