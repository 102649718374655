import React from 'react';
import styled from 'styled-components';
import { Title } from '../components/DashboardLayout';
import { DONATION_URL, WEB_UI_URL } from "../env";
import { Colors } from '../styles/Colors';
import { MatchstikState } from '../redux/store';
import { useSelector } from 'react-redux';


const ImmutableTextArea = styled.a`
  height: fit-content;
  width: 360px;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid ${Colors.Grey5};
  // margin: 0 0 15px 0;
  margin-left: auto;
  @media (max-width:991px){
     display:block;
     margin-left:inherit;
  }
  @media (max-width:767px)
  {
    width:calc(100% - 30px);
    word-break:break-word;
  }
`;
const Iframe = styled.div`
  width: 100%;
  height: calc(100vh - 345px);
  iframe{
   width:100%;  
   height: 100%;
   border: 1px solid #f2f2f2;
  }
`;
const OrganizationText = styled.div`
width: 50%;
font-size: 14px;
color: ${Colors.Grey1};
font-weight: 500;
@media (max-width:991px){
  width: 100%;
  font-size: 14px;
  margin: 0 0 12px 0;
}

`;
const Content = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin: 0 0 20px 0;
@media (max-width:991px){
  display:block;
}
`;


type YourPageProps = {};

const YourPage: React.FC<YourPageProps> = () => {
  const user = useSelector((state: MatchstikState) => state.user as any);
  const { _id, organizationStub } = user.organization;
  const organizationLink = organizationStub ? organizationStub : _id

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = `${DONATION_URL}/embed.js`;
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    }
  }, []);
  
  return (
    <>
      <Title>Your Page</Title>
      <Content>
      <OrganizationText>This is how your organization’s public profile page will look. All parts of this screen can be adjusted from the <a href={`${WEB_UI_URL}/dashboard/settings`} > Profile tab on the Settings screen.</a></OrganizationText>
      <ImmutableTextArea target={"_blank"} href={`${WEB_UI_URL}/org/${organizationLink}`}>{`${WEB_UI_URL}/org/${organizationLink}`}</ImmutableTextArea>
      </Content>
      <Iframe><iframe src={`${WEB_UI_URL}/org/${organizationLink}`}></iframe></Iframe>
    </>
  );
};

export default YourPage;
