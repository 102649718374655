import React, { Fragment, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { useQuery, useMutation } from '@apollo/react-hooks';
import {  useSelector, useDispatch } from "react-redux";
import * as AppActions from "../../redux/actions/app.actions";
import Icon, { Icons } from "../../elements/Icon";
import Loader, { LoaderSizes } from "../../elements/Loader";
import { Colors } from "../../styles/Colors";
import { MatchstikState } from "../../redux/store";
import * as Price from "@matchstik/utils/.dist/price";
import * as Polished from 'polished'
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from "./Modal";
import { DonorTypeEnum } from "@matchstik/models/.dist/enums/DonorTypeEnum";
import SEND_DONATION_RECEIPT from '../../graphql/mutations/sendDonationReceipt.mutation';
import GENERATE_DONATION_RECEIPT from "../../graphql/mutations/generateDonationReceipt.mutation";
import GET_DONATION from '../../graphql/queries/donation.query';
import { ModalTypes } from './Modal';
import Flex from "../../elements/Flex";
import toast, { Toaster } from 'react-hot-toast';
import {successMessages} from '../../utils/MessageUtil';

const LoaderContainer = styled.div`
  width: 350px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 350px;
`;

type SectionProps = {
  noMargin?: boolean;
}

const Section = styled.div<SectionProps>`
  margin-top: ${(props) => (props.noMargin ? "15px" : "15px")};
`;

const Label = styled.div`
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${Colors.Grey3};
  margin-bottom: 15px;
`;

const BlueLabel = styled.div`
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${Colors.Blue};
  margin-bottom: 15px;
`;

const ManageMembershipButton = styled.div`
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${Colors.Grey3};
  margin-bottom: 15px;
  transition: all 0.2s;
  cursor: pointer;
  margin-left: 15px;

  &:hover {
    color: ${Colors.Blue};
  }
`;


const Donor = styled.div`
  display: flex;
`;

const DonorIcon = styled.div`
  background-color: ${Colors.Pink};
  height: 54px;
  width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const DonorInfo = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const DonorName = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Grey1};
`;

const DonorValue = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${Colors.Grey3};
`;

const Message = styled.div`
  font-size: 1.4rem;
  color: ${Colors.Grey1};
  line-height: 160%;
`;


const Row = styled.div`
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 15px;
`;

const Key = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${Colors.Grey2};
  margin-right: 20px;
`;

const Value = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Grey1};
`;


const ActionButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${Colors.White};
  transition: all 0.1s;
  padding: 10px 10px 10px 0px;
  border-radius: 2px;
  

  &:hover {
    cursor: pointer;
    background-color: ${Polished.darken(0.025, Colors.White)};
  }
`;

const ActionIcon = styled.div`
  background-color: ${Colors.Pink};
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const ActionText = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Grey2};
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const RefundedText = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Red};
  padding: 10px 10px 10px 0px;
`;

const ActionLink = styled.a`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Grey2};
  margin-left: 10px;
  text-decoration: none;
`;

const Spacer = styled.div`
  height: 20px;
`;


type DonationDetailsModalProps = {}

const DonationDetailsModal: React.FC<DonationDetailsModalProps> = () => {
  /* State */
  const appState = useSelector((state: MatchstikState) => state.app);
  const { donationId } = appState;

  /* Actions */
  const dispatch = useDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  const pushDonationRefundModal = () => dispatch(AppActions.pushModal(ModalTypes.RefundDonation));
  const viewMembershipDetails = (membershipId: string) => {
    dispatch(AppActions.setSelectedMembership({membershipId}));
    dispatch(AppActions.pushModal(ModalTypes.MembershipDetails));
  };

  /* GraphQL */
  const { data, loading } = useQuery(GET_DONATION, {
    variables: {
      donationId,
    },
  });
  const [
    generateReceipt,
    {
      data: generateReceiptData,
      loading: generateReceiptLoading,
      error: generateReceiptError,
    },
  ] = useMutation(GENERATE_DONATION_RECEIPT, {
    variables: {
      donationId,
    },
    onCompleted: (data) => {
      toast.success(successMessages.GENERATE_RECEIPT_SUCCESS);
    }
  });

  const [
    sendReceipt,
    {
      data: sendReceiptData,
      loading: sendReceiptLoading,
      error: sendReceiptError,
    },
  ] = useMutation(SEND_DONATION_RECEIPT, {
    variables: {
      donationId,
    },
    onCompleted: (data) => {
      toast.success(successMessages.SEND_RECEIPT_SUCCESS);
    }
  });

  /** Render */
  return (
    <ModalContainer width="425px">
      <ModalHeader title={`Donation ${donationId}`} close={popModal} />
      {(() => {
        if(loading) return (
          <ModalContent>
            <LoaderContainer>
              <Loader size={LoaderSizes.Large}/>
            </LoaderContainer>
          </ModalContent>
        );

        const donation = data?.donation || {};

        let dateAndTime: string = "Unknown";

        if (donation?.meta?.createdAt) {
          dateAndTime = moment(parseInt(donation?.meta?.createdAt as any)).format(
            "MMM DD, YYYY h:mma"
          );
        }

        const refundedAtFormatted = moment(parseInt(donation.refundedAt as any)).format(
          "MMM DD, YYYY h:mma"
        );

        return (
          <Fragment>
            <Toaster
              position="top-right"
              reverseOrder={false}
            />
            <ModalContent>
              <Container>
                <Section noMargin>
                  <Flex>
                    <BlueLabel>DONOR</BlueLabel>
                    {donation.membershipId && (
                      <ManageMembershipButton
                        onClick={() => viewMembershipDetails(donation.membershipId)}
                      >
                        MANAGE MEMBERSHIP
                      </ManageMembershipButton>
                    )}
                  </Flex>
                  <Donor>
                    <DonorIcon>
                      <Icon
                        icon={
                          donation.donorType === DonorTypeEnum.Individual
                            ? Icons.UserSolid
                            : Icons.UsersSolid
                        }
                        color={Colors.White}
                        size={24}
                      />
                    </DonorIcon>
                    {donation.anonymous ? (
                      <DonorInfo>
                        <DonorName>Anonymous Donor</DonorName>
                      </DonorInfo>
                    ) : (
                      <DonorInfo>
                        <DonorName>{donation.name}</DonorName>
                        <DonorValue>{donation.email}</DonorValue>
                        <DonorValue>
                          {donation.phoneNumber || donation.url}
                        </DonorValue>
                      </DonorInfo>
                    )}
                  </Donor>
                </Section>
                {donation.message && (
                  <Section>
                    <Label>MESSAGE</Label>
                    <Message>{donation.message}</Message>
                  </Section>
                )}
                <Section>
                  <Label>TRANSACTION DETAILS</Label>
                  <Row>
                    <Key>Donation Amount:</Key>
                    <Value>${Price.output(donation?.transactionDetails?.clientAmount || donation?.amount, true)}</Value>
                  </Row>
                  <Row>
                    <Key>Fees Covered:</Key>
                    <Value>{donation.coverFees ? "Yes" : "No"}</Value>
                  </Row>
                  <Row>
                    <Key>Transaction Total:</Key>
                    <Value>${Price.output(donation?.amount?.toString(), true)}</Value>
                  </Row>
                  {donation.type !== "Offline Donation" && <> <Row>
                    <Key>Payment Method:</Key>
                    <Value>
                      {donation.stripeCharge?.brand?.toUpperCase()} *
                      {donation.stripeCharge?.last4}
                    </Value>
                  </Row></>}
                  <Row>
                    <Key>Date/Time:</Key>
                    <Value>{dateAndTime}</Value>
                  </Row>
                  <Row>
                    <Key>Type:</Key>
                    <Value>{donation.type}</Value>
                  </Row>
                  {donation.type !== "Offline Donation" && <Row>
                    <Key>Source:</Key>
                    <Value>Donate Button</Value>
                  </Row>}
                  {donation.membershipId && (
                    <Row>
                      <Key>Membership ID:</Key>
                      <Value>{donation.membershipId}</Value>
                    </Row>
                  )}
                  {donation.refunded && (
                    <Row>
                      <Key>Refunded At:</Key>
                      <Value>{refundedAtFormatted}</Value>
                    </Row>
                  )}
                </Section>
                <Section>
                  <Label>OPTIONS</Label>
                  {!donation.anonymous && (
                    <ActionButton
                      onClick={() =>
                        sendReceiptLoading || sendReceiptData
                          ? null
                          : sendReceipt()
                      }
                    >
                      <ActionIcon>
                        <Icon
                          icon={Icons.ReceiptRegular}
                          color={Colors.White}
                          size={14}
                        />
                      </ActionIcon>
                      {(() => {
                        if (sendReceiptLoading) {
                          return (
                            <ActionText>
                              <Loader size={LoaderSizes.VerySmall} />
                              &nbsp; Sending...
                            </ActionText>
                          );
                        }

                        if (sendReceiptData) {
                          return <ActionText>Receipt Sent!</ActionText>;
                        }

                        return <ActionText>Send Receipt</ActionText>;
                      })()}
                    </ActionButton>
                  )}
                  <ActionButton
                    onClick={() =>
                      generateReceiptLoading || generateReceiptData
                        ? null
                        : generateReceipt()
                    }
                  >
                    <ActionIcon>
                      <Icon
                        icon={Icons.DownloadReport}
                        color={Colors.White}
                        size={14}
                      />
                    </ActionIcon>
                    {(() => {
                      if (generateReceiptLoading) {
                        return (
                          <ActionText>
                            <Loader size={LoaderSizes.VerySmall} />
                            &nbsp; Generating...
                          </ActionText>
                        );
                      }

                      if (generateReceiptData) {
                        return (
                          <ActionLink
                            href={generateReceiptData.generateDonationReceipt}
                            target="_blank"
                          >
                            Success! Click to download
                          </ActionLink>
                        );
                      }

                      return <ActionText>Generate Receipt</ActionText>;
                    })()}
                  </ActionButton>
                  {donation.refunded ? (
                    <RefundedText>This donation has been refunded</RefundedText>
                  ) : (
                    <ActionButton
                      onClick={() => pushDonationRefundModal()}
                    >
                      <ActionIcon>
                        <Icon
                          icon={Icons.BackArrow}
                          color={Colors.White}
                          size={14}
                        />
                      </ActionIcon>
                      <ActionText>Refund Donation</ActionText>
                    </ActionButton>
                  )}
                </Section>
              </Container>
            </ModalContent>
          </Fragment>
        );
      })()}
    </ModalContainer>
  );
};

export default DonationDetailsModal;
