import gql from 'graphql-tag';

const query = gql`
query donations($startDate:String,$endDate:String) {
  donations(startDate: $startDate, endDate: $endDate) {

    _id
    organizationId
    donorType
    email
    name
    phoneNumber
    url
    amount
    coverFees
    message
    anonymous
    stripeChargeId
    refunded
    refundedAt
    type
    membershipId
    transactionDetails {
      totalAmount
      stripeFee
      appFee
      clientAmount
    }
    meta {
      createdAt
    }
    campaignName
  }
}
`;

export default query;
