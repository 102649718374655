import gql from 'graphql-tag';

const mutation = gql`
  mutation UpdateOrganization($organization: OrganizationInput!) {
    updateOrganization(organization: $organization) {
      _id
      userId
      active
      name
      description
      message
      phoneNumber
      email
      address
      city
      state
      zip
      websiteUrl
      ein
      headerImageUrl
      footerImageUrl
      signatureImageUrl
      signatureText
      signaturePosition
      stripeConnectId
      donationReceiptText
      buttonText
      buttonColor
      fontColor
      donorFees
    }
  }
`;

export default mutation;
