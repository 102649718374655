import gql from "graphql-tag";

const mutation = gql`
  mutation createSubscription($subscriptionPlan: String!, $cardToken: String!) {
    createSubscription(
      subscriptionPlan: $subscriptionPlan
      cardToken: $cardToken
    ) {
      _id
    }
  }
`;

export default mutation;
