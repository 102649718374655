import React, { useState } from "react";
import Joi from "@hapi/joi";
import { useHistory } from "react-router-dom";
import Button, { ButtonTypes } from "../elements/Button";
import LabeledInput from "../elements/LabeledInput";
import * as Schema from "../utils/Schema";
import makeEventHandler from "../utils/makeEventHandler";
import AuthLayout, {
  Content,
  Footer,
} from "../components/AuthLayout";
import Label from "../elements/Label";
import { Colors } from "../styles/Colors";
import styled from "styled-components";
import * as AppActions from "../redux/actions/app.actions";
import { useDispatch } from "react-redux";
import { Icons } from "../elements/Icon";

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  @media (max-width:767px){;
    padding: 15px;
  }
`;

const Title = styled.div`
  position: relative;
  font-size: 36px;
  font-weight: 900;
  color: ${Colors.Grey1};
  margin-bottom: 15px;
  @media (max-width:767px){
    font-size:20px;
  }
`;

const Subtitle = styled.div`
  position: relative;
  font-size: 18px;
  font-weight: 400;
  color: ${Colors.Grey1};
  margin-bottom: 15px;
`;

type FlexProps = {
  flex: string;
};

const Flex = styled.div<FlexProps>`
  flex: ${(props) => props.flex};
  display: flex;
  align-items: baseline;
  width: 500px;
  @media (max-width:767px){
    width: 100%;
  }
`;

const schema = Joi.object({
  email: Schema.user
    .email()
    .required()
    .error(([error]) => {
      const message = "Email is invalid";
      return new Error(
        JSON.stringify({
          field: error.path[0],
          message,
        })
      );
    }),
});

type LandingPageProps = {};

const ViewEnum = {
  Landing: "Landing",
  Register: "Register",
};

const LandingPage: React.FC<LandingPageProps> = () => {
  /** Hooks */
  const history = useHistory();
  /** State */
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const [fieldErrors, setFieldErrorsInternal] = useState({
    email: null,
  });

  /** Actions */

  const dispatch = useDispatch();
  const eventHandler = makeEventHandler(() => setError(""));

  const setFieldErrors = (field: string, message: string | null) => {
    const newFieldErrors: any = {
      [field]: message,
    };
    setFieldErrorsInternal(newFieldErrors);
  };

  const onChangeEmail = eventHandler((value: string) => {
    setFieldErrors("email", null);
    setEmail(value);
  });

  /** GraphQL */

  const register = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    const params = schema.validate({
      email,
    });
    const { error: schemaError } = params;
    if (schemaError) {
      const { field, message } = JSON.parse(schemaError.message);
      setFieldErrors(field, message);
      return;
    }
    dispatch(AppActions.setEmailToRegister(email));
    history.push('/register')
  };

  /** Render */
  return (
    <>
      <AuthLayout
        title="Generosity catches on quickly"
        subTitle="Multiply the impact of your charitable donations 
      with each new match."
        onSubmit={register}
      >
        <Content>
          <>
            <Label text="Join our community of donors and non-profits" />
            <Flex flex="1">
              <LabeledInput
                autoFocus
                // label="First Name"
                placeholder="Enter your email address"
                value={email}
                onChange={onChangeEmail}
                error={fieldErrors["email"]}
              />
              <Button
                type={ButtonTypes.Button}
                onClick={register}
                text="Sign Up"
                margin="0 0 0 30px"
                width="190px"
                // variant="Outlined"
                icon={Icons.RightChevron}
              />
            </Flex>
          </>
        </Content>
      </AuthLayout>

      <Footer>
        <Center>
          <div>
            <Title>Start building your philanthropic identity</Title>
            <Subtitle>
              Follow your favorite non-profits, receive updates on their work,
              and track your donations.
            </Subtitle>
          </div>
        </Center>
      </Footer>
    </>
  );
};

export default LandingPage;
