import gql from 'graphql-tag';

const query = gql`
query campaign($organizationId: String!, $campaignId: String!, $endDate:String, $startDate:String, $custom:Boolean,$dateRange: String){
    campaign(organizationId:$organizationId,campaignId:$campaignId,startDate:$startDate,endDate:$endDate,custom:$custom, dateRange:$dateRange){ 
            campaign{
            _id
            name
            goal
            startDate
            endDate
            description
            posterUrl
            meta {
                createdAt
            }
            isDefault
            totalDonationAmount
            donors
            completed
            active
            gallery
            youtubeLink
            vimeoLink
            averageDonation
            totalMatched
            totalMatchers
            activeMatchers
            averageMatch
            matchImpact
            campaignSlug
            isGeneralFundraiser
            }
            data {
                time
                amount
            }
    
      },
    }
`;

export default query;