import gql from 'graphql-tag';

const query = gql`
query ($startDate:String,$endDate:String){
  totalDonations(startDate:$startDate,endDate:$endDate) {
      totalTransactions
      totalDonationAmount
      totalDonors
}
}`

export default query;
