import gql from "graphql-tag";

const mutation = gql`     

mutation updateDonor($params: DonorInput!){
    updateDonor(params: $params){
       visibility
       slug
       email
       photoUrl
       firstName
       lastName
       phoneNumber
       userId
       city
       state
       statement
    }   
}`

export default mutation
