import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import OrganizationTypeEnum from "@matchstik/models/.dist/enums/OrganizationTypeEnum";
import Joi from "@hapi/joi";
import Button, { ButtonTypes } from "../elements/Button";
import LabeledInput from "../elements/LabeledInput";
import Link from "../elements/Link";
import LOGIN from "../graphql/mutations/login.mutation";
import * as Auth from "../utils/Auth";
import * as Schema from "../utils/Schema";
import * as ErrorUtil from "../utils/ErrorUtil";
import makeEventHandler from "../utils/makeEventHandler";
import AuthLayout, {
  Content,
  Row,
  Text,
  Footer,
} from "../components/AuthLayout";
import ErrorText from "../elements/ErrorText";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

const schema = Joi.object({
  email: Schema.user.email().error(([error]) => {
    let message = "";
    if (error.code === "string.empty") {
      message = "Email is required";
    } else {
      message = "Email is invalid";
    }
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),

  password: Schema.user.password().error(([error]) => {
    const message = "Password is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
});

type LoginPageProps = {};

const LoginPage: React.FC<LoginPageProps> = () => {
  /* Hooks */
  const history = useHistory();
  /* State */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [orgType, setOrgtype] = useState({});
  const [verifyError, setVerifyError] = useState("" as any);
  const [fieldErrors, setFieldErrorsInternal] = useState({
    email: null,
    password: null,
  });
  const [item, setDonorData] = useState([]);
  const notVerifed = "401";

  const dispatch = useDispatch();

  /* Actions */
  const eventHandler = makeEventHandler(() => setError(""));

  const setFieldErrors = (field: string, message: string | null) => {
    const newFieldErrors: any = {
      [field]: message,
    };
    setFieldErrorsInternal(newFieldErrors);
  };

  const onChangeEmail = eventHandler((value: string) => {
    setFieldErrors("email", null);
    setEmail(value);
  });

  const onChangePassword = eventHandler((value: string) => {
    setFieldErrors("password", null);
    setPassword(value);
  });

  /* GraphQL */
  const [loginMutation, { loading }] = useMutation(LOGIN, {
    onCompleted: async ({ login }) => {
      await Auth.setToken(login.token);
      if (login?.user?.organizationType === OrganizationTypeEnum.Individual) {
        localStorage.setItem("donorData", login?.donors?.slug);
        history.push({
          pathname: "/donor/" + login?.donors?.slug,
          state: { orgType: login.user?.organizationType },
        });
      } else {
        history.push({
          pathname: "/dashboard/home",
          // pathname :"/dashboard/overview",
          state: { orgType: login.user?.organizationType },
        });
      }
      localStorage.setItem("clientUser", "true");
      localStorage.setItem("userId", login.user?._id);
      localStorage.setItem("donorData", login?.donors?._id);
      localStorage.setItem("donorSlug", login?.donors?.slug);
      localStorage.setItem("profileType", login?.user?.organizationType);
    },
    onError: async (error) => {
      const errorMsg = ErrorUtil.getErrorMessage(error);
      setError(errorMsg);
      setVerifyError(error.graphQLErrors[0]?.extensions?.code);
    },
  });
  if (verifyError === notVerifed) {
    history.push("/verify-email");
  }

  const login = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    const params = schema.validate({
      email,
      password,
    });
    const { error: schemaError } = params;
    if (schemaError) {
      const { field, message } = JSON.parse(schemaError.message);
      setFieldErrors(field, message);
      return;
    }
    setError("");
    if (!loading) {
      loginMutation({
        variables: {
          email,
          password,
        },
      });
    }
  };

  /* Render */
  return (
    <AuthLayout title="Welcome back" onSubmit={login}>
      <Content>
        <Row>
          <LabeledInput
            autoFocus
            label="Email Address"
            placeholder="hello@example.com"
            value={email}
            onChange={onChangeEmail}
            error={fieldErrors["email"]}
          />
        </Row>
        <Row>
          <LabeledInput
            label="Password"
            placeholder="••••••••••••"
            value={password}
            type="password"
            onChange={onChangePassword}
            error={fieldErrors["password"]}
          />
        </Row>
        {error && <ErrorText margin="40px 0">{error}</ErrorText>}
        <Button
          type={ButtonTypes.Submit}
          onClick={() => login()}
          loading={loading}
          text="Login"
          margin="20px 0 0"
        />
      </Content>
      <Footer>
        <Row>
          <Text>Need an account?</Text>&nbsp;
          <Link to="/register">Register</Link>
        </Row>
        <Row>
          <Link to="/forgot-password">Forgot Password</Link>
        </Row>
      </Footer>
    </AuthLayout>
  );
};

export default LoginPage;
