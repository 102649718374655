import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../styles/Colors";
import { useDispatch, useSelector } from "react-redux";
import { MatchstikState } from "../redux/store";
import { useLocation, useParams } from "react-router";
import DonationAndMatches from "../components/modal/DonationAndMatches";
import SupportedCauses from "./SupportedCauses";
import Following from "./Following";
import { useLazyQuery, useMutation } from "@apollo/client";
import * as UserActions from "../redux/actions/user.actions";
import DONOR_DATA from "../graphql/queries/donorProfile.query";
import DonorProfileLayout from "../components/modal/DonorProfileLayout";
import AuthNavigation from "../components/AuthNavigation";
import avatarImg from "../assets/images/logo-icon.png";
import Icon, { Icons } from "../elements/Icon";
import LandingPage from "./Landing.page";
import NotFound from "./NotFound.page";
import { useHistory } from "react-router"
import FOLLOW_UNFOLLOW from "../graphql/mutations/followUnfollow";
import * as Price from "@matchstik/utils/.dist/price";
import * as ErrorUtil from "../utils/ErrorUtil";

export enum PageStateEnum {
  Donations = "Donations & Matches",
  SupportedCauses = "Supported Causes",
  Following = "Following",
}

const ProfileAbout = styled.div`
  display: flex;
  column-gap: 40px;
  box-sizing border-box;
  @media only screen and (max-width: 767px) {
    column-gap: 20px;
  }
`;
const UserProfile = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  z-index: 0;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  -webkit-user-select: none;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    width: 80px;
    height: 80px;
  }
`;
const Profile = styled.img<any>`
  position: absolute;
  // clip-path: circle();
  min-height: 100%;
  min-width: 100%
  height: auto;
  width: auto;

`;
const UserDetails = styled.div`
box-sizing border-box;
`;
const UserName = styled.div`
  font-family: "Nunito Sans", sans-serif;
`;
const Description = styled.div`
  margin-top: 10px;
  max-width: 600px;
  box-sizing border-box;

  color: #333333;
`;
const NameSection = styled.div`
  display: flex;
  column-gap: 80px;
  box-sizing border-box;

  margin-bottom: 10px;
  align-items: flex-start;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
`;
const Name = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
  box-sizing border-box;

`;
const Text = styled.div`
  font-weight: 400;
  font-size: 18px;
  box-sizing border-box;

  line-height: 22px;
  color: #828282;
`;

const AcountDetailSection = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  box-sizing border-box;

  // width: 350px;
  box-sizing: border-box;
`;
const List = styled.div`
  display: flex;
  box-sizing border-box;

  color: #333333;
`;
export const SettingIcon = styled.div`
  height: 48px;
  width: 48px;
  box-sizing border-box;

  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  // margin: 20px 0 0 95% ;
  position: absolute;
  right: 0;
  bottom: 90px;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    bottom: 155px;
  }
`;

const Listitem = styled.div`
  margin-right: 5px;
  font-weight: 700;
  box-sizing border-box;

  @media only screen and (max-width: 767px) {
    margin-right: 0px;

  }
`;
const ListitemText = styled.div`
  color: #828282;
  padding-left: 3px;
  box-sizing border-box;

`;

export const Secondarybtn = styled.button`
  border-radius: 25px;
  color: ${Colors.Pink};
  display: flex;
  align-items: center;
  box-sizing border-box;

  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  padding: 8px 23px;
  transition: all 0.4s;
  cursor: pointer;
  &:hover {
    background: ${Colors.Pink};
    color: ${Colors.White};
  }
`;

export const CircleImage = styled.div<any>`
  // border: 1px solid black;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-repeat: no-repeat;
  box-sizing border-box;

  cursor: pointer;
  background-size: cover;
  background-image: url(${(props) => props.src});
`;
export const Toggleicon = styled.div`
  align-items: center;
  height: 100%;
  cursor: pointer;
  box-sizing border-box;

  display: none;
  @media only screen and (max-width: 767px) {
    display: flex;
  }
`;
export const AlignSettingIcon = styled.div`
  align-items: center;
  height: 100%;
  box-sizing border-box;

  display: flex;
`;

export const ContentContainer = styled.div`
  width: 60%;
  margin: 60px auto 0;
  background-color: ${Colors.White};
  padding-top: 50px;
  box-sizing border-box;

  // z-index: 1;
  box-sizing: border-box;
  @media only screen and (max-width: 991px) {
    width: 100%;
    padding: 20px;
    padding-top: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding: 15px;
    padding-top: 35px;
    
  }
`;
export const HeaderContainer = styled.div`
  padding: 0 40px;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 16px rgba(17, 0, 40, 0.05);
  display: flex;
  height: 70px;
  gap: 10px;
  align-items: center;
  position: fixed;
  box-sizing border-box;

  // width: calc(100% - 80px);
  width: 100%;
  box-sizing: border-box;
  z-index: 99;
  background: #fff;
  background: ${Colors.White};
`;
export const UserLogo = styled.div`
box-sizing border-box;
`;
export const Usernavbar = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  box-sizing border-box;

`;
export const Navitems = styled.div`
  align-items: center;
  display: flex;
  box-sizing border-box;

  box-sizing: border-box;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    height: 100vh;
    background: white;
    position: absolute;
    top: 70px;
    left: -200px;
    align-items: flex-start;
    width: 200px;
  }
`;
export const Navlink = styled.div`
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  box-sizing border-box;

  line-height: 17px;
  padding: 15px;
  align-items: center;
  font-family: "Nunito Sans", sans-serif;
  background: ${Colors.White};
  display: flex;
  box-sizing: border-box;
  @media only screen and (max-width: 767px) {
    width: 200px;
    padding: 15px 25px;
  }
`;
export const TabsLayout = styled.div`
  width: max-content;
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  box-sizing border-box;
  background: #fff;
  box-shadow: 0 0 10px #0000001e;
  padding: 10px 10px 10px 20px;
  border-radius: 25px;

  @media screen and (max-width: 767px) {
    margin:0;
    padding: 5px;
    border:none;
    margin-top:20px;
    }
  
`;
export const TabsList = styled.form<{ active?: boolean }>`
  padding: 5px 20px;
  transition: all 0.2s;
  font-weight: 400;
  color: ${[(props) => (props.active ? Colors.Pink : Colors.Black2)]};
  margin-right: 15px;
  box-sizing border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-right: 1px solid #f2f2f2;
  @media  screen and (max-width: 767px) {
    margin:0;
    padding: 5px;
    border:none;
    font-size:12px;
    }
  

  &:last-child {
    border: none;
  }
  font-weight: ${[(props) => (props.active && "700") || "600"]};
  .react-datetime-picker {
    .react-datetime-picker__wrapper {
      padding: 2px 12px;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
    }
  }
`;
export const TabListItem = styled.div<{ active?: boolean }>`
color: ${[(props) => (props.active ? Colors.Pink : Colors.Black2)]};
  margin: 0;
  &.space {
    margin-right: 15px;
  }
c

`;
export const Actionstabs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
`;
export const DonationImg = styled.div`
  height: 45px;
  width: 45px;
  padding: 10px;
  border: 1px solid #ccc;
  box-sizing border-box;

  border-radius: 50%;
  display: flex;
  align-items: center;
`;
export const Image = styled.img`
  max-width: 100%;
  width: 100%;
`;
export const Donations = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing border-box;
  align-items: center;
`;
export const Donatedlist = styled.div`
box-sizing border-box;
  .bold {
    font-weight: 700;
    font-size: 14px;
    font-family: "Nunito Sans", sans-serif;
    line-height: 17px;
  }
`;
export const Donationsdata = styled.div`
  display: flex;
  gap: 20px;
  box-sizing border-box;
  align-items: center;
`;
export const Donationtab = styled.div`
  margin-bottom: 30px;
  box-sizing border-box;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-family: "Nunito Sans", sans-serif;
    color: #4f4f4f;
    margin-top: 0;

    &.details {
      margin-left: 80px;
      display: flex;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      font-family: "Nunito Sans", sans-serif;
      color: #4f4f4f;
      gap: 5px;
    }
  }
`;
export const Formitem = styled.div`
  display: flex;
  align-items: center;
  box-sizing border-box;
  gap: 30px;
`;
export type DropDownProps = {
  display: string;
};
export const DropdownMenu = styled.div<DropDownProps>`
  position: absolute;
  top: 60px;
  right: 10px;
  min-width: 160px;
  box-sizing border-box;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  display: ${(props) => (props.display === "1" ? "block" : "none")};
`;
export const MenuItem = styled.div`
  display: flex;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  box-sizing border-box;
  line-height: 17px;
  color: ${Colors.Black2};
  :hover {
    color: ${Colors.Pink};
    & svg {
      color: ${Colors.Pink}!important;
    }
  }
`;
export const Formlink = styled.div`
box-sizing border-box;`;
export const Webtab = styled.div`
box-sizing border-box;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
export const Mobiltab = styled.div`
  margin-top: 20px;
  display: none;
  box-sizing border-box;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;

type DonorprofileProps = {
  donorId?: String;
};

const Donorprofile: React.FC<DonorprofileProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { slug } = useParams<any>();
  const historyState: any = history.location.state;
  const userState: any = useSelector((state: MatchstikState) => state.user);
  const getDonor = () => dispatch(UserActions.getDonor());
  const { donor: reduxDonor } = userState;
  const [slugChange, setSlugChange] = useState(false);
  const phone = historyState?.phoneNumber;
  const [firstName, setFirstName] = useState([""]);
  const [statement, setStatement] = useState([""]);
  const [lastName, setLastName] = useState([""]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [visibility, setVisibility] = useState("");
  const [imgUrl, setImgurl] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [donorData, setDonorData] = useState<any>("");
  const [userId, setUserId] = useState("");
  const loginDonorId = localStorage.getItem("donorData");
  const [supportingOrgCount, setSupportingOrgCount] = useState("");
  const [totalFollowers, setTotalFollowers] = useState("");
  const [donor, setDonor] = useState<any>(reduxDonor);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const activeTab = query.get("activeTab");
  const [pageState, setPageState] = React.useState(() => {
    if (activeTab === "banksettings") {
      return PageStateEnum.SupportedCauses;
    } else {
      return PageStateEnum.Donations;
    }
  });

  const [getAllDonorData, { loading: donorLoading }] = useLazyQuery(
    DONOR_DATA,
    {
      variables: {
        slug: slug,
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const { getDonorAndUserById } = data;
        setFirstName(getDonorAndUserById?.users?.firstName);
        setLastName(getDonorAndUserById?.users?.lastName);
        setStatement(getDonorAndUserById?.statement);
        setUserId(getDonorAndUserById?.userId);
        setSupportingOrgCount(getDonorAndUserById?.supportingOrgs?.length);
        setVisibility(getDonorAndUserById?.visibility);
        setTotalFollowers(getDonorAndUserById?.totalFollower);
        setPhoneNumber(getDonorAndUserById.users?.phoneNumber);
        setEmail(getDonorAndUserById?.users?.email);
        setImgurl(getDonorAndUserById?.photoUrl);
        setCity(getDonorAndUserById?.city);
        setState(getDonorAndUserById?.state);
        setDonorData(getDonorAndUserById);
        if (
          getDonorAndUserById.visibility === "Private" &&
          localStorage.donorData !== getDonorAndUserById._id
        ) {
          if (localStorage?.profileType === "Individual") {
            history.push("/donor/" + localStorage?.donorSlug);
          } else {
            history.push("/dashboard/home");
          }
        }
        if (getDonorAndUserById) {
          setDonor(getDonorAndUserById);
        }
      },
      onError: (error) => {
        const errorMsg = ErrorUtil.getErrorMessage(error);
        if (error) {
          setSlugChange(true);
        }
      },
    }
  );

  const [followDonor, { loading: updateLoading }] = useMutation(
    FOLLOW_UNFOLLOW,
    {
      onCompleted: async (data) => {
        const updatedFollowers =
          donorData?.isFollowing === false
            ? Number(totalFollowers) + 1
            : Number(totalFollowers) - 1;
        setTotalFollowers(updatedFollowers.toString());
        setDonorData((prevDonorData: any) => ({
          ...prevDonorData,
          isFollowing: !prevDonorData?.isFollowing,
        }));
        getAllDonorData();
      },
      onError: (error) => {
        const errorMsg = ErrorUtil.getErrorMessage(error);
      },
    }
  );

  const onEditDonor = () => {
    history.push({
      pathname: "/EditUserSettings",
      state: {
        firstName: firstName,
        lastName: lastName,
        statement: statement,
        slug: slug,
        visibility: visibility,
        phoneNumber: phoneNumber,
        email: email,
        imgUrl: imgUrl,
        city: city,
        state: state,
      },
    });
  };

  useEffect(() => {
    setPhoneNumber(phone);
  }, []);

  useEffect(() => {
    if (slug) {
      getDonor();
      getAllDonorData();
    }
  }, [slug]);
  const isMyProfile = donorData?._id === loginDonorId;
  return (
    <>
      <DonorProfileLayout />
      {!slugChange && (
        <ContentContainer>
          {donor && (
            <>
              <ProfileAbout>
                <UserProfile>
                  {imgUrl ? (
                    <Profile src={imgUrl}></Profile>
                  ) : (
                    <Profile src={avatarImg}></Profile>
                  )}
                </UserProfile>
                <UserDetails>
                  <NameSection>
                    <UserName>
                      <Name>
                        {firstName} {lastName}
                      </Name>
                      <Text>
                        {city && state ? city + ", " + state : "----"}{" "}
                      </Text>
                    </UserName>
                    {isMyProfile ? (
                      <Secondarybtn>{visibility}</Secondarybtn>
                    ) : (
                      ""
                    )}
                    {!isMyProfile && Object.keys(donor).length > 0 ? (
                      // && Object.keys(donor).length > 0
                      <>
                        {localStorage.length > 0 ? <>
                          {donorData?.isFollowing ? (
                            <Secondarybtn
                              onClick={() =>
                                followDonor({
                                  variables: {
                                    params: {
                                      followingId: donorData?._id,
                                    },
                                  },
                                })
                              }
                            >
                              {"Unfollow"}
                            </Secondarybtn>
                          ) : (
                            <Secondarybtn
                              onClick={() =>
                                followDonor({
                                  variables: {
                                    params: {
                                      followingId: donorData?._id,
                                    },
                                  },
                                })
                              }
                            >
                              {"Follow"}
                            </Secondarybtn>
                          )}
                        </> : ""}
                      </>
                    ) : null}
                  </NameSection>
                  <Webtab>
                    <AcountDetailSection>
                      <List>
                        <Listitem>
                          ${Price.output(donorData?.raised || 0, true)}
                        </Listitem>
                        <ListitemText>Raised</ListitemText>
                      </List>
                      <List>
                        <Listitem>{supportingOrgCount}</Listitem>
                        <ListitemText>
                          {supportingOrgCount == "1"
                            ? "Cause Supported"
                            : "Causes Supported"}
                        </ListitemText>
                      </List>
                      <List>
                        <Listitem>{totalFollowers}</Listitem>
                        <ListitemText>
                          {totalFollowers == "1" ? "Follower" : "Followers"}
                        </ListitemText>
                      </List>
                    </AcountDetailSection>
                    <Description>{statement}</Description>
                  </Webtab>
                </UserDetails>
              </ProfileAbout>
              <Mobiltab>
                <AcountDetailSection>
                  <List>
                    <Listitem>
                      ${Price.output(donorData?.raised || 0, true)}
                    </Listitem>
                    <ListitemText>Raised</ListitemText>
                  </List>
                  <List>
                    <Listitem>{supportingOrgCount}</Listitem>
                    <ListitemText>
                      {supportingOrgCount == "1"
                        ? "Cause Supported"
                        : "Causes Supported"}
                    </ListitemText>
                  </List>
                  <List>
                    <Listitem>{totalFollowers}</Listitem>
                    <ListitemText>
                      {totalFollowers == "1" ? "Follower" : "Followers"}
                    </ListitemText>
                  </List>
                </AcountDetailSection>
                <Description>{statement}</Description>
              </Mobiltab>

              <div style={{ position: "relative" }}>
                {isMyProfile ? (
                  <SettingIcon>
                    <Icon
                      icon={Icons.Setting}
                      onClick={onEditDonor}
                      size={25}
                      left="11px"
                      top="11px"
                      color={Colors.Grey4}
                    />
                  </SettingIcon>
                ) : null}
              </div>
              <Actionstabs>
                <TabsLayout>
                  <TabsList active={true}>
                    <TabListItem
                      onClick={() => setPageState(PageStateEnum.Donations)}
                      active={pageState === PageStateEnum.Donations}
                    >
                      {" "}
                      Donations & Matches{" "}
                    </TabListItem>
                  </TabsList>
                  <TabsList>
                    <TabListItem
                      onClick={() =>
                        setPageState(PageStateEnum.SupportedCauses)
                      }
                      active={pageState === PageStateEnum.SupportedCauses}
                    >
                      Supported Causes{" "}
                    </TabListItem>
                  </TabsList>
                  <TabsList>
                    <TabListItem
                      onClick={() => setPageState(PageStateEnum.Following)}
                      active={pageState === PageStateEnum.Following}
                    >
                      {" "}
                      Following{" "}
                    </TabListItem>
                  </TabsList>
                </TabsLayout>
              </Actionstabs>
              {(() => {
                switch (pageState) {
                  case PageStateEnum.Donations:
                    return <DonationAndMatches donorId={donor?._id} />;
                  case PageStateEnum.SupportedCauses:
                    return <SupportedCauses donorId={donor?.slug} />;
                  case PageStateEnum.Following:
                    return <Following donorId={donor?._id} donor={donor} />;
                }
              })()}
            </>
            // ) : (
            // <NoData msg="User Not Found"></NoData>
            // <NotFound />
          )}
        </ContentContainer>
      )}
      {/* : <NotFound />} */}
    </>
  );
};

export default Donorprofile;
