import React, { Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as Price from '@matchstik/utils/.dist/price';
import { Colors } from '../styles/Colors';
import Loader, { LoaderSizes } from '../elements/Loader';
import ScrollTable, {
  ScrollTableBody,
  ScrollTableBodyCell,
  ScrollTableBodyRow,
  ScrollTableHeader,
  ScrollTableHeaderCell,
  ScrollTableNoContent,
  ScrollTableSpace,
  ScrollTableRow
} from './ScrollTable';
import IDonation from "@matchstik/models/.dist/interfaces/IDonation";
import { ModalTypes } from './modal/Modal';
import { useDispatch } from "react-redux";
import * as AppActions from "../redux/actions/app.actions";
import Icon, { Icons } from '../elements/Icon'
import LIST_DONATIONS from '../graphql/queries/listDonations.query';
import GENERATE_DONATION_REPORT from '../graphql/mutations/generateDonationReport.mutation';
import ReactTooltip from "react-tooltip";

const Container = styled.div`
  height: fit-content;
  max-height: 100%;
  max-width: 1400px;
  padding: 0 0px 30px;
`;

const RefundText = styled.div`
  color: ${Colors.Red};
  font-size: 1.4rem;
  font-weight: 700;
`;

const NoContentHead = styled.span`
  font-weight: 600;
  font-size: 1.8rem;
  color: ${Colors.Grey1};
  padding: 10px 0px;
  display:block;
  text-align: center;
`;

const NoContentBody = styled.td`
  font-weight: 500;
  font-size: 1.8rem;
  color: ${Colors.Grey3};
  padding: 10px 25px;
  line-height: 1.42857143;
  vertical-align: top;
  display: table-cell;
  text-align: center;
  border-top: 2px solid #F2F2F2;
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.White};
  border-radius: 0px 0px 10px 10px;
  padding: 10px 0px;
`;

const TableHeader: React.FC = () => (
  <ScrollTableHeader>
    <ScrollTableRow>
      <ScrollTableHeaderCell >Donation ID</ScrollTableHeaderCell>
      <ScrollTableHeaderCell >Amount</ScrollTableHeaderCell>
      <ScrollTableHeaderCell >Contributor</ScrollTableHeaderCell>
      <ScrollTableHeaderCell >Campaign</ScrollTableHeaderCell>
      <ScrollTableHeaderCell >Date/Time</ScrollTableHeaderCell>
      <ScrollTableHeaderCell >Type</ScrollTableHeaderCell>
    </ScrollTableRow>
  </ScrollTableHeader>
);

type TableRowsProps = {
  donations: IDonation[];
  searchQuery?: string;
};

const TableRows: React.FC<TableRowsProps> = ({ donations, searchQuery }) => {
  /* Actions */
  const dispatch = useDispatch();

  const viewDonationDetails = (donationId: string) => {
    dispatch(AppActions.setDonationId(donationId));
    dispatch(AppActions.pushModal(ModalTypes.DonationDetails));
  }

  // Filter donation by searchQuery
  // This is not optimal but a quick solution while there is no pagination.
  let filteredDonations = [...donations];
  if (searchQuery) {
    filteredDonations = filteredDonations.filter((donation) => {
      return Boolean(JSON.stringify(donation).toLowerCase().includes(searchQuery.toLowerCase()))
    });
  }

  return (
    <Fragment>
      {filteredDonations.map((donation: IDonation, index: number) => {
        const {
          _id,
          name,
          amount,
          transactionDetails,
          anonymous,
          campaignName,
          meta: { createdAt },
        } = donation;

        let dateAndTime: string = "Unknown";

        if (createdAt) {
          dateAndTime = moment(parseInt(createdAt as any)).format(
            "MMM DD, YYYY h:mma"
          );
        }

        const onClick = () => {
          viewDonationDetails(donation._id as string);
        };

        return (
          <ScrollTableBodyRow
            key={index}
            // height="40px"
            onClick={(e) => onClick()}
          >
            <ScrollTableBodyCell data-label="Donation ID" >{_id}</ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Amount" >
              ${Price.output(transactionDetails?.clientAmount || amount, true)}
            </ScrollTableBodyCell>
            
            <ScrollTableBodyCell data-label="Contributor" >{anonymous ? 'Anonymous Donor' : name}</ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Campaign" >{campaignName}</ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Date/Time" >{dateAndTime}</ScrollTableBodyCell>
            <ScrollTableBodyCell data-label="Type">
              {donation.type}
              {donation.refunded && (
                <RefundText>
                  Refunded
                </RefundText>
              )}
            </ScrollTableBodyCell>
          </ScrollTableBodyRow>
        );
      })}
      <ReactTooltip
        place="top"
        type="dark"
        effect="solid"
        clickable={false}
      />
    </Fragment>
  );
};

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  box-shadow: 0px 4px 16px rgba(17, 0, 40, 0.05);
  border-radius: 10px;
  border: 1px solid ${Colors.Grey6};
  padding: 0 25px;
  margin-bottom: 30px;
  justify-content: space-between;
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SearchInput = styled.input`
  border: 0px;
  outline: 0px;
  font-size: 1.4rem;
  color: ${Colors.Grey2};
  width: 100%;

  ::placeholder {
    color: ${Colors.Grey3};
  }
`;

const Export = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.Grey3};
  font-size: 1.2rem;

  &:hover {
    cursor: pointer;
  }
`;

const NoLink = styled.a`
  text-decoration: none;
`;

type DonationsTableProps = {
  startDate?: string;
  endDate?: string;
  tabsState?: string;
};

const DonationsTable: React.FC<DonationsTableProps> = (props) => {
  
  /** State **/
  const scrollContainer = React.useRef<any>(null);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [showExport, setShowExport] = React.useState(false);
  const [generateReportDetails, setGenerateReportDatails] = React.useState("");
  const tabsState = props?.tabsState !== undefined ? props?.tabsState : "";
  const startDate = props?.startDate !== undefined ? props?.startDate : "";
  const endDate = props?.endDate !== undefined ? props?.endDate : "";

  /** GraphQL **/
  const { data } = useQuery(LIST_DONATIONS, {
    fetchPolicy: "network-only",
    variables: { startDate: startDate, endDate: endDate },
    onCompleted: (data) => { 
      setShowExport(true);
      setGenerateReportDatails("");
      sessionStorage.setItem("tabsState", tabsState);
      sessionStorage.setItem("startDate", startDate);
      sessionStorage.setItem("endDate", endDate);
    },
  });

  const [
    generateDonationReport,{
      data: generateReportData,
      loading: generateReportLoading,
    },
  ] = useMutation(GENERATE_DONATION_REPORT,{
    variables: { startDate: startDate, endDate: endDate },
    refetchQueries: [{ query: LIST_DONATIONS }],
    awaitRefetchQueries: true,
    onCompleted: (data) => {  
      setShowExport(true);
      setGenerateReportDatails(data?.generateDonationReport);
    },
  });

  let donations = data ? data.donations : [];

  /** Render **/
  if (donations?.length <= 0) {
    return (
      <Container>
        <ScrollTable>
          <TableHeader />
          <ScrollTableBody>
            <ScrollTableNoContent>
              <NoContentBody colSpan={5}><NoContentHead>No donations yet</NoContentHead>Donations will show up here as they are placed</NoContentBody>
            </ScrollTableNoContent>
          </ScrollTableBody>
        </ScrollTable>
      </Container>
    );
  }

  return (
    <Fragment>
      {donations?.length > 0 ? (
        <Container>
          <SearchContainer>
            <Search>
              <Icon
                icon={Icons.SearchRegular}
                color={Colors.Grey3}
                size={14}
                margin="0px 7px 0px 0"
              />
              <SearchInput
                placeholder="Search Donations..."
                value={searchQuery}
                type="text"
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  setSearchQuery(e.currentTarget.value);
                }}
              />
            </Search>
            {(() => {
              if (generateReportLoading) {
                return (
                  <Export>
                    <Loader size={LoaderSizes.VerySmall} />
                    Loading...
                  </Export>
                );
              }

              if (generateReportDetails) {
                
                return (
                  <NoLink href={generateReportData.generateDonationReport} target="_blank">
                    <Export>
                      <Icon
                        icon={Icons.Export}
                        color={Colors.Grey3}
                        size={14}
                        margin="0px 7px 0px 0"
                      />
                      Download Report
                    </Export>
                  </NoLink>
                );
              }
              
              if(showExport){
                
              return (
                <Export onClick={() => generateDonationReport()}>
                  <Icon
                    icon={Icons.Export}
                    color={Colors.Grey3}
                    size={14}
                    margin="0px 7px 0px 0"
                  />
                  Export
                </Export>
              );
              }
            })()}
          </SearchContainer>
          <ScrollTable>
            {(loading: boolean) => {
              return (
                <Fragment>
                  <TableHeader />
                  <ScrollTableBody ref={scrollContainer}>
                    <TableRows donations={donations} searchQuery={searchQuery} />
                    {loading && (
                      <LoaderContainer>
                        <Loader size={LoaderSizes.Large} color={Colors.Pink} />
                      </LoaderContainer>
                    )}
                  </ScrollTableBody>
                </Fragment>
              );
            }}
          </ScrollTable>
        </Container>
      ) : (
        <div />
      )}
    </Fragment>
  );
}

export default DonationsTable;