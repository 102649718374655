import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Button, { ButtonTypes } from "../elements/Button";
import LogoSrc from "./../assets/images/logo-dark.svg";
import LogoImage from "./../assets/images/logo.svg";
import { Colors } from "../styles/Colors";
import { Navlink } from "../pages/Donorprofile";
import Link from "../elements/Link";

export const Container = styled.div`
  position: relative;
  height: 70px;
  width: calc(100% - 60px);
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.White};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  @media (max-width: 767px) {
    padding: 15px 30px;
    height: auto;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoDesktop = styled.img`
  position: relative;
  width: 165px;
  height: auto;
  margin-right: 10px;
  top: 0px;
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
  }
`;
export const LogoMobile = styled.img`
  position: relative;
  width: auto;
  height: 30px;
  margin-right: 10px;
  top: 0px;
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`;

// const ExternalLink = styled.a`
//   font-size: 1.4rem;
//   font-weight: 800;
//   color: ${Colors.Grey1};
//   text-decoration: none;
//   margin-left: 30px;
// `;

type AuthNavigationProps = {};

const AuthNavigation: React.FC<AuthNavigationProps> = () => {
  /** Hooks */
  const history = useHistory();
  // const data = localStorage.getItem("donorData")

  /** Render */
  return (
    <Container>
      <Row>
        <LogoDesktop src={LogoSrc} onClick={() => history.push("/")}/>
        <LogoMobile src={LogoImage} onClick={() => history.push("/")} />
        {/* <ExternalLink href="/what">How It Works</ExternalLink>
        <ExternalLink href="/what">Find Fundraisers</ExternalLink>
        <ExternalLink href="/what">Browse Non-Profits</ExternalLink> */}
        {/* {localStorage.length > 0 ? "" : */}
          <><Navlink onClick={() => history.push("/non-profits")}>
            Browse Non-Profits
          </Navlink>
          <Navlink onClick={() => history.push("/be-the-spark")}>
              Be the Spark
            </Navlink></> 
        {/* } */}
      </Row>
      <Row>
        <Link to="/login">Login</Link>
        <Button
          type={ButtonTypes.Button}
          onClick={(event?: React.FormEvent<HTMLFormElement>) => {
            event?.preventDefault();
            history.push("/register");
          }}
          text="Get Started"
          margin="0 0 0 30px"
          width="190px"
        />

      </Row>
    </Container>
  );
};

export default AuthNavigation;
