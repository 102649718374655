import { useState } from "react";
import styled from "styled-components";
import { Colors } from "../styles/Colors";
import Icon, { Icons } from "../elements/Icon";
import campaignsSrc from "../assets/images/campaignsimage.jpg";
import { useQuery } from "@apollo/client";
import GET_LIST_CAMPAIGN from "../graphql/queries/listCampaign.query";
import * as Price from "@matchstik/utils/.dist/price";
import {
  CampaignsLayout, CampaignsDesc, CampaignsImage,
  CampaignsInner, CampaignsHeading, CampaignsSection, LeftPanel, Desc, Amount,
  Donors, RightPanel, CampaignsProgress, CampaignsProgressBar, CampaignsList, CampaignsCard, NoCampaignText
} from "./CampaignsList.page";
import { Matchers } from "./CampaignsList.page";


const LeftSection = styled.div`{
  width:100%
}`;

type OrganizationProfileCampaignPageProps = {
  organizationId: any,
  setActiveCampaign: any,
};


const OrganizationProfileCampaignPage = (props: OrganizationProfileCampaignPageProps) => {

  /** Hooks **/
  const organizationId = props.organizationId
  const [activeCampaignId, setActiveCampaignId] = useState("");
  const [showData, setShowData] = useState(false);


  /** GraphQL **/
  const { data, loading } = useQuery(GET_LIST_CAMPAIGN, {
    variables: {
      organizationId
    },
    // fetchPolicy: "network-only",
    onCompleted: (data) => {
      setShowData(true);
    },
  });
  const campaignDetail = data?.listCampaign || []
  const filteredData = campaignDetail?.filter((campaign: any) => campaign.active);

  /** Actions **/
  const selectedCampaign = (campaign: any) => {
    setActiveCampaignId(campaign._id);
    props.setActiveCampaign(campaign._id);
  };

  return (
    <>
      <CampaignsLayout>
        <LeftSection>
          {filteredData?.length > 0 ? 
            <CampaignsCard>
              {filteredData?.map((campaign: any , i:number) => {
                let totalDonationAmount = campaign.totalDonationAmount === null ? "" : campaign.totalDonationAmount;
                return (
                  <CampaignsList key={i} active={campaign._id === activeCampaignId} onClick={() => selectedCampaign(campaign)} >
                    <CampaignsImage posterUrl={campaign.posterUrl ? campaign.posterUrl : campaignsSrc} />
                    <CampaignsDesc>
                      <CampaignsInner>
                        <CampaignsHeading>
                          {campaign.name}
                        </CampaignsHeading>
                        {campaign.completed !== -1 && (
                          <CampaignsProgress>
                            <CampaignsProgressBar
                              progress={campaign.completed + "%"}
                            ></CampaignsProgressBar>
                          </CampaignsProgress>
                        )}
                        <CampaignsSection>
                          <LeftPanel>
                            <Amount>
                              ${Price.output(totalDonationAmount, true)}{" "}
                            </Amount>
                            {campaign.goal !== null &&
                              campaign.goal !== "" && (
                                <Desc>
                                  raised of ${Price.output(campaign.goal, true)}{" "}
                                  goal
                                </Desc>
                              )}
                          </LeftPanel>
                          <RightPanel>
                            {campaign.totalMatchers ?
                              <Matchers><Icon icon={Icons.FireRegular} size={14} color={Colors.Grey2} margin='0px 6px 0px 0px' />{campaign.totalMatchers}x Multiplier</Matchers> : ""}
                            <Donors>
                              <Icon
                                icon={Icons.HandHoldingHeart}
                                size={14}
                                color={Colors.Grey2}
                                margin="0px 6px 0px 0px"
                              />
                              {campaign.donors} donors
                            </Donors>
                          </RightPanel>
                        </CampaignsSection>
                      </CampaignsInner>
                    </CampaignsDesc>
                  </CampaignsList>
                );
              }
              )}
            </CampaignsCard>
           : filteredData?.length === 0 && showData ?
            <NoCampaignText>No Campaigns Found </NoCampaignText> : loading
          }
        </LeftSection>
        {/* <RightSection>
          <InnerSection>
            <DonateButton>
                  <div dangerouslySetInnerHTML={{ __html: HTML }} />
                </DonateButton>
            <DonateInfo>
              <DonateTitle>Your donation will be multiplied</DonateTitle>
              <DonateValue>22x</DonateValue>
              <DonateDesc>Thanks to generous matchers Mystery Ranch, Kenyon Noble, Wisetail and 19 others.</DonateDesc>
            </DonateInfo>
          </InnerSection>
        </RightSection> */}
      </CampaignsLayout>
    </>
  );
};

export default OrganizationProfileCampaignPage;
