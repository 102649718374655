import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "@apollo/react-hooks";
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import { Colors } from "../styles/Colors";
import * as UserActions from "../redux/actions/user.actions";
import Icon, { Icons } from "../elements/Icon";
import ICreateDonationParams from "@matchstik/models/.dist/interfaces/ICreateDonationParams";
import * as Polished from "polished";
import CREATE_SUBSCRIPTION from "../graphql/mutations/createSubscription.mutation";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { StripeElementStyle } from "@stripe/stripe-js";
import Button, { ButtonStates, ButtonTypes } from "../elements/Button";
import * as StripeService from "../utils/StripeService";
import ConfirmSubscription from "./SubscriptionConfirmation";
import moment from "moment";
import { organization } from "../utils/Schema";
import GET_BILLING_INFO from "../graphql/queries/getBillingInfo";
import Loader, { LoaderSizes } from "../elements/Loader";
import {SubscriptionPlans,SubscriptionStates,SubscriptionAmounts,SubscriptionDiscounts} from '@matchstik/models/.dist/enums/SubscriptionEnums'
import { GuestSettingsContext } from "../pages/GuestSettings.page";

export enum PlanTypeEnum {
  Monthly = "Monthly",
  Yearly = "Yearly",
  NotSelected = "",
}

export const TRIAL_DAYS = 30;

const Container = styled.div`
  position: relative;
  background-color: ${Colors.White};
  // border-radius: 15px;
  overflow: auto;
  // padding-bottom: 200px;
`;

const Form = styled.form`
  /* display: flex; */
  width: 400px;
  @media (max-width:767px){
   width:100%;
  }
  .Element {
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    text-align: left;
    line-height: 1.21428571em;
    background: #fff;
    border-radius: 10px;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    box-shadow: none;
    font-size: 14px;
    margin-top: 5px;
    padding: 1.4rem;
    padding-left: 3.7rem;
    height: 17px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  flex: 1;
  justify-content: center;
  margin: 30px 0;
`;

const Spacer = styled.div`
  flex: 0.075;
`;

type ElementContainerProps = {
  focused: boolean;
};

const ElementContainer = styled.div<ElementContainerProps>`
  position: relative;
  flex: 1;
  .Element {
    border: 1px solid
      ${(props) => {
        if (props.focused) return Colors.Grey4;
        // if (props.hovered) return Polished.darken(0.05, Colors.Grey5);
        return Colors.Grey5;
      }};

    &:hover {
      border: 1px solid
        ${(props) => {
          if (props.focused) return Colors.Grey4;
          return Polished.darken(0.05, Colors.Grey5);
        }};
    }
  }
`;

const Content = styled.div`
  // margin: 15px 0 0;
  // padding: 0 30px;
  display: flex;
  justify-content: space-between;
  @media (max-width:767px){
   display:block;
  }
`;

const Title = styled.p`
  font-size: 12px;
  font-weight: 700;
  color: ${Colors.DarkBlue};
  margin-bottom: 0px;
`;

const Heading = styled.h5`
  font-size: 14px;
  font-weight: 400;
  color: ${Colors.Grey2};
  margin-bottom: 0px;
  margin-top:0px;
`;

const SubHeading = styled.h5`
  font-size: 14px;
  font-weight: 700;
  color: ${Colors.Grey2};
  margin-bottom: 0px;
`;

type SettingsBillingProps = {};

const CardIconMap: Record<string, React.ReactNode> = {
  Visa: (
    <Icon
      icon={Icons.UpArrow}
      size={14}
      margin="0 7px 0 0"
      color={Colors.Grey1}
    />
    // <Icon icon={Icons.Visa} size={14} margin="0 7px 0 0" color={Colors.Grey1} />
  ),
  MasterCard: (
    <Icon
      icon={Icons.UpArrow}
      // icon={Icons.Mastercard}
      size={14}
      margin="0 7px 0 0"
      color={Colors.Grey1}
    />
  ),
  Discover: (
    <Icon
      icon={Icons.UpArrow}
      // icon={Icons.Discover}
      size={14}
      margin="0 7px 0 0"
      color={Colors.Grey1}
    />
  ),
  AmericanExpress: (
    <Icon
      icon={Icons.UpArrow}
      size={14}
      margin="0 7px 0 0"
      color={Colors.Grey1}
    />
    // <Icon icon={Icons.Amex} size={14} margin="0 7px 0 0" color={Colors.Grey1} />
  ),
};

enum ElementsEnum {
  CardNumber = "CardNumber",
  CVC = "CVC",
  ExpDate = "ExpDate",
}

const SettingsBilling: React.FC<SettingsBillingProps> = ({}) => {
  const stripe = useStripe();
  const elements = useElements();
  const organization = useSelector((state: any) => state.user.organization);

  const [showBuySubscription, setShowBuySubscription] = useState(false)

  /** State **/
  const [focused, setFocus] = React.useState<ElementsEnum | null>(null);
  const [planType, setPlanType] = React.useState<PlanTypeEnum>(
    PlanTypeEnum.NotSelected
  );


  /** Actions **/

  const onButtonClick = (clickedButton: any) => {
    switch (clickedButton) {
      case PlanTypeEnum.Monthly: {
        setPlanType(PlanTypeEnum.Monthly);
        break;
      }
      case PlanTypeEnum.Yearly: {
        setPlanType(PlanTypeEnum.Yearly);
        break;
      }

      default: {
      }
    }
  };

  /** Render **/
  const style: StripeElementStyle = {
    base: {
      fontSize: "14px",
      fontWeight: "500",
      // height: "40px",
      color: Colors.Grey1,
      fontSmoothing: "antialiased",
      fontFamily: "neue-haas-grotesk-display",
      "::placeholder": {
        color: Colors.Grey4,
      },
      ":focus": {},
    },
    invalid: {
      color: Colors.Red,
      fontWeight: "550",
    },
  };

  const isFocused = (field: ElementsEnum) => field === focused;
  const iconColor = (field: ElementsEnum) =>
    isFocused(field) ? Colors.Grey3 : Colors.Grey4;

  useEffect(() => {
    if (stripe && elements) {
      StripeService.setStripe(stripe, elements);
    }
  }, [stripe, elements]);

  const {hasBillingInfo,setHasBillingInfo} = useContext(GuestSettingsContext)

  return (
    <>
      <Container>
        <Content>
          {organization.subscription||hasBillingInfo ? (
            <BillingInfo />
          ) : (
            <>
              <Form>
              <Heading>
                Your first {TRIAL_DAYS} days are Free! Billing will occur monthly
                after sign up at $49.99/Month
              </Heading>
              {planType !== PlanTypeEnum.NotSelected && (
                <>
                  {planType === PlanTypeEnum.Monthly ? (
                    <SubHeading>Save 15% By paying Annually</SubHeading>
                  ) : (
                    <SubHeading>
                      You are saving 15% By paying Annually
                    </SubHeading>
                  )}
                </>
              )}

              <Title>Payment Method</Title>
                <Row>
                  <ElementContainer
                    focused={isFocused(ElementsEnum.CardNumber)}
                  >
                    <Icon
                      icon={Icons.CreditCardFront}
                      color={iconColor(ElementsEnum.CardNumber)}
                      size={14}
                      position="absolute"
                      top="20px"
                      left="15px"
                    />
                    <CardNumberElement
                      className="Element"
                      options={{ style, placeholder: "Card Number" }}
                      onFocus={() => setFocus(ElementsEnum.CardNumber)}
                      onBlur={() => setFocus(null)}
                    />
                  </ElementContainer>
                </Row>
                <Row>
                  <ElementContainer focused={isFocused(ElementsEnum.CVC)}>
                    <Icon
                      icon={Icons.CalendarDayLight}
                      color={iconColor(ElementsEnum.CVC)}
                      size={14}
                      position="absolute"
                      top="20px"
                      left="17px"
                    />
                    <CardCvcElement
                      className="Element"
                      options={{ style, placeholder: "CVC" }}
                      onFocus={() => setFocus(ElementsEnum.CVC)}
                      onBlur={() => setFocus(null)}
                    />
                  </ElementContainer>
                  <Spacer />
                  <ElementContainer focused={isFocused(ElementsEnum.ExpDate)}>
                    <Icon
                      icon={Icons.CreditCardBack}
                      color={iconColor(ElementsEnum.ExpDate)}
                      size={14}
                      position="absolute"
                      top="20px"
                      left="15px"
                    />
                    <CardExpiryElement
                      className="Element"
                      options={{ style, placeholder: "Exp. Date" }}
                      onFocus={() => setFocus(ElementsEnum.ExpDate)}
                      onBlur={() => setFocus(null)}
                    />
                  </ElementContainer>
                </Row>
                <Row>
                  <Button
                    state={
                      planType === PlanTypeEnum.Monthly
                        ? ButtonStates.Active
                        : ButtonStates.Inactive
                    }
                    type="button"
                    onClick={() => onButtonClick(PlanTypeEnum.Monthly)}
                    text="Monthly"
                    margin="0 0 0 0"
                    width="190px"
                    // variant="Outlined"
                  />
                  <Button
                    type="button"
                    state={
                      planType === PlanTypeEnum.Yearly
                        ? ButtonStates.Active
                        : ButtonStates.Inactive
                    }
                    onClick={() => onButtonClick(PlanTypeEnum.Yearly)}
                    text="Annual"
                    margin="0 0 0 30px"
                    width="190px"
                    // variant="Outlined"
                  />
                </Row>
              </Form>
              {planType !== PlanTypeEnum.NotSelected &&(
          <ConfirmSubscription planType={planType} />
        )}
            </>
          )}
        </Content>
        
      </Container>
   
    </>
  );
};

const BillingContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: inherit;
  width: 400px;
`;

const Text = styled.div`
  font-size: 1.2rem;
  color: ${Colors.Grey3};
  margin-bottom: 10px;
`;

const TextLink = styled.div`
  text-decoration: underline;
  font-size: 1.2rem;
  color: ${Colors.Pink};
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.2s;
  width: fit-content;

  &:hover {
    text-decoration: none;
  }
`;

const PaymentText = styled.div`
  color: ${Colors.Grey1};
  font-style: italic;
  font-size: 1.4rem;
  margin-bottom: 10px;
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.White};
  border-radius: 0px 0px 10px 10px;
  padding: 10px 0px;
  height: 300px;
`;

const BlocksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BillingInfo = () => {
  const { data, loading } = useQuery(GET_BILLING_INFO);

  const cardDetails = data?.getPaymentInfo?.paymentInfo;

  const subscriptionDetails = data?.getPaymentInfo?.subscription;

  return (
    <BillingContainer>
      {loading ? (
        <>
          <LoaderContainer>
            <Loader size={LoaderSizes.Large} color={Colors.Pink} />
          </LoaderContainer>
        </>
      ) : (
        <BlocksContainer>
          <InfoBlock>
            <Text>CURRENT PAYMENT METHOD</Text>
            <PaymentText>
              {`${cardDetails?.brand} ${cardDetails?.last4} - Exp. ${cardDetails?.expMonth}/${cardDetails?.expYear}`}
            </PaymentText>
            {/* <TextLink onClick={() => {}}>Change</TextLink> */}
          </InfoBlock>

          <InfoBlock>
            <Text>CURRENT SUBSCRIPTION PLAN</Text>

            <PaymentText>{subscriptionDetails?.subscriptionPlan}</PaymentText>
          </InfoBlock>

          <InfoBlock>
            <Text>NEXT DUE</Text>

            <PaymentText>
              {moment
                .unix(subscriptionDetails?.cycleEndAt)
                .format("DD MMM YYYY hh:mm a")}
            </PaymentText>
          </InfoBlock>
        </BlocksContainer>
      )}
    </BillingContainer>
  );
};

export default SettingsBilling;
