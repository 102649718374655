import gql from "graphql-tag";

const query = gql`
query getPaymentInfo{
    getPaymentInfo {
        subscription{
        _id
        state
        subscriptionPlan
        cycleEndAt
        cycleStartAt
       }
       paymentInfo{
         brand
         last4,
         expMonth
         expYear
       }
       }
    }
  
`;
export default query;
