import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Joi from "@hapi/joi";
import * as Schema from "../utils/Schema";
import { Colors } from "../styles/Colors";
import { useLocation } from "react-router";
import * as UserActions from "../redux/actions/user.actions";
import UpdateDonorProfile from "../components/UpdateDonorProfile";
import { useDispatch, useSelector } from "react-redux";
import { MatchstikState } from "../redux/store";
import DonorProfileLayout from "../components/modal/DonorProfileLayout";
export enum PageStateEnum {
  Profile = "Profile",
  PaymentMethods = "Payment Methods",
  RecuringDonations = "Recuring Donations",
  Receipts = "Receipts",
}

const schema = Joi.object({
  name: Schema.organization.name().error(([error]) => {
    const message = "Organization name is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  email: Schema.organization.email().error(([error]) => {
    const message = "Organization email is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  phoneNumber: Schema.organization.phoneNumber().error(([error]) => {
    const message = "Organiztion phone number is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  description: Schema.organization.description().error(([error]) => {
    const message = "Organization description is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  message: Schema.organization.message().error(([error]) => {
    const message = "Organization message is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  recurringDonationMessage: Schema.organization.message().error(([error]) => {
    const message = "Recurring donation message is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  address: Schema.organization.address().error(([error]) => {
    const message = "Organization address is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  city: Schema.organization.city().error(([error]) => {
    const message = "Organization city is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  zip: Schema.organization.zip().error(([error]) => {
    const message = "Organization zip is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  state: Schema.organization.state().error(([error]) => {
    const message = "Organization state is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  websiteUrl: Schema.organization.websiteUrl().error(([error]) => {
    const message = "Organization websiteUrl is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  logoUrl: Schema.organization.logoUrl().error(([error]) => {
    const message = "Organization logo is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  posterUrl: Schema.organization.posterUrl().error(([error]) => {
    const message = "Organization poster image is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
});

const UserProfileConatiner = styled.div`
  // width: 70vw;
  // padding: 20px 30px;
  // margin: 90px 40px 0;
  // box-shadow: 0 0 10px #00000014;
  margin:0 60px;
  padding-bottom:20px;
  @media screen and (max-width: 767px) {
    margin:0 20px;
  }
`;
const UserProfile = styled.div<any>`
  // border: 1px solid black;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: cover;
  background-image: url(${(props) => props.src});
`;

const SubNav = styled.div`
  display: flex;
  margin: -10px 0px 30px;
  z-index: 1;
`;

const SubNavItem = styled.div<{ active: boolean }>`
  font-weight: 700;
  size: 1.8rem;
  transition: all 0.2s;
  color: ${[(props) => (props.active ? Colors.Blue : Colors.Grey4)]};
  margin-right: 20px;
  cursor: pointer;
  &:hover {
    color: ${Colors.Blue};
  }
  @media (max-width: 576px) {
    margin-right: 0px;
    padding: 10px 0;
  }
`;


interface UserSettingsProps { }

const UserSettings: React.FC<UserSettingsProps> = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const dispatch = useDispatch();
  const activeTab = query.get("activeTab");
  const [pageState, setPageState] = React.useState(() => {
    if (activeTab === "Receipts") {
      return PageStateEnum.Receipts;
    } else if (activeTab === "Payment Methods") {
      return PageStateEnum.PaymentMethods;
    } else if (activeTab === "Recuring Donations") {
      return PageStateEnum.RecuringDonations;
    } else {
      return PageStateEnum.Profile;
    }
  });
  const getDonor = () => dispatch(UserActions.getDonor());
  const userState: any = useSelector((state: MatchstikState) => state.user);
    useEffect(() => {
    getDonor()
  }, [])
  

  return (
    <>
      <DonorProfileLayout />
      <UserProfileConatiner>
        <h2>Your Settings</h2>
        <br />

        <SubNav>
          {/* 
          <SubNavItem
            onClick={() => setPageState(PageStateEnum.Profile)}
            active={pageState === PageStateEnum.Profile}
          >
            {PageStateEnum.Profile}
          </SubNavItem>
          <SubNavItem
            onClick={() => setPageState(PageStateEnum.PaymentMethods)}
            active={pageState === PageStateEnum.PaymentMethods}
          >
            {PageStateEnum.PaymentMethods}
          </SubNavItem>
          <SubNavItem
            onClick={() => setPageState(PageStateEnum.RecuringDonations)}
            active={pageState === PageStateEnum.RecuringDonations}
          >
            {PageStateEnum.RecuringDonations}
          </SubNavItem>
          <SubNavItem
            onClick={() => setPageState(PageStateEnum.Receipts)}
            active={pageState === PageStateEnum.Receipts}
          >
            {PageStateEnum.Receipts}
          </SubNavItem>
          */}
        </SubNav>

        <UpdateDonorProfile />
      </UserProfileConatiner>
    </>
  );
};
export default UserSettings;
