import React, { createContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import SettingsBilling from "../components/SettingsBilling";
import { Colors } from "../styles/Colors";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as AppActions from "../redux/actions/app.actions";
import { ModalTypes } from "../components/modal/Modal";
import { MatchstikState } from "../redux/store";
import url from "url";

export enum PageStateEnum {
  BankSettings = "Bank Settings",

  BillingSettings = "Billing Information",
}

export const GuestSettingsContext = createContext(null as any);

const SubNav = styled.div`
  display: flex;
  margin: 10px 0px 30px;
  z-index: 1;
`;

const SubNavItem = styled.div<{ active: boolean }>`
  font-weight: 700;
  size: 1.8rem;
  transition: all 0.2s;
  color: ${[(props) => (props.active ? Colors.Blue : Colors.Grey4)]};
  margin-right: 20px;

  &:hover {
    color: ${Colors.Blue};
  }
`;

type SettingsPageProps = {};

function useQuery() {
  return new URLSearchParams(useLocation().search) as any;
}

const GuestSettings: React.FC<SettingsPageProps> = () => {
  const dispatch = useDispatch();

  const parsed = url.parse(window.location.toString(), true);
  const query = useQuery();
  const connectCode = parsed?.query?.code;
  console.log("STRIPE connectCode: ", connectCode);

  const code = query?.code;

  console.log("STRIPE code ", code, parsed.query.modalType);

  const [hasBillingInfo, setHasBillingInfo] = useState(false);

  const contextValue = useMemo(
    () => ({ hasBillingInfo, setHasBillingInfo }),
    [hasBillingInfo, setHasBillingInfo]
  );

  const user = useSelector((state: MatchstikState) => state.user as any);

  const { hasActiveSubscription, stripeConnectId, loading } = user.organization;

  const [pageState, setPageState] = React.useState(() => {
    if (hasActiveSubscription) {
      return PageStateEnum.BankSettings;
    } else {
      return PageStateEnum.BillingSettings;
    }
  });

  useEffect(() => {
    if (!loading) {
      if (!hasActiveSubscription && !hasBillingInfo) {
        dispatch(AppActions.pushModal(ModalTypes.FirstLogin));
      } else if (!stripeConnectId || hasBillingInfo) {
        dispatch(AppActions.pushModal(ModalTypes.ConnectStripe));
      }
    }
  }, [loading, hasBillingInfo]);

  return (
    <GuestSettingsContext.Provider value={contextValue as any}>
      <>
        <SubNav>
          {pageState === PageStateEnum.BillingSettings && !hasBillingInfo && (
            <SubNavItem
              onClick={() => setPageState(PageStateEnum.BillingSettings)}
              active={pageState === PageStateEnum.BillingSettings}
            >
              {PageStateEnum.BillingSettings}
            </SubNavItem>
          )}
        </SubNav>
        {!hasBillingInfo && (
          <>
            {(() => {
              switch (pageState) {
                case PageStateEnum.BankSettings:
                  return <></>;
                case PageStateEnum.BillingSettings:
                  return <SettingsBilling />;
                default:
                  return <SettingsBilling />;
              }
            })()}
          </>
        )}
      </>
    </GuestSettingsContext.Provider>
  );
};

export default GuestSettings;
