import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../styles/Colors";
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useLazyQuery } from "@apollo/client";
import DonorProfileLayout from "./DonorProfileLayout";
import ORGANIZATION_LIST_DATA from "../../graphql/queries/getNonProfitOrganizationsList.query";
import ORG_LIST_FOLLOW_UNFOLLOW from "../../graphql/mutations/nonProfitOrgsFollow.mutation";
import OrganizationTypeEnum from "@matchstik/models/.dist/enums/OrganizationTypeEnum";
import { ContentContainer } from "../../pages/Donorprofile";
import {
    CardDesc,
    CardList,
    CardPrice,
    CardProgressValue,
} from "../../pages/CampaignsList.page";
import userAvatar from "../../assets/images/logo-icon.png"
import { useHistory } from "react-router";


const Storycontent = styled.div<any>`
display: grid;
grid-template-columns: repeat(2, 1fr);
  width: 100%;
  box-sizing: border-box;
  margin-top:20px;
  gap:40px;
  @media only screen and (max-width: 767px) {
    display: flex !important;
    margin-top:10px;
    flex-direction: column; 
  gap:5px;

      }
`;

const Heading = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const Profittabs = styled.div`
  padding: 20px 0px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  gap: 20px;
`;
export const FollowButton = styled.button`
  border-radius: 25px;
  background: ${Colors.Pink};
  color: ${Colors.White};
  border:1px solid ${Colors.Pink} ;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  box-sizing: border-box;
//   border: none;
  padding: 8px 23px;
  transition: all 0.4s;
  width:100px;
  cursor: pointer;
  text-align:center;
  &:hover {
    background: ${Colors.White};
    color: ${Colors.Pink};
    border:1px solid ${Colors.Pink};
   
  }
`;
const CardHeading = styled.div`
     font-weight: 600;
     font-size: 14px;
     cursor:pointer;
     color: ${Colors.Grey1};
 `;
const Text = styled.div`
display: flex;
// column-gap: 20px;
justify-content: space-between;
align-items: center;
width: 100%;
`;
const NonProfitDesc = styled.div`
  white-space: pre-wrap;
`;
const CardImage = styled.img<any>`
 position: absolute;
//   clip-path: circle();
  height: 100%;
  width: auto;
 `;
const OrgImages = styled.div`
width: 80px;
height: 80px;
// min-width: 80px;
// min-height: 80px;
// max-width: 80px;
// max-height: 80px;
border-radius: 50%;
display: flex;
align-items: center;
z-index: 0;
justify-content: center;
border: 1px solid #e0e0e0;
position: relative;
box-sizing: border-box;
flex-shrink: 0;
-webkit-user-select: none;
overflow: hidden;
@media only screen and (max-width: 767px) {
    width: 50px;
    height: 50px;
      }

`;

type OrganizationsListTableProps = {
    organizations: IOrganization[];
};
const OrganizationsListTable: React.FC<OrganizationsListTableProps> = ({ organizations }) => {
    const [orgList, setOrgList] = useState([])
    const history = useHistory();
    const [OrganizationList, { data }] = useLazyQuery(ORGANIZATION_LIST_DATA, {
        variables: {
            organizationType: OrganizationTypeEnum.NonProfit
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setOrgList(data?.listOrgsByType);
        },
    });

    useEffect(() => {
        OrganizationList()
    }, [])

    const handleReadMore = (item: any, index: any) => {
        
        setOrgList((prevOrgs: any) => {
            return prevOrgs.map((org: any) => {
                if (org._id === item._id) {
                    org.readMoreEnabled = !org.readMoreEnabled
                }
                return org
            })
        })
    }

    const [followUnfollowOrg, { loading: updateLoading }] = useMutation(
        ORG_LIST_FOLLOW_UNFOLLOW,
        {
            onCompleted: async () => {
                OrganizationList()
            },
            onError: (error) => {
            }
        }
    );

    const onOrgClick = (item: any) => {
        let slugUpdate = item?.organizationStub ? item?.organizationStub : item?._id
        history.push('/org/' + slugUpdate);
    }

    const followOrg = (orgId: any) => {
        followUnfollowOrg({
            variables: {
                organizationId: orgId
            }
        })
    }

    return (
        <>
            <DonorProfileLayout />
            <ContentContainer>
                <Heading> Non Profit Organizations </Heading>
                <Storycontent>
                    {orgList.map((item: any, index: any) => {
                        
                        const maxLength = 300;
                        const isReadMoreEnabled = item?.readMoreEnabled || false
                        return (
                            <>
                                <Profittabs>
                                    <OrgImages>
                                        <CardImage src={item?.logoUrl || userAvatar} />
                                    </OrgImages>
                                    <CardDesc>
                                        <Text>
                                            <CardHeading onClick={(e) => onOrgClick(item)} >{item?.name}</CardHeading>
                                            {localStorage.length !== 0 ?
                                                <FollowButton onClick={() => followOrg(item?._id)} >{item?.isFollowing ? "Unfollow" : "Follow"}</FollowButton> : ""}
                                        </Text>
                                        <CardPrice>{item?.city || "---"}</CardPrice>
                                        <CardProgressValue>
                                            <NonProfitDesc>
                                                {!isReadMoreEnabled ? item?.description.slice(0, maxLength) : item?.description}
                                            </NonProfitDesc>
                                            <span key={index} onClick={(e) => handleReadMore(item, index)} style={{ color: "#E6167B", cursor: "pointer" }} >
                                                {(item?.description.length > maxLength && !isReadMoreEnabled) && " ...read more"}
                                                {(item?.description.length > maxLength && isReadMoreEnabled) && " ...read less"}
                                            </span>
                                        </CardProgressValue>
                                    </CardDesc>
                                </Profittabs>
                            </>
                        )
                    })}
                </Storycontent>
            </ContentContainer>
        </>
    );
};

export default OrganizationsListTable;
