import React, { useState } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import Joi from "@hapi/joi";
import { useHistory } from "react-router-dom";
import Button, { ButtonTypes } from "../elements/Button";
import LabeledInput from "../elements/LabeledInput";
import Link from "../elements/Link";
import REGISTER from "../graphql/mutations/register.mutation";
import * as Auth from "../utils/Auth";
import * as Schema from "../utils/Schema";
import * as ErrorUtil from "../utils/ErrorUtil";
import makeEventHandler from "../utils/makeEventHandler";
import AuthLayout, { Content, Row, Text, Footer, Flex, SuccessText } from "../components/AuthLayout";
import Label from "../elements/Label";
import ErrorText from "../elements/ErrorText";
import { useSelector } from "react-redux";
import { MatchstikState } from "../redux/store";
import { TabsLayout, TabsList, TabListItem } from "../pages/Dashboard.page";
import campaignsSrc from "../assets/images/campaignsimage.jpg";
import { setOrganization } from "../redux/actions/user.actions";

enum RegisterTabsEnums {
  Non_Profit = "Non-Profit",
  Corporation = "Corporation",
  Individual = "Individual",
}

export const CampaignsImage = styled.div<any>`
  width: 150px;
  height: 150px;
  background-image: url(${(props) => props.posterUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  @media (max-width: 576px) {
    width: 100%;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
    height: 150px;
  }
`;

const schema = Joi.object({
  firstName: Joi.string()
    .required()
    .error(([error]) => {
      const message = "First name is required";
      return new Error(
        JSON.stringify({
          field: error.path[0],
          message,
        })
      );
    }),
  

   

    email: Schema.user.email().error(([error]) => {
      let message=''
      if(error.code === 'string.empty'){
        message = "Email is required";
      }
      else {
        message = "Email is invalid";
      }
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message
      })
    );
    }),
   
    password: Schema.user.password().error(([error]) => {
      const message = "Password is required";
      return new Error(
        JSON.stringify({
          field: error.path[0],
          message,
        })
      );
    }),
    confirmPassword: Schema.user
      .password()
      .valid(Joi.ref("password"))
      .error(([error]) => {
        const message = "Passwords do not match";
        return new Error(
          JSON.stringify({
            field: error.path[0],
            message,
          })
        );
      }),
  });

type RegisterPageProps = {};

const RegisterPage: React.FC<RegisterPageProps> = () => {
  /* Hooks */
  const history = useHistory();
  const historyState: any = history.location.state;
  const posterUrl = historyState?.posterUrl;
  const prefilledEmail = useSelector(
    (state: MatchstikState) => state.app.emailToRegister
  );
  const tabState = window.sessionStorage.getItem("tabsState");
  /* State */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastname] = useState("");
  const [tabsState, setTabsState] = useState(
    tabState ? tabState : RegisterTabsEnums.Non_Profit
  );

  const [orgName, setOrgName] = useState("");
  const [email, setEmail] = useState(() => {
    if (prefilledEmail) {
      return prefilledEmail;
    } else {
      return "";
    }
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [fieldErrors, setFieldErrorsInternal] = useState({
    firstName: null,
    lastName: null,
    email: null,
    invalidEmail: null,
    password: null,
    confirmPassword: null,
  });
  const [registerNoProfitForm, setRegisterNoProfitForm] = useState(false);
  const [registerIndividualForm, setRegisterIndividualForm] = useState(false);
  const [registerCorporationForm, setRegisterCorporationForm] = useState(false);
  const [formData, setFormData] = useState(false);
  const [active, setActive] = useState(false);

  /* Actions */
  const eventHandler = makeEventHandler(() => setError(""));
  const setFieldErrors = (field: string, message: string | null) => {
    const newFieldErrors: any = {
      [field]: message,
    };
    setFieldErrorsInternal(newFieldErrors);
  };

  React.useEffect(() => {
    onNonProfitDonations();
  }, []);

  const onChangeFirstName = eventHandler((value: string) => {
    setFieldErrors("firstName", null);
    setFirstName(value);
  });

  const onChangeLastName = eventHandler((value: string) => {
    setFieldErrors("lastName", null);
    setLastname(value);
  });

  const onChangeEmail = eventHandler((value: string) => {
    setFieldErrors("email", null);
    setEmail(value);
  });

  const onChangeOrganization = eventHandler((value: string) => {
    setFieldErrors("organization", null);
    setOrgName(value);
  });

  const onChangePassword = eventHandler((value: string) => {
    setFieldErrors("password", null);
    setPassword(value);
  });

  const onChangeConfirmPassword = eventHandler((value: string) => {
    setFieldErrors("confirmPassword", null);
    setConfirmPassword(value);
  });

  /* GraphQL */
  const [registerMutation, { loading }] = useMutation(REGISTER, {
    variables: {
      user: {
        firstName,
        lastName,
        email,
        password,
        organizationType: tabsState,
      },
    },
    onCompleted: async ({ register: { token } }) => {
      await Auth.setToken(token);
      history.push("/verify-email");
    },
    onError: async (error) => {
      const errorMsg = ErrorUtil.getErrorMessage(error);
      setError(errorMsg);
    },
  });

  const register = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    const params = schema.validate({
      firstName,
      email,
      password,
      confirmPassword,
    });
    const { error: schemaError } = params;
    if (schemaError) {
      const { field, message } = JSON.parse(schemaError.message);
      setFieldErrors(field, message);
      return;
    }
    setError("");
    registerMutation();
  };
  const onNonProfitDonations = () => {
    if(firstName || lastName || email || password !== ""){
      setFirstName("");
      setLastname("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setError("");
    }
    setTabsState(RegisterTabsEnums.Non_Profit);
    setRegisterIndividualForm(false);
    setRegisterCorporationForm(false);
    setActive(true);
  };

  const onIndividual = () => {
    if(firstName || lastName || email || password !== ""){
      setFirstName("");
      setLastname("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setError("");
    }
    setTabsState(RegisterTabsEnums.Individual);
    setRegisterNoProfitForm(false);
    setRegisterCorporationForm(false);
    setActive(true);
  };

  const onCorporation = () => {
    if(firstName || lastName || email || password !== ""){
      setFirstName("");
      setLastname("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setError("");
    }
    setTabsState(RegisterTabsEnums.Corporation);
    setRegisterNoProfitForm(false);
    setRegisterIndividualForm(false);
    setActive(true);
  };

  const onNext = () => {
    if (RegisterTabsEnums.Non_Profit && tabsState === RegisterTabsEnums.Non_Profit) {
      setRegisterNoProfitForm(true);
      setTabsState(RegisterTabsEnums.Non_Profit);
      setFormData(true);
      setActive(false);
    } else if (RegisterTabsEnums.Individual && tabsState === RegisterTabsEnums.Individual) {
      setRegisterIndividualForm(true);
      setTabsState(RegisterTabsEnums.Individual);
      setFormData(true);
      setActive(false);
    } else if (RegisterTabsEnums.Corporation && tabsState === RegisterTabsEnums.Corporation) {
      setRegisterCorporationForm(true);
      setTabsState(RegisterTabsEnums.Corporation);
      setFormData(true);
      setActive(false);
    }
  };

  /* Render */
  return (
    <AuthLayout title="Get started" onSubmit={register}>
      <Content>
        {/* content for tabs register new account data */}
        <TabsLayout>
          <TabsList
            onClick={onNonProfitDonations}
            active={tabsState === RegisterTabsEnums.Non_Profit}
          >
            <TabListItem>Non-Profit</TabListItem>
          </TabsList>
          <TabsList
            onClick={onIndividual}
            active={tabsState === RegisterTabsEnums.Individual}
          >
            <TabListItem>Individual</TabListItem>
          </TabsList>
          <TabsList
            onClick={onCorporation}
            active={tabsState === RegisterTabsEnums.Corporation}
          >
            <TabListItem>Corporation</TabListItem>
          </TabsList>
        </TabsLayout>
        {/* {tabsState === RegisterTabsEnums.Non_Profit || 
                 tabsState === RegisterTabsEnums.Individual || 
                 tabsState === RegisterTabsEnums.Corporation ?  */}
        {registerNoProfitForm || registerIndividualForm || registerCorporationForm ? (
          <>
            <Row>
              {registerNoProfitForm && <Text>Please enter your account administrator details</Text>}
              {registerIndividualForm && <Text>Please enter your donor account details</Text>}
              {registerCorporationForm && <Text>Please enter your account administrator details</Text>}
            </Row>
            <Row>
              <Flex flex="1" style={{ width: "48%", marginRight: "10px" }}>
                <LabeledInput
                  autoFocus
                  // label="First Name"
                  placeholder="Your first name"
                  value={firstName}
                  onChange={onChangeFirstName}
                  error={fieldErrors["firstName"]}
                />
              </Flex>
              <Flex flex="1" style={{ width: "48%" }}>
                <LabeledInput
                  // label="Last Name"
                  placeholder="Your last name"
                  value={lastName}
                  onChange={onChangeLastName}
                 
                />
              </Flex>
            </Row>

            <Label text="Email addresss" />
            <Row>
              <LabeledInput
                // label="Email"
                placeholder="Your email"
                value={email}
                onChange={onChangeEmail}
                error={fieldErrors["email"] ? fieldErrors["email"] : fieldErrors["invalidEmail"]}
              />
            </Row>
            <Label text="Choose a password" />
            <Row>
              <LabeledInput
                // label="Password"
                placeholder="Enter password"
                value={password}
                type="password"
                onChange={onChangePassword}
                error={fieldErrors["password"]}
              />
            </Row>
            <Label text="Confirm your password" />
            <Row>
              <LabeledInput
                // label="Confirm Password"
                placeholder="Re-enter password"
                value={confirmPassword}
                type="password"
                onChange={onChangeConfirmPassword}
                error={fieldErrors["confirmPassword"]}
              />
            </Row>
            <p style={{ color: "red" }}>{error}</p>
            <Button
              type={ButtonTypes.Submit}
              onClick={() => register()}
              loading={loading}
              text="Register"
              margin="25px 0 0"
            />
          </>
        ) : (
          ""
        )}

        {(tabsState === RegisterTabsEnums.Non_Profit && active) ||
        (tabsState === RegisterTabsEnums.Individual && active) ||
        (tabsState === RegisterTabsEnums.Corporation && active) ? (
          <>
            {/* {tabsState === RegisterTabsEnums.Individual && <CampaignsImage id="hello" posterUrl={posterUrl ? posterUrl : bannerImage} />} */}
            {/* {tabsState === RegisterTabsEnums.Corporation && <CampaignsImage id="hello" posterUrl={posterUrl ? posterUrl : defaultCampaignImage} />} */}

            {tabsState === RegisterTabsEnums.Non_Profit && (
              <>
                <b>
                  Welcome to Matchstik, A social network entirely focused on viral philanthropic impact.  With Matchstik, 
                  build community through your passions & mission, igniting a network of good to follow and support the 
                  positive change you're driving forward. Get started by setting up your profile which will spotlight your 
                  mission and impact thousands of new donors. From there, invite your existing donor network to follow and 
                  support your campaigns.
                </b>
              </>
            )}

            {tabsState === RegisterTabsEnums.Individual && (
              <>
                <b>
                  Welcome to Matchstik, A social network entirely focused on viral philanthropic impact.  With Matchstik, 
                  build community through your passions & mission, igniting a network of good to follow and support the 
                  positive change you're driving forward. As a user you're able to connect with non profits, engage with 
                  their community of donors, and actively support any cause you are passionate about. Start with your feed, 
                  an active flow of change makers, donate if you can and if not, follow and share the missions you care about.
                </b>
              </>
            )}

            {tabsState === RegisterTabsEnums.Corporation && (
              <>
                <b>
                  Welcome to Matchstik, A social network entirely focused on viral philanthropic impact.  With Matchstik, 
                  build community through your passions & mission, igniting a network of good to follow and support the 
                  positive change you're driving forward. As an organization, your endorsement and support carry impact far 
                  beyond financial contributions. Use Matchstik to spotlight your philanthropic and social impact programs 
                  to build new levels of engagement with your employees, consumers, and infinitely many more brand stakeholders.
                </b>
              </>
            )}
            <Button
              type={ButtonTypes.Submit}
              onClick={() => onNext()}
              loading={loading}
              text="Next"
              margin="25px 0 0"
            />
          </>
        ) : (
          ""
        )}

        {/* {tabsState === RegisterTabsEnums.Individual &&
            <>
            <h2>Register Individual Form</h2>
            <CampaignsImage id="hello" posterUrl={posterUrl ? posterUrl : campaignsSrc} />
            {/* <Button
            type={ButtonTypes.Submit}
            onClick={() => register()}
            loading={loading}
            text="Register"
            margin="25px 0 0"
          />  

          <Button
            type={ButtonTypes.Submit}
            onClick={() => onNext()}
            loading={loading}
            text="Next"
            margin="25px 0 0"
          />
            </>} */}

        {/* {tabsState === RegisterTabsEnums.Corporation &&
            <>
            <h2>Register Corporation Form</h2>
            <CampaignsImage id="hello" posterUrl={posterUrl ? posterUrl : campaignsSrc} />
            <Button
            type={ButtonTypes.Submit}
            onClick={() => register()}
            loading={loading}
            text="Register"
            margin="25px 0 0"
          />
            </>} */}

       
        <Footer>
          <Row>
            <Text>Already have an account?</Text>&nbsp;
            <Link to="/login">Login</Link>
          </Row>
        </Footer>
      </Content>
    </AuthLayout>
  );
};

export default RegisterPage;
