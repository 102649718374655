import {
  AppActionTypes,
  AppActionCreatorTypes,
  SetDonationIdAction,
  SetMembershipIdAction,
  PushModalAction,
  PopModalAction,
  PushModalConfirmActionAction,
  SetFileUploadAction,
  StartFileUploadAction,
  FinishFileUploadAction,
  SetSaveChangesAction,
  SetAppLoadingAction,
  SetShowFirstLoginModalAction,
  SetEmailToRegisterAction,
  CancelEndDateAction,
  SetDefaultCampaignAction,
  SetDeleteCampaignAction,
} from "../actions/app.actions";
import { ModalTypes, urlSafeModalTypes } from "../../components/modal/Modal";
import UrlParams from "../../models/interfaces/UrlParams";
import * as UrlUtil from "../../utils/UrlUtil";
import IConfirmAction from "../../models/interfaces/IConfirmAction";
import ISaveChanges from "../../models/interfaces/ISaveChanges";
import IFileUpload from "../../models/interfaces/IFileUpload";
import saveChangesState from "../../models/states/saveChanges.state";
import fileUploadState from "../../models/states/fileUpload.state";
import { string } from "@hapi/joi";

type ErrorState = {
  errorMsg: string | null;
  show: boolean;
};

type DeleteCampaignState = {
  deleteCampaign: boolean;
  isDefault: boolean;
};

const deleteCampaignState = (): DeleteCampaignState => {
  return {
    deleteCampaign: false,
    isDefault: false
  };
};

const errorState = (): ErrorState => {
  return {
    errorMsg: null,
    show: false,
  };
};

const confirmActionState = (): IConfirmAction => {
  return {
    title: "",
    message: "",
    confirm: null,
    cancel: null,
  };
};

export type AppReducerState = {
  donationId: string;
  membershipId: string;
  modals: ModalTypes[];
  fileUpload: IFileUpload;
  error: ErrorState;
  saveChanges: ISaveChanges;
  confirmAction: IConfirmAction;
  intercomEnabled: boolean;
  loading: boolean;
  shownFirstLoginModal: boolean;
  emailToRegister: string | null;
  stripeCustomerId: string;
  paymentStatus: string;
  showEndDate: boolean;
  defaultCampaign: boolean;
  deleteCampaignAction: DeleteCampaignState;
};

function appReducerState(): AppReducerState {
  const { query } = UrlUtil.parse(window.location.toString());
  const {
    donationId = "",
    modalType = "",
    membershipId = "",
  }: UrlParams = query;

  const isSafeModal = Object.values(urlSafeModalTypes).includes(
    modalType as ModalTypes
  );
  const modals = modalType && isSafeModal ? [modalType as ModalTypes] : [];

  if (modalType && !isSafeModal) {
    UrlUtil.setQueryString({ modalType: null });
  }

  return {
    donationId,
    membershipId,
    modals,
    fileUpload: fileUploadState(),
    error: errorState(),
    saveChanges: saveChangesState(),
    confirmAction: confirmActionState(),
    intercomEnabled: false,
    loading: false,
    shownFirstLoginModal: false,
    emailToRegister: '',
    stripeCustomerId: '',
    paymentStatus: '',
    showEndDate: false,
    defaultCampaign: false,
    deleteCampaignAction: deleteCampaignState(),
  };
}

export default function reducer(
  state = appReducerState(),
  action: AppActionCreatorTypes
) {
  const { type, payload } = action;

  switch (type) {
    case AppActionTypes.CANCEL_END_DATE:
      return setCancelEndDate(state, payload as CancelEndDateAction["payload"]);
    case AppActionTypes.SET_DEFAULT_CAMPAIGN:
      return setDefaultCampaign(state, payload as SetDefaultCampaignAction["payload"]);
      case AppActionTypes.SET_DELETE_CAMPAIGN:
        return setDeleteCampaignAction(state, payload as SetDeleteCampaignAction["payload"]);
    case AppActionTypes.SET_DONATION_ID:
      return setDonationId(state, payload as SetDonationIdAction["payload"]);
    case AppActionTypes.SET_MEMBERSHIP_ID:
      return setSelectedMembership(
        state,
        payload as SetMembershipIdAction["payload"]
      );
    case AppActionTypes.PUSH_MODAL:
      return pushModal(state, payload as PushModalAction["payload"]);

    case AppActionTypes.POP_MODAL:
      return popModal(state, payload as PopModalAction["payload"]);

    case AppActionTypes.PUSH_MODAL_CONFIRM_ACTION:
      return pushModalConfirmAction(
        state,
        payload as PushModalConfirmActionAction["payload"]
      );

    case AppActionTypes.SET_FILE_UPLOAD:
      return setFileUpload(state, payload as SetFileUploadAction["payload"]);

    case AppActionTypes.START_FILE_UPLOAD:
      return startFileUpload(
        state,
        payload as StartFileUploadAction["payload"]
      );

    case AppActionTypes.FINISH_FILE_UPLOAD:
      return finishFileUpload(
        state,
        payload as FinishFileUploadAction["payload"]
      );

    case AppActionTypes.SET_SAVE_CHANGES:
      return setSaveChanges(state, payload as SetSaveChangesAction["payload"]);

    case AppActionTypes.SET_APP_LODING:
      return setLoading(state, payload as SetAppLoadingAction["payload"]);

    case AppActionTypes.SET_SHOWFIRST_LOGINMODAL:
      return setShownFirstLoginModal(
        state,
        payload as SetShowFirstLoginModalAction["payload"]
      );

    case AppActionTypes.SET_EMAIL_TO_REGISTER:
      return setEmailToRegister(
        state,
        payload as SetEmailToRegisterAction["payload"]
      );

    default:
      return state;
  }
}

/********************************************************************************
 *  Set Id
 *******************************************************************************/
function setCancelEndDate(
  state: AppReducerState,
  { showEndDate }: { showEndDate?: any }
): AppReducerState {
  return {
    ...state,
    showEndDate,
  };
}

function setDefaultCampaign(
  state: AppReducerState,
  { defaultCampaign }: { defaultCampaign?: any }
): AppReducerState {
  return {
    ...state,
    defaultCampaign,
  };
}


function setDeleteCampaignAction(
  state: AppReducerState,
  deleteCampaignAction: Partial<DeleteCampaignState>
): AppReducerState {
  return {
    ...state,
    deleteCampaignAction:{
      ...state.deleteCampaignAction,
      ...deleteCampaignAction,
    }
  };
}

function setDonationId(
  state: AppReducerState,
  { donationId }: { donationId: string }
): AppReducerState {
  UrlUtil.setQueryString({ donationId });
  return {
    ...state,
    donationId,
  };
}

function setSelectedMembership(
  state: AppReducerState,
  { membershipId, stripeCustomerId, paymentStatus }: { membershipId: string, stripeCustomerId?: any, paymentStatus?: any }
): AppReducerState {
  UrlUtil.setQueryString({ membershipId });
  return {
    ...state,
    membershipId,
    stripeCustomerId,
    paymentStatus
  };
}

/********************************************************************************
 *  Modal
 *******************************************************************************/

function pushModal(
  state: AppReducerState,
  { modalType }: { modalType: ModalTypes }
): AppReducerState {
  let modals = [...state.modals];
  UrlUtil.setQueryString({ modalType });
  modals.push(modalType);

  return {
    ...state,
    modals,
  };
}

function popModal(
  state: AppReducerState,
  payload: PopModalAction["payload"]
): AppReducerState {
  UrlUtil.setQueryString({ modalType: null });
  let modals = [...state.modals];
  modals.pop();

  return {
    ...state,
    modals,
  };
}

function pushModalConfirmAction(
  state: AppReducerState,
  { confirmAction }: { confirmAction: IConfirmAction }
): AppReducerState {
  state = pushModal(state, { modalType: ModalTypes.ConfirmAction });
  state.confirmAction = confirmAction;
  return state;
}

/********************************************************************************
 *  File Upload
 *******************************************************************************/

function setFileUpload(
  state: AppReducerState,
  fileUpload: Partial<IFileUpload>
): AppReducerState {
  return {
    ...state,
    fileUpload: {
      ...state.fileUpload,
      ...fileUpload,
    },
  };
}

function startFileUpload(
  state: AppReducerState,
  { blob, key }: { blob: string; key: string }
): AppReducerState {
  const modalType = ModalTypes.ImageCropper;
  state = pushModal(state, { modalType });
  const fileUpload = { ...state.fileUpload };
  fileUpload.keys.push(key);
  fileUpload.blob = blob;

  return {
    ...state,
    fileUpload: {
      ...state.fileUpload,
      ...fileUpload,
    },
  };
}

function finishFileUpload(
  state: AppReducerState,
  { key }: { key: string }
): AppReducerState {
  const fileUpload = {
    keys: state.fileUpload.keys.filter((stateKey) => stateKey !== key),
  };

  return {
    ...state,
    fileUpload: {
      ...state.fileUpload,
      ...fileUpload,
    },
  };
}

/********************************************************************************
 *  Save Changes
 *******************************************************************************/

function setSaveChanges(
  state: AppReducerState,
  saveChanges: Partial<ISaveChanges>
): AppReducerState {
  return {
    ...state,
    saveChanges: {
      ...state.saveChanges,
      ...saveChanges,
    },
  };
}

/********************************************************************************
 *  Set Loading
 *******************************************************************************/

function setLoading(
  state: AppReducerState,
  { loading }: { loading: boolean }
): AppReducerState {
  return {
    ...state,
    loading,
  };
}

function setShownFirstLoginModal(
  state: AppReducerState,
  { shownFirstLoginModal }: { shownFirstLoginModal: boolean }
): AppReducerState {
  return {
    ...state,
    shownFirstLoginModal,
  };
}

function setEmailToRegister(
  state: AppReducerState,
  { emailToRegister }: { emailToRegister: string | null }
): AppReducerState {
  return {
    ...state,
    emailToRegister,
  };
}
