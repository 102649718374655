import React from 'react';
import styled from 'styled-components';
import { Title } from '../components/DashboardLayout';
import OrganizationsTable from '../components/OrganizationsTable';


const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: fill-available;
`;

type SuperAdminPageProps = {};

const SuperAdminPage: React.FC<SuperAdminPageProps> = ({}) => {

  return (
    <Container>
      <Title>Clients</Title>
      <OrganizationsTable />
    </Container>
  );
};

export default SuperAdminPage;
