import React, { Fragment } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { useMutation } from "@apollo/react-hooks";
import { Colors } from '../styles/Colors';
import Icon, { Icons } from './Icon';
import Loader, { LoaderSizes } from './Loader';
import * as Polished from 'polished';
import Label from "./Label";
import Flex from '../elements/Flex';
import UPLOAD_FILES from '../graphql/mutations/uploadFiles.mutation';

const Container = styled.div`
  position: relative;
`;

type DropzoneContainerProps = {
  hover: boolean;
  size: ImageUploadSizes;
}

const DropzoneContainer = styled.div<DropzoneContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${props => {
    if (props.size === ImageUploadSizes.Regular) {
      return '148px';
    }
  }};
  height: ${props => {
    if (props.size === ImageUploadSizes.Regular) {
      return '98px';
    }
  }};
  background-color: ${Polished.lighten(0.45, Colors.Pink)};
  border: 1px solid ${Polished.lighten(0.3, Colors.Pink)};
  transition: all 0.2s;
  border-radius: 10px;

  &:hover {
    cursor: ${(props) => (props.hover ? "pointer" : null)};
    background-color: ${Polished.lighten(0.435, Colors.Pink)};
    border: 1px solid ${Polished.lighten(0.2, Colors.Pink)};
  }

  &:focus {
    outline: none;
  }
`;

type DropzoneTextProps = {
  size: ImageUploadSizes;
};

const DropzoneText = styled.div<DropzoneTextProps>`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${Colors.Pink};
  margin-top: ${props => {
    if (props.size === ImageUploadSizes.Regular) {
      return '10px';
    }
  }};
`;

const Pink = styled.span`
  color: ${Colors.Pink};
`;

type PosterImageProps = {
  image?: string;
  size: ImageUploadSizes;
}

const PosterImage = styled.img<PosterImageProps>`
  position: relative;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  max-width: 300px;
  height: auto;
  
  border-radius: 10px;
`;

const ChangeImageText = styled.div`
  position: relative;
  font-size: 1.4rem;
  color: ${Colors.Pink};
  font-weight: 800;
  margin-top: 15px;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const ChangeImage = styled.div`
  &:focus {
    outline: none;
  }
`;

export enum ImageUploadSizes {
  Regular = 'Regular',
}

type ImageUploadProps = {
  imageUrl?: string;
  setImageUrl: (url: string) => void;
  size?: ImageUploadSizes;
  label?: string;
};

const ImageUpload: React.FC<ImageUploadProps> = ({
  imageUrl,
  setImageUrl,
  size = ImageUploadSizes.Regular,
  label,
}) => {
  /** GraphQL */
  const [uploadFiles, { loading }] = useMutation(UPLOAD_FILES, {
    onCompleted: async (data) => {
      const imageUrl = data?.uploadFiles?.[0]?.url;
      setImageUrl(imageUrl);
    },
    onError: (error) => {
   
    }
  });

  /* Hooks */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files: File[]) => {
    
      uploadFiles({
        variables: {
          files: [files[0]],
        },
        onError: (error) => {
         
        }
      });
    },
  });

  /** Render */
  return (
    <Container>
      {label && <Label text={label} />}
      {(() => {
        if (loading) {
          return (
            <DropzoneContainer hover={false} size={size}>
              <Loader size={LoaderSizes.Small} color={Colors.Pink} />
            </DropzoneContainer>
          );
        }

        if (imageUrl) {
          return (
            <Fragment>
              <PosterImage src={imageUrl} size={size} />
              <Flex>
                <ChangeImageText onClick={() => setImageUrl('')}>Remove Image</ChangeImageText>
                <ChangeImage {...getRootProps()}>
                  <input {...getInputProps()} />
                  <ChangeImageText>Change Image</ChangeImageText>
                </ChangeImage>
              </Flex>
            </Fragment>
          );
        }

        return (
          <DropzoneContainer {...getRootProps()} hover={true} size={size}>
            <input {...getInputProps()} />
            <Icon icon={Icons.UpArrow} color={Colors.Pink} size={24} />
            <DropzoneText size={size}>
              Drop file or <Pink>browse</Pink>
            </DropzoneText>
          </DropzoneContainer>
        );
      })()}
    </Container>
  );
};

export default ImageUpload;
