import React from "react";
import { Colors } from "../styles/Colors";
import Icon, { Icons } from "../elements/Icon";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import GET_LIST_MATCH from "../graphql/queries/listMatches.query";
import campaignsSrc from "./../assets/images/campaignsimage.jpg";
import * as Price from '@matchstik/utils/.dist/price';
import {
    CampaignsDesc, CampaignsImage,
    CampaignsInner, CampaignsHeading, CampaignsSection, LeftPanel, Desc, Amount,
    Donors, RightPanel, CampaignsProgress, CampaignsProgressBar, CampaignsList, CampaignsLayout, LeftLayout, CampaignsCard, RightLayout, NoCampaignText,
} from "../pages/CampaignsList.page";
import { ButtonsLayout } from "../pages/Campaigns.page";
import Button, { ButtonTypes, IconPositionTypes } from "../elements/Button";


interface CampaignMatchesListProps { }

const CampaignMatchesList: React.FC<CampaignMatchesListProps> = () => {

    /* State */
    const [showData, setShowData] = React.useState(false);

    /* Action */
    const history = useHistory();
    const historyState: any = history.location.state
    const campaignId = historyState?.campaignId
    const campaignName = historyState?.campaignName
    const posterUrl = historyState?.posterUrl

    const onNewMatch = (() => {
        history.push({
            pathname: '/dashboard/campaigns/matches/new',
            state: { campaignId: campaignId, campaignName: campaignName, posterUrl: posterUrl, showMatch:true }
        });
    });

    const onMatchList = ((match: any) => {  
        history.push({
            pathname: '/dashboard/campaigns/matches/edit',
            state: {campaignId: campaignId, matchId: match._id, campaignName: campaignName, posterUrl: posterUrl, matchName: match.name }
        });
    });

    /** GraphQL */
    const { data } = useQuery(GET_LIST_MATCH, {
        variables: {
            campaignId,
            "onlyActive":false,
            "sortByAmount": false
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setShowData(true);
        }
    });

    const listMatches = data?.listMatch;

    return (
        <>
            <CampaignsLayout>
                <LeftLayout>
                {listMatches?.length > 0 ?
                    <CampaignsCard>
                        {listMatches?.map((match: any) => {
                            let matchAmount = match.matchAmount === null ? "" : match.matchAmount;
                            let matchedAmount = match.matchedAmount === null ? "" : match.matchedAmount;
                            return (
                                <CampaignsList onClick={() => onMatchList(match)} isComplete={match.isComplete} >
                                    <CampaignsImage posterUrl={match.posterUrl ? match.posterUrl : campaignsSrc} />
                                    <CampaignsDesc>
                                        <CampaignsInner>
                                            <CampaignsHeading>{match.name}
                                            {match.isComplete && <Button
                                                    type={ButtonTypes.Button}
                                                    text="COMPLETE"
                                                    border="10px"
                                                    width='100px'
                                                    height='30px'
                                                />}
                                            </CampaignsHeading>
                                            <CampaignsProgress>
                                                <CampaignsProgressBar progress={match.completed + "%"}>
                                                </CampaignsProgressBar>
                                            </CampaignsProgress>
                                            <CampaignsSection>
                                                <LeftPanel>
                                                    <Amount>${Price.output(matchedAmount, true)}</Amount>
                                                    <Desc>raised of ${Price.output(matchAmount, true)} match</Desc>
                                                </LeftPanel>
                                                {match.donors !== null &&
                                                <RightPanel>
                                                    <Donors><Icon icon={Icons.HandHoldingHeart} size={14} color={Colors.Grey2} margin='0px 6px 0px 0px' />{match.donors} donors</Donors>
                                                </RightPanel>}
                                            </CampaignsSection>
                                        </CampaignsInner>
                                    </CampaignsDesc>
                                </CampaignsList>
                            )})}
                    </CampaignsCard>:
                     listMatches?.length === 0 && showData ?
                     <NoCampaignText>No Matches Found </NoCampaignText> : false}

                </LeftLayout>
                <RightLayout>
                    <ButtonsLayout>
                    <Button
                            type={ButtonTypes.Button}
                            text={"Create New Match"}
                            border="10px"
                            height="57px"
                            onClick={onNewMatch}
                            iconPosition={IconPositionTypes.Left}
                            icon={Icons.HandHoldingHeart}
                        />
                    </ButtonsLayout>
                </RightLayout>
            </CampaignsLayout>


        </>
    );
};

export default CampaignMatchesList;

