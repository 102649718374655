import React from 'react';
import styled from 'styled-components';



const Content = styled.div`
    border: 1px solid #f2f2f2;
    padding: 60px 30px;
    border-radius: 10px;
    min-height: calc(100vh - 325px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

const Title = styled.h2`
    font-weight: bold;
    font-size: 28px;
    margin: 0 0 15px 0;
`;

const Text = styled.h3`
    margin: 0;
    font-weight: 500;
    font-size: 20px;
`;


type NotFoundProps = {};

const NotFound: React.FC<NotFoundProps> = () => {


    return (
        <>
            <Content>
                <Title>404</Title>
                <Text>OPPS! PAGE NOT FOUND</Text>
            </Content>
        </>
    );
};

export default NotFound;
