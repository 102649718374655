import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Icons } from '../elements/Icon';
import { Colors } from '../styles/Colors';
import DetailsCard from '../elements/DetailsCard';
import Label from '../elements/Label';
import LabeledInput from "../elements/LabeledInput";
import Flex from "../elements/Flex"
import ColorPicker from '../elements/ColorPicker';
import Menu, { MenuEventTypes } from "../elements/Menu";
import { useMutation, useQuery } from "@apollo/client";
import GET_USER from '../graphql/queries/user.query';
import { DONATION_URL } from "../env";
import Button, { ButtonTypes } from '../elements/Button';
import UPDATE_ORGANIZATION from '../graphql/mutations/updateOrganization.mutation';
import toast, { Toaster } from 'react-hot-toast';
import { successMessages } from '../utils/MessageUtil';
import { useDispatch, useSelector } from 'react-redux';
import * as UserActions from "../redux/actions/user.actions";
import { MatchstikState } from "../redux/store";

const Section = styled.div`
  margin-right: 20px;
  @media (max-width:767px)
  {
   margin-right:0;
   margin-bottom:20px;
  }
`;

const SelectColorContainer = styled.div`
  border: 1px solid ${Colors.Grey5};
  width: 100px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  @media (max-width:767px)
  {
    width:100%;
  }
`;

const SelectColorColor = styled.div`
  height: 26px;
  width: 26px;
  background-color: ${props => props.color};
  border-radius: 5px;
  margin: 0 7px;
  border: 1px solid ${Colors.Grey5};
`;

const SelectColorText = styled.div`
  font-size: 1.2rem;
  color: ${Colors.Blue};
  font-weight: 400;
`;

const ImmutableTextArea = styled.div`
  height: fit-content;
  // width: 360px;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid ${Colors.Grey5};
  @media (max-width:767px)
  {
    width:calc(100% - 30px);
    word-break:break-word;
  }
`;

const Spacer = styled.div`
  height: 25px;
`;

function getEmbedHTML(organizationId: string, text: string, buttonColor: string, color: string) {
  return (`
    <a href="javascript:void(0)" style="text-decoration:none;">
      <img src="/favicon.ico" onerror="window.Matchstik.preload('${organizationId}')" style="display:none;"> 
      <div style="
        background-color:${buttonColor}; 
        color:${color}; 
        font-family: sans-serif;
        border-radius: 100px; 
        height:50px; 
        width:160px; 
        display:inline-flex; 
        align-items:center; 
        justify-content:center; 
        font-weight: 600;
        font-size: 14px;
      "
      onClick="window.Matchstik.open('${organizationId}')">${text}</div>
    </a>
  `);
}
const loginDonorId = localStorage.getItem("donorData")
function getEmbedLink(organizationId: string, donorId: any,) {
  return `${DONATION_URL}?organizationId=${organizationId}`;
}

function getScriptFileLink() {
  return `<script src="${DONATION_URL}/embed.js"></script>`;
}

type CreateDonationButtonProps = {};

const CreateDonationButton: React.FC<CreateDonationButtonProps> = () => {
  const dispatch = useDispatch()
  const getDonor = () => dispatch(UserActions.getDonor());
  const userState: any = useSelector((state: MatchstikState) => state.user);
  const { donor } = userState;

  /** Hooks */
  const [text, setText] = useState("Donate Now");
  const [color, setColor] = useState(Colors.White);
  const [buttonColor, setButtonColor] = useState(Colors.Pink);
  const fontAnchorElement = useRef<any>(null);
  const buttonAnchorElement = useRef<any>(null);

  /* GraphQL */
  const { data, loading } = useQuery(GET_USER)
  const organization = data?.user?.organization;

  /* Mutation for save button */
  const [updateOrganizationMutation, { loading: updateLoading }] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      organization: {
        buttonText: text,
        buttonColor: buttonColor,
        fontColor: color,
      }
    },
    onCompleted: async () => {
      toast.remove();
      toast.success(successMessages.BUTTON_UPDATE);
    }
  });

  useEffect(() => {
    if (Object.keys(donor).length === 0) {
      getDonor();
    };
  }, []);

  React.useEffect(() => {
    if (!loading) {
      setText(organization?.buttonText ? organization?.buttonText : text);
      setButtonColor(organization?.buttonColor ? organization?.buttonColor : buttonColor);
      setColor(organization?.fontColor ? organization?.fontColor : color)
    }
  }, [loading]);

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = `${DONATION_URL}/embed.js`;
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    }
  }, []);


  /* Render */
  if (loading) return null;

  const HTML = getEmbedHTML(data?.user?.organization?._id, text, buttonColor, color);
  const Link = getEmbedLink(data?.user?.organization?._id, donor?._id);

  const ScriptFileLink = getScriptFileLink();

  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <DetailsCard title="Button Design" headerIcon={Icons.Embed} width="600px">
        <Flex>
          <Section>
            <Label text={"Button Preview"} />
            <div dangerouslySetInnerHTML={{ __html: HTML }} />
          </Section>
        </Flex>
        <Spacer />
        <Flex>
          <Section>
            <LabeledInput
              label="Button Text"
              placeholder="Donate Now"
              value={text}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setText(e.currentTarget.value)
              }
            />
          </Section>
          <Section>
            <Label text="Button Color" />
            <SelectColorContainer ref={buttonAnchorElement}>
              <SelectColorColor color={buttonColor} />
              <SelectColorText>{buttonColor.toUpperCase()}</SelectColorText>
            </SelectColorContainer>
            <Menu
              menuHead={
                <ColorPicker
                  color={buttonColor}
                  onChange={({ hex }: { hex: any }) => setButtonColor(hex)}
                />
              }
              anchorElement={buttonAnchorElement}
              openEvent={MenuEventTypes.Click}
              closeEvent={MenuEventTypes.Click}
              width="fit-content"
            />
          </Section>
          <Section>
            <Label text="Font Color" />
            <SelectColorContainer ref={fontAnchorElement}>
              <SelectColorColor color={color} />
              <SelectColorText>{color.toUpperCase()}</SelectColorText>
            </SelectColorContainer>
            <Menu
              menuHead={
                <ColorPicker
                  color={color}
                  onChange={({ hex }: { hex: any }) => setColor(hex)}
                />
              }
              anchorElement={fontAnchorElement}
              openEvent={MenuEventTypes.Click}
              closeEvent={MenuEventTypes.Click}
              width="fit-content"
            />
          </Section>
        </Flex>
        <Spacer />
        <Label
          text={"Paste this code snippet into the <head> of your entire site:"}
        />
        <ImmutableTextArea>
          <div>{'<script src="https://js.stripe.com/v3/"></script>'}</div>
          <div>
            {ScriptFileLink}
          </div>
        </ImmutableTextArea>
        <Spacer />
        <Label text={"Embed this code where you want the button to show up:"} />
        <ImmutableTextArea>{HTML}</ImmutableTextArea>
        <Spacer />
        <Label text={"Or use this link to direct your donors to your donation portal:"} />
        <ImmutableTextArea>{Link}</ImmutableTextArea>

        <Button
          loading={updateLoading}
          type={ButtonTypes.Button}
          text={"Save"}
          border="25px"
          width="100px"
          height="46px"
          margin="10px 0px 10px 5px"
          onClick={updateOrganizationMutation}
        />
      </DetailsCard>
    </>
  );
};

export default CreateDonationButton;



