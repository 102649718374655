import React, { useEffect, useState } from "react";
import styled from "styled-components";
import avatarImg from "../../assets/images/logo-icon.png";
import Icon, { Icons } from "../../elements/Icon";
import { Colors } from "../../styles/Colors";
import DONATION_AND_MATCHES from "../../graphql/queries/donationAndMatches.query";
import * as Price from "@matchstik/utils/.dist/price";
import NoData from "../NoData";
import moment from "moment";
import { useHistory } from "react-router";
import { useQuery } from "@apollo/client";

export enum Visibility {
  Private = "Private",
  Public = "Public",
}

// background-image: url(${(props) => props.src});
// background-repeat: no-repeat;
// background-size: cover;

export const DonationImg = styled.div`
  width: 80px;
  height: 80px;
  // min-width: 80px;
  // min-height: 80px;
  // max-width: 80px;
  // max-height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  z-index: 0;
  justify-content: center;
  border: 1px solid #e0e0e0;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  -webkit-user-select: none;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    width: 50px;
    height: 50px;
  }
`;
export const Image = styled.img`
  position: absolute;
  // clip-path: circle();
  height: 100%;
  width: auto;
`;
export const Donations = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const DateAndTime = styled.div`
  position: "absolute";
`;
const Donationdata = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  padding-top: 10px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    padding-top: 0px;
    .donationitem {
      display: flex;
      gap: 4px;
      flex-direction: column;
    }
  }
`;

export const Donatedlist = styled.div`
  .bold {
    font-weight: 700;
    font-size: 14px;
    font-family: "Nunito Sans", sans-serif;
    line-height: 17px;
  }
`;

export const Donationsdata = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  width:100%;
  @media screen and (max-width: 767px) {
    gap: 10px;
  }
`;

export const Donationtab = styled.div`
  margin: 30px 0;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-family: "Nunito Sans", sans-serif;
    color: #4f4f4f;
    margin-top: 0;

    &.details {
      margin-left: 100px;
      display: flex;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      font-family: "Nunito Sans", sans-serif;
      color: #4f4f4f;
      gap: 5px;
      @media screen and (max-width: 767px) {
        margin-left: 60px;
        margin-top: 10px;
      }
    }
  }
`;

const Description = styled.div`
  // margin-top: 10px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

type DonationAndMatchesProps = {
  donorId: String;
};

const DonationAndMatches: React.FC<DonationAndMatchesProps> = ({ donorId }) => {
  const history = useHistory();
  const [recentDonations, setRecentDonations] = useState([]);
  const { data } = useQuery(DONATION_AND_MATCHES, {
    variables: {
      donorId: donorId,
    },
    onCompleted: (data) => {
      setRecentDonations(data?.recentDonation);
    },
    fetchPolicy: "network-only",
    onError: (error) => { },
  });

  // useEffect(() => {
  //   getRecentDonations();
  // }, [recentDonations]);

  const onNameClick = (item: any) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    history.push("/donor/" + item?.slug);
  };
  const onOrgClick = (item: any) => {
    let slugUpdate = item?.organizationStub
      ? item?.organizationStub
      : item?.organizationId;
    history.push("/org/" + slugUpdate);
  };

  return (
    <>
      {recentDonations.length > 0 ? (
        <Donationtab>
          {recentDonations.map((item: any, index) => {
            let dateAndTime: string = "Unknown";
            const createdAt = item?.meta?.createdAt ?? null;
            if (createdAt) {
              dateAndTime = moment(parseInt(createdAt as any)).format(
                "MMM DD, YYYY"
              );
            }
            return (
              <>
                <div key={index}>
                  {item?.visibility === Visibility.Private ? (
                    <Donations key={index}>
                      <Donationsdata>
                        <DonationImg>
                          <Image src={avatarImg} />
                        </DonationImg>
                        <Donationdata>
                          <div className="donationitem">
                            <Donatedlist>
                              <span style={{ fontWeight: "bold" }}>A Donor</span>{" "}
                              donated to{" "}
                              {/* <span className="bold">
                              {item?.organizationName}
                            </span> */}
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={(e) => onOrgClick(item)}
                                className="bold"
                              >
                                {item?.organizationName}
                              </span>
                            </Donatedlist>
                            <Donatedlist>
                              <span className="bold">
                                ${Price.output(item?.amount || 0, true)}{" "}
                              </span>
                              with an impact of
                              <span className="bold">
                                {" "}
                                ${Price.output(item?.impact || 0, true)}
                              </span>
                            </Donatedlist>
                          </div>
                          <DateAndTime>{dateAndTime}</DateAndTime>
                        </Donationdata>
                      </Donationsdata>
                    </Donations>
                  ) : (
                    <Donations key={index}>
                      <Donationsdata>
                        <DonationImg>
                          {item?.photoUrl ? (
                            <Image src={item?.photoUrl} />
                          ) : (
                            <Image src={avatarImg} />
                          )}
                        </DonationImg>
                        <Donationdata>
                          <div className="donationitem">
                            <Donatedlist>
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                {item?.userFirstName || item?.userLastName ? (
                                  <span
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => onNameClick(item)}
                                  >
                                    {item?.userFirstName} {item?.userLastName}
                                  </span>
                                ) : (
                                  <span>
                                    {"A"} {"New Donor"}
                                  </span>
                                )}
                              </span>{" "}
                              donated to{" "}
                              <span
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={(e) => onOrgClick(item)}
                                className="bold"
                              >
                                {item?.organizationName}
                              </span>
                            </Donatedlist>
                            <Donatedlist>
                              <span className="bold">
                                ${Price.output(item?.amount || 0, true)}{" "}
                              </span>
                              with an impact of
                              <span className="bold">
                                {" "}
                                ${Price.output(item?.impact || 0, true)}
                              </span>
                            </Donatedlist>
                          </div>
                          <DateAndTime>{dateAndTime}</DateAndTime>
                        </Donationdata>
                      </Donationsdata>
                      <p className="details">
                        {item?.message && (
                          <Icon
                            icon={Icons.faQuoteLeft}
                            size={18}
                            top="2px"
                            color={Colors.Grey4}
                          />
                        )}
                        <Description className="details">
                          {item?.message}
                        </Description>
                      </p>
                    </Donations>
                  )}
                </div>
              </>
            );
          })}
        </Donationtab>
      ) : (
        <NoData />
      )}
    </>
  );
};

export default DonationAndMatches;
