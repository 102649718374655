import gql from 'graphql-tag';

const mutation = gql`
  mutation loginToOrganization($organizationId: String!) {
    loginToOrganization(organizationId: $organizationId) {
      user {
        _id
        firstName
        lastName
        email
        createdAt
      }
      token
    }
  }
`;

export default mutation;
