import gql from 'graphql-tag';

const mutation = gql`
mutation followUnfollowSupportingOrgs($organizationId:String!){
    followUnfollowSupportingOrgs(organizationId:$organizationId){
        message
    }
}
`;

export default mutation;
