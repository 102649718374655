import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import IUser from "@matchstik/models/.dist/interfaces/IUser";
export const UserActionTypes = {
  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER: "GET_USER",
  GET_FULL_USER_REQUEST: "GET_FULL_USER_REQUEST",
  SET_USER: "SET_USER",
  SET_ORGANIZATION: "SET_ORGANIZATION",
  // Errors
  SET_ERROR: "SET_ERROR",
  // Create Stripe Token
  CREATE_STRIPE_TOKEN: "CREATE_STRIPE_TOKEN",
  CREATE_STRIPE_TOKEN_SUCCESS: "CREATE_STRIPE_TOKEN_SUCCESS",
  CREATE_STRIPE_TOKEN_FAILURE: "CREATE_STRIPE_TOKEN_FAILURE",
  // Buy Subscription
  BUY_SUBSCRIPTION: "BUY_SUBSCRIPTION",
  // Loading
  SET_LOADING: "SET_LOADING",
  GET_DONOR_REQUEST: "GET_DONOR_REQUEST",
  SET_DONOR_REQUEST: "SET_DONOR_REQUEST",
  SET_FULL_USER: "SET_FULL_USER"
};

export enum ErrorKeyEnum {
  Global = "Global",
  UserEmail = "UserEmail",
  ConfirmUserEmail = "ConfirmUserEmail",
  UserFullName = "UserFullName",
  UserPhoneNumber = "UserPhoneNumber",
}

export type UserActionCreatorTypes =
   SetUserAction
  | SetOrganizationAction
  | SetErrorAction
  | CreateStripeTokenAction
  | SetLoadingAction
  | SetDonorAction;


/********************************************************************************
 * User
 *******************************************************************************/

export interface GetUserAction {
  type: typeof UserActionTypes.GET_USER_REQUEST;
}

export const getUser = (): GetUserAction => {
  return {
    type: UserActionTypes.GET_USER_REQUEST,
  };
};

export interface SetUserAction {
  type: typeof UserActionTypes.SET_USER;
  payload: IUser| undefined;
}

export const setUser = (payload:IUser| undefined): SetUserAction => {
  return {
    type: UserActionTypes.SET_USER,
    payload,
  };
};

/********************************************************************************
 * Full User
 *******************************************************************************/

 export interface GetFullUserAction {
  type: typeof UserActionTypes.GET_FULL_USER_REQUEST;
}

export const getFullUser = (): GetFullUserAction => {
  return {
    type: UserActionTypes.GET_FULL_USER_REQUEST,
  };
};

export interface SetUserAction {
  type: typeof UserActionTypes.SET_FULL_USER;
  payload: IUser| undefined;
}

export const setFullUser = (payload:IUser| undefined): SetUserAction => {
  return {
    type: UserActionTypes.SET_FULL_USER,
    payload,
  };
};

/********************************************************************************
 *  Get/Set Donor Details
 *******************************************************************************/
export interface GetDonorAction {
  type: typeof UserActionTypes.GET_DONOR_REQUEST;
}

export const getDonor = (): GetDonorAction => {
  return {
    type: UserActionTypes.GET_DONOR_REQUEST,
  };
};

export interface SetDonorAction {
  type: typeof UserActionTypes.SET_DONOR_REQUEST;
  payload: any;
}

export const setDonor = (payload: any): SetDonorAction => {
  return {
    type: UserActionTypes.SET_DONOR_REQUEST,
    payload
  };
};

/********************************************************************************
 *  Set Organization Details
 *******************************************************************************/

export interface SetOrganizationAction {
  type: typeof UserActionTypes.SET_ORGANIZATION,
  payload:IOrganization|undefined
}


export const setOrganization = (payload: IOrganization| undefined): SetOrganizationAction => {
  return {
    type: UserActionTypes.SET_ORGANIZATION,
    payload
  };
};

/********************************************************************************
 *  Set Error
 *******************************************************************************/

export interface SetErrorAction {
  type: typeof UserActionTypes.SET_ERROR;
  payload: {
    key: ErrorKeyEnum;
    errorMsg: string;
  };
}

export function setError(key: ErrorKeyEnum, errorMsg: string): SetErrorAction {
  return {
    type: UserActionTypes.SET_ERROR,
    payload: {
      key,
      errorMsg,
    },
  };
}

/********************************************************************************
 *  Create Stripe Token
 *******************************************************************************/

export interface CreateStripeTokenAction {
  type: typeof UserActionTypes.CREATE_STRIPE_TOKEN;
  payload: {
    subscriptionPlan: string;
  };
}

export function createStripeToken(payload: {
  subscriptionPlan: string;
}): CreateStripeTokenAction {
  return {
    type: UserActionTypes.CREATE_STRIPE_TOKEN,
    payload,
  };
}

/********************************************************************************
 *  Buy Subscription
 *******************************************************************************/

export interface BuySubScriptionAction {
  type: typeof UserActionTypes.BUY_SUBSCRIPTION;
  payload: {
    subscriptionPlan: string;
    cardToken: string;
  };
}

export function buySubscription(payload: {
  subscriptionPlan: string;
  cardToken: string;
}): BuySubScriptionAction {
  return {
    type: UserActionTypes.BUY_SUBSCRIPTION,
    payload,
  };
}

export interface SetLoadingAction {
  type: typeof UserActionTypes.SET_LOADING;
  payload: boolean;
}

export function setLoading(payload: boolean): SetLoadingAction {
  return {
    type: UserActionTypes.SET_LOADING,
    payload,
  };
}
