import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/client";
import StripeButton from "../assets/images/stripe-button.png";
import url from "url";
import PLATFORM_SETTINGS from "../graphql/queries/platformSettings.query";
import IPlatformSettings from "@matchstik/models/.dist/interfaces/IPlatformSettings";
import Label from "../elements/Label";
import { Colors } from "../styles/Colors";
import Icon, { Icons } from "../elements/Icon";
import CONNECT_STRIPE from "../graphql/mutations/connectStripe.mutation";
import { useHistory } from "react-router-dom";
import * as AppActions from "../redux/actions/app.actions";
import { useDispatch } from "react-redux";
import Loader from "../elements/Loader";
import { DropDown } from "./SettingsOrganizationProfile";
import Select from 'react-select';
import Button, { ButtonTypes } from "../elements/Button";
import toast, { Toaster } from 'react-hot-toast';
import ErrorText from "../elements/ErrorText";
import * as ErrorUtil from "../utils/ErrorUtil";
import UPDATE_ORGANIZATION_WITH_ID from "../graphql/mutations/updateOrganizationWithId.mutation";
import UPDATE_ORGANIZATION from "../graphql/mutations/updateOrganization.mutation";
import * as UserActions from "../redux/actions/user.actions";
import { errorMessages, successMessages } from "../utils/MessageUtil";
import GET_USER from "../graphql/queries/user.query";
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import { CoverFeesEnum } from "@matchstik/models/.dist/enums/CoverFeesEnum";
import { CoverFeesPercentEnum } from "@matchstik/models/.dist/enums/CoverFeesPercentEnum";


interface IPlatformSettingsData {
  platformSettings: IPlatformSettings;
}

const Container = styled.div`
  position: relative;

`;

const ButtonImg = styled.img`
  cursor: pointer;
  width: 230px;
  height: auto;
`;

const Text = styled.div`
  font-size: 1.4rem;
  color: ${Colors.Grey2};
  font-weight: 500;
  margin-top: 20px;
  line-height: 160%;
  padding: 0 20px 0 0;
`;

const HasStripe = styled.div`
  font-size: 1.8rem;
  color: ${Colors.Green};
  font-weight: 600;
  line-height: 160%;
  display: flex;
  height: 100%;
  min-height: 50px;
  padding-right: 15px;
`;

const LoaderContainer = styled.div`
  width: 400px;
  height: 70px;
  margin-top: 30px;
  justify-content: center;
  display: flex;
`;

const Spacer = styled.div`
  flex: 0.03;
`;

const SecondContainer = styled.div`
  display: flex;
  @media screen and (max-width: 767px) {
    width: 100%;
    display: block;
  }
`;

const LeftSection = styled.div`
  width: 50%;
  height:100%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const RightSection = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: 100%;
  position: releative;
  .error{
    position: absolute;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  position: releative;
  .error{
    position: absolute;
  }
`;


type ConnectStripeProps = {
  stripeConnectId?: string;
  supportingOrg?: string;
  orgsArray?: any;
  selectedOrg?: any;
  setSelectedOrg?: Function | any;
};

type styledselectprops = {
  hasError: any;
}

const StyledSelect = styled(Select) <styledselectprops>`
div[class*="-control"]{
  height: 45px;
  border:${props => props.hasError ? "1px solid red" : "1px solid #E0E0E0"} !important;
}`;

const colourStyles = {
  menu: (base: any) => ({
    ...base,
    border: "1px solid #e0e0e0",
    height: "auto",
    position: "absolute",
    top: "2px"
  }),
  menuPortal: (base: any) => ({  // to popout dropdown options outside overflow
    ...base,
    zIndex: 9999
  })
};

/* Radio Button */

const RadioButtons = styled.div`
  text-align: left;
`;

const Checkbox = styled.input`
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
`;
type CheckBox = {
  checked?: boolean;
  disabled?: boolean;
}
const Labels = styled.label<CheckBox>`
    position: relative;
    padding: 0 0 0 26px;
    cursor: pointer;
    word-spacing: 2px;
    line-height: 20px;
    &:before {
        content:'';
        -webkit-appearance: none;
        background-color: transparent;
        border: 2px solid ${props => props.checked ? Colors.Pink : '#ddd'};
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 1px;
    }
    &:after {
      content: "";
      display:  ${props => props.checked ? 'block' : 'none'};
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 0;
      background: ${Colors.Pink};
      position: absolute;
      top: 6px;
      left: 4px;
    }
`;

const FormGroup = styled.div`
    display: flex;
    margin: 8px 8px 8px 0;
`;

const ConnectStripe: React.FC<ConnectStripeProps> = ({
  stripeConnectId,
  supportingOrg,
  selectedOrg,
  orgsArray,
  setSelectedOrg
}) => {
  const dispatch = useDispatch();
  const { data } = useQuery<IPlatformSettingsData>(PLATFORM_SETTINGS);
  const [isClicked, setIsClicked] = useState(false);
  const history = useHistory();
  const stripeClientId = data?.platformSettings.stripeClientId ?? null;
  const stripeRedirectUrl = data?.platformSettings.stripeRedirectUrl ?? null;
  const parsed = url.parse(window.location.toString(), true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState("");
  const [validationError, setValidationError] = React.useState("");
  const [donorFees, setDonorFees] = useState("");
  const connectCode = parsed?.query?.code;

  React.useEffect(() => {
    if (connectCode) {
      connectStripe({
        variables: {
          connectCode,
        },
      });
    }
  }, []);

  const onSaveButton = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    if (!selectedOrg) {
      setValidationError("An organization is required.");
      return;
    }
    updateOrganizationWithIdMutation({
      variables: {
        orgId: selectedOrg?.value
      },
    });
  }

  const onCancelButton = () => {
    setValidationError("");
    setSelectedOrg(null);
  }

  const onDonorFees = (value: any) => {
    setDonorFees(value);
  }

  const onSaveCoverFees = (event?: React.FormEvent<HTMLFormElement>) => {
      if (event) {
        event.preventDefault();
      }
      setError("");
      updateOrganizationMutation(); 
  }

  /* GraphQl */
  /* Update Organization */
  const [updateOrganizationMutation] = useMutation(
    UPDATE_ORGANIZATION,
    {
      variables: {
        organization: {
          donorFees
        },
      },
      refetchQueries: [{ query: GET_USER }],
      awaitRefetchQueries: true,
      onCompleted: async () => {
        toast.success(successMessages.UPDATE_ORGANIZATION);
        setError("");
        dispatch(UserActions.getUser());
      },
      onError: async (error: any) => {
        const errorMsg = ErrorUtil.getErrorMessage(error);
        setError(errorMsg);
        // if(error){
        // toast.error(errorMsg);
        // }
      },
    }
  );

  /* Get User */
  const { data: getUser } = useQuery(GET_USER, {
    onCompleted: (data) => {
      const organization: IOrganization = data?.user?.organization;
      if (organization) {
        setDonorFees(organization.donorFees || "");
      }
    },
  });

  const [updateOrganizationWithIdMutation, { loading: updateLoading }] = useMutation(
    UPDATE_ORGANIZATION_WITH_ID,
    {
      refetchQueries: [{ query: GET_USER }],
      onCompleted: () => {
        toast.success(successMessages.SAVE_NONPROFIT_ORGANIZATION);
        setError('');
      },
      onError: async (error: any) => {
        const errorMsg = ErrorUtil.getErrorMessage(error);
        setError(errorMsg);
      },
    }
  );

  const [connectStripe, { loading: savingStripeConnectId }] = useMutation(
    CONNECT_STRIPE,
    {
      onCompleted(data) {
        history.push(`/dashboard/overview`);
        dispatch(AppActions.popModal());
        setTimeout(() => {
          toast.success(successMessages.STRIPE_CONNECT);
        }, 5000);
        setError('');
      },
      onError: async (error: any) => {
          const errorMsg = ErrorUtil.getErrorMessage(error);
           setError(errorMsg);
          toast.error(error.graphQLErrors[0].message);
      }
    }
  );

  const baseLink = "https://connect.stripe.com/oauth/authorize";
  const clientId = `?client_id=${stripeClientId}`;
  const redirectUrl = `&redirect_uri=${stripeRedirectUrl}`;
  const responseType = "&response_type=code";
  const scopes = "&scope=read_write";
  const connectLink = baseLink + clientId + redirectUrl + responseType + scopes;

  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <Container>
        {stripeConnectId ? (
          <>
            <Label text={!supportingOrg ? "Stripe Connection" : "Supporting"} />
            <HasStripe>
              {!supportingOrg ? "Stripe is connected" :
                `You are supporting ${supportingOrg}`}
              <Icon icon={Icons.Check} color={Colors.Green} margin="0 0 0 5px" />
            </HasStripe>
            <Label text={"Donor Fees"} />
              Matchstik has a total 7% fee that is taken on each donation. This fee covers our administrative costs<br /> as well as the Stripe credit card processing fees.
              Some organizations like to allow their donors to <br/> cover all or some of that fee. Select an option that best fits what you'd like your donors to see.<br/>
            <RadioButtons>
              <FormGroup>
                <Checkbox type="checkbox" />
                <Labels checked={donorFees === CoverFeesEnum.TransactionAndAdminFees ? true : false} onClick={() => onDonorFees(CoverFeesEnum.TransactionAndAdminFees)}>
                  <b>{CoverFeesPercentEnum.CoverTransactionAndAdminFees}: </b> Allow the donor the option to increase their donation to <br/> 
                  cover the entire fee. If the donor chooses to cover the fees, their credit card charge will be <br/> increased so that you will receive the entire intended donation amount.<br/></Labels>
              </FormGroup>

              <FormGroup>
                <Checkbox type="checkbox" />
                <Labels checked={donorFees === CoverFeesEnum.TransactionFees ? true : false} onClick={() => onDonorFees(CoverFeesEnum.TransactionFees)}>
                  <b>{CoverFeesPercentEnum.CoverTransactionFees}: </b> Allow the donor the option to cover the Stripe transaction <br/>
                   fees only. If the donor chooses to cover fees, their credit card charge will be increased to cover <br /> the Stripe fee only; the remaining Matchstik admin fee will reduce the donation you receive.<br/></Labels>
              </FormGroup>

              <FormGroup>
                <Checkbox type="checkbox" />
                <Labels checked={donorFees === CoverFeesEnum.NoFees ? true : false} onClick={() => onDonorFees(CoverFeesEnum.NoFees)}>
                  <b>{CoverFeesPercentEnum.CoverNoFees}: </b> Do not provide the option for the donor to cover fees.<br /> 
                  In this case, the amount you receive will be reduced by 7% to cover the Matchstik and Stripe fees.<br/></Labels>
              </FormGroup>
            </RadioButtons>

            <ButtonContainer>
              <Button
                type={ButtonTypes.Submit}
                onClick={() => onSaveCoverFees()}
                loading={loading}
                text="Save Settings"
                margin="20px 0"
                width="150px"
              />
              <ErrorText className="error" margin="45px 0 0 0">{error}</ErrorText>
            </ButtonContainer>
          </>
        ) : (
          <Fragment>
            <>
              {savingStripeConnectId || isClicked ? (
                <>
                  <Text>
                    Stripe is connecting.. please wait, you will be redirected
                    shortly.
                  </Text>
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                </>
              ) : (
                <SecondContainer>
                  <LeftSection>
                    <HasStripe>
                      Are you a nonprofit? Connect your Matchstik account to your Stripe account to get started.
                    </HasStripe>
                    <Label text={!supportingOrg ? "Stripe Connection" : "Supporting"} />
                    <ButtonImg
                      onClick={() => {
                        setIsClicked(true);
                        window.location.replace(connectLink);
                      }}
                      src={StripeButton}
                    />
                    <Spacer />
                    <Text>
                      We use Stripe for payment processing because it is the most
                      trusted payment processing platform in the world. In order to
                      receive donations from Matchstik, you must connect Stripe.
                    </Text>
                  </LeftSection>
                  <RightSection>
                    <HasStripe>
                      Are you using Matchstik to support a nonprofit? Use the dropdown to select the nonprofit that you are supporting and click Save.
                    </HasStripe>
                    <DropDown>
                      <StyledSelect
                        hasError={validationError}
                        maxMenuHeight={150}
                        className="test"
                        value={selectedOrg}
                        onChange={(data: any) => {
                          setSelectedOrg(data)
                        }}
                        placeholder="Organizations"
                        options={orgsArray}
                        styles={colourStyles}
                        menuPortalTarget={document.body}
                      />
                      <ErrorText margin="0 0 0 0">{validationError}</ErrorText>
                    </DropDown>
                    <ButtonContainer>
                      <Button
                        type={ButtonTypes.Submit}
                        onClick={() => onSaveButton()}
                        loading={loading}
                        text="Save"
                        margin="0"
                        width="150px"
                      />
                      <Button
                        type={ButtonTypes.Submit}
                        onClick={() => onCancelButton()}
                        loading={loading}
                        text="Cancel"
                        margin="0"
                        width="150px"
                      />
                      <ErrorText className="error" margin="45px 0 0 0">{error}</ErrorText>
                    </ButtonContainer>
                  </RightSection>
                </SecondContainer>
              )}
            </>
          </Fragment>
        )}
      </Container>
    </>
  );
};

export default ConnectStripe;
