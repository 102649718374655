import gql from 'graphql-tag';

const mutation = gql`
mutation updateCampaign($params: updateCampaignParams!){
    updateCampaign(params:$params){
      _id
    }
  }
`;

export default mutation;
